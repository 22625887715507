import axios, { AxiosResponse, CancelTokenSource } from "axios";
import { InsuranceProduct, ProductBrand } from "../types/insurance/insurance-product";
import { InsuranceProductFamily } from "../types/insurance/insurance-product-family";
import { store } from '../store/store';
import { IataCountry } from "../types/common/iataCountry";

let cancelToken: CancelTokenSource | null = null;
let previousRequestUrl: string | null = null;

export const getProducts = (client_id: number | null, filter?: any): Promise<AxiosResponse<{ count: number; results: InsuranceProduct[]; }>> => {
    const requestUrl = `${API_HREF}client/${client_id}/value-insurances/`;
    // if (cancelToken && previousRequestUrl === requestUrl) {
    //     cancelToken.cancel("Operation canceled due to new request.");
    // }
    // previousRequestUrl = requestUrl;
    // cancelToken = axios.CancelToken.source();
    let handledFilter = {};
    if (filter) {
        handledFilter = { ...filter };
    }
    return axios.get(
        requestUrl,
        {
            params: {
                ...handledFilter,
                limit: 10,
                // agency_id: filter.agency_id || (
                //     (store.getState().app.profile?.is_superuser || store.getState().app.profile?.client_full?.name === "Valeurs Assurances")
                //         ? undefined : store.getState().app.profile?.client) || undefined,
                // agencies_incentive__is_online: "true"
            },
            // cancelToken: cancelToken.token,
            headers: {
                'Authorization': `Token ${localStorage.getItem('token')}`,
                'accept-language': 'fr'
            }
        }
    );
};

export const getProductsExtended = (client_id: number | null, limit: number, offset: number, filter?: any): Promise<AxiosResponse<{ count: number; results: InsuranceProduct[]; }>> => {
    // if (cancelToken) {
    //     cancelToken.cancel("Operation canceled due to new request.");
    // }
    // cancelToken = axios.CancelToken.source();
    let handledFilter = {};
    if (filter) {
        handledFilter = { ...filter };
    }
    return axios.get(
        `${API_HREF}client/${client_id}/value-insurances/`,
        {
            params: {
                limit,
                offset,
                agencies_incentive__agency_id: filter.agency_id || ((store.getState().app.profile?.is_superuser || store.getState().app.profile?.client_full?.name === "Valeurs Assurances") ? undefined : store.getState().app.profile?.client) || undefined,
                ...handledFilter,
                agencies_incentive__is_online: "true"
            },
            // cancelToken: cancelToken.token,
            headers: {
                'Authorization': `Token ${localStorage.getItem('token')}`,
            }
        }
    );
};

export const getProductById = (client_id: number | null, id: number): Promise<AxiosResponse<InsuranceProduct>> => {
    const requestUrl = `${API_HREF}client/${client_id}/value-insurances/${id}/`;
    if (cancelToken && previousRequestUrl === requestUrl) {
        cancelToken.cancel("Operation canceled due to new request.");
    }
    previousRequestUrl = requestUrl;
    cancelToken = axios.CancelToken.source();
    return axios.get(
        requestUrl,
        {
            cancelToken: cancelToken.token,
            headers: {
                'Authorization': `Token ${localStorage.getItem('token')}`,
                'accept-language': 'fr'
            }
        }
    );
};

export const getFamilies = (client_id: number | null, filter?: any): Promise<AxiosResponse<{ count: number; results: InsuranceProductFamily[]; }>> => {
    const requestUrl = `${API_HREF}client/${client_id}/insurances-product-family/`;
    if (cancelToken && previousRequestUrl === requestUrl) {
        cancelToken.cancel("Operation canceled due to new request.");
    }
    previousRequestUrl = requestUrl;
    cancelToken = axios.CancelToken.source();
    let handledFilter: any = {};
    if (filter) {
        handledFilter = { ...filter };
    }
    return axios.get(
        requestUrl,
        {
            params: {
                ...handledFilter,
                limit: handledFilter?.limit || 10
            },
            cancelToken: cancelToken.token,
            headers: {
                'Authorization': `Token ${localStorage.getItem('token')}`,
                'accept-language': 'fr'
            }
        }
    );
};

// PRODUCT BRAND

export const getProductBrands = (client_id: number | null, limit: number, offset: number, filter?: any): Promise<AxiosResponse<{ count: number; results: ProductBrand[]; }>> => {
    // if (cancelToken) {
    //     cancelToken.cancel("Operation canceled due to new request.");
    // }
    // cancelToken = axios.CancelToken.source();
    let handledFilter = {};
    if (filter) {
        handledFilter = { ...filter };
    }
    return axios.get(
        `${API_HREF}client/${client_id}/insurances-brand/`,
        {
            params: {
                limit,
                offset,
                ...handledFilter,
            },
            // cancelToken: cancelToken.token,
            headers: {
                'Authorization': `Token ${localStorage.getItem('token')}`,
            }
        }
    );
};

export const postProductBrand = (client_id: number | null, brand: ProductBrand): Promise<AxiosResponse<ProductBrand>> => {
    if (cancelToken) {
        cancelToken.cancel("Operation canceled due to new request.");
    }
    cancelToken = axios.CancelToken.source();
    return axios.post(
        `${API_HREF}client/${client_id}/insurances-brand/`,
        brand,
        {
            cancelToken: cancelToken.token,
            headers: {
                'Authorization': `Token ${localStorage.getItem('token')}`,
            }
        }
    );
};