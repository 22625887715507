import axios, { AxiosResponse, CancelTokenSource } from "axios";
import { OrganisationCertificate } from "../types/contract/organisationsCertificate";

let cancelToken: CancelTokenSource | null = null;
export const getOrganisationCertificates = (client_id: number | null, limit: number, offset: number, filter?: any): Promise<AxiosResponse<{ count: number; results: OrganisationCertificate[]; }>> => {
    // if (cancelToken) {
    //     cancelToken.cancel("Operation canceled due to new request.");
    // }
    // cancelToken = axios.CancelToken.source();
    let handledFilter = {};
    if (filter) {
        handledFilter = { ...filter };
    }
    return axios.get(
        `${API_HREF}client/${client_id}/value-insurances-certificate/`,
        {
            params: {
                limit,
                offset,
                ...handledFilter,
            },
            // cancelToken: cancelToken.token,
            headers: {
                'Authorization': `Token ${localStorage.getItem('token')}`,
            }
        }
    );
};

export const getOrganisationCertificateById = (client_id: number | null, certificate_id: string | undefined): Promise<AxiosResponse<OrganisationCertificate>> => {
    if (cancelToken) {
        cancelToken.cancel("Operation canceled due to new request.");
    }
    cancelToken = axios.CancelToken.source();
    return axios.get(
        `${API_HREF}client/${client_id}/value-insurances-certificate/${certificate_id}/`,
        {
            params: {},
            cancelToken: cancelToken.token,
            headers: {
                'Authorization': `Token ${localStorage.getItem('token')}`,
            }
        }
    );
};

export const postOrganisationCertificate = (client_id: number | null, certificate: OrganisationCertificate): Promise<AxiosResponse<OrganisationCertificate>> => {
    if (cancelToken) {
        cancelToken.cancel("Operation canceled due to new request.");
    }
    cancelToken = axios.CancelToken.source();
    return axios.post(
        `${API_HREF}client/${client_id}/value-insurances-certificate/`,
        certificate,
        {
            cancelToken: cancelToken.token,
            headers: {
                'Authorization': `Token ${localStorage.getItem('token')}`,
            }
        }
    );
};

export const patchOrganisationCertificate = (client_id: number | null, certificateId: number | undefined, data: any): Promise<AxiosResponse<OrganisationCertificate>> => {
    // if (cancelToken) {
    //     cancelToken.cancel("Operation canceled due to new request.");
    // }
    // cancelToken = axios.CancelToken.source();
    return axios.patch(
        `${API_HREF}client/${client_id}/value-insurances-certificate/${certificateId}/`,
        data,
        {
            // cancelToken: cancelToken.token,
            headers: {
                'Authorization': `Token ${localStorage.getItem('token')}`,
            }
        }
    );
};

export const putOrganisationCertificate = (client_id: number | null, certificate: OrganisationCertificate): Promise<AxiosResponse<OrganisationCertificate>> => {
    if (cancelToken) {
        cancelToken.cancel("Operation canceled due to new request.");
    }
    cancelToken = axios.CancelToken.source();
    return axios.put(
        `${API_HREF}client/${client_id}/value-insurances-certificate/${certificate.id}/`,
        certificate,
        {
            cancelToken: cancelToken.token,
            headers: {
                'Authorization': `Token ${localStorage.getItem('token')}`,
            }
        }
    );
};

export const deleteOrganisationCertificate = (client_id: number | null, certificate_id: number): Promise<AxiosResponse<any>> => {
    if (cancelToken) {
        cancelToken.cancel("Operation canceled due to new request.");
    }
    cancelToken = axios.CancelToken.source();
    return axios.delete(
        `${API_HREF}client/${client_id}/value-insurances-certificate/${certificate_id}/`,
        {
            cancelToken: cancelToken.token,
            headers: {
                'Authorization': `Token ${localStorage.getItem('token')}`,
            }
        }
    );
};

export const downloadOrganisationCertificate = (client_id: number | null, id: number): Promise<AxiosResponse<any>> => {
    if (cancelToken) {
        cancelToken.cancel("Operation canceled due to new request.");
    }
    cancelToken = axios.CancelToken.source();
    return axios.post(
        `${API_HREF}client/${client_id}/value-insurances-certificate/download/`,
        { id },
        {
            responseType: 'blob',
            params: {},
            headers: {
                'Authorization': `Token ${localStorage.getItem('token')}`,
            }
        }
    );
};