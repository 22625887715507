import { Menu, Person } from "@mui/icons-material";
import { Avatar, Button, Grid, IconButton } from "@mui/material";
import React, { FC, ReactElement, useEffect, useState } from "react";
import { Site, SiteLocalized } from "../../types/site/site";
import { getSites } from "../../services/siteService";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { useNavigate } from "react-router-dom";

const EventPage: FC = (): ReactElement => {

    const navigate = useNavigate();

    const client_id = useSelector((state: RootState) => state.app.client_id);
    const current_locale = useSelector((state: RootState) => state.app.current_locale);
    const [site, setSite] = useState<Site | null>(null);
    const [local_index, setLocalIndex] = useState<number>(0);

    useEffect(() => {
        getSites(client_id, 10, 0)
            .then((response) => {
                setSite(response.data.results![0]);
                const local_i = response.data.results![0]?.localization?.findIndex(l => l.locale === current_locale);
                setLocalIndex(local_i > -1 ? local_i : 0);
            })
            .catch((error) => console.log('error', error));
    }, []);



    return (
        <Grid container spacing={2} className="event-page">
            <Grid item xs={12} style={{ background: site?.pictures![0].url || "url(images/bg-log.png)" }}>
                <div>
                    <Grid item xs={1.5}>
                        <img src={site?.logo?.url || "images/VA_logo_blanc.png"} alt="logo" style={{ height: '3em' }} />
                    </Grid>
                    <Grid item xs={9} style={{ display: "flex", justifyContent: "space-evenly" }}>
                        <Button onClick={() => navigate('/insurance/')}>Produits</Button>
                        <Button onClick={() => navigate('/insurance/contracts/')}>Souscriptions</Button>
                        <Button onClick={() => navigate('/insurance/sinisters/')}>Sinistres</Button>
                        <Button onClick={() => navigate('/insurance/bills/')}>Factures</Button>
                    </Grid>
                    <Grid item xs={1.5} style={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
                        <div style={{ display: "flex", alignItems: "center", gap: 10, backgroundColor: "white", borderRadius: "15px", padding: "0 10px", width: "fit-content", height: "fit-content" }}>
                            <IconButton>
                                <Menu style={{ fill: "#23A6F0" }} />
                            </IconButton>
                            <Avatar sx={{ width: 24, height: 24, bgcolor: "#23A6F0" }}>
                                <Person />
                            </Avatar>
                        </div>
                    </Grid>
                </div>

                <Grid className="banner-content" container spacing={2}>
                    <div>
                        <h1>{site?.localization![local_index]?.title || "NOM SITE"}</h1>
                    </div>
                    <div>
                        <p>
                            {
                                site?.localization![local_index]?.description ||
                                "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Eaque quaerat quo, ipsa atque saepe deserunt earum, doloribus sapiente cupiditate quis sunt. Commodi cum deleniti, accusantium amet laudantium aliquid vitae iure suscipit impedit neque. "
                            }
                        </p>
                    </div>
                    <div>
                        <Button variant="contained">Demande de quotation</Button>
                        <Button variant="outlined" onClick={() => navigate('/insurance/')}>Voir mes produits</Button>
                    </div>
                </Grid>

                <div className="mini-site-footer">
                    <ul>
                        {
                            site?.footer_elements?.map((footer, f_i) => {
                                return <li key={`fotter-info-${f_i}`}><a href="">{footer}</a> </li>
                            })
                        }
                        <li><a href="">footer info</a> </li>
                    </ul>
                </div>
            </Grid>
        </Grid>
    );
};

export default EventPage;