import { useTranslation } from 'react-i18next';
import { LockOutlined, MailOutlined, Visibility, VisibilityOff } from "@mui/icons-material";
import { Button, Checkbox, FormControlLabel, Grid, IconButton, InputAdornment, TextField } from "@mui/material";
import { useSnackbar } from "notistack";
import React, { FC, ReactElement, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getPermissions, postLogin, sendMailForgetPassword } from "../../services/commonServices";
import { RootState } from "../../store/store";
import '../../styles/authentification.css';
import { LoadingButton } from '@mui/lab';

const ForgetForm: FC<{ switchPage: (page: string) => void }> = ({ switchPage }): ReactElement => {
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation();

    const [email, setEmail] = React.useState('');
    const [loading, SetLoading] = useState(false);

    const client_id = useSelector((state: RootState) => state.app.client_id);

    const onSendMail = () => {
        SetLoading(true);
        sendMailForgetPassword(client_id, email).then((response) => {
            enqueueSnackbar(t('auth.forgot-email-sent'), {variant: 'success'});
            switchPage("SIGNIN");
        }).catch((error) => {
            SetLoading(false);
            if (error.response) {
                if (error.response.status === 400) {
                    for (const [key, value] of Object.entries(error.response.data)) {
                        if (Array.isArray(value)) {
                            value.map(error => enqueueSnackbar(key === 'non_field_errors' ? error : `${key} : ${error}`, { variant: 'error' }));
                        } else {
                            enqueueSnackbar(key === 'non_field_errors' ? error : `${key} : ${value}`, { variant: 'error' });
                        }
                    }
                } else if (error.response.status === 401 || error.response.status === 403) {
                    enqueueSnackbar(t('errors.permissions'), { variant: 'error' });
                } else if (error.response.status === 500) {
                    enqueueSnackbar(t('errors.servers'), { variant: 'error' });
                }
            } else if (error.request) {
                console.log('error.request : ', error.request);
            } else {
                console.log('Error', error.message);
            }
            console.log('error.config : ', error.config);
        });
    };

    return (
        <>
            <div>
                <h3>{t('auth.forget-password')}</h3>
                <p style={{ marginTop: 0 }}>{t('auth.forget-password-info')}</p>
            </div>
            <div className="login-form-container-inputs">
                <TextField
                    fullWidth
                    label={t('auth.email')}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <MailOutlined />
                            </InputAdornment>
                        ),
                    }}
                    variant="standard"
                    placeholder={t('auth.email-placeholder')}
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                />
                <div style={{ display: "flex", justifyContent: "flex-end", width: "100%" }}>
                    <Button variant="text" onClick={() => switchPage('SIGNIN')}>{t('auth.go-back-login')}</Button>
                </div>
            </div>
            <LoadingButton loading={loading} color='secondary' variant="contained" onClick={onSendMail}>{t('auth.send-reset-email')}</LoadingButton>
        </>
    );
};

export default ForgetForm;