export const COUNTRIES_PHONE = [
    {
        name: "Afghanistan",
        dial_code: "+93",
        emoji: "🇦🇫",
        code: "AF"
    },
    {
        name: "Aland Islands",
        dial_code: "+358",
        emoji: "🇦🇽",
        code: "AX"
    },
    {
        name: "Albania",
        dial_code: "+355",
        emoji: "🇦🇱",
        code: "AL"
    },
    {
        name: "Algeria",
        dial_code: "+213",
        emoji: "🇩🇿",
        code: "DZ"
    },
    {
        name: "AmericanSamoa",
        dial_code: "+1684",
        emoji: "🇦🇸",
        code: "AS"
    },
    {
        name: "Andorra",
        dial_code: "+376",
        emoji: "🇦🇩",
        code: "AD"
    },
    {
        name: "Angola",
        dial_code: "+244",
        emoji: "🇦🇴",
        code: "AO"
    },
    {
        name: "Anguilla",
        dial_code: "+1264",
        emoji: "🇦🇮",
        code: "AI"
    },
    {
        name: "Antarctica",
        dial_code: "+672",
        emoji: "🇦🇶",
        code: "AQ"
    },
    {
        name: "Antigua and Barbuda",
        dial_code: "+1268",
        emoji: "🇦🇬",
        code: "AG"
    },
    {
        name: "Argentina",
        dial_code: "+54",
        emoji: "🇦🇷",
        code: "AR"
    },
    {
        name: "Armenia",
        dial_code: "+374",
        emoji: "🇦🇲",
        code: "AM"
    },
    {
        name: "Aruba",
        dial_code: "+297",
        emoji: "🇦🇼",
        code: "AW"
    },
    {
        name: "Australia",
        dial_code: "+61",
        emoji: "🇦🇺",
        code: "AU"
    },
    {
        name: "Austria",
        dial_code: "+43",
        emoji: "🇦🇹",
        code: "AT"
    },
    {
        name: "Azerbaijan",
        dial_code: "+994",
        emoji: "🇦🇿",
        code: "AZ"
    },
    {
        name: "Bahamas",
        dial_code: "+1242",
        emoji: "🇧🇸",
        code: "BS"
    },
    {
        name: "Bahrain",
        dial_code: "+973",
        emoji: "🇧🇭",
        code: "BH"
    },
    {
        name: "Bangladesh",
        dial_code: "+880",
        emoji: "🇧🇩",
        code: "BD"
    },
    {
        name: "Barbados",
        dial_code: "+1246",
        emoji: "🇧🇧",
        code: "BB"
    },
    {
        name: "Belarus",
        dial_code: "+375",
        emoji: "🇧🇾",
        code: "BY"
    },
    {
        name: "Belgium",
        dial_code: "+32",
        emoji: "🇧🇪",
        code: "BE"
    },
    {
        name: "Belize",
        dial_code: "+501",
        emoji: "🇧🇿",
        code: "BZ"
    },
    {
        name: "Benin",
        dial_code: "+229",
        emoji: "🇧🇯",
        code: "BJ"
    },
    {
        name: "Bermuda",
        dial_code: "+1441",
        emoji: "🇧🇲",
        code: "BM"
    },
    {
        name: "Bhutan",
        dial_code: "+975",
        emoji: "🇧🇹",
        code: "BT"
    },
    {
        name: "Bolivia, Plurinational State of",
        dial_code: "+591",
        emoji: "🇧🇴",
        code: "BO"
    },
    {
        name: "Bosnia and Herzegovina",
        dial_code: "+387",
        emoji: "🇧🇦",
        code: "BA"
    },
    {
        name: "Botswana",
        dial_code: "+267",
        emoji: "🇧🇼",
        code: "BW"
    },
    {
        name: "Brazil",
        dial_code: "+55",
        emoji: "🇧🇷",
        code: "BR"
    },
    {
        name: "British Indian Ocean Territory",
        dial_code: "+246",
        emoji: "🇮🇴",
        code: "IO"
    },
    {
        name: "Brunei Darussalam",
        dial_code: "+673",
        emoji: "🇧🇳",
        code: "BN"
    },
    {
        name: "Bulgaria",
        dial_code: "+359",
        emoji: "🇧🇬",
        code: "BG"
    },
    {
        name: "Burkina Faso",
        dial_code: "+226",
        emoji: "🇧🇫",
        code: "BF"
    },
    {
        name: "Burundi",
        dial_code: "+257",
        emoji: "🇧🇮",
        code: "BI"
    },
    {
        name: "Cambodia",
        dial_code: "+855",
        emoji: "🇰🇭",
        code: "KH"
    },
    {
        name: "Cameroon",
        dial_code: "+237",
        emoji: "🇨🇲",
        code: "CM"
    },
    {
        name: "Canada",
        dial_code: "+1",
        emoji: "🇨🇦",
        code: "CA"
    },
    {
        name: "Cape Verde",
        dial_code: "+238",
        emoji: "🇨🇻",
        code: "CV"
    },
    {
        name: "Cayman Islands",
        dial_code: "+345",
        emoji: "🇰🇾",
        code: "KY"
    },
    {
        name: "Central African Republic",
        dial_code: "+236",
        emoji: "🇨🇫",
        code: "CF"
    },
    {
        name: "Chad",
        dial_code: "+235",
        emoji: "🇹🇩",
        code: "TD"
    },
    {
        name: "Chile",
        dial_code: "+56",
        emoji: "🇨🇱",
        code: "CL"
    },
    {
        name: "China",
        dial_code: "+86",
        emoji: "🇨🇳",
        code: "CN"
    },
    {
        name: "Christmas Island",
        dial_code: "+61",
        emoji: "🇨🇽",
        code: "CX"
    },
    {
        name: "Cocos (Keeling) Islands",
        dial_code: "+61",
        emoji: "🇨🇨",
        code: "CC"
    },
    {
        name: "Colombia",
        dial_code: "+57",
        emoji: "🇨🇴",
        code: "CO"
    },
    {
        name: "Comoros",
        dial_code: "+269",
        emoji: "🇰🇲",
        code: "KM"
    },
    {
        name: "Congo",
        dial_code: "+242",
        emoji: "🇨🇬",
        code: "CG"
    },
    {
        name: "Congo, The Democratic Republic of the Congo",
        dial_code: "+243",
        emoji: "🇨🇩",
        code: "CD"
    },
    {
        name: "Cook Islands",
        dial_code: "+682",
        emoji: "🇨🇰",
        code: "CK"
    },
    {
        name: "Costa Rica",
        dial_code: "+506",
        emoji: "🇨🇷",
        code: "CR"
    },
    {
        name: "Cote d'Ivoire",
        dial_code: "+225",
        emoji: "🇨🇮",
        code: "CI"
    },
    {
        name: "Croatia",
        dial_code: "+385",
        emoji: "🇭🇷",
        code: "HR"
    },
    {
        name: "Cuba",
        dial_code: "+53",
        emoji: "🇨🇺",
        code: "CU"
    },
    {
        name: "Cyprus",
        dial_code: "+357",
        emoji: "🇨🇾",
        code: "CY"
    },
    {
        name: "Czech Republic",
        dial_code: "+420",
        emoji: "🇨🇿",
        code: "CZ"
    },
    {
        name: "Denmark",
        dial_code: "+45",
        emoji: "🇩🇰",
        code: "DK"
    },
    {
        name: "Djibouti",
        dial_code: "+253",
        emoji: "🇩🇯",
        code: "DJ"
    },
    {
        name: "Dominica",
        dial_code: "+1767",
        emoji: "🇩🇲",
        code: "DM"
    },
    {
        name: "Dominican Republic",
        dial_code: "+1849",
        emoji: "🇩🇴",
        code: "DO"
    },
    {
        name: "Ecuador",
        dial_code: "+593",
        emoji: "🇪🇨",
        code: "EC"
    },
    {
        name: "Egypt",
        dial_code: "+20",
        emoji: "🇪🇬",
        code: "EG"
    },
    {
        name: "El Salvador",
        dial_code: "+503",
        emoji: "🇸🇻",
        code: "SV"
    },
    {
        name: "Equatorial Guinea",
        dial_code: "+240",
        emoji: "🇬🇶",
        code: "GQ"
    },
    {
        name: "Eritrea",
        dial_code: "+291",
        emoji: "🇪🇷",
        code: "ER"
    },
    {
        name: "Estonia",
        dial_code: "+372",
        emoji: "🇪🇪",
        code: "EE"
    },
    {
        name: "Ethiopia",
        dial_code: "+251",
        emoji: "🇪🇹",
        code: "ET"
    },
    {
        name: "Falkland Islands (Malvinas)",
        dial_code: "+500",
        emoji: "🇫🇰",
        code: "FK"
    },
    {
        name: "Faroe Islands",
        dial_code: "+298",
        emoji: "🇫🇴",
        code: "FO"
    },
    {
        name: "Fiji",
        dial_code: "+679",
        emoji: "🇫🇯",
        code: "FJ"
    },
    {
        name: "Finland",
        dial_code: "+358",
        emoji: "🇫🇮",
        code: "FI"
    },
    {
        name: "France",
        dial_code: "+33",
        emoji: "🇫🇷",
        code: "FR"
    },
    {
        name: "French Guiana",
        dial_code: "+594",
        emoji: "🇬🇫",
        code: "GF"
    },
    {
        name: "French Polynesia",
        dial_code: "+689",
        emoji: "🇵🇫",
        code: "PF"
    },
    {
        name: "Gabon",
        dial_code: "+241",
        emoji: "🇬🇦",
        code: "GA"
    },
    {
        name: "Gambia",
        dial_code: "+220",
        emoji: "🇬🇲",
        code: "GM"
    },
    {
        name: "Georgia",
        dial_code: "+995",
        emoji: "🇬🇪",
        code: "GE"
    },
    {
        name: "Germany",
        dial_code: "+49",
        emoji: "🇩🇪",
        code: "DE"
    },
    {
        name: "Ghana",
        dial_code: "+233",
        emoji: "🇬🇭",
        code: "GH"
    },
    {
        name: "Gibraltar",
        dial_code: "+350",
        emoji: "🇬🇮",
        code: "GI"
    },
    {
        name: "Greece",
        dial_code: "+30",
        emoji: "🇬🇷",
        code: "GR"
    },
    {
        name: "Greenland",
        dial_code: "+299",
        emoji: "🇬🇱",
        code: "GL"
    },
    {
        name: "Grenada",
        dial_code: "+1473",
        emoji: "🇬🇩",
        code: "GD"
    },
    {
        name: "Guadeloupe",
        dial_code: "+590",
        emoji: "🇬🇵",
        code: "GP"
    },
    {
        name: "Guam",
        dial_code: "+1671",
        emoji: "🇬🇺",
        code: "GU"
    },
    {
        name: "Guatemala",
        dial_code: "+502",
        emoji: "🇬🇹",
        code: "GT"
    },
    {
        name: "Guernsey",
        dial_code: "+44",
        emoji: "🇬🇬",
        code: "GG"
    },
    {
        name: "Guinea",
        dial_code: "+224",
        emoji: "🇬🇳",
        code: "GN"
    },
    {
        name: "Guinea-Bissau",
        dial_code: "+245",
        emoji: "🇬🇼",
        code: "GW"
    },
    {
        name: "Guyana",
        dial_code: "+595",
        emoji: "🇬🇾",
        code: "GY"
    },
    {
        name: "Haiti",
        dial_code: "+509",
        emoji: "🇭🇹",
        code: "HT"
    },
    {
        name: "Holy See (Vatican City State)",
        dial_code: "+379",
        emoji: "🇻🇦",
        code: "VA"
    },
    {
        name: "Honduras",
        dial_code: "+504",
        emoji: "🇭🇳",
        code: "HN"
    },
    {
        name: "Hong Kong",
        dial_code: "+852",
        emoji: "🇭🇰",
        code: "HK"
    },
    {
        name: "Hungary",
        dial_code: "+36",
        emoji: "🇭🇺",
        code: "HU"
    },
    {
        name: "Iceland",
        dial_code: "+354",
        emoji: "🇮🇸",
        code: "IS"
    },
    {
        name: "India",
        dial_code: "+91",
        emoji: "🇮🇳",
        code: "IN"
    },
    {
        name: "Indonesia",
        dial_code: "+62",
        emoji: "🇮🇩",
        code: "ID"
    },
    {
        name: "Iran, Islamic Republic of Persian Gulf",
        dial_code: "+98",
        emoji: "🇮🇷",
        code: "IR"
    },
    {
        name: "Iraq",
        dial_code: "+964",
        emoji: "🇮🇷",
        code: "IQ"
    },
    {
        name: "Ireland",
        dial_code: "+353",
        emoji: "🇮🇪",
        code: "IE"
    },
    {
        name: "Isle of Man",
        dial_code: "+44",
        emoji: "🇮🇲",
        code: "IM"
    },
    {
        name: "Israel",
        dial_code: "+972",
        emoji: "🇮🇱",
        code: "IL"
    },
    {
        name: "Italy",
        dial_code: "+39",
        emoji: "🇮🇹",
        code: "IT"
    },
    {
        name: "Jamaica",
        dial_code: "+1876",
        emoji: "🇯🇲",
        code: "JM"
    },
    {
        name: "Japan",
        dial_code: "+81",
        emoji: "🇯🇵",
        code: "JP"
    },
    {
        name: "Jersey",
        dial_code: "+44",
        emoji: "🇯🇪",
        code: "JE"
    },
    {
        name: "Jordan",
        dial_code: "+962",
        emoji: "🇯🇴",
        code: "JO"
    },
    {
        name: "Kazakhstan",
        dial_code: "+77",
        emoji: "🇰🇿",
        code: "KZ"
    },
    {
        name: "Kenya",
        dial_code: "+254",
        emoji: "🇰🇪",
        code: "KE"
    },
    {
        name: "Kiribati",
        dial_code: "+686",
        emoji: "🇰🇮",
        code: "KI"
    },
    {
        name: "Korea, Democratic People's Republic of Korea",
        dial_code: "+850",
        emoji: "🇰🇵",
        code: "KP"
    },
    {
        name: "Korea, Republic of South Korea",
        dial_code: "+82",
        emoji: "🇰🇷",
        code: "KR"
    },
    {
        name: "Kuwait",
        dial_code: "+965",
        emoji: "🇰🇼",
        code: "KW"
    },
    {
        name: "Kyrgyzstan",
        dial_code: "+996",
        emoji: "🇰🇬",
        code: "KG"
    },
    {
        name: "Laos",
        dial_code: "+856",
        emoji: "🇱🇦",
        code: "LA"
    },
    {
        name: "Latvia",
        dial_code: "+371",
        emoji: "🇱🇻",
        code: "LV"
    },
    {
        name: "Lebanon",
        dial_code: "+961",
        emoji: "🇱🇧",
        code: "LB"
    },
    {
        name: "Lesotho",
        dial_code: "+266",
        emoji: "🇱🇸",
        code: "LS"
    },
    {
        name: "Liberia",
        dial_code: "+231",
        emoji: "🇱🇷",
        code: "LR"
    },
    {
        name: "Libyan Arab Jamahiriya",
        dial_code: "+218",
        emoji: "🇱🇾",
        code: "LY"
    },
    {
        name: "Liechtenstein",
        dial_code: "+423",
        emoji: "🇱🇮",
        code: "LI"
    },
    {
        name: "Lithuania",
        dial_code: "+370",
        emoji: "🇱🇹",
        code: "LT"
    },
    {
        name: "Luxembourg",
        dial_code: "+352",
        emoji: "🇱🇺",
        code: "LU"
    },
    {
        name: "Macao",
        dial_code: "+853",
        emoji: "🇲🇴",
        code: "MO"
    },
    {
        name: "Macedonia",
        dial_code: "+389",
        emoji: "🇲🇰",
        code: "MK"
    },
    {
        name: "Madagascar",
        dial_code: "+261",
        emoji: "🇲🇬",
        code: "MG"
    },
    {
        name: "Malawi",
        dial_code: "+265",
        emoji: "🇲🇼",
        code: "MW"
    },
    {
        name: "Malaysia",
        dial_code: "+60",
        emoji: "🇲🇾",
        code: "MY"
    },
    {
        name: "Maldives",
        dial_code: "+960",
        emoji: "🇲🇻",
        code: "MV"
    },
    {
        name: "Mali",
        dial_code: "+223",
        emoji: "🇲🇱",
        code: "ML"
    },
    {
        name: "Malta",
        dial_code: "+356",
        emoji: "🇲🇹",
        code: "MT"
    },
    {
        name: "Marshall Islands",
        dial_code: "+692",
        emoji: "🇲🇭",
        code: "MH"
    },
    {
        name: "Martinique",
        dial_code: "+596",
        emoji: "🇲🇶",
        code: "MQ"
    },
    {
        name: "Mauritania",
        dial_code: "+222",
        emoji: "🇲🇷",
        code: "MR"
    },
    {
        name: "Mauritius",
        dial_code: "+230",
        emoji: "🇲🇺",
        code: "MU"
    },
    {
        name: "Mayotte",
        dial_code: "+262",
        emoji: "🇾🇹",
        code: "YT"
    },
    {
        name: "Mexico",
        dial_code: "+52",
        emoji: "🇲🇽",
        code: "MX"
    },
    {
        name: "Micronesia, Federated States of Micronesia",
        dial_code: "+691",
        emoji: "🇫🇲",
        code: "FM"
    },
    {
        name: "Moldova",
        dial_code: "+373",
        emoji: "🇲🇩",
        code: "MD"
    },
    {
        name: "Monaco",
        dial_code: "+377",
        emoji: "🇲🇨",
        code: "MC"
    },
    {
        name: "Mongolia",
        dial_code: "+976",
        emoji: "🇲🇳",
        code: "MN"
    },
    {
        name: "Montenegro",
        dial_code: "+382",
        emoji: "🇲🇪",
        code: "ME"
    },
    {
        name: "Montserrat",
        dial_code: "+1664",
        emoji: "🇲🇸",
        code: "MS"
    },
    {
        name: "Morocco",
        dial_code: "+212",
        emoji: "🇲🇦",
        code: "MA"
    },
    {
        name: "Mozambique",
        dial_code: "+258",
        emoji: "🇲🇿",
        code: "MZ"
    },
    {
        name: "Myanmar",
        dial_code: "+95",
        emoji: "🇲🇲",
        code: "MM"
    },
    {
        name: "Namibia",
        emoji: "🇳🇦",
        dial_code: "+264",
        code: "NA"
    },
    {
        name: "Nauru",
        dial_code: "+674",
        emoji: "🇳🇷",
        code: "NR"
    },
    {
        name: "Nepal",
        dial_code: "+977",
        emoji: "🇳🇵",
        code: "NP"
    },
    {
        name: "Netherlands",
        dial_code: "+31",
        emoji: "🇳🇱",
        code: "NL"
    },
    {
        name: "Netherlands Antilles",
        dial_code: "+599",
        emoji: "🇧🇶",
        code: "AN"
    },
    {
        name: "New Caledonia",
        dial_code: "+687",
        emoji: "🇳🇨",
        code: "NC"
    },
    {
        name: "New Zealand",
        dial_code: "+64",
        emoji: "🇳🇿",
        code: "NZ"
    },
    {
        name: "Nicaragua",
        dial_code: "+505",
        emoji: "🇳🇮",
        code: "NI"
    },
    {
        name: "Niger",
        dial_code: "+227",
        emoji: "🇳🇪",
        code: "NE"
    },
    {
        name: "Nigeria",
        dial_code: "+234",
        emoji: "🇳🇬",
        code: "NG"
    },
    {
        name: "Niue",
        dial_code: "+683",
        emoji: "🇳🇺",
        code: "NU"
    },
    {
        name: "Norfolk Island",
        dial_code: "+672",
        emoji: "🇳🇫",
        code: "NF"
    },
    {
        name: "Northern Mariana Islands",
        dial_code: "+1670",
        emoji: "🇲🇵",
        code: "MP"
    },
    {
        name: "Norway",
        dial_code: "+47",
        emoji: "🇳🇴",
        code: "NO"
    },
    {
        name: "Oman",
        dial_code: "+968",
        emoji: "🇴🇲",
        code: "OM"
    },
    {
        name: "Pakistan",
        dial_code: "+92",
        emoji: "🇵🇰",
        code: "PK"
    },
    {
        name: "Palau",
        dial_code: "+680",
        emoji: "🇵🇼",
        code: "PW"
    },
    {
        name: "Palestinian Territory, Occupied",
        dial_code: "+970",
        emoji: "🇵🇸",
        code: "PS"
    },
    {
        name: "Panama",
        dial_code: "+507",
        emoji: "🇵🇦",
        code: "PA"
    },
    {
        name: "Papua New Guinea",
        dial_code: "+675",
        emoji: "🇵🇬",
        code: "PG"
    },
    {
        name: "Paraguay",
        dial_code: "+595",
        emoji: "🇵🇾",
        code: "PY"
    },
    {
        name: "Peru",
        dial_code: "+51",
        emoji: "🇵🇪",
        code: "PE"
    },
    {
        name: "Philippines",
        dial_code: "+63",
        emoji: "🇵🇭",
        code: "PH"
    },
    {
        name: "Pitcairn",
        dial_code: "+872",
        emoji: "🇵🇳",
        code: "PN"
    },
    {
        name: "Poland",
        dial_code: "+48",
        emoji: "🇵🇱",
        code: "PL"
    },
    {
        name: "Portugal",
        dial_code: "+351",
        emoji: "🇵🇹",
        code: "PT"
    },
    {
        name: "Puerto Rico",
        dial_code: "+1939",
        emoji: "🇵🇷",
        code: "PR"
    },
    {
        name: "Qatar",
        dial_code: "+974",
        emoji: "🇶🇦",
        code: "QA"
    },
    {
        name: "Romania",
        dial_code: "+40",
        emoji: "🇷🇴",
        code: "RO"
    },
    {
        name: "Russia",
        dial_code: "+7",
        emoji: "🇷🇺",
        code: "RU"
    },
    {
        name: "Rwanda",
        dial_code: "+250",
        emoji: "🇷🇼",
        code: "RW"
    },
    {
        name: "Reunion",
        dial_code: "+262",
        emoji: "🇷🇪",
        code: "RE"
    },
    {
        name: "Saint Barthelemy",
        dial_code: "+590",
        emoji: "🇧🇱",
        code: "BL"
    },
    {
        name: "Saint Helena, Ascension and Tristan Da Cunha",
        dial_code: "+290",
        emoji: "🇸🇭",
        code: "SH"
    },
    {
        name: "Saint Kitts and Nevis",
        dial_code: "+1869",
        emoji: "🇰🇳",
        code: "KN"
    },
    {
        name: "Saint Lucia",
        dial_code: "+1758",
        emoji: "🇱🇨",
        code: "LC"
    },
    {
        name: "Saint Martin",
        dial_code: "+590",
        emoji: "🇲🇫",
        code: "MF"
    },
    {
        name: "Saint Pierre and Miquelon",
        dial_code: "+508",
        emoji: "🇵🇲",
        code: "PM"
    },
    {
        name: "Saint Vincent and the Grenadines",
        dial_code: "+1784",
        emoji: "🇻🇨",
        code: "VC"
    },
    {
        name: "Samoa",
        dial_code: "+685",
        emoji: "🇼🇸",
        code: "WS"
    },
    {
        name: "San Marino",
        dial_code: "+378",
        emoji: "🇸🇲",
        code: "SM"
    },
    {
        name: "Sao Tome and Principe",
        dial_code: "+239",
        emoji: "🇸🇹",
        code: "ST"
    },
    {
        name: "Saudi Arabia",
        dial_code: "+966",
        emoji: "🇸🇦",
        code: "SA"
    },
    {
        name: "Senegal",
        dial_code: "+221",
        emoji: "🇸🇳",
        code: "SN"
    },
    {
        name: "Serbia",
        dial_code: "+381",
        emoji: "🇷🇸",
        code: "RS"
    },
    {
        name: "Seychelles",
        dial_code: "+248",
        emoji: "🇸🇨",
        code: "SC"
    },
    {
        name: "Sierra Leone",
        dial_code: "+232",
        emoji: "🇸🇱",
        code: "SL"
    },
    {
        name: "Singapore",
        dial_code: "+65",
        emoji: "🇸🇬",
        code: "SG"
    },
    {
        name: "Slovakia",
        dial_code: "+421",
        emoji: "🇸🇰",
        code: "SK"
    },
    {
        name: "Slovenia",
        dial_code: "+386",
        emoji: "🇸🇮",
        code: "SI"
    },
    {
        name: "Solomon Islands",
        dial_code: "+677",
        emoji: "🇸🇧",
        code: "SB"
    },
    {
        name: "Somalia",
        dial_code: "+252",
        emoji: "🇸🇴",
        code: "SO"
    },
    {
        name: "South Africa",
        dial_code: "+27",
        emoji: "🇿🇦",
        code: "ZA"
    },
    {
        name: "South Sudan",
        dial_code: "+211",
        emoji: "🇸🇸",
        code: "SS"
    },
    {
        name: "South Georgia and the South Sandwich Islands",
        dial_code: "+500",
        emoji: "🇬🇸",
        code: "GS"
    },
    {
        name: "Spain",
        dial_code: "+34",
        emoji: "🇪🇸",
        code: "ES"
    },
    {
        name: "Sri Lanka",
        dial_code: "+94",
        emoji: "🇱🇰",
        code: "LK"
    },
    {
        name: "Sudan",
        dial_code: "+249",
        emoji: "🇸🇩",
        code: "SD"
    },
    {
        name: "Suriname",
        dial_code: "+597",
        emoji: "🇸🇷",
        code: "SR"
    },
    {
        name: "Svalbard and Jan Mayen",
        dial_code: "+47",
        emoji: "🇸🇯",
        code: "SJ"
    },
    {
        name: "Swaziland",
        dial_code: "+268",
        emoji: "🇸🇿",
        code: "SZ"
    },
    {
        name: "Sweden",
        dial_code: "+46",
        emoji: "🇸🇪",
        code: "SE"
    },
    {
        name: "Switzerland",
        dial_code: "+41",
        emoji: "🇨🇭",
        code: "CH"
    },
    {
        name: "Syrian Arab Republic",
        dial_code: "+963",
        emoji: "🇸🇾",
        code: "SY"
    },
    {
        name: "Taiwan",
        dial_code: "+886",
        emoji: "🇹🇼",
        code: "TW"
    },
    {
        name: "Tajikistan",
        dial_code: "+992",
        emoji: "🇹🇯",
        code: "TJ"
    },
    {
        name: "Tanzania, United Republic of Tanzania",
        dial_code: "+255",
        emoji: "🇹🇿",
        code: "TZ"
    },
    {
        name: "Thailand",
        dial_code: "+66",
        emoji: "🇹🇭",
        code: "TH"
    },
    {
        name: "Timor-Leste",
        dial_code: "+670",
        emoji: "🇹🇱",
        code: "TL"
    },
    {
        name: "Togo",
        dial_code: "+228",
        emoji: "🇹🇬",
        code: "TG"
    },
    {
        name: "Tokelau",
        dial_code: "+690",
        emoji: "🇹🇰",
        code: "TK"
    },
    {
        name: "Tonga",
        dial_code: "+676",
        emoji: "🇹🇴",
        code: "TO"
    },
    {
        name: "Trinidad and Tobago",
        dial_code: "+1868",
        emoji: "🇹🇹",
        code: "TT"
    },
    {
        name: "Tunisia",
        dial_code: "+216",
        emoji: "🇹🇳",
        code: "TN"
    },
    {
        name: "Turkey",
        dial_code: "+90",
        emoji: "🇹🇷",
        code: "TR"
    },
    {
        name: "Turkmenistan",
        dial_code: "+993",
        emoji: "🇹🇲",
        code: "TM"
    },
    {
        name: "Turks and Caicos Islands",
        dial_code: "+1649",
        emoji: "🇹🇨",
        code: "TC"
    },
    {
        name: "Tuvalu",
        dial_code: "+688",
        emoji: "🇹🇻",
        code: "TV"
    },
    {
        name: "Uganda",
        dial_code: "+256",
        emoji: "🇺🇬",
        code: "UG"
    },
    {
        name: "Ukraine",
        dial_code: "+380",
        emoji: "🇺🇦",
        code: "UA"
    },
    {
        name: "United Arab Emirates",
        dial_code: "+971",
        emoji: "🇦🇪",
        code: "AE"
    },
    {
        name: "United Kingdom",
        dial_code: "+44",
        emoji: "🇬🇧",
        code: "GB"
    },
    {
        name: "United States",
        dial_code: "+1",
        emoji: "🇺🇸",
        code: "US"
    },
    {
        name: "Uruguay",
        dial_code: "+598",
        emoji: "🇺🇾",
        code: "UY"
    },
    {
        name: "Uzbekistan",
        dial_code: "+998",
        emoji: "🇺🇿",
        code: "UZ"
    },
    {
        name: "Vanuatu",
        dial_code: "+678",
        emoji: "🇻🇺",
        code: "VU"
    },
    {
        name: "Venezuela, Bolivarian Republic of Venezuela",
        dial_code: "+58",
        emoji: "🇻🇪",
        code: "VE"
    },
    {
        name: "Vietnam",
        dial_code: "+84",
        emoji: "🇻🇳",
        code: "VN"
    },
    {
        name: "Virgin Islands, British",
        dial_code: "+1284",
        emoji: "🇻🇬",
        code: "VG"
    },
    {
        name: "Virgin Islands, U.S.",
        dial_code: "+1340",
        emoji: "🇻🇮",
        code: "VI"
    },
    {
        name: "Wallis and Futuna",
        dial_code: "+681",
        emoji: "🇼🇫",
        code: "WF"
    },
    {
        name: "Yemen",
        dial_code: "+967",
        emoji: "🇾🇪",
        code: "YE"
    },
    {
        name: "Zambia",
        dial_code: "+260",
        emoji: "🇿🇲",
        code: "ZM"
    },
    {
        name: "Zimbabwe",
        dial_code: "+263",
        emoji: "🇿🇼",
        code: "ZW"
    }
];

export const COUNTRIES_CURRENCY = [
    {
        countryCode: "AD",
        countryName: "Andorra",
        currencyCode: "EUR"
    },
    {
        countryCode: "AE",
        countryName: "United Arab Emirates",
        currencyCode: "AED"
    },
    {
        countryCode: "AF",
        countryName: "Afghanistan",
        currencyCode: "AFN"
    },
    {
        countryCode: "AG",
        countryName: "Antigua and Barbuda",
        currencyCode: "XCD"
    },
    {
        countryCode: "AI",
        countryName: "Anguilla",
        currencyCode: "XCD"
    },
    {
        countryCode: "AL",
        countryName: "Albania",
        currencyCode: "ALL"
    },
    {
        countryCode: "AM",
        countryName: "Armenia",
        currencyCode: "AMD"
    },
    {
        countryCode: "AO",
        countryName: "Angola",
        currencyCode: "AOA"
    },
    {
        countryCode: "AQ",
        countryName: "Antarctica",
        currencyCode: ""
    },
    {
        countryCode: "AR",
        countryName: "Argentina",
        currencyCode: "ARS"
    },
    {
        countryCode: "AS",
        countryName: "American Samoa",
        currencyCode: "USD"
    },
    {
        countryCode: "AT",
        countryName: "Austria",
        currencyCode: "EUR"
    },
    {
        countryCode: "AU",
        countryName: "Australia",
        currencyCode: "AUD"
    },
    {
        countryCode: "AW",
        countryName: "Aruba",
        currencyCode: "AWG"
    },
    {
        countryCode: "AX",
        countryName: "Åland",
        currencyCode: "EUR"
    },
    {
        countryCode: "AZ",
        countryName: "Azerbaijan",
        currencyCode: "AZN"
    },
    {
        countryCode: "BA",
        countryName: "Bosnia and Herzegovina",
        currencyCode: "BAM"
    },
    {
        countryCode: "BB",
        countryName: "Barbados",
        currencyCode: "BBD"
    },
    {
        countryCode: "BD",
        countryName: "Bangladesh",
        currencyCode: "BDT"
    },
    {
        countryCode: "BE",
        countryName: "Belgium",
        currencyCode: "EUR"
    },
    {
        countryCode: "BF",
        countryName: "Burkina Faso",
        currencyCode: "XOF"
    },
    {
        countryCode: "BG",
        countryName: "Bulgaria",
        currencyCode: "BGN"
    },
    {
        countryCode: "BH",
        countryName: "Bahrain",
        currencyCode: "BHD"
    },
    {
        countryCode: "BI",
        countryName: "Burundi",
        currencyCode: "BIF"
    },
    {
        countryCode: "BJ",
        countryName: "Benin",
        currencyCode: "XOF"
    },
    {
        countryCode: "BL",
        countryName: "Saint Barthélemy",
        currencyCode: "EUR"
    },
    {
        countryCode: "BM",
        countryName: "Bermuda",
        currencyCode: "BMD"
    },
    {
        countryCode: "BN",
        countryName: "Brunei",
        currencyCode: "BND"
    },
    {
        countryCode: "BO",
        countryName: "Bolivia",
        currencyCode: "BOB"
    },
    {
        countryCode: "BQ",
        countryName: "Bonaire",
        currencyCode: "USD"
    },
    {
        countryCode: "BR",
        countryName: "Brazil",
        currencyCode: "BRL"
    },
    {
        countryCode: "BS",
        countryName: "Bahamas",
        currencyCode: "BSD"
    },
    {
        countryCode: "BT",
        countryName: "Bhutan",
        currencyCode: "BTN"
    },
    {
        countryCode: "BV",
        countryName: "Bouvet Island",
        currencyCode: "NOK"
    },
    {
        countryCode: "BW",
        countryName: "Botswana",
        currencyCode: "BWP"
    },
    {
        countryCode: "BY",
        countryName: "Belarus",
        currencyCode: "BYR"
    },
    {
        countryCode: "BZ",
        countryName: "Belize",
        currencyCode: "BZD"
    },
    {
        countryCode: "CA",
        countryName: "Canada",
        currencyCode: "CAD"
    },
    {
        countryCode: "CC",
        countryName: "Cocos [Keeling] Islands",
        currencyCode: "AUD"
    },
    {
        countryCode: "CD",
        countryName: "Democratic Republic of the Congo",
        currencyCode: "CDF"
    },
    {
        countryCode: "CF",
        countryName: "Central African Republic",
        currencyCode: "XAF"
    },
    {
        countryCode: "CG",
        countryName: "Republic of the Congo",
        currencyCode: "XAF"
    },
    {
        countryCode: "CH",
        countryName: "Switzerland",
        currencyCode: "CHF"
    },
    {
        countryCode: "CI",
        countryName: "Ivory Coast",
        currencyCode: "XOF"
    },
    {
        countryCode: "CK",
        countryName: "Cook Islands",
        currencyCode: "NZD"
    },
    {
        countryCode: "CL",
        countryName: "Chile",
        currencyCode: "CLP"
    },
    {
        countryCode: "CM",
        countryName: "Cameroon",
        currencyCode: "XAF"
    },
    {
        countryCode: "CN",
        countryName: "China",
        currencyCode: "CNY"
    },
    {
        countryCode: "CO",
        countryName: "Colombia",
        currencyCode: "COP"
    },
    {
        countryCode: "CR",
        countryName: "Costa Rica",
        currencyCode: "CRC"
    },
    {
        countryCode: "CU",
        countryName: "Cuba",
        currencyCode: "CUP"
    },
    {
        countryCode: "CV",
        countryName: "Cape Verde",
        currencyCode: "CVE"
    },
    {
        countryCode: "CW",
        countryName: "Curacao",
        currencyCode: "ANG"
    },
    {
        countryCode: "CX",
        countryName: "Christmas Island",
        currencyCode: "AUD"
    },
    {
        countryCode: "CY",
        countryName: "Cyprus",
        currencyCode: "EUR"
    },
    {
        countryCode: "CZ",
        countryName: "Czechia",
        currencyCode: "CZK"
    },
    {
        countryCode: "DE",
        countryName: "Germany",
        currencyCode: "EUR"
    },
    {
        countryCode: "DJ",
        countryName: "Djibouti",
        currencyCode: "DJF"
    },
    {
        countryCode: "DK",
        countryName: "Denmark",
        currencyCode: "DKK"
    },
    {
        countryCode: "DM",
        countryName: "Dominica",
        currencyCode: "XCD"
    },
    {
        countryCode: "DO",
        countryName: "Dominican Republic",
        currencyCode: "DOP"
    },
    {
        countryCode: "DZ",
        countryName: "Algeria",
        currencyCode: "DZD"
    },
    {
        countryCode: "EC",
        countryName: "Ecuador",
        currencyCode: "USD"
    },
    {
        countryCode: "EE",
        countryName: "Estonia",
        currencyCode: "EUR"
    },
    {
        countryCode: "EG",
        countryName: "Egypt",
        currencyCode: "EGP"
    },
    {
        countryCode: "EH",
        countryName: "Western Sahara",
        currencyCode: "MAD"
    },
    {
        countryCode: "ER",
        countryName: "Eritrea",
        currencyCode: "ERN"
    },
    {
        countryCode: "ES",
        countryName: "Spain",
        currencyCode: "EUR"
    },
    {
        countryCode: "ET",
        countryName: "Ethiopia",
        currencyCode: "ETB"
    },
    {
        countryCode: "FI",
        countryName: "Finland",
        currencyCode: "EUR"
    },
    {
        countryCode: "FJ",
        countryName: "Fiji",
        currencyCode: "FJD"
    },
    {
        countryCode: "FK",
        countryName: "Falkland Islands",
        currencyCode: "FKP"
    },
    {
        countryCode: "FM",
        countryName: "Micronesia",
        currencyCode: "USD"
    },
    {
        countryCode: "FO",
        countryName: "Faroe Islands",
        currencyCode: "DKK"
    },
    {
        countryCode: "FR",
        countryName: "France",
        currencyCode: "EUR"
    },
    {
        countryCode: "GA",
        countryName: "Gabon",
        currencyCode: "XAF"
    },
    {
        countryCode: "GB",
        countryName: "United Kingdom",
        currencyCode: "GBP"
    },
    {
        countryCode: "GD",
        countryName: "Grenada",
        currencyCode: "XCD"
    },
    {
        countryCode: "GE",
        countryName: "Georgia",
        currencyCode: "GEL"
    },
    {
        countryCode: "GF",
        countryName: "French Guiana",
        currencyCode: "EUR"
    },
    {
        countryCode: "GG",
        countryName: "Guernsey",
        currencyCode: "GBP"
    },
    {
        countryCode: "GH",
        countryName: "Ghana",
        currencyCode: "GHS"
    },
    {
        countryCode: "GI",
        countryName: "Gibraltar",
        currencyCode: "GIP"
    },
    {
        countryCode: "GL",
        countryName: "Greenland",
        currencyCode: "DKK"
    },
    {
        countryCode: "GM",
        countryName: "Gambia",
        currencyCode: "GMD"
    },
    {
        countryCode: "GN",
        countryName: "Guinea",
        currencyCode: "GNF"
    },
    {
        countryCode: "GP",
        countryName: "Guadeloupe",
        currencyCode: "EUR"
    },
    {
        countryCode: "GQ",
        countryName: "Equatorial Guinea",
        currencyCode: "XAF"
    },
    {
        countryCode: "GR",
        countryName: "Greece",
        currencyCode: "EUR"
    },
    {
        countryCode: "GS",
        countryName: "South Georgia and the South Sandwich Islands",
        currencyCode: "GBP"
    },
    {
        countryCode: "GT",
        countryName: "Guatemala",
        currencyCode: "GTQ"
    },
    {
        countryCode: "GU",
        countryName: "Guam",
        currencyCode: "USD"
    },
    {
        countryCode: "GW",
        countryName: "Guinea-Bissau",
        currencyCode: "XOF"
    },
    {
        countryCode: "GY",
        countryName: "Guyana",
        currencyCode: "GYD"
    },
    {
        countryCode: "HK",
        countryName: "Hong Kong",
        currencyCode: "HKD"
    },
    {
        countryCode: "HM",
        countryName: "Heard Island and McDonald Islands",
        currencyCode: "AUD"
    },
    {
        countryCode: "HN",
        countryName: "Honduras",
        currencyCode: "HNL"
    },
    {
        countryCode: "HR",
        countryName: "Croatia",
        currencyCode: "HRK"
    },
    {
        countryCode: "HT",
        countryName: "Haiti",
        currencyCode: "HTG"
    },
    {
        countryCode: "HU",
        countryName: "Hungary",
        currencyCode: "HUF"
    },
    {
        countryCode: "ID",
        countryName: "Indonesia",
        currencyCode: "IDR"
    },
    {
        countryCode: "IE",
        countryName: "Ireland",
        currencyCode: "EUR"
    },
    {
        countryCode: "IL",
        countryName: "Israel",
        currencyCode: "ILS"
    },
    {
        countryCode: "IM",
        countryName: "Isle of Man",
        currencyCode: "GBP"
    },
    {
        countryCode: "IN",
        countryName: "India",
        currencyCode: "INR"
    },
    {
        countryCode: "IO",
        countryName: "British Indian Ocean Territory",
        currencyCode: "USD"
    },
    {
        countryCode: "IQ",
        countryName: "Iraq",
        currencyCode: "IQD"
    },
    {
        countryCode: "IR",
        countryName: "Iran",
        currencyCode: "IRR"
    },
    {
        countryCode: "IS",
        countryName: "Iceland",
        currencyCode: "ISK"
    },
    {
        countryCode: "IT",
        countryName: "Italy",
        currencyCode: "EUR"
    },
    {
        countryCode: "JE",
        countryName: "Jersey",
        currencyCode: "GBP"
    },
    {
        countryCode: "JM",
        countryName: "Jamaica",
        currencyCode: "JMD"
    },
    {
        countryCode: "JO",
        countryName: "Jordan",
        currencyCode: "JOD"
    },
    {
        countryCode: "JP",
        countryName: "Japan",
        currencyCode: "JPY"
    },
    {
        countryCode: "KE",
        countryName: "Kenya",
        currencyCode: "KES"
    },
    {
        countryCode: "KG",
        countryName: "Kyrgyzstan",
        currencyCode: "KGS"
    },
    {
        countryCode: "KH",
        countryName: "Cambodia",
        currencyCode: "KHR"
    },
    {
        countryCode: "KI",
        countryName: "Kiribati",
        currencyCode: "AUD"
    },
    {
        countryCode: "KM",
        countryName: "Comoros",
        currencyCode: "KMF"
    },
    {
        countryCode: "KN",
        countryName: "Saint Kitts and Nevis",
        currencyCode: "XCD"
    },
    {
        countryCode: "KP",
        countryName: "North Korea",
        currencyCode: "KPW"
    },
    {
        countryCode: "KR",
        countryName: "South Korea",
        currencyCode: "KRW"
    },
    {
        countryCode: "KW",
        countryName: "Kuwait",
        currencyCode: "KWD"
    },
    {
        countryCode: "KY",
        countryName: "Cayman Islands",
        currencyCode: "KYD"
    },
    {
        countryCode: "KZ",
        countryName: "Kazakhstan",
        currencyCode: "KZT"
    },
    {
        countryCode: "LA",
        countryName: "Laos",
        currencyCode: "LAK"
    },
    {
        countryCode: "LB",
        countryName: "Lebanon",
        currencyCode: "LBP"
    },
    {
        countryCode: "LC",
        countryName: "Saint Lucia",
        currencyCode: "XCD"
    },
    {
        countryCode: "LI",
        countryName: "Liechtenstein",
        currencyCode: "CHF"
    },
    {
        countryCode: "LK",
        countryName: "Sri Lanka",
        currencyCode: "LKR"
    },
    {
        countryCode: "LR",
        countryName: "Liberia",
        currencyCode: "LRD"
    },
    {
        countryCode: "LS",
        countryName: "Lesotho",
        currencyCode: "LSL"
    },
    {
        countryCode: "LT",
        countryName: "Lithuania",
        currencyCode: "EUR"
    },
    {
        countryCode: "LU",
        countryName: "Luxembourg",
        currencyCode: "EUR"
    },
    {
        countryCode: "LV",
        countryName: "Latvia",
        currencyCode: "EUR"
    },
    {
        countryCode: "LY",
        countryName: "Libya",
        currencyCode: "LYD"
    },
    {
        countryCode: "MA",
        countryName: "Morocco",
        currencyCode: "MAD"
    },
    {
        countryCode: "MC",
        countryName: "Monaco",
        currencyCode: "EUR"
    },
    {
        countryCode: "MD",
        countryName: "Moldova",
        currencyCode: "MDL"
    },
    {
        countryCode: "ME",
        countryName: "Montenegro",
        currencyCode: "EUR"
    },
    {
        countryCode: "MF",
        countryName: "Saint Martin",
        currencyCode: "EUR"
    },
    {
        countryCode: "MG",
        countryName: "Madagascar",
        currencyCode: "MGA"
    },
    {
        countryCode: "MH",
        countryName: "Marshall Islands",
        currencyCode: "USD"
    },
    {
        countryCode: "MK",
        countryName: "Macedonia",
        currencyCode: "MKD"
    },
    {
        countryCode: "ML",
        countryName: "Mali",
        currencyCode: "XOF"
    },
    {
        countryCode: "MM",
        countryName: "Myanmar [Burma]",
        currencyCode: "MMK"
    },
    {
        countryCode: "MN",
        countryName: "Mongolia",
        currencyCode: "MNT"
    },
    {
        countryCode: "MO",
        countryName: "Macao",
        currencyCode: "MOP"
    },
    {
        countryCode: "MP",
        countryName: "Northern Mariana Islands",
        currencyCode: "USD"
    },
    {
        countryCode: "MQ",
        countryName: "Martinique",
        currencyCode: "EUR"
    },
    {
        countryCode: "MR",
        countryName: "Mauritania",
        currencyCode: "MRO"
    },
    {
        countryCode: "MS",
        countryName: "Montserrat",
        currencyCode: "XCD"
    },
    {
        countryCode: "MT",
        countryName: "Malta",
        currencyCode: "EUR"
    },
    {
        countryCode: "MU",
        countryName: "Mauritius",
        currencyCode: "MUR"
    },
    {
        countryCode: "MV",
        countryName: "Maldives",
        currencyCode: "MVR"
    },
    {
        countryCode: "MW",
        countryName: "Malawi",
        currencyCode: "MWK"
    },
    {
        countryCode: "MX",
        countryName: "Mexico",
        currencyCode: "MXN"
    },
    {
        countryCode: "MY",
        countryName: "Malaysia",
        currencyCode: "MYR"
    },
    {
        countryCode: "MZ",
        countryName: "Mozambique",
        currencyCode: "MZN"
    },
    {
        countryCode: "NA",
        countryName: "Namibia",
        currencyCode: "NAD"
    },
    {
        countryCode: "NC",
        countryName: "New Caledonia",
        currencyCode: "XPF"
    },
    {
        countryCode: "NE",
        countryName: "Niger",
        currencyCode: "XOF"
    },
    {
        countryCode: "NF",
        countryName: "Norfolk Island",
        currencyCode: "AUD"
    },
    {
        countryCode: "NG",
        countryName: "Nigeria",
        currencyCode: "NGN"
    },
    {
        countryCode: "NI",
        countryName: "Nicaragua",
        currencyCode: "NIO"
    },
    {
        countryCode: "NL",
        countryName: "Netherlands",
        currencyCode: "EUR"
    },
    {
        countryCode: "NO",
        countryName: "Norway",
        currencyCode: "NOK"
    },
    {
        countryCode: "NP",
        countryName: "Nepal",
        currencyCode: "NPR"
    },
    {
        countryCode: "NR",
        countryName: "Nauru",
        currencyCode: "AUD"
    },
    {
        countryCode: "NU",
        countryName: "Niue",
        currencyCode: "NZD"
    },
    {
        countryCode: "NZ",
        countryName: "New Zealand",
        currencyCode: "NZD"
    },
    {
        countryCode: "OM",
        countryName: "Oman",
        currencyCode: "OMR"
    },
    {
        countryCode: "PA",
        countryName: "Panama",
        currencyCode: "PAB"
    },
    {
        countryCode: "PE",
        countryName: "Peru",
        currencyCode: "PEN"
    },
    {
        countryCode: "PF",
        countryName: "French Polynesia",
        currencyCode: "XPF"
    },
    {
        countryCode: "PG",
        countryName: "Papua New Guinea",
        currencyCode: "PGK"
    },
    {
        countryCode: "PH",
        countryName: "Philippines",
        currencyCode: "PHP"
    },
    {
        countryCode: "PK",
        countryName: "Pakistan",
        currencyCode: "PKR"
    },
    {
        countryCode: "PL",
        countryName: "Poland",
        currencyCode: "PLN"
    },
    {
        countryCode: "PM",
        countryName: "Saint Pierre and Miquelon",
        currencyCode: "EUR"
    },
    {
        countryCode: "PN",
        countryName: "Pitcairn Islands",
        currencyCode: "NZD"
    },
    {
        countryCode: "PR",
        countryName: "Puerto Rico",
        currencyCode: "USD"
    },
    {
        countryCode: "PS",
        countryName: "Palestine",
        currencyCode: "ILS"
    },
    {
        countryCode: "PT",
        countryName: "Portugal",
        currencyCode: "EUR"
    },
    {
        countryCode: "PW",
        countryName: "Palau",
        currencyCode: "USD"
    },
    {
        countryCode: "PY",
        countryName: "Paraguay",
        currencyCode: "PYG"
    },
    {
        countryCode: "QA",
        countryName: "Qatar",
        currencyCode: "QAR"
    },
    {
        countryCode: "RE",
        countryName: "Réunion",
        currencyCode: "EUR"
    },
    {
        countryCode: "RO",
        countryName: "Romania",
        currencyCode: "RON"
    },
    {
        countryCode: "RS",
        countryName: "Serbia",
        currencyCode: "RSD"
    },
    {
        countryCode: "RU",
        countryName: "Russia",
        currencyCode: "RUB"
    },
    {
        countryCode: "RW",
        countryName: "Rwanda",
        currencyCode: "RWF"
    },
    {
        countryCode: "SA",
        countryName: "Saudi Arabia",
        currencyCode: "SAR"
    },
    {
        countryCode: "SB",
        countryName: "Solomon Islands",
        currencyCode: "SBD"
    },
    {
        countryCode: "SC",
        countryName: "Seychelles",
        currencyCode: "SCR"
    },
    {
        countryCode: "SD",
        countryName: "Sudan",
        currencyCode: "SDG"
    },
    {
        countryCode: "SE",
        countryName: "Sweden",
        currencyCode: "SEK"
    },
    {
        countryCode: "SG",
        countryName: "Singapore",
        currencyCode: "SGD"
    },
    {
        countryCode: "SH",
        countryName: "Saint Helena",
        currencyCode: "SHP"
    },
    {
        countryCode: "SI",
        countryName: "Slovenia",
        currencyCode: "EUR"
    },
    {
        countryCode: "SJ",
        countryName: "Svalbard and Jan Mayen",
        currencyCode: "NOK"
    },
    {
        countryCode: "SK",
        countryName: "Slovakia",
        currencyCode: "EUR"
    },
    {
        countryCode: "SL",
        countryName: "Sierra Leone",
        currencyCode: "SLL"
    },
    {
        countryCode: "SM",
        countryName: "San Marino",
        currencyCode: "EUR"
    },
    {
        countryCode: "SN",
        countryName: "Senegal",
        currencyCode: "XOF"
    },
    {
        countryCode: "SO",
        countryName: "Somalia",
        currencyCode: "SOS"
    },
    {
        countryCode: "SR",
        countryName: "Suriname",
        currencyCode: "SRD"
    },
    {
        countryCode: "SS",
        countryName: "South Sudan",
        currencyCode: "SSP"
    },
    {
        countryCode: "ST",
        countryName: "São Tomé and Príncipe",
        currencyCode: "STD"
    },
    {
        countryCode: "SV",
        countryName: "El Salvador",
        currencyCode: "USD"
    },
    {
        countryCode: "SX",
        countryName: "Sint Maarten",
        currencyCode: "ANG"
    },
    {
        countryCode: "SY",
        countryName: "Syria",
        currencyCode: "SYP"
    },
    {
        countryCode: "SZ",
        countryName: "Swaziland",
        currencyCode: "SZL"
    },
    {
        countryCode: "TC",
        countryName: "Turks and Caicos Islands",
        currencyCode: "USD"
    },
    {
        countryCode: "TD",
        countryName: "Chad",
        currencyCode: "XAF"
    },
    {
        countryCode: "TF",
        countryName: "French Southern Territories",
        currencyCode: "EUR"
    },
    {
        countryCode: "TG",
        countryName: "Togo",
        currencyCode: "XOF"
    },
    {
        countryCode: "TH",
        countryName: "Thailand",
        currencyCode: "THB"
    },
    {
        countryCode: "TJ",
        countryName: "Tajikistan",
        currencyCode: "TJS"
    },
    {
        countryCode: "TK",
        countryName: "Tokelau",
        currencyCode: "NZD"
    },
    {
        countryCode: "TL",
        countryName: "East Timor",
        currencyCode: "USD"
    },
    {
        countryCode: "TM",
        countryName: "Turkmenistan",
        currencyCode: "TMT"
    },
    {
        countryCode: "TN",
        countryName: "Tunisia",
        currencyCode: "TND"
    },
    {
        countryCode: "TO",
        countryName: "Tonga",
        currencyCode: "TOP"
    },
    {
        countryCode: "TR",
        countryName: "Turkey",
        currencyCode: "TRY"
    },
    {
        countryCode: "TT",
        countryName: "Trinidad and Tobago",
        currencyCode: "TTD"
    },
    {
        countryCode: "TV",
        countryName: "Tuvalu",
        currencyCode: "AUD"
    },
    {
        countryCode: "TW",
        countryName: "Taiwan",
        currencyCode: "TWD"
    },
    {
        countryCode: "TZ",
        countryName: "Tanzania",
        currencyCode: "TZS"
    },
    {
        countryCode: "UA",
        countryName: "Ukraine",
        currencyCode: "UAH"
    },
    {
        countryCode: "UG",
        countryName: "Uganda",
        currencyCode: "UGX"
    },
    {
        countryCode: "UM",
        countryName: "U.S. Minor Outlying Islands",
        currencyCode: "USD"
    },
    {
        countryCode: "US",
        countryName: "United States",
        currencyCode: "USD"
    },
    {
        countryCode: "UY",
        countryName: "Uruguay",
        currencyCode: "UYU"
    },
    {
        countryCode: "UZ",
        countryName: "Uzbekistan",
        currencyCode: "UZS"
    },
    {
        countryCode: "VA",
        countryName: "Vatican City",
        currencyCode: "EUR"
    },
    {
        countryCode: "VC",
        countryName: "Saint Vincent and the Grenadines",
        currencyCode: "XCD"
    },
    {
        countryCode: "VE",
        countryName: "Venezuela",
        currencyCode: "VEF"
    },
    {
        countryCode: "VG",
        countryName: "British Virgin Islands",
        currencyCode: "USD"
    },
    {
        countryCode: "VI",
        countryName: "U.S. Virgin Islands",
        currencyCode: "USD"
    },
    {
        countryCode: "VN",
        countryName: "Vietnam",
        currencyCode: "VND"
    },
    {
        countryCode: "VU",
        countryName: "Vanuatu",
        currencyCode: "VUV"
    },
    {
        countryCode: "WF",
        countryName: "Wallis and Futuna",
        currencyCode: "XPF"
    },
    {
        countryCode: "WS",
        countryName: "Samoa",
        currencyCode: "WST"
    },
    {
        countryCode: "XK",
        countryName: "Kosovo",
        currencyCode: "EUR"
    },
    {
        countryCode: "YE",
        countryName: "Yemen",
        currencyCode: "YER"
    },
    {
        countryCode: "YT",
        countryName: "Mayotte",
        currencyCode: "EUR"
    },
    {
        countryCode: "ZA",
        countryName: "South Africa",
        currencyCode: "ZAR"
    },
    {
        countryCode: "ZM",
        countryName: "Zambia",
        currencyCode: "ZMW"
    },
    {
        countryCode: "ZW",
        countryName: "Zimbabwe",
        currencyCode: "ZWL"
    }
];
