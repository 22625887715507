// Dependencies
import 'jspdf-autotable';
import { useTranslation } from 'react-i18next';
import React, { FC, ReactElement, useEffect, useState } from 'react';
// Core & Lab
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Radio from '@mui/material/Radio';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
// Icons
import Close from '@mui/icons-material/Close';
// Actions

const ExportDialog: FC<{
    open: boolean,
    onExportCallback: (type: string, full?: boolean) => void,
    onCloseCallback: () => void,
    hideFull?: boolean,
    types?: string[],
}> = ({ open, onExportCallback, onCloseCallback, hideFull, types }): ReactElement => {
    const { t } = useTranslation();

    const [full, setFull] = useState(true);
    const [type, setType] = useState('XLSX');
    const [valid_types, setValidTypes] = useState<string[]>([]);

    useEffect(() => {
        const valid_types: string[] = types?.length ? types : ['XLSX', 'PDF'];
        setType(types?.length ? types[0] : 'XLSX');
        setValidTypes(valid_types);
    }, []);

    const onClose = () => {
        if (onCloseCallback !== undefined) onCloseCallback();
    };
    const onSetExportType = (type: string) => () => {
        setType(type);
    };
    const onExport = () => {
        if (onExportCallback !== undefined) {
            onExportCallback(type, full);
        }
    };
    return (
        <Dialog fullWidth maxWidth={'lg'} open={open} onClose={onClose}>
            <AppBar className={'ft-user-appBar'}>
                <Toolbar>
                    <IconButton edge={'start'} color={'inherit'} onClick={onClose}><Close /></IconButton>
                    <Typography variant={'h6'}>{t('list.export')}</Typography>
                </Toolbar>
            </AppBar>
            <Paper className={'ft-user-export-paper'}>
                <Grid container alignItems={'center'} spacing={2}>
                    {
                        !hideFull &&
                        <>
                            <Grid item xs={4}>
                                <Typography><strong>{t('list.what-to-export')}</strong></Typography>
                            </Grid>
                            <Grid item xs={8}>
                                <FormControl>
                                    <FormControlLabel control={<Radio color={'primary'} checked={!full} onChange={() => setFull(false)} />} label={t('list.export-page')} />
                                </FormControl>
                                <FormControl>
                                    <FormControlLabel control={<Radio color={'primary'} checked={full} onChange={() => setFull(true)} />} label={t('list.export-full')} />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                                <Divider />
                            </Grid>
                        </>
                    }
                    <Grid item xs={4}>
                        <Typography><strong>{t('list.what-format-to-export')}</strong></Typography>
                    </Grid>
                    <Grid item xs={8}>
                        {
                            valid_types.map(valid_type => {
                                return (
                                    <FormControl key={`export-type-${valid_type}`}>
                                        <FormControlLabel control={<Radio color={'primary'} checked={type === valid_type} onChange={onSetExportType(valid_type)} />} label={t(`list.export-format`) + " " + valid_type} />
                                    </FormControl>
                                );
                            })
                        }
                    </Grid>
                </Grid>
            </Paper>
            <Grid container justifyContent={'flex-end'}>
                <Grid item>
                    <Button onClick={onClose}>{t('list.cancel')}</Button>
                </Grid>
                <Grid item>
                    <Button className={'ft-user-button'} onClick={onExport}>{t('list.confirm')}</Button>
                </Grid>
            </Grid>
        </Dialog>
    );
};

export default ExportDialog;