import { Typography } from '@mui/material';
import React, { FC, ReactElement, useEffect, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';

// Style
import '../../../styles/dropzone.css';
import { useTranslation } from 'react-i18next';

const Dropzone: FC<{ onUpload: (file: any) => void, uploadProgress: number, accept?: string[] }> = ({ onUpload, uploadProgress, accept }): ReactElement => {
    const { t } = useTranslation();
    const [isDraggingOver, setIsDraggingOver] = useState(false);
    const [fileName, setFileName] = useState(null);
    const [fileSize, setFileSize] = useState(0);
    const [fileState, setFileState] = useState<string | null>(null);

    useEffect(() => {
        if (uploadProgress && uploadProgress >= 100) {
            setFileState("complete");
        }
    }, [uploadProgress]);

    const handleFileUpload = (event: any) => {
        const file = event.target.files[0];
        handleFile(file);
    };

    const handleDrop = (event: any) => {
        event.preventDefault();
        setIsDraggingOver(false);
        const file = event.dataTransfer.files[0];
        handleFile(file);
    };

    const handleDragOver = (event: any) => {
        event.preventDefault();
        setIsDraggingOver(true);
    };

    const handleDragLeave = () => {
        setIsDraggingOver(false);
    };

    const handleFile = (file: any) => {
        setFileState(null);
        if (file && file.size <= 3 * 1024 * 1024) {
            setFileName(file.name);
            setFileSize(file.size);
            // You can perform additional operations with the uploaded file here
            if (onUpload !== undefined && onUpload !== null) {
                onUpload(file);
            }
        }
    };

    const removeFile = () => {
        setFileState(null);
        setFileSize(0);
        setFileName(null);
    };

    return (
        <>
            <div className={`dropzone ${isDraggingOver ? "drag-over" : ''}`}
                onDrop={handleDrop}
                onDragOver={handleDragOver}
                onDragLeave={handleDragLeave}>
                <input
                    type="file"
                    id="fileInput"
                    style={{ display: 'none' }}
                    accept={accept?.map(e => `.${e}`)?.join(',') || ".pdf"}
                    onChange={handleFileUpload}
                />
                <label htmlFor="fileInput">
                    <UploadFileIcon className={'dropzone-icon'} color="primary" />
                    <Typography variant="body1" className={"dropzone-text"}>
                        <u>{t('list.click-to-download')}</u> {t('list.drag')}
                    </Typography>
                    <Typography className={"dropzone-text dropzone-subtitle"}>{accept?.map(e => `.${e}`)?.join(' ou ') || 'PDF'} (max. 3MB)</Typography>
                </label>
            </div>
            {
                fileName &&
                <div className='file-status'>
                    <UploadFileIcon className={'dropzone-icon'} color="primary" />
                    <div>
                        <Typography variant="body2" className={"dropzone-text"}>{fileName}</Typography>
                        <Typography className={"dropzone-text dropzone-subtitle"}>
                            {fileSize}Ko • {fileState === "complete" ? "Terminé" : ""}
                        </Typography>
                        <Box sx={{ width: '100%' }}>
                            <LinearProgress variant="determinate" value={uploadProgress} />
                        </Box>
                    </div>
                    <CloseIcon style={{ cursor: "pointer" }} onClick={removeFile} />
                </div>
            }
        </>
    );
};

export default Dropzone;
