// Dependencies
import { createSlice } from '@reduxjs/toolkit';
// Types
import { AppState } from '../types/appState';

const initialState: AppState = {
	client_id: null,
	logged_user_client_id: null,
	currencies: null,
	current_locale: null,
	favicon: null,
	keys: [],
	locales: null,
	logo: null,
	language: 'en',
	providers: null,
	quotation_code: null,
	finished: false,
	permissions: null,
	profile: null,
	selected_contract: null,
	contract_list: [],
	selected_user: null,
	user_list: [],
	selected_product: null,
	product_list: [],
	contract_types: null,
	selected_sinister: null,
	sinister_list: [],
	bill_list: [],
	price_index: 0,
	incentive_list: []
};

export const appSlice = createSlice({
	name: 'app',
	initialState,
	reducers: {
		setLogout: () => {
			return { ... initialState };
		},
		setConfig: (state, action) => {
			const { client_id, logo, keys, favicon, quotation_code } = action.payload;
			state.client_id = client_id;
			state.logo = logo;
			state.keys = keys;
			state.favicon = favicon;
			state.quotation_code = quotation_code;
		},
		setLoggedUserClientId: (state, action) => {
			state.logged_user_client_id = action.payload;
			// state.client_id = action.payload;
		},
		setPriceIndex: (state, action) => {
			state.price_index = action.payload;
		},
		setCurrencies: (state, action) => {
			state.currencies = action.payload;
		},
		setLocales: (state, action) => {
			state.locales = action.payload;
			state.current_locale = action.payload.results?.length > 0 ? action.payload.results[0].id : null;
		},
		setLanguage: (state, action) => {
			state.language = action.payload;
		},
		setProviders: (state, action) => {
			state.providers = action.payload;
		},
		setContractTypes: (state, action) => {
			state.contract_types = action.payload;
		},
		setFinished: (state, action) => {
			state.finished = action.payload;
		},
		setProfile: (state, action) => {
			state.profile = action.payload;
		},
		setPermissions: (state, action) => {
			state.permissions = action.payload;
		},
		setSelectedContract: (state, action) => {
			state.selected_contract = action.payload;
		},
		setContractList: (state, action) => {
			state.contract_list = action.payload;
		},
		setSelectedProduct: (state, action) => {
			state.selected_product = action.payload;
		},
		setProductList: (state, action) => {
			state.product_list = action.payload;
		},
		setSelectedUser: (state, action) => {
			state.selected_user = action.payload;
		},
		setUserList: (state, action) => {
			state.user_list = action.payload;
		},
		setSelectedSinister: (state, action) => {
			state.selected_sinister = action.payload;
		},
		setSinisterList: (state, action) => {
			state.sinister_list = action.payload;
		},
		setBillList: (state, action) => {
			state.bill_list = action.payload;
		},
		setIncentiveList: (state, action) => {
			state.incentive_list = action.payload;
		}
	}
});

export const {
	setLogout,
	setConfig,
	setCurrencies,
	setLocales,
	setLanguage,
	setProviders,
	setFinished,
	setProfile,
	setPermissions,
	setSelectedContract,
	setSelectedProduct,
	setLoggedUserClientId,
	setPriceIndex,
	setContractTypes,
	setSelectedSinister,
	setSelectedUser,
	setUserList,
	setContractList,
	setProductList,
	setBillList,
	setSinisterList,
	setIncentiveList
} = appSlice.actions;

export default appSlice.reducer;