/* eslint-disable react/display-name */
import { ArrowBackIosNew, FilePresentOutlined, InsertDriveFileOutlined, NoteAddOutlined } from "@mui/icons-material";
import { Button, Chip, Grid, Paper, Tooltip, Typography, useMediaQuery, useTheme } from "@mui/material";
import { format } from "date-fns";
import React, { FC, ReactElement, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { getContractById } from "../../services/contractServices";
import { setSelectedContract } from "../../store/reducer";
import { RootState } from "../../store/store";
import { Contract } from "../../types/contract/contract";
import GenericField from "../common/genericList/genericField";
import { useSnackbar } from "notistack";
import { updateContractPrices } from "../../functions/contract";
import { getOrganisationsDiscounts } from "../../services/organisationDiscountServices";
import ContractDetailPanel from "./ContractDetailPanel";
import Loading from "../common/loading/loading";
import { DataGrid, frFR, GridColDef, GridColumnMenu, GridColumnMenuProps, GridRenderCellParams } from "@mui/x-data-grid";

const ContractDetail: FC = (): ReactElement => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();

    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down(600));
    const isTablet = useMediaQuery(theme.breakpoints.down(800));

    const [contract, setContract] = useState<Contract | null>(null);
    const [loading, setLoading] = useState(false);
    const selected_contract = useSelector((state: RootState) => state.app.selected_contract);
    const profile = useSelector((state: RootState) => state.app.profile);
    const client_id = useSelector((state: RootState) => state.app.client_id);
    const contract_types = useSelector((state: RootState) => state.app.contract_types);

    const { id } = useParams();

    const [discount, setDiscount] = React.useState<any | null>(null);

    const getAgeDiff = (birth_date: any) => {
        if (!birth_date) return 0;
        const now = new Date();
        const b_date = new Date(birth_date);
        const diff = now.getFullYear() - b_date.getFullYear();
        return diff;
    };
    const columns: GridColDef[] = [
        {
            field: 'type', headerName: t('contract.traveler_type'), flex: 1, headerClassName: 'product-extension-header',
            valueGetter: (params) => {
                if (params.row.birth_date) {
                    const diff = getAgeDiff(params.row.birth_date);
                    return diff <= 2 ? 'Bébé' : (diff >= 18 ? 'Adulte' : 'Enfant');
                }
            }
        },
        {
            field: 'title', headerName: t('contract.traveler_title_short'), flex: 1, headerClassName: 'product-extension-header',
        },
        {
            field: 'first_name', headerName: t('contract.traveler_first_name'), flex: 1, headerClassName: 'product-extension-header',
        },
        {
            field: 'last_name', headerName: t('contract.traveler_last_name'), flex: 1, headerClassName: 'product-extension-header',
        },
        {
            field: 'price', headerName: t('contract.traveler_price'), flex: 1, headerClassName: 'product-extension-header',
        },
        {
            field: 'net_price', headerName: t('contract.net_price'), flex: 1, headerClassName: 'product-extension-header',
            renderCell: (params: GridRenderCellParams<any>) => {
                return (
                    <div>
                        <div style={{ padding: 8 }}>{parseFloat(params.row.net_price || "0")?.toFixed(2)}</div>
                        {
                            params.row.reduction_net !== undefined && parseFloat(params.row.reduction_net) !== 0 && isFinite(parseFloat(params.row.reduction_net)) &&
                            <div>{`${params.row.is_reduction ? 'Remise' : 'Surprime'} : ${parseFloat(params.row.reduction_net).toFixed(2)}€`}</div>
                        }
                    </div>
                )
            }
        },
        {
            field: 'public_price', headerName: t('contract.public_price'), flex: 1, headerClassName: 'product-extension-header',
            valueGetter: (params) => (parseFloat(params.row.public_price || "0"))?.toFixed(2),
        },
    ];

    useEffect(() => {
        setLoading(true);
        getContractById(client_id, id).then((resp) => {
            if (resp.data) {
                setLoading(false);
                dispatch(setSelectedContract(resp.data));
            }
        })
    }, []);

    useEffect(() => {
        if (selected_contract) {
            let selected_contract_c = JSON.parse(JSON.stringify(selected_contract));
            if (contract_types?.length) {
                const type = contract_types.find(c => c.id === selected_contract_c.contracts_type);
                selected_contract_c.contracts_type = type || selected_contract_c.contracts_type;
                selected_contract_c.travelers = selected_contract_c?.group_passenger?.travelers_list?.length ? selected_contract_c.group_passenger.travelers_list : [];
                selected_contract_c.travelers.forEach((traveler, index) => {
                    selected_contract_c = updateContractPrices(selected_contract_c, -1, (selected_contract_c.subscribing_agency?.id || selected_contract_c.subscribing_agency), index, type?.abbreviation, undefined, traveler.public_price, undefined, true, true);
                });
                setContract(selected_contract_c);
            }

            getOrganisationsDiscounts(client_id, 100, 0, { agency_id: selected_contract_c.subscribing_agency?.id || selected_contract_c.subscribing_agency })
                .then(resp => {
                    if (resp.data.results) {
                        const number_of_trav = selected_contract_c.group_passenger?.travelers_list?.length;
                        let max = number_of_trav;
                        let discount: any = null;
                        resp.data.results?.forEach(d => {
                            if (number_of_trav > d.travelers_number) {
                                max = d.travelers_number;
                                discount = d;
                            }
                        });
                        setDiscount(discount);
                    }
                })
                .catch(err => console.log(err));
        }
    }, [selected_contract]);
    const redirection = (type:string, index?:number) => {
        if (contract !== undefined && contract !== null) {
            if (index === undefined) {
                navigate(`/insurance/product/${contract[type].id}`);
            } else {
                navigate(`/insurance/product/${contract[type][index].id}`);
            }
        }
    }
    const CustomColumnMenu = (props: GridColumnMenuProps) => {
        return (
            <GridColumnMenu
                {...props}
                slots={{
                    columnMenuColumnsItem: null,
                    columnMenuFilterItem: null,
                }}
            />
        );
    }
    if (!contract) return <></>;
    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={isSmallScreen ? 12 : 2}>
                    <Button startIcon={<ArrowBackIosNew />} onClick={() => navigate('/insurance/contracts')}>{t('shared.back')}</Button>
                </Grid>
                <Grid item xs={isSmallScreen ? 12 : 9}>
                    <Typography variant="h4" className="title" style={{ fontWeight: "bold", textAlign: "center" }}>{t('contract.number')}{contract.reference} - {contract.group_name || `${contract.subscriber?.first_name || ''} ${contract.subscriber?.last_name || ''}`}
                    {contract.contracts_status === "CANCELLED" ? ' (Annulé)' : ''}</Typography>
                </Grid>
            </Grid>
            <Grid container spacing={2} style={{ alignItems: "flex-start" }}>
                <Grid item xs={isSmallScreen ? 12 : (isTablet ? 8 : 9)}>
                    <Paper variant='outlined' square style={{ padding: 16, marginBottom: 20 }}>
                        <Grid container spacing={2}>
                            {
                                (profile?.is_superuser || profile?.client_full?.name === "Valeurs Assurances") &&
                                <>
                                    <Grid item xs={12}>
                                        <Typography variant="subtitle1" className="title">{t('contract.agency')}</Typography>
                                    </Grid>
                                    <Grid item xs={isSmallScreen ? 12 : 4}>
                                        <GenericField field={"subscribing_agency.name"} object={contract} label={t('contract.agency')} readonly variant="standard" />
                                    </Grid>
                                </>
                            }
                            <Grid item xs={12}>
                                <Typography variant="subtitle1" className="title">{t('contract.dates')}</Typography>
                            </Grid>
                            <Grid item xs={isSmallScreen ? 12 : 4}>
                                <GenericField field={"created_date"} object={contract} label={t('contract.contract_date')} type="datepicker" readonly variant="standard" />
                            </Grid>
                            <Grid item xs={isSmallScreen ? 12 : 4}>
                                <GenericField field={"trip_reservation_date"} object={contract} label={t('contract.trip_reservation_date')} type="datepicker" readonly variant="standard" />
                            </Grid>
                            <Grid item xs={isSmallScreen ? 12 : 4}>
                                <GenericField field={"travel_start_date"} object={contract} label={t('contract.start_date')} readonly
                                    displayValue={`${contract.travel_start_date ? format(new Date(contract.travel_start_date), 'dd/MM/yyy') : ''} - ${contract.travel_end_date ? format(new Date(contract.travel_end_date), 'dd/MM/yyy') : ''}`} variant="standard" />
                            </Grid>
                            <Grid item xs={isSmallScreen ? 12 : 4}>
                                <Typography variant="subtitle1" className="title">{t('contract.destinations')}</Typography>
                                <div style={{ display: "flex", gap: 10, flexWrap: "wrap" }}>
                                    {
                                        contract.destinations?.map((destination: any) => {
                                            return <Chip key={destination.id} label={destination.name || destination.international_name} />;
                                        })
                                    }
                                </div>
                            </Grid>
                            <Grid item xs={isSmallScreen ? 12 : 4}>
                                <Typography variant="subtitle1" className="title">{t('contract.departure_country')}</Typography>
                                <Chip label={contract.departure_country?.name} />
                            </Grid>
                            <Grid item xs={isSmallScreen ? 12 : 4}></Grid>
                            <Grid item xs={12}>
                                <Paper variant='outlined' square style={{ padding: 10 }}>
                                    <Typography variant="subtitle1" className="title">{t('contract.products')}</Typography>
                                    <Typography variant="body2" className="title">{t('contract.products-info')}</Typography>
                                        <Grid container spacing={2} sx={{marginTop: '10px', marginBottom: '5px', '&:hover': {color: '#344482 !important'}}}>
                                            {
                                                !isSmallScreen &&
                                                <Tooltip placement={'top'} title={'Cliquez pour accéder à la fiche du produit'}>
                                                    <Grid item xs={1}>
                                                        <InsertDriveFileOutlined style={{cursor: 'pointer'}} onClick={() => redirection('principal_product')}/>
                                                    </Grid>
                                                </Tooltip>
                                            }
                                            <Grid item xs={10} sx={{
                                                '&:hover': {color: '#344482 !important'}
                                            }}>
                                                <div style={{cursor: 'pointer', width: 'fit-content'}} onClick={() => redirection('principal_product')}>
                                                    <Typography variant="body1" className="title">{t('contract.principal_product')}</Typography>
                                                    <Tooltip followCursor placement={'top'} title={'Cliquez pour accéder à la fiche du produit'}>
                                                        <Typography variant="body2" className="title" sx={{
                                                            '&:hover': {color: '#344482 !important', fontWeight: 'bold'}
                                                        }}>{contract.principal_product?.name}</Typography>
                                                    </Tooltip>
                                                </div>
                                            </Grid>
                                            {/* <Grid item xs={1}>
                                                <IconButton><Close /></IconButton>
                                            </Grid> */}
                                        </Grid>
                                    {
                                        contract?.secondary_product && contract.secondary_product.id !== undefined &&
                                        <Grid container spacing={2}>
                                            {
                                                !isSmallScreen &&
                                                <Tooltip placement={'top'} title={'Cliquez pour accéder à la fiche du produit'}>
                                                    <Grid item xs={1} style={{ marginTop: 10, cursor: 'pointer' }} onClick={() => redirection('secondary_product')}>
                                                        <NoteAddOutlined />
                                                    </Grid>
                                                </Tooltip>
                                            }
                                                <Grid item xs={10}>
                                                    <div style={{cursor: 'pointer', width: 'fit-content'}} onClick={() => redirection('secondary_product')}>
                                                        <Typography variant="body1" className="title">{t('contract.secondary_product')} </Typography>
                                                        <Grid container direction={'column'}>
                                                            <Grid item>
                                                                <Tooltip followCursor placement={'top'} title={'Cliquez pour accéder à la fiche du produit'}>
                                                                    <Typography variant="body2" className="title" sx={{
                                                                    '&:hover': {color: '#344482 !important', fontWeight: 'bold'}
                                                                }}>{contract.secondary_product.name}</Typography>
                                                                </Tooltip>
                                                            </Grid>
                                                        </Grid>
                                                    </div>
                                                </Grid>
                                            {/* <Grid item xs={1}>
                                                <IconButton><Close /></IconButton>
                                            </Grid> */}
                                        </Grid>
                                    }
                                    {
                                        contract?.product_extension !== undefined && contract?.product_extension !== null && contract?.product_extension.length !== 0 &&
                                        <Grid container spacing={2}>
                                            {
                                                !isSmallScreen &&
                                                <Grid item xs={1}>
                                                    <FilePresentOutlined />
                                                </Grid>
                                            }
                                            <Grid item xs={10}>
                                                <div style={{width: 'fit-content'}}>
                                                    <Typography variant="body1" className="title">{t('contract.extension')} </Typography>
                                                    <Grid container direction={'column'}>
                                                    {
                                                        contract.product_extension.map((e, index) => {
                                                            return (
                                                                <Tooltip followCursor placement={'top'} title={'Cliquez pour accéder à la fiche du produit'}>
                                                                    <Grid item key={e.id} style={{ cursor: 'pointer' }} onClick={() => redirection('product_extension', index)}>
                                                                        <Typography variant="body2" className="title" sx={{
                                                                            '&:hover': {color: '#344482 !important', fontWeight: 'bold'}
                                                                        }}>{e.name}</Typography>
                                                                    </Grid>
                                                                </Tooltip>
                                                            )
                                                        })
                                                    }
                                                    </Grid>
                                                </div>
                                            </Grid>
                                            {/* <Grid item xs={1}>
                                                <IconButton><Close /></IconButton>
                                            </Grid> */}
                                        </Grid>
                                    }
                                    <Typography variant="subtitle1" className="title">{t('contract.warranties')}</Typography>
                                    <Typography variant="body2" className="title">{contract.principal_product?.warranty?.map(w => w.name)?.join(', ')}</Typography>
                                </Paper>
                            </Grid>
                            {
                                !contract.principal_product?.inclusion_contract &&
                                <Grid item xs={12}>
                                    <Typography variant="subtitle1" className="title">{t('contract.travelers')} ({contract.group_passenger?.travelers_list?.length || 0})</Typography>
                                    {
                                        discount &&
                                        <Typography>*{t('contract.free')} : {discount?.discount_number}</Typography>
                                    }
                                </Grid>
                            }
                            {
                                !contract.subscriber && !contract.principal_product?.inclusion_contract &&
                                <>
                                    <Grid item xs={12}>
                                        <b>{t('contract.group')}</b>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <GenericField field={"group_name"} object={contract} label={t('contract.group_name')} readonly variant="standard" />
                                    </Grid>
                                </>
                            }
                            {
                                contract.subscriber && !contract.principal_product?.inclusion_contract &&
                                <>
                                    <Grid item xs={12}>
                                        <b>{t('contract.subscriber')}</b>
                                    </Grid>
                                    {
                                        !isSmallScreen &&
                                        <Grid item xs={3}>
                                            <GenericField field={"subscriber.title"} object={contract} label={t('contract.subscriber_title')} readonly variant="standard" />
                                        </Grid>
                                    }
                                    <Grid item xs={isSmallScreen ? 6 : 4}>
                                        <GenericField field={"subscriber.first_name"} object={contract} label={t('contract.subscriber_last_name')} readonly variant="standard" />
                                    </Grid>
                                    <Grid item xs={isSmallScreen ? 6 : 4}>
                                        <GenericField field={"subscriber.last_name"} object={contract} label={t('contract.subscriber_first_name')} readonly variant="standard" />
                                    </Grid>
                                    {
                                        !isSmallScreen &&
                                        <Grid item xs={3}></Grid>
                                    }
                                </>
                            }
                            {
                                !contract.principal_product?.inclusion_contract && contract?.travelers?.map((traveler: any, trav_index: number) => {
                                    return (
                                        <React.Fragment key={'trav' + traveler.id}>
                                            <Grid item xs={12}>
                                                <b>{t('contract.traveler')} {trav_index + 1}</b>
                                            </Grid>
                                            {
                                                !isSmallScreen &&
                                                <Grid item xs={3}>
                                                    <GenericField field={`title`} object={traveler} label={t('contract.traveler_title')} readonly variant="standard" />
                                                </Grid>
                                            }
                                            <Grid item xs={isSmallScreen ? 6 : 3}>
                                                <GenericField field={`first_name`} object={traveler} label={t('contract.traveler_last_name')} readonly variant="standard" />
                                            </Grid>
                                            <Grid item xs={isSmallScreen ? 6 : 4}>
                                                <GenericField field={`last_name`} object={traveler} label={t('contract.traveler_first_name')} readonly variant="standard" />
                                            </Grid>
                                            {
                                                // contract.subscriber &&
                                                // <>
                                                //     <Grid item xs={isSmallScreen ? 6 : 3}>
                                                //         <GenericField field={`price`} object={traveler} label={t('contract.traveler_price')} readonly variant="standard" displayValue={traveler.price?.toFixed(2) || "0"} />
                                                //     </Grid>
                                                //     <Grid item xs={isSmallScreen ? 6 : 3}>
                                                //         <GenericField field={`incentive`} object={traveler} label={t('incentive.title')} readonly variant="standard" displayValue={parseFloat(traveler.incentive || "0").toFixed(2)} />
                                                //     </Grid>
                                                //     <Grid item xs={isSmallScreen ? 6 : 3}>
                                                //         <GenericField field={`net_price`} object={traveler} label={t('contract.net_price')} readonly variant="standard" displayValue={parseFloat(traveler.net_price || "0").toFixed(2)} />
                                                //     </Grid>
                                                //     <Grid item xs={isSmallScreen ? 6 : 3}>
                                                //         <GenericField field={`public_price`} object={traveler} label={t('contract.public_price')} readonly variant="standard" displayValue={parseFloat(traveler.public_price || "0").toFixed(2)} />
                                                //     </Grid>
                                                //     {
                                                //         (profile?.is_superuser || profile?.client_full?.name === "Valeurs Assurances") &&
                                                //         <Grid item xs={isSmallScreen ? 6 : 3}>
                                                //             <GenericField field={`margin`} object={traveler} label={t('contract.margin')} readonly variant="standard" displayValue={`${traveler.margin?.toFixed(2) || "0"}%`} />
                                                //         </Grid>
                                                //     }
                                                //     <Grid item xs={isSmallScreen ? 6 : 3}>
                                                //         <GenericField field={`extension_price`} object={traveler} label={"Total prix extensions (en €)"} readonly variant="standard" displayValue={parseFloat(traveler.extension_price || "0").toFixed(2)} />
                                                //     </Grid>
                                                //     {
                                                //         traveler.reduction !== 0 &&
                                                //         <Grid item xs={isSmallScreen ? 6 : 3}>
                                                //             <GenericField field={`reduction`} object={traveler} label={traveler.is_reduction ? "Réduction (en €)" : "Surprime (en €)"} readonly variant="standard" displayValue={(traveler.is_reduction && traveler.is_reduction < 0 ? '-' : '') + parseFloat(traveler.reduction || "0").toFixed(2)} />
                                                //         </Grid>
                                                //     }
                                                // </>
                                            }
                                        </React.Fragment>
                                    )
                                })
                            }
                            {
                                contract.subscriber && !contract.principal_product?.inclusion_contract &&
                                <div style={{ height: 400, width: '100%', marginTop: 10 }}>
                                    <DataGrid
                                        slots={{ columnMenu: CustomColumnMenu }}
                                        rows={contract.travelers?.map((traveler, idx) => { return { ...traveler, id: traveler.id ? traveler.id : idx } })}
                                        columns={columns}
                                        disableRowSelectionOnClick
                                        initialState={{
                                            pagination: { paginationModel: { pageSize: 25 } },
                                        }}
                                        pageSizeOptions={[5, 10, 25, 50]}
                                        localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
                                    />
                                </div>
                            }
                            {
                                !contract.principal_product?.inclusion_contract &&
                                <Grid item xs={12} style={{ marginTop: 15 }}>
                                    <b><u>{t('contract.traveler_total_price')}</u></b>
                                </Grid>
                            }
                            {
                                !contract.subscriber && !contract.principal_product?.inclusion_contract &&
                                <>
                                    <Grid item xs={3}>
                                        <GenericField field={"travel_prices"} object={contract} label={t('contract.travel_price')} readonly variant="standard" />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <GenericField field={"total_bonus"} object={contract} label={t('contract.total_net_price')} readonly variant="standard" />
                                    </Grid>
                                </>
                            }
                            {
                                contract.subscriber && !contract.principal_product?.inclusion_contract &&
                                <>
                                    <Grid item xs={3}>
                                        <GenericField field={"total_public_price"} object={contract} label={t('contract.total_public_price')} readonly variant="standard"
                                            displayValue={
                                                (parseFloat(contract.travelers?.reduce((acc, t: any) => acc + parseFloat(t.public_price || '0'), 0).toFixed(2)) +
                                                parseFloat(contract.travelers?.reduce((acc, t: any) => acc + parseFloat(t.extension_price || '0'), 0).toFixed(2)) +
                                                (contract.is_re_invoiced ? parseFloat(contract.travelers?.reduce((acc, t: any) => acc + parseFloat(t.incentive || '0'), 0).toFixed(2)) : 0) +
                                                parseFloat(contract.travelers?.reduce((acc, t: any) => t.is_reduction ? (acc - parseFloat(t.reduction || '0'))  : (acc + parseFloat(t.reduction || '0')), 0).toFixed(2))).toFixed(2)
                                            } />
                                    </Grid>
                                </>
                            }
                        </Grid>
                        {
                            contract?.note !== undefined && contract?.note !== null && contract?.note?.text !== null &&
                            <Grid item xs={12}>
                                <Typography variant="subtitle1" className="title">{'Commentaire'}</Typography>
                                <Typography>{contract.note.text}</Typography>
                            </Grid>
                        }
                    </Paper>
                </Grid>
                <ContractDetailPanel contract={contract} setContract={setContract} />
            </Grid>
            {loading && <Loading />}
        </>
    );
};

export default ContractDetail;