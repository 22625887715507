import axios, { AxiosResponse, CancelTokenSource } from "axios";
import { store } from "../store/store";
import { InsuranceBill } from "../types/bill/insurance-bill";

let cancelToken: CancelTokenSource | null = null;
export const getBills = (client_id: number | null, limit: number, offset: number, filter?: any): Promise<AxiosResponse<{ count: number; results: InsuranceBill[]; }>> => {
    // if (cancelToken) {
    //     cancelToken.cancel("Operation canceled due to new request.");
    // }
    // cancelToken = axios.CancelToken.source();
    let handledFilter = {};
    if (filter) {
        handledFilter = { ...filter };
    }
    return axios.get(
        `${API_HREF}client/${client_id}/invoice-history/`,
        // `${API_HREF}client/${client_id}/value-insurances-contracts-invoice/`,
        {
            params: {
                limit,
                offset,
                ...handledFilter,
                invoice_type__in: 'INVOICE,CREDIT_NOTE',
                target_client: ((store.getState().app.profile?.is_superuser || store.getState().app.profile?.client_full?.name === "Valeurs Assurances")
                    ? undefined : store.getState().app.profile?.client) || undefined,
                // subscribing_agency: ((store.getState().app.profile?.is_superuser || store.getState().app.profile?.client_full?.name === "Valeurs Assurances")
                //     ? undefined : store.getState().app.profile?.client) || undefined,
            },
            // cancelToken: cancelToken.token,
            headers: {
                'Authorization': `Token ${localStorage.getItem('token')}`,
            }
        }
    );
};

export const getBillById = (client_id: number | null, bill_id: string | undefined): Promise<AxiosResponse<InsuranceBill>> => {
    if (cancelToken) {
        cancelToken.cancel("Operation canceled due to new request.");
    }
    cancelToken = axios.CancelToken.source();
    return axios.get(
        `${API_HREF}client/${client_id}/value-insurances-contracts-invoice/${bill_id}/`,
        {
            params: {},
            cancelToken: cancelToken.token,
            headers: {
                'Authorization': `Token ${localStorage.getItem('token')}`,
            }
        }
    );
};

export const dowloadBillDocument = (client_id: number | null, file: any): Promise<AxiosResponse<Blob>> => {
    const requestUrl = `${API_HREF}client/${client_id}/file/download_from_aws/?bucket_name=${file.bucket_name}&object_name=${file.object_name}&file_name=${file.file_name}/`;
    // const requestUrl = `${API_HREF}client/${client_id}/value-insurances-contracts-invoice/download/`;
    return axios.post(
        requestUrl,
        {
            responseType: 'blob',
            params: {},
            headers: {
                'Authorization': `Token ${localStorage.getItem('token')}`,
            }
        }
    );
};