import { store } from '../store/store';

export default (permission: string) => {
    const state = store.getState();
    if(!state.app.profile) return false;
    if (state.app.profile?.is_superuser) {
        return true;
    }
    const permissions = state.app.permissions;
    if (permissions === null) {
        return false;
    }
    for (let i = 0; i < permissions.length; i++) {
        if (permissions[i].codename === permission) {
            return true;
        }
    }
    return false;
};