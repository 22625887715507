// Dependencies
import { useTranslation } from 'react-i18next';
import React, { FC, ReactElement, useState } from 'react';
// Core & Lab
import Typography from '@mui/material/Typography';
import { CircularProgress, Grid } from '@mui/material';
import { getContracts } from '../../services/contractServices';
import { Sinister } from '../../types/contract/sinister';
import GenericField from '../common/genericList/genericField';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/store';
import { getProductById } from '../../services/productServices';

const SinisterFormDescription: FC<{ sinister: Sinister | null, updateFieldCallback: (field: string, value: any) => void }> = ({ sinister, updateFieldCallback }): ReactElement => {
    const { t } = useTranslation();

    const client_id = useSelector((state: RootState) => state.app.client_id);
    const [loading, setLoading] = useState(false);

    const updateContract = (field: string, value: any) => {
        if (value.principal_product?.id) {
            setLoading(true);
            getProductById(client_id, value.principal_product.id).then(response => {
                if (response.data) {
                    const contract = JSON.parse(JSON.stringify(value));
                    contract.principal_product = response.data;
                    if (updateFieldCallback !== undefined) updateFieldCallback(field, contract);
                    setLoading(false);
                }
            }).catch(error => console.error(error));
        }
    };
    console.log('sinister:', sinister);
    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                {sinister?.id && <Typography variant='h6'>{t('sinisters.description')}</Typography>}
                <Typography style={{ fontSize: "0.8rem" }}>{t('sinisters.contract_reference_search')}</Typography>
            </Grid>
            <Grid item xs={6}>
                <GenericField object={sinister} field={'policy'} onChange={updateContract} type="autocomplete"
                    label={`${t('sinisters.contract_reference')}`} autocompleteGetter={(c, search) => getContracts(c, 10, 0, { search, contracts_status: "IN_PROGRESS" })}
                    getOptionLabel={(value) => (value.reference || (value?.id ? `N°${value.id}` : "")) + (value !== null && Object.keys(value).length !== 0 && value.subscriber !== null && value.subscriber.first_name !== null ? " - " + value.subscriber.first_name : "")} required
                />
            </Grid>
            <Grid item xs={6}></Grid>
            {
                sinister !== null && sinister.policy !== null && Object.keys(sinister.policy).length !== 0 && sinister.policy.principal_product?.inclusion_contract &&
                <>
                    <Grid item xs={6}>
                        <GenericField object={sinister} field={'first_name'} onChange={updateFieldCallback} label={`${t('sinisters.first_name')}`} />
                    </Grid>
                    <Grid item xs={6}>
                        <GenericField object={sinister} field={'last_name'} onChange={updateFieldCallback} label={`${t('sinisters.last_name')}`} />
                    </Grid>
                </>
            }
            {
                ((sinister?.policy?.id && !sinister?.policy?.subscriber) || (sinister !== null && sinister.policy !== null && Object.keys(sinister.policy).length !== 0 && sinister.policy.principal_product?.inclusion_contract)) &&
                <Grid item xs={6}>
                    <GenericField object={sinister} field={'email'} onChange={updateFieldCallback} label={`${t('sinisters.email')}`} />
                </Grid>
            }
            {
                loading &&
                <Grid item xs={1}>
                    <CircularProgress />
                </Grid>
            }
        </Grid>
    );

};

export default SinisterFormDescription;