import { differenceInDays } from "date-fns";
import { Contract } from "../types/contract/contract";
import { Sinister } from "../types/contract/sinister";
import { AgencyIncentive, InsuranceProduct } from "../types/insurance/insurance-product";
import { Traveler } from "../types/users/traveler";
import { convertToId, convertToIdsArray } from "./ObjectHandler";

export const cleanContract = (contract: Contract): Contract => {
    const clean: any = JSON.parse(JSON.stringify(contract));
    // validate and put

    for (const key in clean) {
        if (key.endsWith('_error')) {
            delete clean[key];
        }
    }

    delete clean.last_author;
    delete clean.creator;
    delete clean.created_date;
    delete clean.modified_date;
    if (!Array.isArray(clean.destinations)) clean.destinations = [clean.destinations];
    clean.destinations = convertToIdsArray(clean.destinations);
    clean.product_extension = convertToIdsArray(clean.product_extension);

    if (clean.subscriber?.country?.id) clean.subscriber.country = clean.subscriber.country.id;
    if (clean.subscriber?.birth_date) (clean.subscriber.birth_date = clean.subscriber.birth_date.split("T")[0] as string);
    clean.travelers = clean.travelers?.map(t => {
        return {
            email: t.email,
            first_name: t.first_name,
            id: t.id,
            last_name: t.last_name,
            country: t.country?.id ? t.country.id : t.country,
            birth_date: t.birth_date ? t.birth_date.split("T")[0] : null,
            price: t.price,
            public_price: t.public_price,
            reduction_public: t.reduction_public
        };
    });

    if (clean.subscriber?.id) clean.subscriber = {
        email: clean.subscriber.email, first_name: clean.subscriber.first_name, id: clean.subscriber.id, last_name: clean.subscriber.last_name,
        country: clean.subscriber.country?.id ? clean.subscriber.country.id : clean.subscriber.country, birth_date: clean.subscriber.birth_date ? clean.subscriber.birth_date.split("T")[0] : null,
        price: clean.subscriber.price, public_price: clean.subscriber.public_price,
        reduction_public: clean.subscriber.reduction_public
    }

    convertToId(clean, "product_family");
    convertToId(clean, "departure_country");
    convertToId(clean, "principal_product");
    convertToId(clean, "secondary_product");

    // if (clean.secondary_product) clean.secondary_product = [clean.secondary_product];
    // if (clean.product_extension) clean.product_extension = [clean.product_extension];
    if (clean.trip_reservation_date) clean.trip_reservation_date = clean.trip_reservation_date.toString().split("T")[0];
    if (clean.contract_purchase_date) clean.contract_purchase_date = clean.contract_purchase_date.toString().split("T")[0];
    if (clean.travel_start_date) clean.travel_start_date = clean.travel_start_date?.toString().split("T")[0];
    if (clean.travel_end_date) clean.travel_end_date = clean.travel_end_date.toString().split("T")[0];
    if (clean.subscriber?.id && clean.subscriber.net_price) delete clean.subscriber.net_price;
    return clean;
};

export const initSinister = (sinister: Sinister) => {
    const clean_sinister = JSON.parse(JSON.stringify(sinister));
    if (!clean_sinister?.provider_id) clean_sinister.provider_id = null;
    if (!clean_sinister?.policy) clean_sinister.policy = {};
    if (!clean_sinister?.warranty) clean_sinister.warranty = {};
    if (!clean_sinister?.policy) clean_sinister.policy = {};
    if (!clean_sinister?.status) clean_sinister.status = 0;
    if (!clean_sinister?.documents?.length) clean_sinister.documents = [];
    if (!clean_sinister?.causes?.length) clean_sinister.causes = [];
    return clean_sinister;
};

export const cleanSinister = (sinister: Sinister): Sinister => {
    const clean: Sinister = JSON.parse(JSON.stringify(sinister));
    // validate and put
    delete clean.last_author;
    delete clean.creator;
    delete clean.created_date;
    delete clean.modified_date;

    clean.documents = convertToIdsArray(clean.documents);

    convertToId(clean, "policy");
    convertToId(clean, "beneficiary_traveler");
    convertToId(clean, "destination");
    convertToId(clean, "agency");
    convertToId(clean, "provider_id");

    if (clean.warranty?.id) clean.warranty = clean.warranty.id;
    if (clean.warranty?.provider_id?.id) clean.warranty.provider_id = clean.warranty.provider_id.id;
    if (clean.claim_date) clean.claim_date = clean.claim_date.toString().split("T")[0];
    if (clean.reporting_date) clean.reporting_date = clean.reporting_date.toString().split("T")[0];
    if (clean.annulation_date) clean.annulation_date = clean.annulation_date.toString().split("T")[0];
    if (clean.paiement_date) clean.paiement_date = clean.paiement_date.toString().split("T")[0];

    return clean;
};

export const calculateTravelerNetPrice = (current_traveler: Traveler, index: number, contract: Contract, subscribing_agency_id: number) => {
    const default_net_price = parseFloat(current_traveler.net_price || "0");
    const default_public_price = parseFloat(current_traveler.public_price || "0");
    let final_net_price = default_net_price;
    let final_public_price = default_public_price;
    let principal_product = contract.principal_product;
    let is_reduction = true;
    const current_traveler_age = current_traveler.birth_date ? (new Date(contract.travel_end_date).getFullYear() - (new Date(current_traveler.birth_date)).getFullYear()) : null;
    let total_reduction_net = 0;
    let total_reduction_public = 0;
    if (principal_product && principal_product.product_discount?.length) {
        if (principal_product.product_discount.length > 1) {
            is_reduction = true;
        }
        principal_product.product_discount.forEach(discount => {
            if (discount.clients.length === 0 || (discount.clients.find((client) => client.id === subscribing_agency_id) !== undefined)) {
                is_reduction = discount.value_is_reduction;
                let real_amount_net = parseFloat(discount.value_amount || "0");
                let real_amount_public = parseFloat(discount.value_amount || "0");
                if (discount.value_type === "PER") {
                    real_amount_net = default_net_price * discount.value_amount / 100;
                    real_amount_public = default_public_price * discount.value_amount / 100;
                }
    
                if (discount.apply_to_pax_number && discount.min_pax_number && discount.max_pax_number &&
                    discount.min_pax_number <= contract.travelers?.length && contract.travelers?.length <= discount.max_pax_number) {
                    // if (discount.value_is_reduction) final_net_price -= (real_amount_net / (contract.travelers?.length || 1));
                    // else final_net_price += (real_amount_net / (contract.travelers?.length || 1));
                    if (discount.apply_to_pax && discount.min_pax && discount.max_pax &&
                        discount.min_pax <= (index + 1) && (index + 1) <= discount.max_pax) {
                            if (discount.value_is_reduction) {
                                total_reduction_net -= real_amount_net;
                                final_net_price -= real_amount_net;
                                total_reduction_public -= real_amount_public;
                                final_public_price -= real_amount_public;
                            } else {
                                total_reduction_net += real_amount_net;
                                final_net_price += real_amount_net;
                                total_reduction_public += real_amount_public;
                                final_public_price += real_amount_public;
                            }
                        }
                } else if (discount.apply_to_traveler_age && discount.min_traveler_age && discount.max_traveler_age && current_traveler_age &&
                    discount.min_traveler_age <= current_traveler_age && current_traveler_age <= discount.max_traveler_age) {
                        if (discount.apply_to_pax && discount.min_pax && discount.max_pax && discount.min_pax >= index && index <= discount.max_pax) {
                            if (discount.value_is_reduction) {
                                total_reduction_net -= real_amount_net;
                                final_net_price -= real_amount_net;
                                total_reduction_public -= real_amount_public;
                                final_public_price -= real_amount_public;
                            } else {
                                total_reduction_net += real_amount_net;
                                final_net_price += real_amount_net;
                                total_reduction_public += real_amount_public;
                                final_public_price += real_amount_public;
                            }
                        }
                } else if (discount.apply_to_validity_date && discount.validity_start_date && discount.validity_end_date) {
                    if (discount.apply_to_pax && discount.min_pax && discount.max_pax && discount.min_pax >= index && index <= discount.max_pax) {
                        const vsdate = new Date(discount.validity_start_date);
                        const vedate = new Date(discount.validity_end_date);
                        const now = new Date(discount.validity_end_date);
                        if (vsdate.getTime() <= now.getTime() && now.getTime() <= vedate.getTime()) {
                            // if (discount.value_is_reduction) final_net_price -= (real_amount_net / (contract.travelers?.length || 1));
                            // else final_net_price += (real_amount_net / (contract.travelers?.length || 1));
                            if (discount.value_is_reduction) {
                                total_reduction_net -= real_amount_net;
                                final_net_price -= real_amount_net;
                                total_reduction_public -= real_amount_public;
                                final_public_price -= real_amount_public;
                            } else {
                                total_reduction_net += real_amount_net;
                                final_net_price += real_amount_net;
                                total_reduction_public += real_amount_public;
                                final_public_price += real_amount_public;
                            }
                        }
                    }
                } else if (discount.apply_to_duration && discount.min_duration && discount.max_duration) {
                    if (discount.apply_to_pax && discount.min_pax && discount.max_pax && discount.min_pax >= index && index <= discount.max_pax) {
                        const date1 = new Date(contract.travel_start_date);
                        const date2 = new Date(contract.travel_end_date);
                        const diffTime = Math.abs(date2 - date1);
                        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
                        if (discount.min_duration <= diffDays && diffDays <= discount.max_duration) {
                            // if (discount.value_is_reduction) final_net_price -= (real_amount_net / (contract.travelers?.length || 1));
                            // else final_net_price += (real_amount_net / (contract.travelers?.length || 1));
                            if (discount.value_is_reduction) {
                                total_reduction_net -= real_amount_net;
                                final_net_price -= real_amount_net;
                                total_reduction_public -= real_amount_public;
                                final_public_price -= real_amount_public;
                            } else {
                                total_reduction_net += real_amount_net;
                                final_net_price += real_amount_net;
                                total_reduction_public += real_amount_public;
                                final_public_price += real_amount_public;
                            }
                        }
                    }
                } else if (discount.apply_to_kids && discount.min_kids && discount.max_kids) {
                    if (discount.apply_to_pax && discount.min_pax && discount.max_pax && discount.min_pax >= index && index <= discount.max_pax) {
                        let kids = 0;
                        contract.travelers?.forEach(traveler => {
                            const b_date = new Date(traveler.birth_date);
                            const age = (new Date().getFullYear()) - b_date.getFullYear();
                            if (age < 18) kids++;
                        });
                        if (discount.min_kids <= kids && kids <= discount.max_kids) {
                            // if (discount.value_is_reduction) final_net_price -= (real_amount_net / (contract.travelers?.length || 1));
                            // else final_net_price += (real_amount_net / (contract.travelers?.length || 1));
                            if (discount.value_is_reduction) {
                                total_reduction_net -= real_amount_net;
                                final_net_price -= real_amount_net;
                                total_reduction_public -= real_amount_public;
                                final_public_price -= real_amount_public;
                            } else {
                                total_reduction_net += real_amount_net;
                                final_net_price += real_amount_net;
                                total_reduction_public += real_amount_public;
                                final_public_price += real_amount_public;
                            }
                        }
                    }
                }
            }
        });
    }
    total_reduction_net = is_reduction && total_reduction_net !== 0 ? total_reduction_net * (-1) : total_reduction_net;
    total_reduction_public = is_reduction && total_reduction_public !== 0 ? total_reduction_public * (-1) : total_reduction_public;
    return {final_net_price, total_reduction_net, total_reduction_public, is_reduction};
};

export const updateContractPrices = (contract: Contract, subscriberIndex: number, subscribing_agency_id: number, index: any, type: string, price?: string, public_price?: string, force_price?: boolean, force_public_price?: boolean, add_incentive?: boolean) => {
    const contract_c = JSON.parse(JSON.stringify((contract)));
    if (force_price) contract_c.travelers[index].price = parseFloat(price || "0");
    else contract_c.travelers[index].price = parseFloat(price || contract_c.travelers[index].price || "0");

    if (contract_c.principal_product) {
        const trav_price = contract_c.travelers[index].price;
        // INCENTIVES
        let incentive_value = 0;
        const incentive = contract_c.principal_product?.agencies_incentive?.find(ai => (ai.agency?.id || ai.agency) === subscribing_agency_id);
        if (incentive) {
            let is_in_price = null;
            if (!contract_c.principal_product?.is_in_duration_destination) {
                is_in_price = contract_c.principal_product?.prices.find((price) => {
                    return trav_price >= price.min_slice && trav_price <= price.max_slice;
                });
            } else {
                if (contract_c.travel_start_date !== null && contract_c.travel_end_date !== null) {
                    let diff = differenceInDays(
                        new Date(contract_c.travel_end_date),
                        new Date(contract_c.travel_start_date)
                    );
                    is_in_price = contract_c.principal_product?.prices.find((price) => {
                        return diff >= price.min_slice && diff <= price.max_slice && price.destinations.map(destination => destination.id).includes(contract_c.destinations.id);
                    });
                }
            }
            if (type.includes('GROUP')) {
                let travel_price = contract_c.travel_prices;
                incentive_value = incentive.bonus_type === 'FIX' ? incentive.amount : (((incentive.amount || 0) / 100) * (parseFloat(travel_price) || 1));
            } else if (is_in_price !== undefined) {
                incentive_value = incentive.bonus_type === 'FIX' ? incentive.amount : (((incentive.amount || 0) / 100) * (parseFloat(is_in_price.selling_price) || 1));
            }
            if (!incentive.per_traveler) {
                incentive_value = incentive_value / (contract_c.travelers?.length || contract_c.group_passenger?.travelers_list?.length || 1);
            }
            contract_c.is_re_invoiced = incentive.is_re_invoiced;
        }
        // PRICES
        let price_found = null;
        if (!contract_c.principal_product?.is_in_duration_destination) {
            price_found = contract_c.principal_product.prices?.find(p => p.min_slice <= trav_price && trav_price <= p.max_slice);
        } else {
            if (contract_c.travel_start_date !== null && contract_c.travel_end_date !== null) {
                let diff = differenceInDays(
                    new Date(contract_c.travel_end_date),
                    new Date(contract_c.travel_start_date)
                );
                price_found = contract_c.principal_product.prices?.find(p => p.min_slice <= diff && diff <= p.max_slice);
            }
        }
        let agency_price_found = null;
        if (!contract_c.principal_product?.is_in_duration_destination) {
            agency_price_found = contract_c.principal_product.agencies_prices?.find(p => p.min_slice <= trav_price && trav_price <= p.max_slice && (p.agency?.id || p.agency) == subscribing_agency_id);
        } else {
            if (contract_c.travel_start_date !== null && contract_c.travel_end_date !== null) {
                let diff = differenceInDays(
                    new Date(contract_c.travel_end_date),
                    new Date(contract_c.travel_start_date)
                );
                agency_price_found = contract_c.principal_product.agencies_prices?.find(p => p.min_slice <= diff && diff <= p.max_slice && (p.agency?.id || p.agency) == subscribing_agency_id);
            }
        }
        // Secondary product
        let secondary_public_price = 0;
        let secondary_net_price = 0;
        if (contract_c.secondary_product !== null && contract_c.secondary_product.length !== 0) {
            let secondary_price_found = null;
            let secondary_agency_price_found = null;
            if (!contract_c.secondary_product?.is_in_duration_destination) {
                secondary_price_found = contract_c.secondary_product.prices?.find(p => p.min_slice <= trav_price && trav_price <= p.max_slice);
                secondary_agency_price_found = contract_c.secondary_product.agencies_prices?.find(p => p.min_slice <= trav_price && trav_price <= p.max_slice && (p.agency?.id || p.agency) == subscribing_agency_id);
            } else {
                if (contract_c.travel_start_date !== null && contract_c.travel_end_date !== null) {
                    let diff = differenceInDays(
                        new Date(contract_c.travel_end_date),
                        new Date(contract_c.travel_start_date)
                    );
                    secondary_price_found = contract_c.secondary_product.prices?.find(p => p.min_slice <= diff && diff <= p.max_slice);
                    secondary_agency_price_found = contract_c.secondary_product.agencies_prices?.find(p => p.min_slice <= diff && diff <= p.max_slice && (p.agency?.id || p.agency) == subscribing_agency_id);
                }
            }
            if (secondary_agency_price_found !== undefined && secondary_agency_price_found?.selling_price) {
                if (secondary_agency_price_found.price_type !== 'PER') {
                    secondary_net_price = parseFloat(secondary_agency_price_found?.selling_price);
                } else {
                    secondary_net_price = (parseFloat(secondary_agency_price_found?.selling_price) / 100) * contract_c.travelers[index].price
                }
            } else if (secondary_price_found !== undefined && secondary_price_found?.selling_price) {
                if (secondary_price_found.price_type !== 'PER') {
                    secondary_net_price = parseFloat(secondary_price_found?.selling_price);
                } else {
                    secondary_net_price = (parseFloat(secondary_price_found?.selling_price) / 100) * contract_c.travelers[index].price
                }
            } else {
                secondary_net_price = 0;
            }
            secondary_public_price = secondary_agency_price_found?.recommended_price || secondary_price_found?.recommended_price;
        }
        // net price
        if (agency_price_found !== undefined && agency_price_found?.selling_price) {
            if (agency_price_found.price_type !== 'PER') {
                contract_c.travelers[index].net_price = parseFloat(agency_price_found?.selling_price);
            } else {
                contract_c.travelers[index].net_price = (parseFloat(agency_price_found?.selling_price) / 100) * contract_c.travelers[index].price
            }
        } else if (price_found !== undefined && price_found?.selling_price) {
            if (price_found.price_type !== 'PER') {
                contract_c.travelers[index].net_price = parseFloat(price_found?.selling_price);
            } else {
                contract_c.travelers[index].net_price = (parseFloat(price_found?.selling_price) / 100) * contract_c.travelers[index].price
            }
        } else {
            contract_c.travelers[index].net_price = 0;
        }
        // contract_c.travelers[index].net_price = parseFloat(agency_price_found?.selling_price || price_found?.selling_price || "0");

        // public price
        let recommanded_price = 0;
        if (agency_price_found !== undefined && agency_price_found?.recommended_price) {
            if (agency_price_found.price_type !== 'PER') {
                recommanded_price = parseFloat(agency_price_found?.recommended_price);
            } else {
                recommanded_price = (parseFloat(agency_price_found?.recommended_price) / 100) * contract_c.travelers[index].price
            }
        } else if (price_found !== undefined && price_found?.recommended_price) {
            if (price_found.price_type !== 'PER') {
                recommanded_price = parseFloat(price_found?.recommended_price);
            } else {
                recommanded_price = (parseFloat(price_found?.recommended_price) / 100) * contract_c.travelers[index].price
            }
        } else {
            recommanded_price = 0;
        }
        if (force_public_price) contract_c.travelers[index].public_price = parseFloat(public_price || "0");
        else if (force_price) contract_c.travelers[index].public_price = recommanded_price;
        else contract_c.travelers[index].public_price = parseFloat(public_price) || recommanded_price;
        // secondary public price to public price
        contract_c.travelers[index].public_price = parseFloat(contract_c.travelers[index].public_price || "0") + parseFloat(secondary_public_price ?? "0");
        // add incentive to public price
        contract_c.travelers[index].public_price = parseFloat(contract_c.travelers[index].public_price || "0") + parseFloat(parseFloat(contract_c.travelers[index].public_price) > 0 && incentive !== undefined && incentive !== null && incentive.is_re_invoiced ? incentive_value || "0" : "0");
        // margins
        contract_c.travelers[index].margin_fix = (contract_c.travelers[index].public_price || 0) - (contract_c.travelers[index].net_price || 0);
        contract_c.travelers[index].margin = (contract_c.travelers[index].margin_fix / (contract_c.travelers[index].net_price || 1)) * 100;
        // contract_c.travelers[index].reduction = net_total - contract_c.travelers[index].margin_fix;
        const { final_net_price, total_reduction_net, total_reduction_public, is_reduction} = calculateTravelerNetPrice(contract_c.travelers[index], index, contract_c, subscribing_agency_id)
        contract_c.travelers[index].net_price = contract_c.travelers[index].net_price + secondary_net_price + parseFloat(final_net_price > 0 && incentive !== undefined && incentive !== null && incentive.is_re_invoiced ? incentive_value || "0" : "0");
        contract_c.travelers[index].incentive = incentive_value;
        contract_c.travelers[index].reduction_net = total_reduction_net;
        contract_c.travelers[index].reduction_public = total_reduction_public;
        contract_c.travelers[index].is_reduction = is_reduction;
        // SUBSCRIBER
        if (contract_c.subscriber && subscriberIndex === index) {
            contract_c.subscriber.price = contract_c.travelers[index].price;
            contract_c.subscriber.public_price = contract_c.travelers[index].public_price;
        }
        contract_c.travelers[index].is_re_invoiced = incentive?.is_re_invoiced ?? false;
    }
    if (contract_c.product_extension !== null) {
        const trav_price = contract_c.travelers[index].price;
        let ext_total_price = 0;
        let ext_total_price_net = 0;

        contract_c.product_extension?.forEach(ext => {
            const price_found = ext.prices?.find(p => p.min_slice <= trav_price && trav_price <= p.max_slice);
            const agency_price_found = ext.agencies_prices?.find(p => p.min_slice <= trav_price && trav_price <= p.max_slice && (p.agency?.id || p.agency) == subscribing_agency_id);
            if (agency_price_found?.price_type !== 'PER' && price_found?.price_type !== 'PER') {
                ext_total_price += parseFloat(agency_price_found?.recommended_price || agency_price_found?.selling_price || price_found?.recommended_price || price_found?.selling_price || "0");
                ext_total_price_net += parseFloat(agency_price_found?.selling_price || price_found?.selling_price || "0");
            } else if (agency_price_found?.price_type !== 'PER' || price_found?.price_type !== 'PER') {
                ext_total_price += (parseFloat(agency_price_found?.recommended_price || agency_price_found?.selling_price || price_found?.recommended_price || price_found?.selling_price || "0") / 100) * contract_c.travelers[index].price;
                ext_total_price_net += (parseFloat(agency_price_found?.selling_price || price_found?.selling_price || "0") / 100) * contract_c.travelers[index].price;
            }
        });
        contract_c.travelers[index].extension_price = ext_total_price;
        contract_c.travelers[index].extension_price_net = ext_total_price_net;
    }
    return contract_c;
};

export const resetContractPrices = (contract: Contract) => {
    const contract_c = JSON.parse(JSON.stringify((contract)));

    contract_c.travelers?.forEach(traveler => {
        traveler.price = 0;
        traveler.net_price = 0;
        traveler.net_price = 0;
        traveler.public_price = 0;
        traveler.margin_fix = 0;
        traveler.margin = 0;
        traveler.extension_price = 0;
    });

    return contract_c;
};