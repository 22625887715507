import axios, { AxiosResponse, CancelTokenSource } from "axios";
import { Preferences } from "../types/users/preferences";

let cancelToken: CancelTokenSource | null = null;
export const getPreferences = (client_id: number | null, module_name: string): Promise<AxiosResponse<{ count: number; results: Preferences[]; }>> => {
    if (cancelToken) {
        cancelToken.cancel("Operation canceled due to new request.");
    }
    cancelToken = axios.CancelToken.source();
    return axios.get(
        `${API_HREF}client/${client_id}/user-table-preference/`,
        {
            params: {
                limit: 1000,
                module: module_name
            },
            cancelToken: cancelToken.token,
            headers: {
                'Authorization': `Token ${localStorage.getItem('token')}`,
            }
        }
    );
};

export const addPreferences = (client_id: number | null, preferences: Preferences[]): Promise<AxiosResponse<{ count: number; results: Preferences[]; }>> => {
    if (cancelToken) {
        cancelToken.cancel("Operation canceled due to new request.");
    }
    cancelToken = axios.CancelToken.source();
    return axios.post(
        `${API_HREF}client/${client_id}/user-table-preference/`,
        preferences,
        {
            params: {
                limit: 1000
            },
            cancelToken: cancelToken.token,
            headers: {
                'Authorization': `Token ${localStorage.getItem('token')}`,
            }
        }
    );
};

export const updatePreference = (client_id: number | null, preferences: Preferences[]): Promise<AxiosResponse<{ count: number; results: Preferences[]; }>> => {
    if (cancelToken) {
        cancelToken.cancel("Operation canceled due to new request.");
    }
    cancelToken = axios.CancelToken.source();
    return axios.post(
        `${API_HREF}client/${client_id}/user-table-preference/array_patch/`,
        preferences,
        {
            params: {
                limit: 1000
            },
            cancelToken: cancelToken.token,
            headers: {
                'Authorization': `Token ${localStorage.getItem('token')}`,
            }
        }
    );
};