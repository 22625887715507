
import * as locales from 'date-fns/locale';
import { ArrowBackIosNew, BackupOutlined, Category } from "@mui/icons-material";
import { Grid, Button, Typography, Paper, useTheme, useMediaQuery, Switch, Divider, Avatar } from "@mui/material";
import React, { FC, ReactElement, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { arrayPatch, getUserById, patchUser, postUser } from "../../services/userServices";
import { setPermissions, setProfile, setSelectedUser } from "../../store/reducer";
import { RootState } from "../../store/store";
import Loading from '../common/loading/loading';
import { User } from '../../types/users/user';
import GenericField from '../common/genericList/genericField';
import { updateObjectField } from '../../functions/ObjectHandler';
import { useSnackbar } from 'notistack';
import checkPermissions from '../../functions/checkPermissions';
import ChangePicture from './ChangePicture';

const UserProfile: FC = (): ReactElement => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { enqueueSnackbar } = useSnackbar();

    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down(600));

    const [user, setUser] = useState<User | null>(null);
    const client_id = useSelector((state: RootState) => state.app.client_id);
    const [loading, setLoading] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const profile = useSelector((state: RootState) => state.app.profile);

    useEffect(() => {
        setUser(profile);
    }, [profile]);

    const updateField = (field: string, value: any) => {
        updateObjectField(user, field, value, setUser);
    };

    const validate = () => {
        if (user) {
            if (user.password && user.password !== user.confirm_password) {
                enqueueSnackbar('Mot de passe invalide', { variant: "error" });
                return;
            }

            const user_c = { id: user.id, username: user.username, email: user.email, first_name: user.first_name, last_name: user.last_name, password: user.password };
            if (user.id) {
                patchUser(client_id, user.id, user_c)
                    .then(response => {
                        enqueueSnackbar('Utilisateur modifié', { variant: "success" });
                        dispatch(setProfile(response.data));
                    }).catch(error => {
                        setLoading(false);
                        if (error.response?.data?.errors) {
                            for (const [key, value] of Object.entries(error.response.data.errors)) {
                                if (Array.isArray(value)) {
                                    value.map(error => enqueueSnackbar(`${error}`, { variant: 'error' }));
                                } else {
                                    enqueueSnackbar(`${value}`, { variant: 'error' });
                                }
                            }
                        } else if (error.response) {
                            if (error.response.status === 400) {
                                for (const [key, value] of Object.entries(error.response.data)) {
                                    if (Array.isArray(value)) {
                                        value.map(error => enqueueSnackbar(`${key} : ${error}`, { variant: 'error' }));
                                    } else {
                                        enqueueSnackbar(`${key} : ${value}`, { variant: 'error' });
                                    }
                                }
                            } else if (error.response.status === 401 || error.response.status === 403) {
                                enqueueSnackbar(t('errors.permissions'), { variant: 'error' });
                            } else if (error.response.status === 500) {
                                enqueueSnackbar(t('errors.servers'), { variant: 'error' });
                            }
                        } else if (error.request) {
                            console.log('error.request : ', error.request);
                        } else {
                            console.log('Error', error.message);
                        }
                        console.log('error.config : ', error.config);
                    });
            }
        }
    };

    if (!user) return <Loading />;
    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={isSmallScreen ? 12 : 2}>
                    <Button startIcon={<ArrowBackIosNew />} onClick={() => navigate('/insurance/')}>{t('shared.back')}</Button>
                </Grid>
                <Grid item xs={isSmallScreen ? 12 : 9}>
                    <Typography variant="h4" className="title" style={{ fontWeight: "bold", textAlign: "center" }}>Modification de profil</Typography>
                </Grid>
            </Grid>
            <Grid container spacing={2} alignItems={'flex-start'}>
                <Grid item xs={12} md={4}>
                    <Paper variant='outlined' square style={{ padding: 16, width: "100%" }}>
                        <Grid container direction={"column"} justify={"center"} alignItems={"center"}>
                            <Grid item>
                                <Avatar sx={{ width: 200, height: 200 }} id="imagePreview" src={user.avatar?.thumbnail_little || user.avatar?.url || "/images/avatar.png"} />
                            </Grid>
                            <Grid item style={{ marginTop: 5 }}>
                                <Button onClick={() => setOpenModal(true)} startIcon={<BackupOutlined />}>Changer la photo</Button>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
                <Grid container item xs={12} md={8}>
                    <Paper variant='outlined' square style={{ padding: 16, width: "100%" }}>
                        <Grid container item xs={12} spacing={1}>
                            <Grid item xs={12}>
                                <h5>Informations Personnelles</h5>
                            </Grid>

                            <Grid item xs={12}>
                                <GenericField onChange={updateField} field='first_name' object={user} label="Prénom" />
                            </Grid>
                            <Grid item xs={12}>
                                <GenericField onChange={updateField} field='last_name' object={user} label="Nom" />
                            </Grid>
                            <Grid item xs={12}>
                                <GenericField onChange={updateField} field='username' object={user} label="Nom d'utilisateur" />
                            </Grid>
                            <Grid item xs={12}>
                                <GenericField onChange={updateField} field='phone_number' object={user} label="N° de téléphone" type='phone' />
                            </Grid>
                            <Grid item xs={12}>
                                <GenericField onChange={updateField} field='email' object={user} label="Email" />
                            </Grid>
                            <Grid item xs={12}>
                                <Divider />
                            </Grid>
                            <Grid item xs={12}>
                                <h5>Changer le mot de passe</h5>
                            </Grid>
                            <Grid item xs={12}>
                                <GenericField onChange={updateField} type='password' field='password' object={user} label="Nouveau mot de passe" />
                            </Grid>
                            <Grid item xs={12}>
                                <GenericField onChange={updateField} type='password' field='confirm_password' object={user} label="Confirmer le mot de passe" />
                            </Grid>
                            <Grid item xs={12}>
                                <Divider />
                            </Grid>
                            {/* <Grid item xs={12}>
                                <h5>Préférences</h5>
                            </Grid> */}

                            <Grid item xs={12} style={{ display: "flex", justifyContent: "flex-end", gap: 15 }}>
                                <Button onClick={() => navigate('/insurance/users/')}>Annuler</Button>
                                <Button variant='contained' onClick={validate}>Confirmer</Button>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
            </Grid>
            <ChangePicture setAvatar={(v) => updateObjectField(user, 'avatar', v, setUser)} user={user} setAvatarId={(v) => updateObjectField(user, 'avatar_id', v, setUser)} setOpenModal={setOpenModal} openModal={openModal} avatar={user.avatar}/>
            {loading && <Loading />}
        </>
    );
};

export default UserProfile;