import axios, { AxiosResponse, CancelTokenSource } from "axios";
import { OrganisationMinimal } from "../types/organisationMinimal";

export const patchOrganisationsDisplayIncentive = (client_id: number | null | undefined, data: any): Promise<AxiosResponse<OrganisationMinimal>> => {
    return axios.patch(
        `${API_HREF}client/${client_id}/`,
        data,
        {
            headers: {
                'Authorization': `Token ${localStorage.getItem('token')}`,
            }
        }
    );
};