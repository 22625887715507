// Dependencies
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import React, { FC, ReactElement, useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { setProviders, setCurrencies, setConfig, setLocales, setPermissions, setProfile, setLoggedUserClientId, setContractTypes } from '../store/reducer';
import { RootState } from '../store/store';
// Core & Lab
// Components
import NotFound from './notFound/notFound';
import HomePage from './homePage/HomePage';
import LoginPage from './authentication/LoginPage';
// Functions
// Style
import '../index.css';
import { getPermissions, getUser } from '../services/commonServices';
import InsurancePage from './insurance/InsurancePage';
import ContractList from './contracts/ContractList';
import ContractDetail from './contracts/ContractDetail';
import CreateContractForm from './contracts/CreateContractForm';
import ProductsPage from './products/ProductsPage';
import EditContractForm from './contracts/EditContractForm';
import ProductDetail from './products/ProductDetail';
import { getContractTypes, postContractType } from '../services/contractServices';
import SinistersList from './sinisters/sinistersList';
import SinisterEdit from './sinisters/sinisterEdit';
import BillsPage from './bills/BillsPage';
import UserDocuments from './user-documents/userDocuments';
import { getProductBrands, postProductBrand } from '../services/productServices';
import DefectsList from './contracts/DefectsList';
import EventPage from './event/EventPage';
import UsersPage from './users/UsersPage';
import UserDetail from './users/UserDetail';
import UserProfile from './users/UserProfile';
import IncentivePage from './incentive/IncentivePage';
// Types

const App: FC = (): ReactElement => {
	const dispatch = useDispatch();
	const client_id = useSelector((state: RootState) => state.app.client_id);
	const currencies = useSelector((state: RootState) => state.app.currencies);
	const providers = useSelector((state: RootState) => state.app.providers);

	const [loaded, setLoaded] = useState(false);

	useEffect(() => {
		if (providers === null) {
			axios({
				method: 'GET',
				url: `${API_HREF}providers/`,
				headers: {
					'Accept': 'application/json',
					'Content-Type': 'application/json'
				},
				params: {
					limit: 10000
				}
			}).then((response) => {
				dispatch(setProviders(response.data.results));
			}).catch((error) => {
				console.log(error);
			});
		}
	}, [providers]);

	useEffect(() => {
		if (currencies === null) {
			axios({
				method: 'GET',
				url: `${API_HREF}currencies/`,
				headers: {
					'Accept': 'application/json',
					'Content-Type': 'application/json'
				},
				params: {
					limit: 250
				}
			}).then((response) => {
				dispatch(setCurrencies(response.data.results));
			}).catch((error) => {
				console.log(error);
			});
		}
	}, [currencies]);

	useEffect(() => {
		if (client_id === null) {
			let result = /.([^.]+)/.exec(window.location.host);
			let customer_identifier = result !== null ? result[0] : 'vat-dev';
			let domain_name = window.location.host;
			if (window.location.host === 'localhost:3003') {
				customer_identifier = 'dev';
				domain_name = 'dev.facilitatrip.com';
				// customer_identifier = 'valeurs-assurances';
				// domain_name = 'valeurs-assurances.facilitatrip.fr';
			}
			// Temporary values for testing
			// customer_identifier = 'valeurs-assurances';
			// domain_name = 'valeurs-assurances.facilitatrip.fr';
			axios({
				method: 'GET',
				url: `${API_HREF}instances/?customer_identifier=${customer_identifier}&domain_name=${domain_name}`
			}).then((response) => {
				dispatch(setConfig(response.data));
				const user_id = localStorage.getItem('user_id');
				if (user_id) {
					// get User
					getUser(localStorage.getItem('logged_user_client_id'), +user_id).then((resp) => {
						dispatch(setProfile(resp.data));
						if (resp.data?.client) dispatch(setLoggedUserClientId(resp.data.client));
						getPermissions(response.data.client_id, +user_id).then((res) => {
							dispatch(setPermissions(res.data));
						}).catch((error) => {
							console.log('error : ', error.config);
						});
					}).catch((error) => {
						console.log('error : ', error.config);
					}).finally(() => setLoaded(true));
				} else {
					setLoaded(true);
				}
				if (response.data.favicon !== undefined && response.data.favicon !== null && response.data.favicon.thumbnail_little !== undefined && response.data.favicon.url !== undefined) {
					let link = document.querySelector("link[rel*='icon']") || document.createElement('link');
					// @ts-ignore
					link.type = 'image/x-icon';
					// @ts-ignore
					link.rel = 'shortcut icon';
					// @ts-ignore
					// link.href = (response.data.favicon.thumbnail_little !== null ? response.data.favicon.thumbnail_little : response.data.favicon.url);
					link.href = '/images/VA_logo_min_3c.png'
					document.getElementsByTagName('head')[0].appendChild(link);
				}
			}).catch((error) => {
				console.log('error : ', error);
			});
			axios({
				method: 'GET',
				url: `${API_HREF}locales/?ordering=id`
			}).then((response) => {
				dispatch(setLocales(response.data.results));
			}).catch((error) => {
				console.log('error : ', error);
			});
		} else {
			// get Contarct Types
			getContractTypes(client_id).then((resp) => {
				if (!resp.data.results?.length) {
					postContractType(client_id, { abbreviation: "FT-INDIV", name: "Individuel" })
						.then(() => {
							postContractType(client_id, { abbreviation: "FT-GROUP", name: "Groupe" })
								.then(() => {
									postContractType(client_id, { abbreviation: "FT-LOC", name: "Location" })
										.then(() => {
											postContractType(client_id, { abbreviation: "FT-GIR", name: "Gir" })
												.then(() => {
													postContractType(client_id, { abbreviation: "FT-PROD", name: "Production" })
														.then(() => {
															getContractTypes(client_id).then((respo) => {
																dispatch(setContractTypes(respo.data.results));
															});
														})
												})
										})
								})
						})
				} else dispatch(setContractTypes(resp.data.results));
			}).catch((error) => {
				console.log('error : ', error.config);
			});

			// get brands
			getProductBrands(client_id, 10, 0).then((resp) => {
				if (!resp.data.results?.length) {
					postProductBrand(client_id, { name: "LIZA" })
						.then(() => {
							postProductBrand(client_id, { name: "MICE" })
								.then(() => {
									postProductBrand(client_id, { name: "Valeur Assurance" })
										.then(() => {
											console.log('All brands loaded successfully');
										});
								});
						});
				} else console.log('All brands loaded successfully');
			}).catch((error) => {
				console.log('error : ', error.config);
			});
		}

	}, [client_id]);

	if (!loaded) return <></>;

	return (
		<Routes>
			<Route path={'/'} element={<HomePage />} />
			<Route path={'/login'} element={<LoginPage />} />
			<Route path={'/reset-password'} element={<LoginPage />} />
			<Route path={'/event'} element={<EventPage />} />
			<Route path={'insurance'} element={<InsurancePage />} children={[
				<Route path='' element={<ProductsPage />} />,
				<Route path='product/:id' element={<ProductDetail />} />,
				<Route path='contracts' element={<ContractList />} />,
				<Route path='contracts/:id' element={<ContractDetail />} />,
				<Route path='edit-contract/:id' element={<EditContractForm />} />,
				<Route path='add-contract' element={<CreateContractForm />} />,
				<Route path='sinisters' element={<SinistersList />} />,
				<Route path='sinisters/:id' element={<SinisterEdit />} />,
				<Route path='bills' element={<BillsPage />} />,
				<Route path='defects' element={<DefectsList />} />,
				<Route path='users' element={<UsersPage />} />,
				<Route path='user/:id' element={<UserDetail />} />,
				<Route path='profile' element={<UserProfile />} />,
				<Route path='incentive' element={<IncentivePage />} />,
			]} />
			<Route path='/upload-documents/:sinister_id' element={<UserDocuments />} />,
			<Route path={'/not-found'} element={<NotFound />} />
			<Route path={'/*'} element={<NotFound />} />
		</Routes>
	)
};

export default App;