// Dependencies
import { useTranslation } from 'react-i18next';
import React, { FC, ReactElement } from 'react';
// Core & Lab
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';

const NotFound: FC = (): ReactElement => {
    const { t } = useTranslation();
    return (
        <Container maxWidth={ 'xl' }>
            <Grid className={ 'not-found-container' } container justifyContent={ 'center' } alignItems={ 'center' } spacing={ 2 }>
                <Grid item xs>
                    <Paper className={ 'not-found-paper' } elevation={ 6 }>
                        <Typography align={ 'center' }>{ t('page-not-found') }</Typography>
                    </Paper>
                </Grid>
            </Grid>
        </Container>
    );
};

export default NotFound;