// Dependencies
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect, FC, ReactElement, useState, Fragment } from 'react';
import { AxiosResponse } from 'axios';
import { jsPDF } from 'jspdf';
import * as XLSX from 'xlsx';
import 'jspdf-autotable';
import ReactDOMServer from 'react-dom/server';
// Core & Lab
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import { Divider, FormControlLabel, IconButton, Menu, MenuItem, Switch, TablePagination } from '@mui/material';
// Icons
import Add from '@mui/icons-material/Add';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { MoreVert } from '@mui/icons-material';
// Components
import ListHandler from './listHandler';
import Finished from '../loading/finished';
// Services
import { getPreferences, updatePreference, addPreferences } from '../../../services/preferenceServices';
// Store
import { RootState } from '../../../store/store';
// Style
import '../../../styles/list.css';
// Types
import { FilterData } from '../../../types/genericModule/filterData';
import { GenericColumn } from '../../../types/genericModule/genericColumn';
import { Preferences } from '../../../types/users/preferences';
import { patchOrganisationsDisplayIncentive } from '../../../services/organisationServices';
import { getUser } from '../../../services/commonServices';
import { setProfile } from '../../../store/reducer';
import clsx from 'clsx';

const GenericList: FC<{
    prefModuleName: string,
    cols: GenericColumn[],
    dataLoader: (client_id: number | null, limit: number, offset: number, filter: any) => Promise<AxiosResponse<{ count: number; results: any[]; }>>,
    onAddClick?: () => void,
    onRowClick?: (item: any) => void,
    selected_row?: any,
    actionSelectedItem?: any
    labels: {
        title: string,
        long_title?: string,
        add_button?: string
    },
    addPermission?: string,
    search?: string,
    filtersData?: FilterData[],
    actionsMenu?: { label: any, handleClick: (row: any, event: any) => void }[],
    reloader?: number,
    cachedList?: any[],
    setCachedList?: (list: any[]) => void,
    incentiveFilter? : any,
    setTotalIncentive? : (total: number) => void
}> = ({ prefModuleName, dataLoader, cols, onAddClick, onRowClick, selected_row, labels, addPermission, search, filtersData, actionsMenu, reloader, cachedList, setCachedList, incentiveFilter, setTotalIncentive }): ReactElement => {
    const { t } = useTranslation();
    const dispatch = useDispatch()

    const client_id = useSelector((state: RootState) => state.app.client_id);
    const profile = useSelector((state: RootState) => state.app.profile);

    const [allItemsCount, setAllItemsCount] = useState<number>(0);
    const [pageSize, setPageSize] = useState<number>(25);
    const [active_page, setActivePage] = useState<number>(1);
    const [rows, setRows] = useState<any[]>(cachedList || []);
    const [columns, setColumns] = useState<GenericColumn[]>([]);
    const [prefExist, setPrefExist] = useState(false);
    const [loading, setLoading] = React.useState(false);
    const [listFilter, setListFilter] = React.useState<any>({});
    const [preferences, setPrefs] = React.useState<any>([]);
    const [displayIncentive, setDisplayIncentive] = useState<boolean | undefined>(false);
    //Filter drawer
    const [anchorElDrawer, setAnchorElDrawer] = useState(null);
    useEffect(() => {
        if (client_id) {
            setDisplayIncentive(profile?.client_full?.display_incentive);
            getPreferences(client_id, prefModuleName).then((res) => {
                const prefs = res.data?.results;
                if (prefs.length) {
                    setPrefs(prefs);
                    setPrefExist(true);
                    cols.forEach((col, col_index) => {
                        const pref = prefs.find(p => p.field_name === col.field.toUpperCase());
                        if (pref) {
                            cols[col_index].order = pref.order;
                            cols[col_index].ordering_asc = pref.ordering_asc;
                            cols[col_index].active = pref.active;
                            cols[col_index].id = pref.id;
                        }
                    });
                }
                cols = cols.sort((a, b) => a.order - b.order);
                setColumns(cols);
            }).catch((error) => console.log('error:', error));
            loadRows(1);
        }
    }, []);

    useEffect(() => {
        cols.forEach((col, col_index) => {
            const pref = preferences.find((p: any) => p.field_name === col.field.toUpperCase());
            if (pref) {
                cols[col_index].order = pref.order;
                cols[col_index].ordering_asc = pref.ordering_asc;
                cols[col_index].active = pref.active;
                cols[col_index].id = pref.id;
            }
        });
        cols = cols.sort((a, b) => a.order - b.order);
        setColumns(cols);
        loadRows(1);
    }, [reloader, incentiveFilter]);

    useEffect(() => {
        loadRows(1, search);
    }, [search]);

    const handleClickDrawer = (event: any) => {
        setAnchorElDrawer(event.currentTarget);
    };
    const handleCloseDrawer = () => {
        setAnchorElDrawer(null);
    };

    const loadRows = (page: number, name?: string, size?: number, filter?: any) => {
        if (!filter) filter = {};
        setLoading(true);
        let current_filter = {...filter};
        if (incentiveFilter !== undefined) {
            current_filter = {...current_filter, ...incentiveFilter};
        } else if (name) {
            current_filter.search = name;
        }
        dataLoader(client_id, size ? size : pageSize, (page <= 0 ? 0 : page - 1) * (size ? size : pageSize), current_filter).then((resp) => {
            if (resp?.data) {
                setLoading(false);
                setAllItemsCount(resp.data.count !== undefined ? resp.data.count : resp.data.length);
                setRows(resp.data.results !== undefined ? resp.data.results : resp.data);
                if (setCachedList !== undefined) setCachedList(resp.data.results !== undefined ? resp.data.results : resp.data);
                if (incentiveFilter !== undefined) {
                    dataLoader(client_id, 500, 0, current_filter).then((resp) => {
                        let data = resp.data.results !== undefined ? resp.data.results : resp.data;
                        let total_incentive = data.reduce((prev: number, current: { margin_fix: string; }) => prev + parseFloat(current.margin_fix), 0);
                        if (total_incentive !== undefined) setTotalIncentive(total_incentive);
                    });
                }
            }
        }).catch((err) => console.log('ERR', err));
    };

    //CLICK ITEM
    const clickItem = (item: any) => {
        if (onRowClick !== undefined) onRowClick(item);
    };

    //PAGINATION
    const handlePageChange = (page: number) => {
        setActivePage(page + 1);
        loadRows(page + 1);
    };
    const onLimitChange = (event: { target: { value: any; }; }) => {
        setPageSize(event.target.value);
        loadRows(1, undefined, event.target.value);
    };

    //REFRESH LIST
    const refreshList = () => {
        loadRows(active_page, undefined, pageSize);
    };

    //HANDLE COLUMNS
    const onUpdateColumns = (columns: GenericColumn[]) => {
        setColumns(columns);
        setPreferences(columns);
    };

    const setPreferences = (columns: GenericColumn[]) => {
        const prefs: Preferences[] = columns.map((c, index) => {
            return ({
                active: c.active,
                can_edit: true,
                field_name: c.field.toUpperCase(),
                module: prefModuleName,
                order: index + 1,
                ordering_asc: c.ordering_asc,
                id: c.id
            } as any);
        });
        if (prefExist) {
            updatePreference(client_id, prefs).then(() => {
                console.log('prefs updated');
            }).catch((error) => console.log('error:', error));
        } else {
            addPreferences(client_id, prefs).then(() => {
                console.log('prefs added');
            }).catch((error) => console.log('error:', error));
        }
    };

    //HANDLE SORT
    const switchColumnSort = (column: GenericColumn) => {
        if (!column.sortable) {
            console.log('column.sortable', column);
            return;
        }
        const field = column.field;
        let updatedColumns = [...columns];
        const colIndex = updatedColumns.findIndex(item => item.field === field);
        if (colIndex !== -1) {
            const sortValueIni = updatedColumns[colIndex].ordering_asc;
            updatedColumns = updatedColumns.map(e => ({ ...e, ordering_asc: null }));
            if (sortValueIni === false) {
                updatedColumns[colIndex].ordering_asc = null;
            } else if (sortValueIni === true) {
                updatedColumns[colIndex].ordering_asc = false;
            } else {
                updatedColumns[colIndex].ordering_asc = true;
            }
        }
        setColumns(updatedColumns);
        setPreferences(updatedColumns);
        const filter = updatedColumns[colIndex].ordering_asc !== null ? { ordering: `${updatedColumns[colIndex].ordering_asc ? '-' : ''}${updatedColumns[colIndex].nested_field || updatedColumns[colIndex].field}` } : null;
        loadRows(1, undefined, pageSize, { ...listFilter, ...filter });
    };

    const onAddItem = () => {
        if (onAddClick !== undefined) onAddClick();
    };

    //HANDLE FILTER
    function formatDate(date: Date) {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    }
    function getDatesForValue(value: string) {
        const today = new Date();
        const startDate = new Date();
        const endDate = new Date();

        switch (value) {
            case 'current_week':
                startDate.setDate(today.getDate() - today.getDay()); // Set to the beginning of the current week (Sunday)
                break;
            case 'last_week':
                startDate.setDate(today.getDate() - today.getDay() - 7); // Set to the beginning of the last week (Sunday)
                endDate.setDate(startDate.getDate() + 6); // Set to the end of the last week (Saturday)
                break;
            case 'current_month':
                startDate.setDate(1); // Set to the first day of the current month
                break;
            case 'last_month':
                startDate.setMonth(today.getMonth() - 1); // Set to the first day of the last month
                startDate.setDate(1);
                endDate.setDate(0); // Set to the last day of the last month
                break;
            case 'last_30':
                startDate.setDate(today.getDate() - 29); // Set to 30 days ago from today
                break;
            case 'this_year':
                startDate.setMonth(0); // Set to January 1st of the current year
                startDate.setDate(1);
                break;
            default:
                break;
        }

        return {
            startDate: formatDate(startDate),
            endDate: formatDate(endDate || today),
        };
    }
    const filterChangeHandler = (field: string, value: any, nested_field?: string) => {
        const list_filter_c = JSON.parse(JSON.stringify(listFilter));
        if (['current_week', 'last_week', 'current_month', "last_month", "last_30", "this_year"].includes(value)) {
            const { startDate, endDate } = getDatesForValue(value);
            list_filter_c[nested_field || field + "__gte"] = startDate;
            list_filter_c[nested_field || field + "__lte"] = endDate;
            if (['created_date', 'modified_date'].includes(field)) {
                list_filter_c[nested_field || field + "__gte"] = startDate + 'T00:00:00';
                list_filter_c[nested_field || field + "__lte"] = endDate + 'T00:00:00';
            }
        } else {
            list_filter_c[nested_field || field] = value;
        }
        setListFilter(list_filter_c);
        loadRows(1, '', pageSize, list_filter_c);
    };

    const applyFilterHandler = (filters: any[]) => {
        const filter_obj: any = {};
        const filters_copy = JSON.parse(JSON.stringify(filters));
        filters_copy.forEach((filter: any) => {
            if (filter.type === 'autocomplete' && filter.value?.id) {
                filter.value = filter.value.id;
            }
            if (['current_week', 'last_week', 'current_month', "last_month", "last_30", "this_year"].includes(filter.value)) {
                const { startDate, endDate } = getDatesForValue(filter.value);
                filter_obj[filter.field + "__gte"] = startDate;
                filter_obj[filter.field + "__lte"] = endDate;
                if (['created_date', 'modified_date'].includes(filter.field)) {
                    filter_obj[filter.field + "__gte"] = startDate + 'T00:00:00';
                    filter_obj[filter.field + "__lte"] = endDate + 'T00:00:00';
                }
            } else if (filter.value) {
                filter_obj[filter.field] = filter.value;
            }
        });
        setListFilter(filter_obj);
        loadRows(1, '', pageSize, filter_obj);
    };

    const resetFilterHandler = () => {
        setListFilter({});
        loadRows(1, '', pageSize, {});
    };

    /** EXPORT */
    const onExportLocal = (type: string) => {
        setLoading(true);
        if (type === 'XLSX') {
            const table_export = (
                <table>
                    <tr>
                        {
                            columns.map(column => column.active && column.label && (
                                <th key={`column-tr-${column.field}`} className={column.sortable ? 'sortable-th' : ''} onClick={() => switchColumnSort(column.field)}>
                                    {column.label}
                                </th>
                            ))
                        }
                    </tr>
                    {
                        rows?.map((row: any, row_index) => {
                            return (
                                <tr key={`row-tr-${row_index}`} className={`clickable_row ${selected_row?.id === row.id ? "selected_row" : ""}`} onClick={() => clickItem(row)}>
                                    {
                                        columns.map((column: GenericColumn, col_index: number) => {
                                            if (!column.active || !column.label) {
                                                return <td key={`row-tr-${row_index}-${col_index}`}></td>;
                                            }
                                            return (
                                                <td key={`row-tr-${row_index}-${col_index}`} style={{ minWidth: column.width ? column.width : '' }}>{
                                                    column.displayExportValue !== undefined ? column.displayExportValue(row[column.field], row) : (
                                                        column.displayValue !== undefined ? column.displayValue(row[column.field], row) : row[column.field]
                                                    )
                                                }</td>
                                            );
                                        })
                                    }
                                </tr>
                            );
                        })
                    }
                </table>
            );
            const workbook = XLSX.utils.book_new();
            const string_data = ReactDOMServer.renderToStaticMarkup(table_export).trim();
            const template = document.createElement('template');
            template.innerHTML = string_data;
            XLSX.utils.book_append_sheet(workbook, XLSX.utils.table_to_sheet(template.content.firstChild), labels.long_title ? labels.long_title : labels.title);
            XLSX.writeFile(workbook, `${labels.long_title ? labels.long_title : labels.title}.xlsx`);
        } else if (type === 'PDF') {
            const doc = new jsPDF();
            const head: string[] = [];
            const body: string[][] = [];
            columns.map(column => {
                if (column.active && column.label) {
                    head.push(column.label);
                }
            });
            rows?.map(item => {
                const row: string[] = [];
                columns.map((column: GenericColumn, col_index: number) => {
                    if (column.active && column.label) row.push(
                        column.displayExportValue !== undefined ? column.displayExportValue(item[column.field], item) : (
                            column.displayValue !== undefined ? column.displayValue(item[column.field], item) : item[column.field]
                        )
                    );
                });
                body.push(row);
            });
            (doc as any).autoTable({
                head: [head],
                body: body
            });
            doc.save(`${labels.long_title ? labels.long_title : labels.title}.pdf`);
        }
        setLoading(false);
    };

    /** ACTIONS */
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [selectedIndex, setSelectedIndex] = React.useState<number>(-1);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>, index: number) => {
        setAnchorEl(event.currentTarget);
        setSelectedIndex(index);
    };
    const handleClose = (row_index: number, index: number) => {
        setAnchorEl(null);
        setSelectedIndex(-1);
        if (actionsMenu && actionsMenu[index] && actionsMenu[index].handleClick !== undefined && row_index !== -1 && index !== -1) {
            actionsMenu[index].handleClick(rows.at(row_index), null);
        }
    };
    const handleDisplayChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        patchOrganisationsDisplayIncentive(profile.client, { display_incentive: event.target.checked }).then((resp) => {
            getUser(profile.client, profile?.id).then((resp_user) => {
                dispatch(setProfile(resp_user.data));
            });
        });
        setDisplayIncentive(event.target.checked);
    };
    return (
        <div className={ clsx('ft-list-root', {['ft-list-rootShift']: anchorElDrawer !== null}) }>
            <Grid container spacing={2}>
                <Grid item container xs={12} md={12} spacing={2}>
                    <Grid item xs={12}>
                        <div>
                            <Grid container spacing={2}>
                                {
                                    labels.title &&
                                    <Grid item>
                                        <Typography className={'list-title'} variant={'h4'} component={'div'}>Liste des {labels.title}</Typography>
                                    </Grid>
                                }
                                {
                                    labels.add_button &&
                                    <Grid item>
                                        <Button className={'ft-list-blueTextButton'} style={{ marginTop: 5 }} startIcon={<Add />} onClick={onAddItem}>{labels.add_button}</Button>
                                    </Grid>
                                }
                            </Grid>
                        </div>
                        <div className="list-container">
                            {
                                // prefModuleName === 'VA_USERS' &&
                                prefModuleName === 'VA_USERS' && profile?.is_agency_director &&
                                <div style={{marginTop: '20px', marginBottom: '20px'}}>
                                    <Divider/>
                                    <FormControlLabel sx={{marginTop: '10px', marginBottom: '10px'}} control={<Switch checked={displayIncentive} onChange={handleDisplayChange} />} label="Autoriser les utilisateurs à voir leurs incentives" />
                                    <Divider/>
                                </div>
                            }
                            <ListHandler
                                columns={columns}
                                onUpdateColumns={onUpdateColumns}
                                refreshList={refreshList}
                                filtersData={filtersData}
                                filterChangeHandler={filterChangeHandler}
                                applyFilterHandler={applyFilterHandler}
                                onExport={onExportLocal}
                                resetFilterHandler={resetFilterHandler}
                                handleClickDrawer={handleClickDrawer}
                                handleCloseDrawer={handleCloseDrawer}
                                anchorElDrawer={anchorElDrawer}
                            />
                            <div className='table-container'>
                                <table>
                                    <tr>
                                        {
                                            columns.filter(c => !c.fullWidth)?.map((column: GenericColumn, col_index: number) => {
                                                if (!column.active) {
                                                    return <Fragment key={`column-tr-${col_index}`}></Fragment>;
                                                }
                                                return (
                                                    <th key={`column-tr-${col_index}`} className={column.sortable ? 'sortable-th' : ''} onClick={() => switchColumnSort(column)}>
                                                        {column.label} {
                                                            column.sortable &&
                                                            <>
                                                                {column.ordering_asc === true && <ArrowDownwardIcon style={{ opacity: 1 }} />}
                                                                {column.ordering_asc === false && <ArrowUpwardIcon style={{ opacity: 1 }} />}
                                                                {/* {(column.ordering_asc === null || column.ordering_asc === undefined) && <ArrowUpwardIcon onClick={() => switchColumnSort(column.field)} />} */}
                                                            </>
                                                        }
                                                    </th>
                                                );
                                            })
                                        }
                                        {
                                            actionsMenu !== undefined && actionsMenu.length > 0 &&
                                            <th></th>
                                        }
                                    </tr>
                                    {
                                        loading &&
                                        <tr>
                                            <td colSpan={columns.filter(c => c.active).length}>
                                                <Box sx={{ width: '100%' }}>
                                                    <LinearProgress />
                                                </Box>
                                            </td>
                                        </tr>
                                    }
                                    {
                                        rows?.map((row: any, row_index) => {
                                            return (
                                                <Fragment key={`row-tr-${row_index}`}>
                                                    <tr className={`clickable_row ${selected_row?.id === row.id ? "selected_row" : ""}`} onClick={() => clickItem(row)}>
                                                        {
                                                            columns.filter(c => !c.fullWidth)?.map((column: GenericColumn, col_index: number) => {
                                                                if (!column.active) {
                                                                    return <Fragment key={`row-tr-${row_index}-${col_index}`}></Fragment>;
                                                                }
                                                                return (
                                                                    <td key={`row-tr-${row_index}-${col_index}`} style={{ minWidth: column.width ? column.width : '', width: column.width ? column.width : '' }}>{
                                                                        column.displayValue !== undefined ? column.displayValue(row[column.field], row) : row[column.field]
                                                                    }</td>
                                                                );
                                                            })
                                                        }
                                                        {
                                                            actionsMenu !== undefined && actionsMenu.length > 0 &&
                                                            <td>
                                                                <IconButton onClick={(e) => handleClick(e, row_index)}>
                                                                    <MoreVert />
                                                                </IconButton>
                                                                <Menu
                                                                    anchorEl={anchorEl}
                                                                    open={open && selectedIndex === row_index}
                                                                    onClose={() => handleClose(-1, -1)}
                                                                >
                                                                    {
                                                                        actionsMenu.map((menu, index) => {
                                                                            return <MenuItem key={`meny-item-index-${index}`} onClick={(e) => handleClose(row_index, index)}>{typeof menu.label === "string" ? menu.label : menu.label(row)}</MenuItem>;
                                                                        })
                                                                    }
                                                                </Menu>
                                                            </td>
                                                        }
                                                    </tr>
                                                    {
                                                        columns.filter(c => c.fullWidth)?.map((column: GenericColumn, col_index: number) => {
                                                            if (!column.active) {
                                                                return <Fragment key={`row-tr-${row_index}`}></Fragment>;
                                                            }
                                                            return (
                                                                <tr key={`row-tr-${row_index}`}>
                                                                    <td key={`row-tr-${row_index}-${col_index}`} style={{ width: '100%', paddingTop: 0, paddingBottom: 0 }} colSpan={20}>{
                                                                        column.displayValue !== undefined ? column.displayValue(row[column.field], row) : row[column.field]
                                                                    }</td>
                                                                </tr>
                                                            );
                                                        })
                                                    }
                                                </Fragment>
                                            );
                                        })
                                    }
                                </table>
                            </div>
                            <div className="list-pagination">
                                {
                                    rows && rows.length > 0 &&
                                    <TablePagination
                                        rowsPerPageOptions={[5, 10, 25, 50]}
                                        component={'div'}
                                        count={allItemsCount}
                                        rowsPerPage={pageSize}
                                        page={active_page - 1}
                                        onPageChange={(e, v) => handlePageChange(v)}
                                        onRowsPerPageChange={onLimitChange} />
                                }
                            </div>
                        </div>
                    </Grid>
                </Grid>
            </Grid>
            <Finished />
        </div>
    );
};

export default GenericList;