
import * as locales from 'date-fns/locale';
import { ArrowBackIosNew } from "@mui/icons-material";
import { Grid, Button, Typography, Paper, useTheme, useMediaQuery } from "@mui/material";
import { format } from "date-fns";
import i18next from "i18next";
import React, { FC, ReactElement, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { getProductById } from "../../services/productServices";
import { setSelectedProduct } from "../../store/reducer";
import { RootState } from "../../store/store";
import { IataCountry } from "../../types/common/iataCountry";
import { InsuranceProduct } from "../../types/insurance/insurance-product";
import GenericPanel from '../common/genericList/genericPanel';
import GenericSubmenu from '../common/genericList/genericSubmenu';
import ProductDetailDescription from './ProductDetailDescription';
import ProductDetailDocuments from './ProductDetailDocuments';
import ProductDetailJoinExtension from './ProductDetailJoinExtension';
import Loading from '../common/loading/loading';

const ProductDetail: FC = (): ReactElement => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const locales_cpy: { [key: string]: any } = locales;

    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down(600));

    const [product, setProduct] = useState<InsuranceProduct | null>(null);
    const [loading, setLoading] = useState(false);
    const selected_product = useSelector((state: RootState) => state.app.selected_product);
    const client_id = useSelector((state: RootState) => state.app.client_id);
    const [menuItems, setMenuItems] = useState<any[]>([]);
    const [selected_menu, setSelectedMenu] = useState(1);

    const { id } = useParams();

    useEffect(() => {
        setLoading(true);
        getProductById(client_id, +id).then((resp) => {
            if (resp.data) {
                setLoading(false);
                dispatch(setSelectedProduct(resp.data));
                setProduct(resp.data);
                handleMenu(resp.data);
            }
        })
    }, [id]);

    const handleMenu = (product: InsuranceProduct) => {
        let menuItems = [
            { id: 1, label: t('products.description') },
            { id: 2, label: t('products.documents') }
        ];
        if (product.type === "PRI") {
            menuItems.push({ id: 3, label: t('products.extensions') });
        } else {
            menuItems.push({ id: 3, label: t('products.link_principal_product') });
        }
        setMenuItems(menuItems);
    };


    if (!product) return <Loading />;
    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={isSmallScreen ? 12 : 2}>
                    <Button startIcon={<ArrowBackIosNew />} onClick={() => navigate('/insurance/')}>{t('shared.back')}</Button>
                </Grid>
                <Grid item xs={isSmallScreen ? 12 : 9}>
                    <Typography variant="h4" className="title" style={{ fontWeight: "bold", textAlign: "center" }}>{product.name}</Typography>
                </Grid>
            </Grid>
            <Grid container spacing={2} alignItems={'flex-start'}>
                <Grid container item xs={isSmallScreen ? 12 : 9}>
                    <Paper variant='outlined' square style={{ padding: 16, width: "100%" }}>
                        {
                            selected_menu === 1 &&
                            <ProductDetailDescription product={product} />
                        }
                        {
                            selected_menu === 2 &&
                            <Grid container item xs={12}>
                                <div style={{ width: "100%" }}>
                                    <ProductDetailDocuments product={product} />
                                </div>
                            </Grid>
                        }
                        {
                            selected_menu === 3 &&
                            <ProductDetailJoinExtension extension={product} />
                        }
                    </Paper>
                </Grid>
                <Grid item xs={isSmallScreen ? 12 : 3}>
                    <Paper variant='outlined' square style={{ padding: 16 }}>
                        <GenericSubmenu items={menuItems} onClickItem={setSelectedMenu} />
                    </Paper>
                </Grid>
            </Grid>
            {loading && <Loading />}
        </>
    );
};

export default ProductDetail;