// Dependencies
import { useTranslation } from "react-i18next";
import React, { FC, ReactElement, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useSnackbar } from "notistack";
import { cleanSinister } from "../../functions/contract";
import { patchSinister } from "../../services/sinisterServices";
import { setSelectedSinister } from "../../store/reducer";
import { RootState } from "../../store/store";
import { Sinister } from "../../types/contract/sinister";
import GenericPanel from "../common/genericList/genericPanel";
import SinisterForm from "./sinisterForm";
import { getProviders } from "../../services/commonServices";
import Loading from "../common/loading/loading";

const SinisterInfo: FC = (): ReactElement => {
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const dispatch = useDispatch();

    const selected_sinister = useSelector((state: RootState) => state.app.selected_sinister);
    const client_id = useSelector((state: RootState) => state.app.client_id);

    const [edit_step, setEditStep] = useState<string[] | null>(null);
    const [openCancel, setOpenCancel] = useState(false);
    const [loading, setLoading] = useState(false);
    const editPermission = 'change_insuranceproduct';

    const onClose = () => {
        setEditStep(null);
    };

    const onEdit = (sinister: Sinister) => {
        setLoading(true);
        const lead_warranty = sinister?.policy?.principal_product?.warranty?.at(0);
        if (!lead_warranty) {
            enqueueSnackbar(t('sinisters.no_warranty'), { variant: 'error' });
            return;
        }
        const sinisterToEdit: any = cleanSinister(sinister);
        patchSinister(client_id, sinisterToEdit.id, sinisterToEdit)
            .then((response) => {
                enqueueSnackbar(t('product-sinister-updated'), { variant: 'success' });
                setEditStep(null);
                setLoading(false);
                dispatch(setSelectedSinister(response?.data));
            }).catch((error) => {
                setLoading(false);
                if (error.response?.data?.errors) {
                    for (const [key, value] of Object.entries(error.response.data.errors)) {
                        if (Array.isArray(value)) {
                            value.map(error => enqueueSnackbar(`${error}`, { variant: 'error' }));
                        } else {
                            enqueueSnackbar(`${value}`, { variant: 'error' });
                        }
                    }
                } else if (error.response) {
                    if (error.response.status === 400) {
                        for (const [key, value] of Object.entries(error.response.data)) {
                            if (Array.isArray(value)) {
                                value.map(error => enqueueSnackbar(`${key} : ${error}`, { variant: 'error' }));
                            } else {
                                enqueueSnackbar(`${key} : ${value}`, { variant: 'error' });
                            }
                        }
                    } else if (error.response.status === 401 || error.response.status === 403) {
                        enqueueSnackbar(t('errors.permissions'), { variant: 'error' });
                    } else if (error.response.status === 500) {
                        enqueueSnackbar(t('errors.servers'), { variant: 'error' });
                    }
                } else if (error.request) {
                    console.log('error.request : ', error.request);
                } else {
                    console.log('Error', error.message);
                }
                console.log('error.config : ', error.config);
            });
    };

    if (edit_step) {
        return (
            <>
                <SinisterForm mode="edit" onConfirm={onEdit} onConfirmLabel={t('sinisters.edit')} onCancel={onClose} display={edit_step} />
                {loading && <Loading />}
            </>
        );
    }
    else if (selected_sinister !== null) {
        return (
            <>
                <GenericPanel mainTitle={t("sinisters.description")} editStepCallback={() => setEditStep(["WARRANTY", "DESCRIPTION"])}
                    fields={[
                        { title: t("sinisters.name"), value: selected_sinister.warranty?.name },
                        { title: t("sinisters.code"), value: selected_sinister.warranty?.code },
                        { title: t("sinisters.warranty_reference"), value: selected_sinister.warranty?.reference },
                        { title: t("sinisters.contract_reference"), value: selected_sinister.policy?.reference || selected_sinister.policy?.id },
                        { title: t("sinisters.circumstance"), value: selected_sinister.motif?.motif},
                        { title: t("sinisters.email"), value: selected_sinister.email || selected_sinister.policy?.subscriber?.email},
                    ]}
                    editPermission={editPermission}
                />
            </>
        );
    }
    return <></>;
};

export default SinisterInfo;
