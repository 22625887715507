// Dependencies
import { useTranslation } from 'react-i18next';
import React, { FC, ReactElement } from 'react';
import { useSelector } from 'react-redux';
// Core & Lab
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Accordion from '@mui/material/Accordion';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import { CircularProgress, Grid } from '@mui/material';
import Box from '@mui/material/Box';
// Icons
import VisibilityIcon from '@mui/icons-material/Visibility';
import DownloadIcon from '@mui/icons-material/Download';
import { RootState } from '../../store/store';
import { dowloadDocument } from '../../services/commonServices';
import { InsuranceProduct } from '../../types/insurance/insurance-product';

const ProductDetailDocuments: FC<{ product: InsuranceProduct | null }> = ({ product }): ReactElement => {
    const { t } = useTranslation();

    const client_id = useSelector((state: RootState) => state.app.client_id);

    const [loading, setLoading] = React.useState(false);

    const download = (file: any, newTab?: boolean) => {
        setLoading(true);
        const filename = file.name ? file.name : file.file_name;
        dowloadDocument(client_id, file.file_name).then((response) => {
            if (response.data) {
                const url = URL.createObjectURL(response.data);
                if (newTab) {
                    window.open(url, '_blank');
                } else {
                    const a = document.createElement("a");
                    a.href = url;
                    a.setAttribute('download', filename ? filename : Date.now().toString());
                    a.dispatchEvent(new MouseEvent('click'));
                }
            }
        }).catch((error) => {
            console.log(error);
        }).finally(() => setLoading(false));
    };

    return (
        <Accordion square expanded>
            <AccordionSummary className={'ft-title'}>
                <Typography variant="subtitle1" className="title">{t('products.manage_documents')}</Typography>
            </AccordionSummary>
            <AccordionDetails style={{ paddingTop: 16 }}>
                <Grid container spacing={2}>
                    <Grid item xs={8}>
                        <h3>{t('products.product_documents')}</h3>
                    </Grid>
                    <Grid item xs={4} style={{ display: "flex", justifyContent: "flex-end" }}>
                    </Grid>
                    {
                        product?.documents?.map((doc) => {
                            return (
                                <Grid key={doc.id} item xs={12} className={'va-extension-document'}>
                                    <div>
                                        <p>{doc.name}</p>
                                    </div>
                                    <div>
                                        <IconButton aria-label="download" onClick={() => download(doc)}><DownloadIcon /></IconButton>
                                        <IconButton aria-label="view" onClick={() => download(doc, true)}><VisibilityIcon /></IconButton>
                                    </div>
                                </Grid>
                            );
                        })
                    }
                </Grid>
                {
                    loading &&
                    <div className="upload-progress">
                        <Box sx={{ width: '400px' }}>
                            <CircularProgress size={24} />
                        </Box>
                    </div>
                }
            </AccordionDetails>
        </Accordion>
    );

};

export default ProductDetailDocuments;