import { Grid, Typography } from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { FC, ReactElement } from "react";
import { updateObjectField } from "../../functions/ObjectHandler";
import { Contract } from "../../types/contract/contract";
import GenericField from "../common/genericList/genericField";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { updateContractPrices } from "../../functions/contract";

type minMaxPriceProps =  {
    min_price: number,
    max_price: number
};
const ContractFormDates: FC<{
    contract: Contract,
    SetContract: (contract: Contract) => void,
    errors: any,
    type: string | undefined,
    subscriberIndex: number,
    selection: any,
    setErrors: (errors: any) => void
}> = ({ contract, SetContract, errors, type, subscriberIndex, selection, setErrors }): ReactElement => {

    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();

    const profile = useSelector((state: RootState) => state.app.profile);
    // const [minMaxPrice, setMinMaxPrice] = useState<minMaxPriceProps>({min_price: 0, max_price: 0});
    // const updateField = (field: string, value: any) => {
    //     updateObjectField(contract, field, value, SetContract);
    // };

    const updateStartDate = (field: string, value: any) => {
        let contract_c = JSON.parse(JSON.stringify(contract));
        contract_c.travel_start_date = value;
        contract_c.travel_end_date = value;
        const r_date = new Date(contract_c.trip_reservation_date);
        const s_date = new Date(contract_c.travel_start_date);
        r_date.setHours(0, 0, 0, 0);
        s_date.setHours(0, 0, 0, 0);
        if (r_date.getTime() === s_date.getTime()) {
            enqueueSnackbar(t('warning.same_date'), { variant: 'error' });
        }
        if (contract_c.travelers?.some(t => t.price)) {
            enqueueSnackbar(t('contract.prices_changes'), { variant: "warning" });
        }
        contract_c.travelers?.forEach((trav, trav_index) => {
            contract_c = updateContractPrices(contract_c, subscriberIndex, value?.id || value, trav_index, type);
        });
        // enqueueSnackbar(t('contract.incentive_added'), { variant: "warning" });
        SetContract(contract_c);
    };

    const updateEndDate = (field: string, value: any) => {
        let contract_c = JSON.parse(JSON.stringify(contract));
        contract_c.travel_end_date = value;
        if (contract_c.travelers?.some(t => t.price)) {
            enqueueSnackbar(t('contract.prices_changes'), { variant: "warning" });
        }
        contract_c.travelers?.forEach((trav, trav_index) => {
            contract_c = updateContractPrices(contract_c, subscriberIndex, selection.agency?.id || selection.agency, trav_index, type);
        });
        // enqueueSnackbar(t('contract.incentive_added'), { variant: "warning" });
        SetContract(contract_c);
    };

    const updateTripResaDate = (field: string, value: any) => {
        const contract_c = JSON.parse(JSON.stringify((contract)));
        contract_c.trip_reservation_date = value;
        contract_c.travel_start_date = getMinStartDate(value);
        contract_c.travel_end_date = null;
        if (contract_c.principal_product) {
            const now = new Date();
            const resa = new Date(value);
            now.setHours(0, 0, 0, 0);
            resa.setHours(0, 0, 0, 0);
            const diffTime = resa - now;
            const diffHours = Math.ceil(diffTime / (1000 * 60 * 60));
            let w_two_days_set = false;
            contract_c.principal_product.warranty?.forEach(warranty => {
                if (warranty.not_editable_after_two_days && !w_two_days_set && diffHours <= 72 && (type === "INDIV" || type === "LOC")) {
                    const mesg = t('contract.not_editable_after_two_days').toString().replace('{product_name}', contract_c.principal_product.name).replace('{warranty_name}', warranty.name);
                    enqueueSnackbar(mesg, { variant: 'warning' });
                    w_two_days_set = true;
                }
            });
        }
        SetContract(contract_c);
    };

    const getEndDateLimit = () => {
        if (contract.travel_start_date) {
            const start = (contract.travel_start_date as string);
            const date = new Date(start);
            let max = 90;
            if (contract.principal_product?.max_duration) {
                max = contract.principal_product?.max_duration;
            }
            date.setDate(date.getDate() + max);
            return date;
        }
        return undefined;
    };

    const getStartDateLimit = () => {
        if (contract.travel_start_date) {
            const start = (contract.travel_start_date as string);
            const date = new Date(start);
            let min = 0;
            if (contract.principal_product?.min_duration) {
                min = contract.principal_product?.min_duration;
            }
            date.setDate(date.getDate() + min);
            return date;
        }
        return undefined;
    };

    const getMinStartDate = (value?: string | Date | undefined) => {
        let min = value || contract.trip_reservation_date;
        if (!profile?.is_superuser || profile?.client_full?.name !== "Valeurs Assurances") {
            const now = new Date();
            const trip_date = new Date(min || '');
            now.setHours(0, 0, 0, 0);
            trip_date.setHours(0, 0, 0, 0);
            if (trip_date < now) {
                min = new Date();
            }
        };
        return min;
    };

    const updateTravelPrice = (field: string, value: any) => {
        let contract_c = JSON.parse(JSON.stringify((contract)));
        let error_c = { ...errors };
        const new_value = parseFloat(value || "0");
        contract_c[field] = new_value;
        if (!contract_c.is_in_duration_destination) {
            let find_slice = contract_c.principal_product.prices.find((price) => {
                return parseFloat(price.min_slice) <= new_value && new_value <= parseFloat(price.max_slice);
            });
            if (find_slice === undefined) {
                error_c['travel_prices'] = t('list.field_max_error');
                setErrors(error_c);
            } else {
                delete error_c['travel_prices'];
                setErrors(error_c);
            }

        }
        const price_per_traveler = new_value / (contract_c.travelers?.length || 1);
        contract_c.travelers?.forEach((traveler, t_index) => {
            contract_c.travelers[t_index].price = price_per_traveler;
            contract_c = updateContractPrices(contract_c, -1, (selection.agency?.id || selection.agency), t_index, type);
        });
        // enqueueSnackbar(t('contract.incentive_added'), { variant: "warning" });
        SetContract(contract_c);
    };
    // useEffect(() => {
    //     let contract_c = JSON.parse(JSON.stringify((contract)));
    //     if (contract_c.principal_product !== null && !contract_c.principal_product.is_in_duration_destination) {
    //         let min_slice = contract_c.principal_product.prices.reduce((a: { min_slice: number; }, b: { min_slice: number; }) => a.min_slice < b.min_slice ? a : b).min_slice;
    //         let max_slice = contract_c.principal_product.prices.reduce((a: { max_slice: number; }, b: { max_slice: number; }) => a.max_slice > b.max_slice ? a : b).max_slice;
    //         setMinMaxPrice({ min_price: min_slice, max_price: max_slice });
    //     }
    // }, [contract]);
    return (
        <>
            <Grid item xs={12}>
                <Typography variant="subtitle1" className="title">{t('contract.dates')}</Typography>
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
                <GenericField onChange={updateTripResaDate} field={"trip_reservation_date"} object={contract} label={t('contract.trip_reservation_date')} type="datepicker"
                    required injectedError={errors?.trip_reservation_date}
                />
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
                <GenericField onChange={updateStartDate} field={"travel_start_date"} object={contract} label={t('contract.start_date')} type="datepicker"
                    max={contract.travel_end_date} required injectedError={errors?.travel_start_date} readonly={!contract.trip_reservation_date}
                    min={getMinStartDate()} />
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
                <GenericField onChange={updateEndDate} field={"travel_end_date"} object={contract} label={t('contract.end_date')} type="datepicker"
                    min={getStartDateLimit()} max={getEndDateLimit()} required injectedError={errors?.travel_end_date} readonly={!contract.travel_start_date} />
            </Grid>
            {
                // type === "GROUP" && contract.principal_product !== null &&
                // <Grid item xs={12} md={6}>
                //     <GenericField onChange={updateTravelPrice} field={"travel_prices"} object={contract} label={t('contract.travel_price')} required
                //         min={!contract.principal_product.is_in_duration_destination ? minMaxPrice.min_price : undefined} max={!contract.principal_product.is_in_duration_destination ? minMaxPrice.max_price : undefined} injectedError={errors?.travel_prices} />
                // </Grid>
            }
            <Grid item xs={12}>
                <hr />
            </Grid>
        </>
    );
};

export default ContractFormDates;