import axios, { AxiosResponse, CancelTokenSource } from "axios";
import { store } from '../store/store';
import { User } from "../types/users/user";

let cancelToken: CancelTokenSource | null = null;
export const getUsers = (client_id: number | null, limit: number, offset: number, filter?: any): Promise<AxiosResponse<{ count: number; results: User[]; }>> => {
    if (cancelToken) {
        cancelToken.cancel("Operation canceled due to new request.");
    }
    cancelToken = axios.CancelToken.source();
    let handledFilter = {};
    if (filter) {
        handledFilter = { ...filter };
    }
    return axios.get(
        `${API_HREF}client/${store.getState().app.profile?.client}/user/`,
        {
            params: {
                limit,
                offset,
                ...handledFilter,
                subscribing_agency__id: (
                    (store.getState().app.profile?.is_superuser || store.getState().app.profile?.client_full?.name === "Valeurs Assurances")
                        ? undefined
                        : store.getState().app.profile?.client
                ) || undefined,
                all_user: (store.getState().app.profile?.is_superuser || store.getState().app.profile?.client_full?.name === "Valeurs Assurances") ? true : undefined
            },
            cancelToken: cancelToken.token,
            headers: {
                'Authorization': `Token ${localStorage.getItem('token')}`,
            }
        }
    );
};

export const getUserById = (client_id: number | null, user_id: string | undefined): Promise<AxiosResponse<User>> => {
    if (cancelToken) {
        cancelToken.cancel("Operation canceled due to new request.");
    }
    cancelToken = axios.CancelToken.source();
    return axios.get(
        `${API_HREF}client/${client_id}/user/${user_id}/`,
        {
            params: {},
            cancelToken: cancelToken.token,
            headers: {
                'Authorization': `Token ${localStorage.getItem('token')}`,
            }
        }
    );
};

export const postUser = (client_id: number | null, user: User): Promise<AxiosResponse<User>> => {
    if (cancelToken) {
        cancelToken.cancel("Operation canceled due to new request.");
    }
    cancelToken = axios.CancelToken.source();
    return axios.post(
        `${API_HREF}client/${client_id}/user/`,
        user,
        {
            cancelToken: cancelToken.token,
            headers: {
                'Authorization': `Token ${localStorage.getItem('token')}`,
            }
        }
    );
};

export const arrayPatch = (client_id: number | null, roles: string[], user_id?: number): Promise<AxiosResponse<User>> => {
    if (cancelToken) {
        cancelToken.cancel("Operation canceled due to new request.");
    }
    cancelToken = axios.CancelToken.source();
    return axios.post(
        `${API_HREF}client/${client_id}/user/${user_id}/permissions/array_post/`,
        roles,
        {
            cancelToken: cancelToken.token,
            headers: {
                'Authorization': `Token ${localStorage.getItem('token')}`,
            }
        }
    );
};

export const patchUser = (client_id: number | null, userId: number | undefined, data: any): Promise<AxiosResponse<User>> => {
    if (cancelToken) {
        cancelToken.cancel("Operation canceled due to new request.");
    }
    cancelToken = axios.CancelToken.source();
    return axios.patch(
        `${API_HREF}client/${client_id}/user/${userId}/`,
        data,
        {
            cancelToken: cancelToken.token,
            headers: {
                'Authorization': `Token ${localStorage.getItem('token')}`,
            }
        }
    );
};

export const putUser = (client_id: number | null, user: User): Promise<AxiosResponse<User>> => {
    if (cancelToken) {
        cancelToken.cancel("Operation canceled due to new request.");
    }
    cancelToken = axios.CancelToken.source();
    return axios.put(
        `${API_HREF}client/${client_id}/user/${user.id}/`,
        user,
        {
            cancelToken: cancelToken.token,
            headers: {
                'Authorization': `Token ${localStorage.getItem('token')}`,
            }
        }
    );
};