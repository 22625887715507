import { Download, Search, Visibility } from "@mui/icons-material";
import { Box, Button, CircularProgress, Grid, IconButton, InputAdornment, TextField } from "@mui/material";
import React, { FC, ReactElement, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { RootState } from "../../store/store";
import GenericList from "../common/genericList/genericList";
import Finished from "../common/loading/finished";
import { setBillList } from "../../store/reducer";
import { dowloadBillDocument, getBills } from "../../services/billServices";
import { format } from "date-fns";
import { OrganisationMinimal } from "../../types/organisationMinimal";
import { FilterData } from "../../types/genericModule/filterData";

const BillsPage: FC = (): ReactElement => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const bill_list = useSelector((state: RootState) => state.app.bill_list);
    const client_id = useSelector((state: RootState) => state.app.client_id);
    const profile = useSelector((state: RootState) => state.app.profile);

    const [search, setSearch] = useState("");
    const [loading, setLoading] = React.useState(false);

    let columns: any = [
        {
            order: 2, field: 'insurance_contract', label: t("bills.policy_id"), sortable: true, active: true,
            displayValue: (value: any) => value ? value.reference : null
        },
        {
            order: 3, field: 'id', label: t("bills.document_id"), sortable: true, active: true,
        },
        {
            order: 4, field: 'invoice_type', label: t("bills.type"), sortable: true, active: true,
        },
        {
            // order: 5, field: 'amount', label: t("bills.commission"), sortable: true, active: true,
        },
        {
            order: 6, field: 'billing_period_start_date', label: t("bills.effect_date"), sortable: true, active: true,
            displayValue: (value: any) => value ? format(new Date(value), 'dd/MM/yyy') : null
        },
        {
            order: 7, field: 'billing_period_end_date', label: t("bills.end_date"), sortable: true, active: true,
            displayValue: (value: any) => value ? format(new Date(value), 'dd/MM/yyy') : null
        },
        {
            order: 8, field: 'due_date', label: t("bills.limit_date"), sortable: true, active: true,
            displayValue: (value: any) => value ? format(new Date(value), 'dd/MM/yyy') : null
        },
        {
            order: 9, field: 'document', label: '', active: true, ordering_asc: null,
            displayValue: (document: any) => {
                if (document.bucket_name !== "" && document.file_name !== "" && document.object_name !== "") {
                    return (
                        <div style={{ display: "flex", gap: 15 }}>
                            <IconButton size='small' onClick={(e) => { e.stopPropagation(); download(document) }}><Download /> </IconButton>
                            <IconButton size='small' onClick={(e) => { e.stopPropagation(); download(document, true) }}><Visibility /> </IconButton>
                        </div>
                    )
                }
            }
        },
    ];
    if (profile?.is_superuser || profile?.client_full?.name === "Valeurs Assurances") {
        columns = [
            {
                order: 1, field: 'client_name', label: t('contract.agency'), active: true, ordering_asc: null,
            }, ...columns
        ];
    }

    const download = (file: any, newTab?: boolean) => {
        dowloadBillDocument(client_id, file?.id).then((response) => {
            if (response.data) {
                const url = URL.createObjectURL(response.data);
                if (newTab) {
                    window.open(url, '_blank');
                } else {
                    const a = document.createElement("a");
                    a.href = url;
                    a.setAttribute('download', file.filename);
                    a.dispatchEvent(new MouseEvent('click'));
                }
            }
        }).catch((error) => {
            console.log(error);
        }).finally(() => setLoading(false));
    };

    const filtersData: FilterData[] = [
        {
            label: t("bills.policy_id"), field: "policy_id"
        },
        {
            label: t("contract.agency"), field: "agency__name"
        },
        {
            label: t("bills.end_date"), field: "end_date", type: "period"
        },
        {
            label: t("bills.limit_date"), field: "limit_date", type: "period"
        },
        {
            label: t("bills.effect_date"), field: "effect_date", type: "period"
        },
    ];

    return (
        <>
            <Grid item xs={12} style={{ display: "flex", justifyContent: "center" }}>
                <TextField label={t('bills.search')}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start"> <Search /> </InputAdornment>
                        ),
                        sx: { borderRadius: '35px' }
                    }}
                    variant="outlined"
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                    sx={{width: '50%'}}
                />
            </Grid>
            <GenericList
                prefModuleName="VA_BILL"
                cols={columns}
                search={search}
                dataLoader={getBills}
                cachedList={bill_list}
                filtersData={filtersData}
                setCachedList={(list) => dispatch(setBillList(list))}
                labels={{
                    title: t('bills.title'),
                    add_button: ""
                }}
            />
            {
                loading &&
                <div className="upload-progress">
                    <Box sx={{ width: '400px' }}>
                        <CircularProgress size={24} />
                    </Box>
                </div>
            }
            <Finished />
        </>
    );
};

export default BillsPage;