// Dependencies
import { useSelector } from 'react-redux';
import React, { FC, ReactElement } from 'react';
// Core & Lab
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { RootState } from '../../../store/store';
// Types

const Finished: FC = (): ReactElement => {
    const finished = useSelector((state: RootState) => state.app.finished);
    return (
        <Backdrop className={'ft-utils-backdrop'} open={finished}>
            <CircularProgress />
        </Backdrop>
    );
};

export default Finished;