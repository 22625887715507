import React, { FC, ReactElement, useEffect, useState } from "react";
import '../../styles/homepage.css';

const images = [
    'images/bg/1-min.jpg',
    'images/bg/2-min.jpg',
    'images/bg/3-min.jpg',
    'images/bg/4-min.jpg',
    'images/bg/5-min.jpg',
    'images/bg/6-min.jpg'
];

const HomePage: FC = (): ReactElement => {
    const [currentImageIndex, setCurrentImageIndex] = useState(1);

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
        }, 6000);

        return () => clearInterval(interval);
    }, []);

    return (
        <div className="main-container">
            <div className="main-container-with-background carousel">
                <div className="homepage-overlay"></div>
                {images.map((image, index) => (
                    <img
                        key={index}
                        src={image}
                        alt={`Background ${index}`}
                        className={`carousel-image ${currentImageIndex === index ? 'active' : ''}`}
                    />
                ))}
                <div className="header">
                    <div>
                        <img className="logo" src="images/VA_logo_blanc.png" alt="" />
                    </div>
                    <div>
                        {/* <a href="" style={{ visibility: "hidden" }}>Espace partenaire</a> */}
                        <a href="/login">Accès Pro</a>
                        <img className="logo" src="images/logo-agme.png" alt="" />
                    </div>
                </div>

                <div className="content">
                    <h1>Le comparateur qui garantit la meilleure assurance mobilité en 3 clics !</h1>
                    <button> <a href="https://fr.chubbtravelinsurance.com/valeurs-assurances">Obtenir une estimation gratuite</a></button>
                    {/* <img className="floating-logo logo" src="images/logo-va.png" alt="" /> */}
                </div>
            </div>

            <div className="footer-container">
                <div className="footer">
                    <div>
                        <p style={{ marginBottom: 30 }}><b>Vous avez des questions? </b></p>
                        <p>01 56 02 04 45</p>
                        <p><u><a href="mailto:email@example.com">Nous écrire</a> </u></p>
                        <p>Ouvert Lun-Ven 9h-18h</p>
                    </div>
                    <div className="payment-content">
                        <p><b>Paiement sécurisé</b></p>
                        <img src="images/payment-methods.png" alt="" />
                    </div>
                    <div>
                        <div className="footer-newsletter">
                            <p><b>Newsletter</b></p>
                            <div className="inline-input">
                                <input type="email" placeholder="email address" />
                                <button>
                                    <span>→</span>
                                </button>
                            </div>
                            <p style={{ fontSize: 15 }}>Abonnez vous à la newsletter pour recevoir toutes nos offres privilégiées</p>
                        </div>
                    </div>
                </div>

                <hr className="footer-divider" />
                <div className="footer-menu">
                    <div>
                        <a target="_blank" href="http://www.diplomatie.gouv.fr/fr/conseils-aux-voyageurs/">Conseils du gouvernement aux voyageurs</a>
                        <a href="/legal-mentions">Mentions Légales</a>
                        <a href="/general-sales-conditions">Conditions générales de vente</a>
                        <a target="_blank" href="https://pastel.diplomatie.gouv.fr/fildariane/dyn/public/login.html">Enregistrement Ariane</a>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default HomePage;