import axios, { AxiosResponse, CancelTokenSource } from "axios";
import { store } from '../store/store';
import { Site } from "../types/site/site";

let cancelToken: CancelTokenSource | null = null;
export const getSites = (client_id: number | null, limit: number, offset: number, filter?: any): Promise<AxiosResponse<{ count: number; results: Site[]; }>> => {
    if (cancelToken) {
        cancelToken.cancel("Operation canceled due to new request.");
    }
    cancelToken = axios.CancelToken.source();
    let handledFilter = {};
    if (filter) {
        handledFilter = { ...filter };
    }
    return axios.get(
        `${API_HREF}client/${client_id}/insurances-site/`,
        {
            params: {
                limit,
                offset,
                ...handledFilter,
                subscribing_agency__id: (
                    (store.getState().app.profile?.is_superuser || store.getState().app.profile?.client_full?.name === "Valeurs Assurances")
                        ? undefined
                        : store.getState().app.profile?.client
                ) || undefined,
            },
            cancelToken: cancelToken.token,
            headers: {
                'Authorization': `Token ${localStorage.getItem('token')}`,
            }
        }
    );
};

export const getSiteById = (client_id: number | null, id: string | undefined): Promise<AxiosResponse<Site>> => {
    if (cancelToken) {
        cancelToken.cancel("Operation canceled due to new request.");
    }
    cancelToken = axios.CancelToken.source();
    return axios.get(
        `${API_HREF}client/${client_id}/insurances-site/${id}/`,
        {
            params: {},
            cancelToken: cancelToken.token,
            headers: {
                'Authorization': `Token ${localStorage.getItem('token')}`,
            }
        }
    );
};