// Dependencies
import React, { FC, ReactElement } from 'react';
// Core & Lab
import CircularProgress from '@mui/material/CircularProgress';
import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';

const Loading: FC = (): ReactElement =>  {
    const { t } = useTranslation();
    return (
        <Box className='loading' sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <Box className='progress'>
                <CircularProgress size={100} />
            </Box>
            <p color='default' className='text'>{t('loading')}</p>
        </Box>
    );
};

export default Loading;