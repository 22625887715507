// Dependencies
import React, { FC, ReactElement, useEffect } from "react";
import { AxiosResponse } from 'axios';
import * as locales from 'date-fns/locale';
import i18next from "i18next";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

// Core & Lab
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { Autocomplete, FormHelperText, Grid, Switch, TextField } from "@mui/material";
import MaterialUiPhoneNumber from "mui-phone-number";
import { DatePicker } from '@mui/x-date-pickers';
import { LocalizationProvider } from '@mui/x-date-pickers';
import CircularProgress from '@mui/material/CircularProgress';
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import InputAdornment from '@mui/material/InputAdornment';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

// Redux
import { RootState } from "../../../store/store";

// Data
import { COUNTRIES_PHONE } from "../../../constants/data";

const GenericField: FC<{
    type?: string,
    placeholder?: string | null,
    field: string,
    label: string,
    object: any,
    getOptionLabel?: (value: any) => string,
    displayValue?: string,
    onChange?: (field: string, value: any) => void,
    multiple?: boolean,
    required?: boolean,
    options?: any[],
    endAdornment?: any,
    readonly?: boolean,
    size?: "small" | "medium" | undefined,
    min?: any,
    max?: any,
    openTo?: "year" | "month" | "day",
    dateViews?: ("year" | "month" | "day")[],
    variant?: "outlined" | "filled" | "standard",
    injectedError?: string
    autocompleteFilter?: (item: any, index: number) => any
    autocompleteGetter?: (client_id: number | null, filter: string) => Promise<AxiosResponse<{ count: number; results: any; }>>
}> = ({ type, label, field, object, required, displayValue, onChange, multiple, options, endAdornment, getOptionLabel, autocompleteGetter, dateViews, readonly, size, min, max, openTo, variant, injectedError, autocompleteFilter, placeholder }): ReactElement => {

    const { t } = useTranslation();

    const client_id = useSelector((state: RootState) => state.app.client_id);
    const locales_cpy: { [key: string]: any } = locales;

    const [localError, setLocalError] = React.useState('');
    const [focused, setFocused] = React.useState(false);

    //AUTOCOMPLETE
    const [openAutocomplete, setOpenAutocomplete] = React.useState(false);
    const [autocompleteOptions, setAutocompleteOptions] = React.useState([]);
    const [autocompleteLoading, setAutocompleteLoading] = React.useState(false);
    // const autocompleteLoading = openAutocomplete && autocompleteOptions.length === 0;

    useEffect(() => {
        if (!openAutocomplete) {
            // setAutocompleteOptions([]);
        } else {
            onAutocompleteChange(null);
        }
    }, [openAutocomplete]);

    useEffect(() => {
        if (injectedError) setLocalError(injectedError);
    }, [injectedError]);

    useEffect(() => {
        if (type === "autocomplete" && autocompleteGetter !== undefined) {
            setAutocompleteLoading(true);
            autocompleteGetter(client_id, '').then((resp) => {
                if (resp.data?.results) {
                    const res = autocompleteFilter !== undefined ? resp.data.results.filter(autocompleteFilter) : resp.data.results.filter((x: any) => x.id && x.name);
                    setAutocompleteOptions(res);
                    setAutocompleteLoading(false);
                }
            }).catch((err) => {
                console.log('Error AUTOCOMPLETE', err);
            });
        }
    }, []);

    const onAutocompleteChange = (event: any) => {
        if (autocompleteGetter !== undefined) {
            setAutocompleteLoading(true);
            autocompleteGetter(client_id, event?.target?.value).then((resp) => {
                if (resp.data?.results) {
                    const res = autocompleteFilter !== undefined ? resp.data.results.filter(autocompleteFilter) : resp.data.results;
                    setAutocompleteOptions(res);
                    setAutocompleteLoading(false);
                }
            }).catch((err) => {
                console.log('Error AUTOCOMPLETE', err);
            });
        }
    };

    const onChangeAutocomplete = (event: any, value: any) => {
        if (multiple) {
            onChangeField({ target: { value: value } });
        } else onChangeField({ target: { value: value } });
    };

    const onChangeField = (event: any) => {
        if (field && onChange !== undefined) {
            let local_error = '';
            if (field !== 'travel_prices') {
                if (min && event.target.value && parseFloat(event.target.value) < parseFloat(min)) {
                    local_error = t('list.field_min_error');
                }
                if (max && event.target.value && parseFloat(event.target.value) > parseFloat(max)) {
                    local_error = t('list.field_max_error');
                }
                if (required && !event.target.value) {
                    local_error = t('list.field_required_error');
                }
            }
            setLocalError(local_error);
            if (type === "checkbox") onChange(field, event.target.checked);
            else onChange(field, event.target.value);
        }
    };

    const handleDateChange = (date: any) => {
        if (field && onChange !== undefined) {
            let local_error = '';
            if (min && date && (new Date(date)).getTime() < (new Date(min)).getTime()) {
                local_error = t('list.field_min_error');
            }
            if (max && date && (new Date(date)).getTime() > (new Date(max)).getTime()) {
                local_error = t('list.field_max_error');
            }
            if (required && !date) {
                local_error = t('list.field_required_error');
            }
            setLocalError(local_error);
            onChange(field, date);
        }
    };

    const checkExist = (value: any) => {
        return value !== null && value !== undefined;
    };

    let value: any = null;
    const fields = field?.split('.');
    if (object) {
        switch (fields.length) {
            case 1:
                if (checkExist(object)) value = object[fields[0]];
                break;
            case 2:
                if (checkExist(object) && checkExist(object[fields[0]])) value = object[fields[0]][fields[1]];
                break;
            case 3:
                if (checkExist(object) && checkExist(object[fields[0]]) && checkExist(object[fields[0]][fields[1]])) value = object[fields[0]][fields[1]][fields[2]];
                break;
            case 4:
                if (checkExist(object) && checkExist(object[fields[0]]) && checkExist(object[fields[0]][fields[1]]) && checkExist(object[fields[0]][fields[1]][fields[2]])) {
                    value = object[fields[0]][fields[1]][fields[2]][fields[3]];
                }
                break;
            default:
                break;
        }
        if (displayValue) value = displayValue;
    }

    const getPhoneCode = () => {
        const defaultLanguage = navigator.language;
        const locale = new Intl.Locale(defaultLanguage);
        const countryCode = locale.region ? locale.region : locale.language?.slice(-2)?.toLocaleUpperCase();
        const found = COUNTRIES_PHONE.find(e => e.code === countryCode);
        if (found) {
            return found.dial_code;
        }
        return "+1";
    };

    const getAutocompleteValue = () => {
        if (!multiple && typeof value === "number") {
            return autocompleteOptions.find((option: any) => option.id === value) || null;
        } else if (multiple && value?.length && value[0] && typeof value[0] === "number") {
            return value.map((v: number) => autocompleteOptions.find((option: any) => option.id === v)).filter(Boolean);
        }
        return value;
    };

    const getSelectValue = () => {
        let ret_value = value;
        if (!multiple && typeof value === "object") {
            ret_value = value.id || null;
        } else if (multiple && value?.length && value[0] && typeof value[0] === "object") {
            ret_value = value.map((v: any) => v.id).filter(Boolean);
        }
        return ret_value;
    };
    if (!object) {
        return <></>;
    }

    if (type === "autocomplete") {
        return (
            <>
                <FormControl id={field} fullWidth>
                    <Autocomplete
                        noOptionsText={t('list.autocomplete_no_options_text')}
                        loadingText={t('list.autocomplete_loading_text')}
                        multiple={Boolean(multiple)}
                        fullWidth
                        disabled={readonly}
                        open={openAutocomplete}
                        onOpen={() => {
                            setOpenAutocomplete(true);
                        }}
                        onClose={() => {
                            setOpenAutocomplete(false);
                        }}
                        onChange={onChangeAutocomplete}
                        isOptionEqualToValue={(option: any, value: any) => {
                            if (option?.id === value?.id) {
                                return true;
                            }
                            return false;
                        }}
                        size={size}
                        getOptionLabel={getOptionLabel !== undefined ? getOptionLabel : (option: any) => option?.name || option?.international_name}
                        options={autocompleteOptions}
                        loading={autocompleteLoading}
                        value={getAutocompleteValue()}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label={label + `${required ? '*' : ''}`}
                                InputLabelProps={{ shrink: Boolean(getAutocompleteValue()) || focused }}
                                onFocus={() => setFocused(true)}
                                onBlur={() => setFocused(false)}
                                onKeyUp={onAutocompleteChange}
                                error={object && object[field + '_error'] ? object[field + '_error'] : (localError ? localError : null)}
                                variant={variant ? variant : "outlined"}
                                InputProps={{
                                    ...params.InputProps,
                                    endAdornment: (
                                        <React.Fragment>
                                            {autocompleteLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                            {params.InputProps.endAdornment}
                                        </React.Fragment>
                                    )
                                }}
                            />
                        )}
                    />
                </FormControl>
                <FormHelperText style={{ color: "red" }}>{object && object[field + '_error'] ? object[field + '_error'] : (localError ? localError : null)}</FormHelperText>
            </>
        );
    }
    if (type === "datepicker") {
        return (
            <>
                <FormControl id={field} fullWidth error={object && object[field + '_error'] ? object[field + '_error'] : (localError ? localError : null)}>
                    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={locales_cpy[i18next.language]}>
                        <Grid>
                            <DatePicker
                                label={label + `${required ? '*' : ''}`}
                                disabled={readonly}
                                value={checkExist(value) ? value : null}
                                onChange={(newValue) => handleDateChange(newValue)}
                                openTo={openTo ? openTo : 'day'}
                                minDate={min ? min : null}
                                maxDate={max ? max : null}
                                views={dateViews}
                                renderInput={(props) => (
                                    <TextField
                                        {...props}
                                        InputLabelProps={{ shrink: checkExist(value) || focused }}
                                        onFocus={() => setFocused(true)}
                                        onBlur={() => setFocused(false)}
                                        error={object && object[field + '_error'] ? object[field + '_error'] : (localError ? localError : null)}
                                        variant={variant ? variant : "outlined"}
                                        fullWidth={true}
                                    />
                                )}
                            />
                        </Grid>
                    </LocalizationProvider>
                </FormControl>
                <FormHelperText style={{ color: "red" }}>{object && object[field + '_error'] ? object[field + '_error'] : (localError ? localError : null)}</FormHelperText>
            </>
        );
    }
    if (type === "select") {
        return (
            <>
                <FormControl fullWidth size={size} error={object && object[field + '_error'] ? object[field + '_error'] : (localError ? localError : null)}>
                    <InputLabel id={`${field}-label`} >{label}</InputLabel>
                    <Select
                        labelId={`${field}-label`}
                        error={object && object[field + '_error'] ? object[field + '_error'] : (localError ? localError : null)}
                        label={label + `${required ? '*' : ''}`}
                        onChange={onChangeField}
                        onFocus={() => setFocused(true)}
                        onBlur={() => setFocused(false)}
                        value={checkExist(value) ? getSelectValue() : (multiple ? [] : '')}
                        multiple={Boolean(multiple)}
                        disabled={readonly}
                        size={size}
                        id={field}
                        variant={variant ? variant : "outlined"}
                    >
                        {options?.map(option => {
                            return (
                                <MenuItem key={`option-${option.value}`} value={option.value}>{option.label}</MenuItem>
                            );
                        })}
                    </Select>
                </FormControl>
                <FormHelperText style={{ color: "red" }}>{object && object[field + '_error'] ? object[field + '_error'] : (localError ? localError : null)}</FormHelperText>
            </>
        );
    }
    if (type === "phone") {
        return (
            <MaterialUiPhoneNumber
                id={field}
                fullWidth
                type={'text'}
                label={label + `${required ? '*' : ''}`}
                onChange={(v) => onChangeField({ target: { value: v } })}
                value={value ? value : getPhoneCode()}
                disabled={readonly}
                disableAreaCodes
                helperText={`ex: +33 6 12 34 56 78`}
                error={object && object[field + '_error'] ? object[field + '_error'] : (localError ? localError : null)}
                size={size}
                variant={variant ? variant : "outlined"}
                InputLabelProps={{ shrink: true }}
            />
        );
    }
    if (type === "checkbox") {
        return (
            <FormControlLabel id={field} control={<Checkbox checked={value} />} label={label + (required ? '*' : '')} onChange={onChangeField} />
        );
    }
    if (type === "switch") {
        return (
            <FormControlLabel control={<Switch checked={value} />} label={label + (required ? '*' : '')} onChange={onChangeField} id={field} />
        );
    }
    return (
        <FormControl id={field} fullWidth error={object && object[field + '_error'] ? object[field + '_error'] : (localError ? localError : null)}>
            <TextField
                type={type ? type : 'text'}
                label={label + (required ? '*' : '')}
                value={value}
                onChange={onChangeField}
                error={object && object[field + '_error'] ? object[field + '_error'] : (localError ? localError : null)}
                rows={multiple ? 4 : 1}
                multiline={Boolean(multiple)}
                disabled={readonly}
                size={size}
                variant={variant ? variant : "outlined"}
                InputLabelProps={{ shrink: value !== undefined || focused }}
                onBlur={() => setFocused(false)}
                onFocus={(e) => {
                    setFocused(true)
                    e.target.addEventListener(
                        "wheel",
                        (e) => e.preventDefault(),
                        { passive: false }
                    )
                }}
                placeholder={placeholder !== null ? placeholder : undefined}
                InputProps={{
                    endAdornment: <InputAdornment position="end">{endAdornment}</InputAdornment>,
                    inputProps: {
                        min: min,
                        max: max
                    },
                }}
            />
            <FormHelperText>{object && object[field + '_error'] ? object[field + '_error'] : (localError ? localError : null)}</FormHelperText>
        </FormControl>
    );

};
export default GenericField;