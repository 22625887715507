import { LoadingButton } from "@mui/lab";
import { Checkbox, FormControlLabel, Grid, Paper, Typography, useMediaQuery, useTheme } from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { FC, ReactElement } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { Contract } from "../../types/contract/contract";
import { useTranslation } from "react-i18next";
import { AgencyIncentive } from "../../types/insurance/insurance-product";
import Loading from "../common/loading/loading";
import { ContractType } from "../../types/contract/contractType";
import ContractFormDescription from "./ContractFormDescription";
import ContractFormDates from "./ContractFormDates";
import ContractFormTravelers from "./ContractFormTravelers";
import ContractFormAdditionnalInfo from "./ContractFormAdditionnalInfo";
import { calculateTravelerNetPrice, updateContractPrices } from "../../functions/contract";
import { getProductById } from "../../services/productServices";

const ContractForm: FC<{
    type?: string | undefined,
    onConfirm: (contract: Contract) => void,
    loading: boolean
}> = ({ type, onConfirm, loading }): ReactElement => {

    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();

    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down(600));
    const client_id = useSelector((state: RootState) => state.app.client_id);
    const logged_user_client_id = useSelector((state: RootState) => state.app.logged_user_client_id);
    const selected_contract = useSelector((state: RootState) => state.app.selected_contract);
    const contract_types = useSelector((state: RootState) => state.app.contract_types);

    const [contract, SetContract] = useState<Contract>({
        multi_destination_travel: false,
        contract_purchase_date: null,
        travel_start_date: null,
        travel_end_date: null,
        destinations: null,
        product_family: null,
        departure_country: null,
        principal_product: null,
        secondary_product: null,
        product_extension: null,
        subscriber_is_part_of_the_trip: true,
        one_pax_is_baby: false,
        number_of_travelers: 0,
        subscriber: ({ birth_date: '1980-01-01' } as any),
        travelers: [],
        periodicity: "ANNUAL"
    });

    const [incentive, setIncentive] = useState<AgencyIncentive | null>(null);
    const [contract_type, setContractType] = useState<ContractType | null>(null);
    const [uploadLater, setUploadLater] = useState(false);
    const [term_accepted, setTermAccepted] = useState(true);
    const [policy_accepted, setPolicyAccepted] = useState(false);
    const [subscriberIndex, setSubscriberIndex] = useState(0);
    const [travelerRange, setTravelerRange] = useState({ min: 0, max: 9 });
    const [local_loading, setLocalLoading] = useState(false);
    const [selection, setSelection] = useState<any>({ agency: null });
    const [errors, setErrors] = useState<any>({});
    useEffect(() => {
        if (selected_contract?.id) {

            if (selected_contract?.contracts_type?.abbreviation === "FT-GROUP") setTravelerRange({ min: 11, max: 300 });

            getProductById(client_id, selected_contract?.principal_product?.id)
                .then(response => {
                    if (response.data) {
                        const new_val = response.data;
                        const incentive = new_val?.agencies_incentive?.find(ai => (ai.agency?.id || ai.agency) === (selected_contract.subscribing_agency?.id || selected_contract.subscribing_agency || logged_user_client_id));
                        if (incentive) setIncentive(incentive);

                        let contract_c = JSON.parse(JSON.stringify(selected_contract));
                        contract_c.principal_product = new_val;
                        contract_c.travelers = contract_c?.group_passenger?.travelers_list?.length ? contract_c.group_passenger.travelers_list : [];
                        contract_c.travelers.forEach((traveler, index) => {
                            contract_c = updateContractPrices(contract_c, subscriberIndex, (selection.agency?.id || selection.agency || contract_c.subscribing_agency?.id), index, type, undefined, traveler.public_price, undefined, true);
                        });

                        SetContract({
                            ...selected_contract,
                            principal_product: new_val,
                            contracts_type: selected_contract.contracts_type,
                            travelers: contract_c.travelers,
                            destinations: (selected_contract?.multi_destination_travel ? selected_contract.destinations : selected_contract.destinations?.at(0) as any),
                            subscribing_agency: (selected_contract.subscribing_agency?.id || selected_contract.subscribing_agency || logged_user_client_id) as any,
                            subscriber_is_part_of_the_trip: (type === "GROUP" && !contract_c.travelers.length) ? false : contract_c.subscriber_is_part_of_the_trip
                        });

                    }
                })
                .catch(error => console.log("Principal product loading error", error));

            setSelection({ agency: selected_contract.subscribing_agency?.id || selected_contract.subscribing_agency || logged_user_client_id });
        } else {
            if (type === "GROUP") setTermAccepted(false);
            /////////////

            const contr: any = {
                multi_destination_travel: false,
                contract_purchase_date: null,
                travel_start_date: null,
                travel_end_date: null,
                destinations: null,
                product_family: null,
                departure_country: null,
                principal_product: null,
                secondary_product: null,
                product_extension: [],
                contracts_status: "IN_PROGRESS",
                subscriber_is_part_of_the_trip: true,
                one_pax_is_baby: false,
                subscribing_agency: (logged_user_client_id as any),
                number_of_travelers: 0,
                subscriber: ({ birth_date: '1980-01-01' } as any),
                travelers: [],
                periodicity: "ANNUAL"
            };

            if (type !== "GROUP") {
                contr.number_of_travelers = 1;
                contr.travelers.push({ birth_date: '1980-01-01' })
            }

            SetContract(contr);
            setSelection({ agency: (logged_user_client_id as any) });
        }
    }, [selected_contract]);

    useEffect(() => {
        if (type === "GROUP") {
            setTravelerRange({ min: 10, max: 100 });
        }
        if (contract_types?.length) {
            const found = contract_types.find(t => t.abbreviation === "FT-" + type);
            if (found) setContractType(found);
        }
    }, [type, contract_types]);
    useEffect(() => {
        if (contract !== null && contract.principal_product !== null && !term_accepted) {
            if (type === "GROUP" && !contract.principal_product.inclusion_contract) {
                setTermAccepted(true);
            }
        }
    }, [contract.principal_product]);
    const changeMultiDest = (checked: boolean) => {
        const contract_c = JSON.parse(JSON.stringify(contract));
        contract_c.multi_destination_travel = checked;
        contract_c.destinations = checked ? [] : null;
        SetContract(contract_c);
    };

    const travelerPriceCalulation = (index: any, price?: any, public_price?: any, force_price?: boolean, force_public_price?: boolean) => {
        const contract_c = updateContractPrices(contract, subscriberIndex, (selection.agency?.id || selection.agency || contract.subscribing_agency?.id), index, type, price, public_price, force_price, force_public_price);
        SetContract(contract_c);
    };

    const confirmForm = () => {
        const contract_c = JSON.parse(JSON.stringify(contract));
        let valid = true;
        const error_c: any = { travelers: [], subscriber: {} };
        const requiredFields = ['departure_country', 'destinations', 'product_family', 'principal_product', 'travel_start_date', 'travel_end_date', 'trip_reservation_date'];
        if (type === "GROUP" && !contract_c.principal_product.inclusion_contract) requiredFields.push('travel_prices');
        requiredFields.forEach(field => {
            if (!contract_c[field]) {
                error_c[field] = t('errors.required');
                enqueueSnackbar(`${t('contract.' + field)} : ${t('errors.required')}`, { variant: 'error' });
                const element = document.getElementById(field);
                if (element) element.scrollIntoView();
                valid = false;
            }
        });

        const requiredTravelerFields = ['title', 'first_name', 'last_name'];
        let error_send = false;
        if (contract.subscriber && type !== "GROUP" && !contract_c.principal_product.inclusion_contract) {
            [...requiredTravelerFields, 'email'].forEach(field => {
                if (contract_c.subscriber && !contract_c.subscriber[field]) {
                    error_c.subscriber[field] = t('errors.required');
                    if (!error_send) {
                        enqueueSnackbar(t('errors.subscriber_incomplete_info'), { variant: 'error' });
                        const element = document.getElementById('subscriber_info');
                        if (element) element.scrollIntoView();
                        error_send = true;
                    }
                    valid = false;
                }
            });
        }

        contract_c.margin_fix = contract_c.travelers?.reduce((acc, t: any) => acc + parseFloat(t.margin_fix || '0'), 0).toFixed(2);
        contract_c.margin_per = contract_c.travelers?.reduce((acc, t: any) => acc + parseFloat(t.margin || '0'), 0).toFixed(2);

        if (type != "GROUP") contract_c.travel_prices = contract.travelers?.reduce((acc, t: any) => acc + parseFloat(t.price || '0'), 0).toFixed(2);
        let total_reduction = contract.travelers?.reduce((acc, t:any) => acc + parseFloat(t.reduction || '0'), 0).toFixed(2);
        contract_c.total_public_prices = parseFloat(contract.travelers?.reduce((acc, t: any) => acc + parseFloat(t.public_price || '0'), 0) - total_reduction).toFixed(2);
        contract_c.total_bonus = contract.travelers?.reduce((acc, t: any) => acc + parseFloat(t.net_price || '0'), 0).toFixed(2);
        contract_c.total_reduction = total_reduction;
        contract_c.total_incentive = contract.travelers?.reduce((acc, t: any) => acc + parseFloat(t.incentive || '0'), 0).toFixed(2);
        if (!contract_c.principal_product.inclusion_contract && (type !== "GROUP" && (!contract_c.total_bonus || contract_c.total_bonus < 0))) {
            valid = false;
            enqueueSnackbar('Prime à 0, Veuillez contacter le service client', { variant: 'error' });
            return;
        }
        if (errors['travel_prices'] !== undefined) {
            valid = false;
            enqueueSnackbar('Prix des voyages : ' + errors['travel_prices'], { variant: 'error' });
            return;
        }
        //remove subsciber from contract GROUP
        if (type == "GROUP") {
            contract_c.subscriber = null;
        }
        // remove subscriber from travelers
        if (type !== "GROUP" && contract_c.subscriber_is_part_of_the_trip && contract_c.travelers?.length) {

            contract_c.subscriber.price = contract_c.travelers[subscriberIndex].price;
            // contract_c.subscriber.net_price = contract_c.travelers[subscriberIndex].net_price;
            // contract_c.subscriber.net_price = calculateTravelerNetPrice(contract_c.subscriber, contract_c);
            contract_c.subscriber.public_price = contract_c.travelers[subscriberIndex].public_price || 0;

            if (!contract_c.subscriber.price && !contract_c.principal_product.inclusion_contract) {
                enqueueSnackbar(t('errors.subscriber_incomplete_info'), { variant: 'error' });
                error_c.subscriber.price = t('errors.required');
                const element = document.getElementById('subscriber_info');
                if (element) element.scrollIntoView();
                valid = false;
            }
        }

        error_send = false;
        if (!uploadLater && !contract_c.principal_product.inclusion_contract) {
            contract_c.travelers?.forEach((traveler, t_index) => {
                if (type !== "GROUP") {
                    requiredTravelerFields.push('price');
                }
                contract_c.travelers[t_index].email = contract_c.travelers[t_index].email || contract_c.subscriber?.email;
                requiredTravelerFields.forEach(field => {
                    if (!traveler[field]) {
                        if (!error_c.travelers[t_index]) error_c.travelers[t_index] = {};
                        error_c.travelers[t_index][field] = t('errors.required');
                        if (!error_send) {
                            enqueueSnackbar(t('errors.traveler_incomplete_info'), { variant: 'error' });
                            error_send = true;
                            const element = document.getElementById('traveler-info-' + t_index);
                            if (element) element.scrollIntoView();
                        }
                        valid = false;
                    }
                });
            });
            // if ((contract_c && contract_c.travelers?.some(t => !t.birth_date)) || !contract_c.subscriber?.birth_date) {
            //     enqueueSnackbar(`${t('errors.travelers_bday')}`, { variant: 'error' });
            //     valid = false;
            // }
        }
        if (!contract_c.travel_start_date || !contract_c.travel_end_date) {
            if (contract_c.travel_start_date) error_c.travel_start_date = t('contract.invalid_dates');
            if (contract_c.travel_end_date) error_c.contract_c.travel_end_date = t('contract.invalid_dates');
            enqueueSnackbar(t('contract.invalid_dates'), { variant: 'error' });
            valid = false;
        }
        if (contract_c.travel_start_date && contract_c.travel_end_date && new Date(contract_c.travel_start_date).getTime() > new Date(contract_c.travel_end_date).getTime()) {
            enqueueSnackbar(t('contract.invalid_dates'), { variant: 'error' });
            valid = false;
        }
        contract_c.subscribing_agency = selection?.agency?.id || selection?.agency || logged_user_client_id;
        setErrors(error_c);
        if (!valid) {
            const firstErrorKey = Object.keys(error_c).find((key) => typeof error_c[key] === 'string');
            if (firstErrorKey) {
                // const field = firstErrorKey.split('_')[0];
                const field = firstErrorKey;
                const element = document.getElementById(field);
                if (element) element.scrollIntoView();
            }
        }

        // TOTAL PRICE
        if (!contract_c.travel_prices) contract_c.travel_prices = contract_c.travelers?.reduce((acc, t: any) => acc + parseFloat(t.price || '0'), 0);
        contract_c.number_of_travelers = contract_c.travelers?.length || 0;

        if (type !== "GROUP" && contract_c.subscriber_is_part_of_the_trip && contract_c.travelers?.length) {
            contract_c.travelers.splice(subscriberIndex, 1);
        }
        if (valid && onConfirm !== undefined) onConfirm(contract_c);
    };

    return (
        <Grid item xs={12} className="contract-form-container">
            <Paper variant="outlined" square>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={4} lg={3}>
                        <Typography variant="h5" className="title" style={{ marginLeft: 16, marginTop: 8 }}>{t('contract.info')}</Typography>
                    </Grid>
                    <Grid item xs={12} md={8} lg={9} style={{ display: "flex", alignItems: "center", justifyContent: isSmallScreen ? "flex-start" : "flex-end" }}>
                        <FormControlLabel control={<Checkbox checked={contract.multi_destination_travel} onChange={(e) => changeMultiDest(e.target.checked)} />} label={t('contract.multi_destination')} />
                    </Grid>
                </Grid>
            </Paper>
            <Paper variant="outlined">
                <Grid container spacing={2} style={{ padding: isSmallScreen ? 3 : 16 }}>
                    <ContractFormDescription
                        contract={contract}
                        SetContract={SetContract}
                        selection={selection}
                        setSelection={setSelection}
                        errors={errors}
                        incentive={incentive}
                        setIncentive={setIncentive}
                        contract_type={contract_type}
                        subscriberIndex={subscriberIndex}
                        travelerPriceCalulation={travelerPriceCalulation}
                        type={type}
                    />
                    <ContractFormDates
                        contract={contract}
                        SetContract={SetContract}
                        errors={errors}
                        setErrors={setErrors}
                        type={type}
                        selection={selection}
                        subscriberIndex={subscriberIndex}
                    />
                    {
                        !contract?.principal_product?.inclusion_contract &&
                        <ContractFormTravelers
                            contract={contract}
                            SetContract={SetContract}
                            setErrors={setErrors}
                            errors={errors}
                            type={type}
                            travelerRange={travelerRange}
                            travelerPriceCalulation={travelerPriceCalulation}
                            subscriberIndex={subscriberIndex}
                            selection={selection}
                            setIncentive={setIncentive}
                            incentive={incentive}
                            setSubscriberIndex={setSubscriberIndex}
                            uploadLater={uploadLater}
                            setUploadLater={setUploadLater}
                        />
                    }
                    <ContractFormAdditionnalInfo
                        contract={contract}
                        incentive={incentive}
                        subscriberIndex={subscriberIndex}
                    />
                    {
                        type === "GROUP" && !contract?.principal_product?.inclusion_contract &&
                        <Grid item xs={12} style={{ display: "flex", justifyContent: "center", margin: 16, marginBottom: 0 }}>
                            <FormControlLabel control={<Checkbox checked={term_accepted} />} label={t('contract.agency_term')} onChange={(e) => setTermAccepted((e.target as any).checked)} />
                        </Grid>
                    }
                    <Grid item xs={12} style={{ display: "flex", flexDirection: "column", margin: 16, marginBottom: 0 }}>
                        <FormControlLabel control={<Checkbox checked={policy_accepted} />} label={t('contract.agency_policy')} onChange={(e) => setPolicyAccepted((e.target as any).checked)} />
                        <ul>
                            <li>{t('contract.agency_policy_1')}</li>
                            <li>{t('contract.agency_policy_2')}</li>
                            <li>{t('contract.agency_policy_3')}</li>
                        </ul>
                    </Grid>
                    <Grid item xs={12}></Grid>
                    <Grid item xs={12} style={{ display: "flex", justifyContent: "center", margin: 16, marginBottom: 0 }}>
                        <LoadingButton loading={loading} disabled={!term_accepted || !policy_accepted} className={(!term_accepted || !policy_accepted) ? 'disabled-button' : 'confirm-button'} variant="contained" onClick={confirmForm}>{t('contract.confirm')}</LoadingButton>
                        {/* <LoadingButton loading={loading} disabled={!term_accepted || !policy_accepted || contract.travelers.length !== parseInt(contract.number_of_travelers)} className={(!term_accepted || !policy_accepted || contract.travelers.length !== parseInt(contract.number_of_travelers)) ? 'disabled-button' : 'confirm-button'} variant="contained" onClick={confirmForm}>{t('contract.confirm')}</LoadingButton> */}
                    </Grid>
                </Grid>
                {
                    local_loading &&
                    <div style={{ position: "fixed", top: 0, left: 0, width: "100vw", height: "100vh", display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <Loading />
                    </div>
                }
            </Paper>
        </Grid>
    );
};

export default ContractForm;