import axios, { AxiosResponse, CancelTokenSource } from "axios";
import { Sinister, SinisterDocument, SinisterWarranty } from "../types/contract/sinister";
import { store } from "../store/store";

let cancelToken: CancelTokenSource | null = null;
export const getSinisters = (client_id: number | null, limit: number, offset: number, filter?: any): Promise<AxiosResponse<{ count: number; results: Sinister[]; }>> => {
    // if (cancelToken) {
    //     cancelToken.cancel("Operation canceled due to new request.");
    // }
    // cancelToken = axios.CancelToken.source();
    let handledFilter = {};
    if (filter) {
        handledFilter = { ...filter };
    }
    return axios.get(
        `${API_HREF}client/${client_id}/sinistres/`,
        {
            params: {
                limit,
                offset,
                ...handledFilter,
                policy__subscribing_agency_id: ((store.getState().app.profile?.is_superuser || store.getState().app.profile?.client_full?.name === "Valeurs Assurances")
                    ? undefined : store.getState().app.profile?.client) || undefined,
            },
            // cancelToken: cancelToken.token,
            headers: {
                'Authorization': `Token ${localStorage.getItem('token')}`,
            }
        }
    );
};

export const getSinisterById = (client_id: number | null, sinister_id: string | undefined, params?: any): Promise<AxiosResponse<Sinister>> => {
    if (cancelToken) {
        cancelToken.cancel("Operation canceled due to new request.");
    }
    cancelToken = axios.CancelToken.source();
    return axios.get(
        `${API_HREF}client/${client_id}/sinistres/${sinister_id}/`,
        {
            params,
            cancelToken: cancelToken.token,
            headers: params?.token ? {} : {
                'Authorization': `Token ${localStorage.getItem('token')}`,
            }
        }
    );
};

export const postSinister = (client_id: number | null, sinister: Sinister): Promise<AxiosResponse<Sinister>> => {
    if (cancelToken) {
        cancelToken.cancel("Operation canceled due to new request.");
    }
    cancelToken = axios.CancelToken.source();
    return axios.post(
        `${API_HREF}client/${client_id}/sinistres/`,
        sinister,
        {
            cancelToken: cancelToken.token,
            headers: {
                'Authorization': `Token ${localStorage.getItem('token')}`,
            }
        }
    );
};

export const getSinisterWarranty = (client_id: number | null): Promise<AxiosResponse<{ count: number; results: SinisterWarranty[]; }>> => {
    if (cancelToken) {
        cancelToken.cancel("Operation canceled due to new request.");
    }
    cancelToken = axios.CancelToken.source();
    return axios.get(
        `${API_HREF}client/${client_id}/value-insurances-contracts-warranty/`,
        {
            params: {
                limit: 1000,
                offset: 0
            },
            cancelToken: cancelToken.token,
            headers: {
                'Authorization': `Token ${localStorage.getItem('token')}`,
            }
        }
    );
};

export const postSinisterWarranty = (client_id: number | null, sinister: Sinister): Promise<AxiosResponse<SinisterWarranty>> => {
    if (cancelToken) {
        cancelToken.cancel("Operation canceled due to new request.");
    }
    cancelToken = axios.CancelToken.source();
    return axios.post(
        `${API_HREF}client/${client_id}/value-insurances-contracts-warranty/`,
        sinister,
        {
            cancelToken: cancelToken.token,
            headers: {
                'Authorization': `Token ${localStorage.getItem('token')}`,
            }
        }
    );
};

export const patchSinister = (client_id: number | null, sinisterId: number | undefined, data: any): Promise<AxiosResponse<Sinister>> => {
    // if (cancelToken) {
    //     cancelToken.cancel("Operation canceled due to new request.");
    // }
    // cancelToken = axios.CancelToken.source();
    return axios.patch(
        `${API_HREF}client/${client_id}/sinistres/${sinisterId}/`,
        data,
        {
            // cancelToken: cancelToken.token,
            headers: {
                'Authorization': `Token ${localStorage.getItem('token')}`,
            }
        }
    );
};

export const putSinister = (client_id: number | null, sinister: Sinister): Promise<AxiosResponse<Sinister>> => {
    if (cancelToken) {
        cancelToken.cancel("Operation canceled due to new request.");
    }
    cancelToken = axios.CancelToken.source();
    return axios.put(
        `${API_HREF}client/${client_id}/sinistres/${sinister.id}/`,
        sinister,
        {
            cancelToken: cancelToken.token,
            headers: {
                'Authorization': `Token ${localStorage.getItem('token')}`,
            }
        }
    );
};


export const getSinisterDocuments = (client_id: number | null, sinisterId: number, params?: any): Promise<AxiosResponse<{ count: number; results: SinisterDocument[]; }>> => {
    if (cancelToken) {
        cancelToken.cancel("Operation canceled due to new request.");
    }
    cancelToken = axios.CancelToken.source();
    return axios.get(
        `${API_HREF}client/${client_id}/sinistres-documents/?claim_id=${sinisterId}`,
        {
            cancelToken: cancelToken.token,
            params,
            headers: params?.token ? {} : {
                'Authorization': `Token ${localStorage.getItem('token')}`,
            }
        }
    );
};

export const postSinisterDocument = (client_id: number | null, sinisterDocument: SinisterDocument, params?: any): Promise<AxiosResponse<SinisterDocument>> => {
    if (cancelToken) {
        cancelToken.cancel("Operation canceled due to new request.");
    }
    cancelToken = axios.CancelToken.source();
    return axios.post(
        `${API_HREF}client/${client_id}/sinistres-documents/`,
        sinisterDocument,
        {
            cancelToken: cancelToken.token,
            headers: params?.token ? {} : {
                'Authorization': `Token ${localStorage.getItem('token')}`,
            }
        }
    );
};

export const patchSinisterDocument = (client_id: number | null, sinisterDocId: number | undefined, data: any): Promise<AxiosResponse<SinisterDocument>> => {
    // if (cancelToken) {
    //     cancelToken.cancel("Operation canceled due to new request.");
    // }
    // cancelToken = axios.CancelToken.source();
    return axios.patch(
        `${API_HREF}client/${client_id}/sinistres-documents/${sinisterDocId}/`,
        data,
        {
            // cancelToken: cancelToken.token,
            headers: {
                'Authorization': `Token ${localStorage.getItem('token')}`,
            }
        }
    );
};

export const deleteSinisterDocument = (client_id: number | null, sinisterDocId: number | undefined): Promise<AxiosResponse<any>> => {
    return axios.delete(
        `${API_HREF}client/${client_id}/sinistres-documents/${sinisterDocId}/`,
        {
            headers: {
                'Authorization': `Token ${localStorage.getItem('token')}`,
            }
        }
    );
};

export const dowloadSinisterDocument = (client_id: number | null, id: number, claims_id?: number, params?: any): Promise<AxiosResponse<Blob>> => {
    const requestUrl = `${API_HREF}client/${client_id}/sinistres-documents/download/`;
    return axios.post(
        requestUrl,
        {
            id,
            claims_id
        },
        {
            responseType: 'blob',
            params,
            headers: params?.token ? {} : {
                'Authorization': `Token ${localStorage.getItem('token')}`,
            }
        }
    );
};

export const dowloadWarrantyModel = (client_id: number | null, id: number, claim_id: number, params?: any): Promise<AxiosResponse<Blob>> => {
    const requestUrl = `${API_HREF}client/${client_id}/expecteddocuments/download_template/`;
    return axios.post(
        requestUrl,
        {
            documenttemplate_id: id,
            claim_id
        },
        {
            responseType: 'blob',
            params,
            headers: params?.token ? {} : {
                'Authorization': `Token ${localStorage.getItem('token')}`,
            }
        }
    );
};

export const generateUserPassword = (client_id: number | null, claim_id: number): Promise<AxiosResponse<any>> => {
    const requestUrl = `${API_HREF}client/${client_id}/sinistres/generate_sso_password/`;
    return axios.post(
        requestUrl,
        {
            claim_id
        },
        {
            params: {},
            headers: {
                'Authorization': `Token ${localStorage.getItem('token')}`,
            }
        }
    );
};
