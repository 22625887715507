import { useTranslation } from 'react-i18next';
import { LockOutlined, MailOutlined, Visibility, VisibilityOff } from "@mui/icons-material";
import { Button, Checkbox, FormControlLabel, Grid, IconButton, InputAdornment, TextField } from "@mui/material";
import { useSnackbar } from "notistack";
import React, { FC, ReactElement, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { resetPassword } from "../../services/commonServices";
import { RootState } from "../../store/store";
import '../../styles/authentification.css';
import { LoadingButton } from '@mui/lab';

const ResetForm: FC<{ switchPage: (page: string) => void, token:null|string, user_id:null|number }> = ({ switchPage, token, user_id }): ReactElement => {
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation();

    const [newPassword, setNewPassword] = useState('');
    const [confirmNewPassword, setConfirmNewPassword] = useState('');
    const [loading, SetLoading] = useState(false);
    const [showPassword, setShowPassword] = useState(false);

    const client_id = useSelector((state: RootState) => state.app.client_id);
    console.log('client_id:', client_id);
    const onResetPassword = () => {
        SetLoading(true);
        if (confirmNewPassword !== "" && newPassword === confirmNewPassword) {
            resetPassword(client_id, newPassword, confirmNewPassword, token, user_id).then((response) => {
                enqueueSnackbar(('Votre mot de passe a été modifié avec succès !'), {variant: 'success'});
                switchPage("SIGNIN");
                navigate(`/login`);
            }).catch((error) => {
                SetLoading(false);
                if (error.response) {
                    if (error.response.status === 400) {
                        for (const [key, value] of Object.entries(error.response.data)) {
                            if (Array.isArray(value)) {
                                value.map(error => enqueueSnackbar(key === 'non_field_errors' ? error : `${key} : ${error}`, { variant: 'error' }));
                            } else {
                                enqueueSnackbar(key === 'non_field_errors' ? error : `${key} : ${value}`, { variant: 'error' });
                            }
                        }
                    } else if (error.response.status === 401 || error.response.status === 403) {
                        enqueueSnackbar(t('errors.permissions'), { variant: 'error' });
                    } else if (error.response.status === 500) {
                        enqueueSnackbar(t('errors.servers'), { variant: 'error' });
                    }
                } else if (error.request) {
                    console.log('error.request : ', error.request);
                } else {
                    console.log('Error', error.message);
                }
                console.log('error.config : ', error.config);
            });
        } else {
            enqueueSnackbar('Les mots de passes sont différents', { variant: 'error' });
        }
    };

    const handleClickShowPassword = () => setShowPassword((show) => !show);
    const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    };
    return (
        <>
            <div>
                <h3>{t('auth.reset-password')}</h3>
                {/* <p style={{ marginTop: 0 }}>{t('auth.forget-password-info')}</p> */}
            </div>
            <div className="login-form-container-inputs">
                <TextField
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                    fullWidth
                    label={'Nouveau mot de passe'}
                    type={showPassword ? "text" : "password"}
                    placeholder={t('auth.password-info')}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <LockOutlined />
                            </InputAdornment>
                        )
                    }}
                    variant="standard"
                />
                <TextField
                    value={confirmNewPassword}
                    onChange={(e) => setConfirmNewPassword(e.target.value)}
                    fullWidth
                    label={'Confirmer le nouveau mot de passe'}
                    type={showPassword ? "text" : "password"}
                    placeholder={t('auth.password-info')}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <LockOutlined />
                            </InputAdornment>
                        )
                    }}
                    variant="standard"
                />
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={showPassword}
                            onChange={handleClickShowPassword}
                            color="primary"
                        />
                    }
                    label={'Afficher le mot de passe'}
                />
                <div style={{ display: "flex", justifyContent: "flex-end", width: "100%" }}>
                    <Button variant="text" onClick={() => {
                        navigate('/login');
                        switchPage('SIGNIN');
                    }}>{t('auth.go-back-login')}</Button>
                </div>
            </div>

            <LoadingButton loading={loading} color='secondary' variant="contained" onClick={onResetPassword}>{'Modifier le mot de passe'}</LoadingButton>
        </>
    );
};

export default ResetForm;