// Dependencies
import React, { FC, ReactElement } from 'react';
// Core & Lab
import Typography from '@mui/material/Typography';
import { Grid, Paper } from '@mui/material';
import IconButton from "@mui/material/IconButton";
// Icons
import Edit from "@mui/icons-material/Edit";
// Types
// Functions
// Actions

const GenericPanel: FC<{
    mainTitle: string | undefined | null,
    fields: { title: string, value: any, gridSize?: number }[],
    editStepCallback?: () => void,
    editPermission?: string | undefined,
    readOnly?: boolean
}> = ({ fields, mainTitle, editStepCallback, editPermission, readOnly }): ReactElement => {
    return (
        <>
            <Grid
                className={"panel-item"}
                item
                xs={12}
            >
                <Grid container item xs={12} justifyContent={"space-between"}>
                    <Grid item>
                        <Typography variant="subtitle1" className="title">
                            {mainTitle ? mainTitle : ''}
                        </Typography>
                    </Grid>
                    <Grid item>
                        {
                            !readOnly &&
                            <IconButton onClick={editStepCallback}>
                                <Edit />
                            </IconButton>
                        }
                    </Grid>
                </Grid>
                <Grid item xs={12} container spacing={2}>
                    {
                        fields.map((field, index) => {
                            return (
                                <Grid key={`field-${field.title.replace(/^a-zA-Z0-9 ]/g, '')}-${index}`} item xs={field.gridSize ? field.gridSize : 4}>
                                    <Typography>{field.title}</Typography>
                                    <Typography className={"panel-item-subTest"} style={{ wordBreak: "break-word" }}>{field.value}</Typography>
                                </Grid>
                            );
                        })
                    }
                </Grid>
            </Grid>
        </>
    );
};

export default GenericPanel;
