import { Euro } from "@mui/icons-material";
import { Checkbox, FormControlLabel, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import { DataGrid, frFR, GridColDef, GridColumnMenu, GridColumnMenuProps, GridRenderCellParams } from "@mui/x-data-grid";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { FC, ReactElement } from "react";
import { updateObjectField } from "../../functions/ObjectHandler";
import { Contract } from "../../types/contract/contract";
import Dropzone from "../common/genericList/dropzone";
import GenericField from "../common/genericList/genericField";
import * as XLSX from 'xlsx';
import { useTranslation } from "react-i18next";
import { updateContractPrices } from "../../functions/contract";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import ContractFormTravelersPublicPriceInput from "./ContractFormTravelersPublicPriceInput";
import { AgencyIncentive } from "../../types/insurance/insurance-product";

type minMaxPriceProps =  {
    min_price: number,
    max_price: number
};
const ContractFormTravelers: FC<{
    contract: Contract,
    SetContract: (contract: Contract) => void,
    errors: any,
    type: string | undefined,
    travelerRange: { min: number, max: number },
    subscriberIndex: number,
    setIncentive: (incentive: AgencyIncentive | null) => void,
    incentive: AgencyIncentive | null,
    setSubscriberIndex: (index: number) => void
    travelerPriceCalulation: (index: any, price?: any, public_price?: any, force_price?: boolean, force_public_price?: boolean) => void,
    selection: any,
    uploadLater: boolean,
    setUploadLater: any,
    setErrors: (errors: any) => void
}> = ({ contract, SetContract, setIncentive, incentive, errors, type, travelerRange, travelerPriceCalulation, subscriberIndex, setSubscriberIndex, selection, uploadLater, setUploadLater, setErrors }): ReactElement => {
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();

    const profile = useSelector((state: RootState) => state.app.profile);

    const [loadingProgress, setLoadingProgress] = useState(0);
    const [editPrice, setEditPrice] = useState({ index: -1, value: "0" });
    const [incentiveValue, setIncentiveValue] = useState(0);
    const [remiseValue, setRemiseValue] = useState(0);
    const [minMaxPrice, setMinMaxPrice] = useState<minMaxPriceProps>({min_price: 0, max_price: 0});

    useEffect(() => {
        let contract_c = JSON.parse(JSON.stringify((contract)));
        if (contract_c.principal_product !== null && !contract_c.principal_product.is_in_duration_destination) {
            let min_slice = contract_c.principal_product.prices.reduce((a: { min_slice: number; }, b: { min_slice: number; }) => parseFloat(a.min_slice) < parseFloat(b.min_slice) ? a : b, 0).min_slice;
            let max_slice = contract_c.principal_product.prices.reduce((a: { max_slice: number; }, b: { max_slice: number; }) => parseFloat(a.max_slice) > parseFloat(b.max_slice) ? a : b, 0).max_slice;
            setMinMaxPrice({ min_price: min_slice, max_price: max_slice });
        }

    }, [contract]);
    useEffect(() => {
        if (type === "GROUP" && !contract.group_passenger?.travelers?.length && uploadLater === false) {
            setUploadLater(true);
        }
        if (type === "GROUP") setSubscriberIndex(-1);
    }, [type, contract]);
    useEffect(() => {
        if (type === "GROUP") {
            let contract_c = JSON.parse(JSON.stringify(contract));
            let price = contract_c.travel_prices;
            const price_per_traveler = price / (contract_c.travelers?.length || 1);
            contract_c.travelers?.forEach((trav, trav_index) => {
                contract_c.travelers[trav_index].price = price_per_traveler;
                contract_c = updateContractPrices(contract_c, subscriberIndex, selection.agency?.id || selection.agency || contract_c.subscribing_agency, trav_index, type);
            });
            SetContract(contract_c);
        }
    }, [contract.number_of_travelers]);
    useEffect(() => {
        let contract_c = JSON.parse(JSON.stringify(contract));
        let incentive_value = 0;
        let remise_value = contract_c.travelers?.reduce((acc, t: any) => acc + parseFloat(t.reduction_net || '0'), 0).toFixed(2);
        const incentive = contract_c.principal_product?.agencies_incentive?.find(ai => (ai.agency?.id || ai.agency) === (selection?.agency?.id || selection?.agency));
        if (incentive) {
            for (let i = 0; i < contract_c.number_of_travelers; i++) {
                const trav_price = contract_c.travelers[i]?.price !== undefined && contract_c.travelers[i]?.price !== null ? contract_c.travelers[i]?.price : 0;
                if (trav_price !== 0) {
                    const is_in_price = contract_c.principal_product?.prices.find((price) => {
                        return trav_price >= price.min_slice && trav_price <= price.max_slice;
                    });
                    if (is_in_price !== undefined) {
                        incentive_value += incentive.bonus_type === 'FIX' ? parseFloat(incentive.amount) : (trav_price !== 0 ? (((parseFloat(incentive.amount) / 100 || 0)) * (parseFloat(is_in_price.selling_price) || 1)) : 0);
                    } 
                }
            }
        }
        setIncentiveValue(incentive_value);
        setRemiseValue(isFinite(remise_value) ? remise_value : 0);
    }, [contract]);
    const getAgeDiff = (birth_date: any) => {
        if (!birth_date) return 0;
        const now = new Date();
        const b_date = new Date(birth_date);
        const diff = now.getFullYear() - b_date.getFullYear();
        return diff;
    };
    const columns: GridColDef[] = [
        {
            field: 'type', headerName: t('contract.traveler_type'), flex: 1, headerClassName: 'product-extension-header',
            valueGetter: (params) => {
                if (params.row.birth_date) {
                    const diff = getAgeDiff(params.row.birth_date);
                    return diff <= 2 ? 'Bébé' : (diff >= 18 ? 'Adulte' : 'Enfant');
                }
            }
        },
        {
            field: 'title', headerName: t('contract.traveler_title_short'), flex: 1, headerClassName: 'product-extension-header',
        },
        {
            field: 'first_name', headerName: t('contract.traveler_first_name'), flex: 1, headerClassName: 'product-extension-header',
        },
        {
            field: 'last_name', headerName: t('contract.traveler_last_name'), flex: 1, headerClassName: 'product-extension-header',
        },
    ];

    if (type !== "GROUP") {
        columns.push(
            {
                field: 'price', headerName: t('contract.traveler_price'), flex: 1, headerClassName: 'product-extension-header',
            },
            {
                field: 'net_price', headerName: t('contract.net_price'), flex: 1, headerClassName: 'product-extension-header',
                renderCell: (params: GridRenderCellParams<any>) => {
                    return (
                        <div>
                            <div style={{ padding: 8 }}>{parseFloat(params.row.net_price || "0")?.toFixed(2)}</div>
                            {
                                params.row.reduction_net !== undefined && parseFloat(params.row.reduction_net) !== 0 && isFinite(parseFloat(params.row.reduction_net)) &&
                                <div>{`${params.row.is_reduction ? 'Remise' : 'Surprime'} : ${parseFloat(params.row.reduction_net).toFixed(2)}€`}</div>
                            }
                        </div>
                    )
                }
            },
            {
                field: 'public_price', headerName: t('contract.public_price'), flex: 1, headerClassName: 'product-extension-header',
                valueGetter: (params) => (parseFloat(params.row.public_price || "0"))?.toFixed(2),
                renderCell: (params: GridRenderCellParams<any>) => {
                    return (
                        <ContractFormTravelersPublicPriceInput
                            row={params.row}
                            price={params.row.public_price}
                            reduction={params.row.reduction_public}
                            is_reduction={params.row.is_reduction}
                            SetPrice={(value) => updatePublicPriceField((params.id as any), value)} />
                    );
                },
            },
            {
                // field: 'extension_price', headerName: "Prix extensions (en €)", flex: 1, headerClassName: 'product-extension-header', valueGetter: (params) => (parseFloat(params.row.extension_price || "0"))?.toFixed(2)
            },
        );
    }

    if ((profile?.is_superuser || profile?.client_full?.name === "Valeurs Assurances") && type !== "GROUP") {
        columns.push(
            {
                field: 'margin_fix', headerName: t('contract.margin_fix'), flex: 1, headerClassName: 'product-extension-header', valueGetter: (params) => `${params.row.margin_fix?.toFixed(2) || 0}`
            },
            {
                field: 'margin', headerName: t('contract.margin'), flex: 1, headerClassName: 'product-extension-header', valueGetter: (params) => `${params.row.margin?.toFixed(2) || 0}%`
            }
        );
    }

    const CustomColumnMenu = (props: GridColumnMenuProps) => {
        return (
            <GridColumnMenu
                {...props}
                slots={{
                    columnMenuColumnsItem: null,
                    columnMenuFilterItem: null,
                }}
            />
        );
    }

    const updateField = (field: string, value: any) => {
        updateObjectField(contract, field, value, SetContract);
    };

    const changeNumber = (field: string, value: any) => {
        let contract_c = JSON.parse(JSON.stringify(contract));
        contract_c.number_of_travelers = value;
        if (contract_c.travelers?.length < value) {
            while (contract_c.travelers?.length < value) {
                contract_c.travelers.push({ birth_date: '1980-01-01', temp_email: contract_c.subscriber?.email });
            }
        } else if (contract_c.travelers?.length > value) {
            while (contract_c.travelers?.length > value) {
                contract_c.travelers.splice(contract_c.travelers.length - 1, 1);
            }
        }
        if (subscriberIndex !== -1 && contract_c?.travelers?.at(subscriberIndex)) {
            contract_c.travelers[subscriberIndex] = { ...contract_c.travelers[subscriberIndex], ...contract_c.subscriber };
            SetContract(contract_c);
        }
        if (contract_c.travelers?.some(t => t.price)) {
            enqueueSnackbar(t('contract.prices_changes'), { variant: "warning" });
        }
        contract_c.travelers?.forEach((trav, trav_index) => {
            contract_c = updateContractPrices(contract_c, subscriberIndex, selection.agency?.id || selection.agency || contract_c.subscribing_agency, trav_index, type);
        });
        // enqueueSnackbar(t('contract.incentive_added'), { variant: "warning" });
        SetContract(contract_c);
    };

    const changeSubs = (field: string, value: any) => {
        let contract_c = JSON.parse(JSON.stringify((contract)));
        if (!contract_c.subscriber) contract_c.subscriber = {};
        contract_c.subscriber[field.split('.')[1]] = value;
        if (subscriberIndex !== -1 && contract?.travelers?.at(subscriberIndex)) {
            contract_c.travelers[subscriberIndex] = { ...contract_c.travelers[subscriberIndex], ...contract_c.subscriber };
        }
        SetContract(contract_c);
    };

    const changeSubsEmail = (field: string, value: any) => {
        const contract_c = JSON.parse(JSON.stringify((contract)));
        if (!contract_c.subscriber) contract_c.subscriber = {};
        contract_c.subscriber[field.split('.')[1]] = value;
        if (subscriberIndex !== -1 && contract?.travelers?.at(subscriberIndex)) {
            contract_c.travelers[subscriberIndex] = { ...contract_c.travelers[subscriberIndex], ...contract_c.subscriber };
        }
        contract_c.travelers?.forEach((trav, trav_index) => {
            if (contract_c.travelers[trav_index]) contract_c.travelers[trav_index].temp_email = value;
        });
        SetContract(contract_c);
    };

    const changeSubscriberIndex = (index: number) => {
        if (index !== -1 && contract?.travelers?.at(index)) {
            let contract_c = JSON.parse(JSON.stringify((contract)));
            contract_c.travelers[index] = { ...contract_c.subscriber };
            if (contract_c.travelers?.some(t => t.price)) {
                enqueueSnackbar(t('contract.prices_changes'), { variant: "warning" });
            }
            contract_c.travelers?.forEach((trav, trav_index) => {
                contract_c = updateContractPrices(contract_c, index, selection.agency?.id || selection.agency || contract_c.subscribing_agency, trav_index, type);
            });
            // enqueueSnackbar(t('contract.incentive_added'), { variant: "warning" });
            SetContract(contract_c);
        }
        setSubscriberIndex(index);
    };

    const updatePriceField = (index: number, value: any) => {
        let contract_c = JSON.parse(JSON.stringify((contract)));
        let error_c = { ...errors };
        if (!contract_c.principal_product.is_in_duration_destination) {
            let find_slice = contract_c.principal_product.prices.find((price) => {
                return parseFloat(price.min_slice) <= value && value <= parseFloat(price.max_slice);
            });
            if (find_slice === undefined) {
                error_c['travel_prices'] = t('list.field_max_error');
                setErrors(error_c);
            } else {
                delete error_c['travel_prices'];
                setErrors(error_c);
            }

        }
        travelerPriceCalulation(index, value, undefined, true, false);
    };

    const updatePublicPriceField = (id: number, public_price: any) => {
        const contract_c = JSON.parse(JSON.stringify((contract)));
        let index = contract_c.id ? contract_c.travelers?.findIndex(t => t.id == id) : id;
        if (index === -1) {
            index = id;
        }
        const new_val = `${public_price}${public_price.endsWith(".") ? '0' : ''}`;
        travelerPriceCalulation(index, undefined, new_val, false, true);
    };

    function excelSerialDateToJSDate(serial) {
        if (!serial) return new Date('1980-01-01');
        const millisecondsPerDay = 24 * 60 * 60 * 1000; // milliseconds in a day
        const date = new Date((serial - 1) * millisecondsPerDay + Date.UTC(1900, 0, 1));
        return date;
    }

    const handleFileSelection = (file: File) => {
        setLoadingProgress(0);
        if (file) {
            const reader = new FileReader();

            reader.onload = (e: any) => {
                console.log(">>> FILE LOADED ")
                const workbook = XLSX.read(e.target.result, { type: 'binary' });
                const sheetName = workbook.SheetNames[0];
                const worksheet = workbook.Sheets[sheetName];

                // Specify the data range using the range option
                const range = XLSX.utils.decode_range((worksheet['!ref'] as string));
                range.s.r++; // Skip the header row
                const rows = XLSX.utils.sheet_to_json(worksheet, {
                    header: ['Nom', 'Prénom', 'Date de naissance'],
                    raw: true,
                    range, // Use the specified range
                });
                console.log(">>> FILE ROWS ", rows)

                let hasError = false;
                let contract_c = JSON.parse(JSON.stringify(contract));
                contract_c.travelers = [];
                rows.forEach((row: any, index) => {
                    const firstName = row['Nom'];
                    const lastName = row['Prénom'];
                    const birthDate = row['Date de naissance'];
                    if (
                        typeof firstName !== 'string' ||
                        typeof lastName !== 'string' ||
                        (birthDate && typeof birthDate !== 'number')
                        // || !/^\d{2}\/\d{2}\/\d{4}$/.test(birthDate)
                    ) {
                        hasError = true;
                        enqueueSnackbar(`La ligne n°${index + 1} n'est pas dans le bon format`, { variant: 'error' });
                    } else contract_c.travelers.push({ title: "MR", first_name: firstName, last_name: lastName, birth_date: excelSerialDateToJSDate(birthDate) });
                });

                if (hasError) {
                    enqueueSnackbar(`${contract_c.travelers?.length} ligne(s) importée(s) sur ${rows.length}`, { variant: 'warning', autoHideDuration: 10000 });
                } else {
                    enqueueSnackbar(`Liste des nom importée avec succès`, { variant: 'success' });
                }

                const price_per_traveler = (contract_c.travel_prices || 0) / (contract_c.travelers?.length || 1);
                contract_c.travelers?.forEach((traveler, t_index) => {
                    contract_c.travelers[t_index].price = price_per_traveler;
                    contract_c = updateContractPrices(contract_c, -1, (selection.agency?.id || selection.agency || contract_c.subscribing_agency), t_index, type);
                    // enqueueSnackbar(t('contract.incentive_added'), { variant: "warning" });
                });
                SetContract(contract_c);
            };

            reader.readAsBinaryString(file);
        }
        setLoadingProgress(100);
    };
    const updateTravelPrice = (field: string, value: any) => {
        let contract_c = JSON.parse(JSON.stringify((contract)));
        let error_c = { ...errors };
        const new_value = parseFloat(value || "0");
        contract_c[field] = new_value;
        const price_per_traveler = new_value / (contract_c.travelers?.length || 1);
        contract_c.travelers?.forEach((traveler, t_index) => {
            contract_c.travelers[t_index].price = price_per_traveler;
            contract_c = updateContractPrices(contract_c, -1, (selection.agency?.id || selection.agency), t_index, type);
        });
        if (!contract_c.principal_product.is_in_duration_destination) {
            console.log('price_per_traveler:', price_per_traveler);
            let find_slice = contract_c.principal_product.prices.find((price) => {
                return parseFloat(price.min_slice) <= price_per_traveler && price_per_traveler <= parseFloat(price.max_slice);
            });
            if (find_slice === undefined) {
                error_c['travel_prices'] = t('list.field_max_error');
                setErrors(error_c);
            } else {
                delete error_c['travel_prices'];
                setErrors(error_c);
            }

        }
        // enqueueSnackbar(t('contract.incentive_added'), { variant: "warning" });
        SetContract(contract_c);
    };
    return (
        <>
            <Grid item xs={12}>
                <Typography variant="subtitle1" className="title" id="subscriber_info">{t('contract.travelers')}</Typography>
            </Grid>

            {
                type === "GROUP" &&
                <>
                    <Grid item xs={12}>
                        <Typography variant="subtitle2" className="title" style={{ margin: 0 }}>{t('contract.group')}</Typography>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <GenericField onChange={updateField} field={"group_name"} object={contract} label={t('contract.group_name')} />
                    </Grid>
                    <Grid item xs={12} md={6} lg={1.5}>
                        <GenericField onChange={changeNumber} field={"number_of_travelers"} object={contract} label={t('contract.travelers')} type="number"
                            min={travelerRange.min} max={travelerRange.max} />
                    </Grid>
                    {
                        contract.principal_product !== null &&
                        <Grid item xs={12} md={6}>
                            <GenericField onChange={updateTravelPrice} field={"travel_prices"} object={contract} label={t('contract.travel_price')} required
                                min={!contract.principal_product.is_in_duration_destination ? minMaxPrice.min_price : undefined} max={!contract.principal_product.is_in_duration_destination ? minMaxPrice.max_price : undefined} injectedError={errors?.travel_prices} />
                        </Grid>
                    }
                </>
            }
            {
                type !== "GROUP" &&
                <>
                    <Grid item xs={12} md={6} lg={4}>
                        <GenericField onChange={updateField} field={"subscriber_is_part_of_the_trip"} object={contract} label={t('contract.subscriber_is_traveler')} type="checkbox" />
                    </Grid>
                    <Grid item xs={0} md={6} lg={8}></Grid>
                    <Grid item xs={12} md={6} lg={1.5}>
                        <GenericField onChange={changeNumber} field={"number_of_travelers"} object={contract} label={t('contract.travelers')} type="select"
                            options={Array.from({ length: travelerRange.max - travelerRange.min + 1 }, (_, index) => ({
                                label: index + travelerRange.min,
                                value: index + travelerRange.min,
                            }))} />
                    </Grid>
                    <Grid item xs={0} md={6} lg={10.5}></Grid>
                    <Grid item xs={12}>
                        <Typography variant="subtitle2" className="title" style={{ margin: 0 }}>{t('contract.subscriber')}</Typography>
                    </Grid>
                    <Grid item xs={12} md={6} lg={2}>
                        <GenericField onChange={changeSubs} field={"subscriber.title"} object={contract} label={t('contract.title')} type="select" options={[
                            { label: "MR", value: "MR" },
                            { label: "MME", value: "MRS" },
                            { label: "MLLE", value: "MSS" },
                            { label: "AUTRE", value: "MX" },
                        ]} required injectedError={errors?.subscriber?.title} />
                    </Grid>
                    <Grid item xs={12} md={6} lg={3}>
                        <GenericField onChange={changeSubs} field={"subscriber.first_name"} object={contract} label={t('contract.first_name')} required injectedError={errors?.subscriber?.first_name} />
                    </Grid>
                    <Grid item xs={12} md={6} lg={4}>
                        <GenericField onChange={changeSubs} field={"subscriber.last_name"} object={contract} label={t('contract.last_name')} required injectedError={errors?.subscriber?.last_name} />
                    </Grid>
                    <Grid item xs={12} md={6} lg={3}>
                        <GenericField onChange={changeSubs} field={"subscriber.birth_date"} object={contract} label={t('contract.birth_date')} type="datepicker"
                            dateViews={['year', 'month', 'day']} openTo="year" min="1920-01-01" max={new Date()} />
                    </Grid>
                    <Grid item xs={12} md={6} lg={5}>
                        <GenericField onChange={changeSubsEmail} field={`subscriber.email`} object={contract} label={t('contract.email')} required
                            injectedError={errors?.subscriber?.email} />
                    </Grid>
                    <Grid item xs={12} md={6} lg={3}>
                        <GenericField onChange={changeSubs} field={`subscriber.phone_number`} object={contract} label={t('contract.phone')} type="phone" />
                    </Grid>
                    <Grid item md={12} lg={4}></Grid>
                </>
            }


            <Grid item xs={12}>
                <Typography variant="subtitle2" className="title" style={{ margin: 0 }}>{t('contract.travelers')}</Typography>
                {
                    type === "GROUP" &&
                    <Grid container>
                        {
                            // !contract.travelers?.length &&
                            <Grid item xs={6}>
                                <FormControlLabel control={<Checkbox checked={uploadLater} />} label={t('contract.upload_later')} onChange={() => setUploadLater(!uploadLater)} />
                            </Grid>
                        }
                        <Grid item xs={6}>
                            <a href="/documents/liste-nom-contrat-groupe_-_modele.xlsx" download>{t('contract.download_model')}</a>
                        </Grid>
                        <Grid item xs={12}>
                            <hr />
                        </Grid>
                        {
                            !uploadLater &&
                            <Grid item xs={12}>
                                <Typography variant="subtitle1" className="title">{t('contract.upload_names')}</Typography>
                                <Typography variant="subtitle2" className="title">{t('contract.upload_names_restriction')}</Typography>
                                <Dropzone onUpload={handleFileSelection} uploadProgress={loadingProgress} accept={["xlsx"]} />
                            </Grid>
                        }
                    </Grid>
                }
            </Grid>
            {
                (!uploadLater || (contract.id && Boolean(contract.travelers?.length))) &&
                <>
                    {
                        contract.travelers?.map((traveler, t_index) => {
                            return (
                                <React.Fragment key={"traveler-" + t_index}>
                                    {
                                        type !== "GROUP" && contract.subscriber_is_part_of_the_trip && (!traveler.birth_date || getAgeDiff(traveler.birth_date) > 18) &&
                                        <Grid item xs={12}>
                                            <FormControlLabel control={<Checkbox checked={subscriberIndex === t_index} />} label={t('contract.traveler_is_subscriber')} onChange={() => changeSubscriberIndex(t_index)} />
                                        </Grid>
                                    }
                                    <Grid item xs={12} md={6} lg={2} id={`traveler-info-${t_index}`}>
                                        <GenericField onChange={updateField} field={`travelers.${t_index}.title`} object={contract} label={t('contract.title')} type="select" options={[
                                            { label: "MR", value: "MR" },
                                            { label: "MME", value: "MRS" },
                                            { label: "MLLE", value: "MSS" },
                                            { label: "AUTRE", value: "MX" },
                                        ]} readonly={subscriberIndex === t_index} required
                                            injectedError={subscriberIndex === t_index ? undefined : errors?.travelers?.at(t_index)?.title} />
                                    </Grid>
                                    <Grid item xs={12} md={6} lg={3}>
                                        <GenericField onChange={updateField} field={`travelers.${t_index}.first_name`} object={contract} label={t('contract.first_name')} readonly={subscriberIndex === t_index}
                                            required injectedError={subscriberIndex === t_index ? undefined : errors?.travelers?.at(t_index)?.first_name} />
                                    </Grid>
                                    <Grid item xs={12} md={6} lg={4}>
                                        <GenericField onChange={updateField} field={`travelers.${t_index}.last_name`} object={contract} label={t('contract.last_name')} readonly={subscriberIndex === t_index}
                                            required injectedError={subscriberIndex === t_index ? undefined : errors?.travelers?.at(t_index)?.last_name} />
                                    </Grid>
                                    <Grid item xs={12} md={6} lg={3}>
                                        <GenericField onChange={updateField} field={`travelers.${t_index}.birth_date`} object={contract} label={t('contract.birth_date')} type="datepicker" readonly={subscriberIndex === t_index}
                                            dateViews={['year', 'month', 'day']} openTo="year" min="1920-01-01" max={new Date()} />
                                    </Grid>
                                    {
                                        type !== "GROUP" &&
                                        <>
                                            <Grid item xs={12} md={6} lg={5}>
                                                <GenericField onChange={updateField} field={`travelers.${t_index}.email`} object={contract} label={t('contract.email')} readonly={subscriberIndex === t_index}
                                                    placeholder={contract.subscriber?.email} displayValue={contract.travelers?.at(t_index)?.email || contract.travelers?.at(t_index)?.temp_email} />
                                            </Grid>
                                            <Grid item xs={12} md={6} lg={3}>
                                                <GenericField onChange={updateField} field={`travelers.${t_index}.phone_number`} type="phone" object={contract} label={t('contract.phone')} readonly={subscriberIndex === t_index} />
                                            </Grid>
                                            <Grid item xs={12} md={6} lg={4}>
                                                <GenericField onChange={(f, v) => updatePriceField(t_index, v)} field={`travelers.${t_index}.price`} object={contract} label={t('contract.traveler_price')}
                                                    required endAdornment={<Euro />} injectedError={subscriberIndex === t_index ? errors?.subscriber?.price : errors?.travelers?.at(t_index)?.price}
                                                    displayValue={contract.travelers[t_index]?.price || ""} min={!contract.principal_product?.is_in_duration_destination ? minMaxPrice.min_price : undefined} max={!contract.principal_product?.is_in_duration_destination ? minMaxPrice.max_price : undefined}/>
                                            </Grid>
                                        </>
                                    }
                                </React.Fragment>
                            );
                        })
                    }
                    {
                        type !== "GROUP" &&
                        <Grid item xs={12}>
                            <small>*{t('contract.click_on_public_price')}</small>
                            <div style={{ height: 400, width: '100%' }}>
                                <DataGrid
                                    slots={{ columnMenu: CustomColumnMenu }}
                                    rows={contract.travelers?.map((traveler, idx) => { return { ...traveler, id: traveler.id ? traveler.id : idx } })}
                                    columns={columns}
                                    disableRowSelectionOnClick
                                    initialState={{
                                        pagination: { paginationModel: { pageSize: type !== "GROUP" ? 25 : 50 } },
                                    }}
                                    pageSizeOptions={[5, 10, 25, 50]}
                                    localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
                                />
                            </div>
                            <div style={{ border: '1px solid rgba(0, 0, 0, 0.2)', display: "flex", justifyContent: "space-between", paddingRight: 16 }}>
                                {
                                    // incentiveValue !== 0 &&
                                    // <p style={{ color: "#F3A561" }}>{t('contract.incentive')} {contract.travelers?.reduce((acc, t: any) => acc + parseFloat(t.price || '0'), 0) > 0 ? incentiveValue : 0} {'€'}</p>
                                }
                                <p style={{ color: "#F3A561", marginLeft: 10 }}>{t('contract.total_traveler_price')} {contract.travelers?.reduce((acc, t: any) => acc + parseFloat(t.price || '0'), 0)} €</p>
                                {/* <p style={{ color: "#F3A561" }}>{t('contract.total_price')} {contract.travelers?.reduce((acc, t: any) => acc + parseFloat(t.net_price || '0'), 0).toFixed(2)} €</p> */}
                                {
                                    ((profile?.is_superuser || profile?.client_full?.name === "Valeurs Assurances")) &&
                                    <p style={{ color: "#F3A561" }}>{t('contract.total_margin')} {(contract.travelers?.reduce((acc, t: any) => acc + parseFloat(t.margin || '0'), 0) / contract.travelers?.length).toFixed(2)} %</p>
                                }
                                {
                                    // parseFloat(remiseValue) !== 0 &&
                                    // <p style={{ color: "#F3A561" }}>{t('contract.total_remise')} {remiseValue} €</p>
                                }
                            </div>
                        </Grid>
                    }
                </>
            }
            {
                type === "GROUP" &&
                <Grid item xs={12}>
                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>{t('contract.group_total_traveler')}</TableCell>
                                    <TableCell>{t('contract.group_total_travel_price')}</TableCell>
                                    <TableCell>{t('contract.group_total_net_price')}</TableCell>
                                    <TableCell>{t('contract.group_total_public_price')}</TableCell>
                                    {/* <TableCell>Prix total des extensions</TableCell> */}
                                    {
                                        // incentive &&
                                        // <TableCell>{t('contract.incentive')}</TableCell>
                                    }
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell component="th" scope="row">{contract.travelers?.length}</TableCell>
                                    <TableCell>{contract.travel_prices || 0} €</TableCell>
                                    <TableCell>{(contract.travelers?.reduce((acc, t: any) => acc + parseFloat(t.net_price || '0'), 0)).toFixed(2)} €</TableCell>
                                    <TableCell>{(contract.travelers?.reduce((acc, t: any) => acc + parseFloat(t.public_price || '0'), 0)).toFixed(2)} €</TableCell>
                                    {/* <TableCell>{(contract.travelers?.reduce((acc, t: any) => acc + parseFloat(t.extension_price || '0'), 0)).toFixed(2)} €</TableCell> */}
                                    {
                                        // incentiveValue !== 0 &&
                                        // <TableCell>{incentiveValue} {'€'}</TableCell>
                                    }
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            }
            <div style={{marginTop: 10, width: '100%'}}>
                <Grid container direction={'row-reverse'}>
                    <Grid item md={6}>
                        <Paper sx={{padding: '10px'}}>
                            <Typography sx={{textAlign: 'center', fontWeight: 'bold'}}>{'Récapitulatif prix'}</Typography>
                            <TableContainer>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell></TableCell>
                                            <TableCell align="right">{'Prix NET'}</TableCell>
                                            <TableCell align="right">{'Prix de vente'}</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 }, padding: '5px' }}>
                                            <TableCell component="th" scope="row">{'Garantie'}</TableCell>
                                            <TableCell align="right">{contract.travelers?.reduce((acc, t: any) => acc + parseFloat(t.net_price || '0'), 0).toFixed(2)}€</TableCell>
                                            <TableCell align="right">{contract.travelers?.reduce((acc, t: any) => acc + parseFloat(t.public_price || '0'), 0).toFixed(2)}€</TableCell>
                                        </TableRow>
                                        <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 }, padding: '5px' }}>
                                            <TableCell component="th" scope="row">{'Extension(s)'}</TableCell>
                                            <TableCell align="right">{contract.travelers?.reduce((acc, t: any) => acc + parseFloat(t.extension_price_net || '0'), 0).toFixed(2)}€</TableCell>
                                            <TableCell align="right">{contract.travelers?.reduce((acc, t: any) => acc + parseFloat(t.extension_price || '0'), 0).toFixed(2)}€</TableCell>
                                        </TableRow>
                                        {
                                            incentiveValue !== 0 &&
                                            <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 }, padding: '5px' }}>
                                                <TableCell component="th" scope="row">{'Incentive'}</TableCell>
                                                <TableCell align="right">
                                                    {
                                                        incentive !== null && incentive.is_re_invoiced ?
                                                        contract.travelers?.reduce((acc, t: any) => acc + parseFloat(t.incentive || '0'), 0).toFixed(2) + '€' :
                                                        '-'
                                                    }
                                                </TableCell>
                                                <TableCell align="right">
                                                    {
                                                        incentive !== null && incentive.is_re_invoiced ?
                                                        contract.travelers?.reduce((acc, t: any) => acc + parseFloat(t.incentive || '0'), 0).toFixed(2) + '€' :
                                                        '-'
                                                    }
                                                </TableCell>
                                            </TableRow>
                                        }
                                        <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 }, padding: '5px' }}>
                                            <TableCell component="th" scope="row">{'Remise(s)'}</TableCell>
                                            <TableCell align="right">{contract.travelers?.reduce((acc, t: any) => t.is_reduction ? (acc - parseFloat(t.reduction_net || '0'))  : (acc + parseFloat(t.reduction_net || '0')), 0).toFixed(2)}€</TableCell>
                                            <TableCell align="right">{contract.travelers?.reduce((acc, t: any) => t.is_reduction ? (acc - parseFloat(t.reduction_public || '0'))  : (acc + parseFloat(t.reduction_public || '0')), 0).toFixed(2)}€</TableCell>
                                        </TableRow>
                                        <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 }, padding: '5px' }}>
                                            <TableCell component="th" scope="row" sx={{fontWeight: 'bold'}}>{'TOTAL'}</TableCell>
                                            <TableCell align="right">{
                                                (parseFloat(contract.travelers?.reduce((acc, t: any) => acc + parseFloat(t.net_price || '0'), 0).toFixed(2)) +
                                                parseFloat(contract.travelers?.reduce((acc, t: any) => acc + parseFloat(t.extension_price_net || '0'), 0).toFixed(2)) + 
                                                parseFloat(contract.travelers?.reduce((acc, t: any) => t.is_reduction ? (acc - parseFloat(t.reduction_net || '0'))  : (acc + parseFloat(t.reduction_net || '0')), 0).toFixed(2)) +
                                                (incentive !== null && incentive.is_re_invoiced ? parseFloat(contract.travelers?.reduce((acc, t: any) => acc + parseFloat(t.incentive || '0'), 0).toFixed(2)) : 0)).toFixed(2)
                                            }€</TableCell>
                                            <TableCell align="right">{
                                                (parseFloat(contract.travelers?.reduce((acc, t: any) => acc + parseFloat(t.public_price || '0'), 0).toFixed(2)) +
                                                parseFloat(contract.travelers?.reduce((acc, t: any) => acc + parseFloat(t.extension_price || '0'), 0).toFixed(2)) +
                                                parseFloat(contract.travelers?.reduce((acc, t: any) => t.is_reduction ? (acc - parseFloat(t.reduction_public || '0'))  : (acc + parseFloat(t.reduction_public || '0')), 0).toFixed(2)) +
                                                (incentive !== null && incentive.is_re_invoiced ? parseFloat(contract.travelers?.reduce((acc, t: any) => acc + parseFloat(t.incentive || '0'), 0).toFixed(2)) : 0)).toFixed(2)
                                            }€</TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Paper>
                    </Grid>
                </Grid>
            </div>
            <Grid item xs={12}>
                <hr />
            </Grid>
        </>
    );
};

export default ContractFormTravelers;