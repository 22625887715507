// Dependencies
import { useTranslation } from "react-i18next";
import React, { FC, ReactElement, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useSnackbar } from "notistack";
// Core & Lab
// Icons
import CloseIcon from '@mui/icons-material/Close';
import { RootState } from "../../store/store";
import { Sinister, SinisterDocument, SinisterExpectedDocument } from "../../types/contract/sinister";
import { DataGrid, GridColumnMenu, GridColumnMenuProps, GridRenderCellParams, frFR } from "@mui/x-data-grid";
import { Box, Button, Chip, CircularProgress, Container, Dialog, DialogContent, DialogContentText, DialogTitle, Grid, IconButton, Typography } from "@mui/material";
import { Download, Visibility, Done, DoDisturb, Upload } from "@mui/icons-material";
import axios from "axios";
import { updateObjectField } from "../../functions/ObjectHandler";
import Dropzone from "../common/genericList/dropzone";
import { dowloadSinisterDocument, dowloadWarrantyModel, getSinisterById, getSinisterDocuments } from "../../services/sinisterServices";
import { useParams, useSearchParams } from "react-router-dom";
import UserLoginForm from "./userLoginForm";
import Loading from "../common/loading/loading";
import jsPDF from "jspdf";

const UserDocuments: FC = (): ReactElement => {
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const dispatch = useDispatch();
    const params = useParams();
    const [searchParams, setSearchParams] = useSearchParams();

    const client_id = useSelector((state: RootState) => state.app.client_id);
    const [selected_sinister, setSelectedSinister] = useState<Sinister | null>(null);
    const [uploadExpected, setUploadExpected] = useState<any>(null);
    const [documents, setDocuments] = useState<SinisterDocument[]>([]);
    const [progress, setProgress] = React.useState(0);
    const [loading, setLoading] = React.useState(false);
    // const [loggedIn, setLoggedIn] = React.useState(false);
    const [loggedIn, setLoggedIn] = React.useState(true);

    useEffect(() => {
        if (params.sinister_id) {
            loadDocs(params.sinister_id);
        }
    }, [params]);

    const loadDocs = (sinister_id: number) => {
        setLoading(true);
        getSinisterById(client_id, sinister_id, { token: searchParams.get("token") }).then((res) => {
            if (res.data) {
                const sinister = res.data;
                setSelectedSinister(sinister);
                getSinisterDocuments(client_id, sinister.id, { token: searchParams.get("token") })
                    .then((resp) => {
                        const sn_docs = resp.data.results?.length ? resp.data.results : [];
                        const docs: SinisterExpectedDocument[] = [];
                        sinister.motif?.expecteddocuments?.forEach(dc => {
                            const sn_doc = sn_docs.find(d => d.expected_document_id?.id === dc.id);
                            docs.push({ ...dc, state: sn_doc ? 1 : 0, document: sn_doc || {} });
                        });
                        // sinister.warranty?.expected_documents?.forEach(dc => {
                        //     const sn_doc = sn_docs.find(d => d.expected_document_id?.id === dc.id);
                        //     docs.push({ ...dc, state: sn_doc ? 1 : 0, document: sn_doc || {} });
                        // });
                        setDocuments(docs);
                        setLoading(false);
                    })
                    .catch((err) => console.log(err));
            }
        })
    };

    const productColumns: any = [
        {
            field: 'label', headerName: t('sinisters.doc_label'), flex: 1, headerClassName: 'product-extension-header',
        },
        {
            field: 'required', headerName: t('sinisters.doc_required'), flex: 1, headerClassName: 'product-extension-header',
            renderCell: (params: GridRenderCellParams<any>) => {
                return (
                    <>
                        {params.row.required ? <Done /> : <DoDisturb />}
                    </>
                );
            }
        },
        {
            field: 'state', headerName: t('sinisters.doc_state'), flex: 1, headerClassName: 'product-extension-header',
            renderCell: (params: GridRenderCellParams<any>) => {
                return (
                    <Chip style={{ width: 100, textAlign: "center", color: "white", backgroundColor: getStatusColor(params.row.state) }} label={t(`sinisters.doc_state_${params.row.state || 0}`)} />
                );
            },
        },
        {
            field: 'document', headerName: t('sinisters.doc_filename'), flex: 1, headerClassName: 'product-extension-header',
            valueGetter: (params: any) => params.row.document?.filename,
        },
        {
            field: 'model', headerName: t('sinisters.model'), flex: 1, headerClassName: 'product-extension-header',
            renderCell: (params: GridRenderCellParams<any>) => {
                if (params.row.state !== 1) {
                    return (
                        <Grid container direction={'column'} justifyContent={'center'} alignItems={'left'} sx={{ padding: '2px' }}>
                            <Grid item>
                                <Button onClick={() => downloadTemplate(params.row, true)}>{t('sinisters.view_template')}</Button>
                            </Grid>
                            <Grid item>
                                <Button onClick={() => downloadTemplate(params.row)}>{t('sinisters.download_template')}</Button>
                            </Grid>
                        </Grid>
                    );
                }
                return <div/>
            },
        },
        {
            // field: 'model_fake', headerName: '', flex: 1, headerClassName: 'product-extension-header',
            // renderCell: (params: GridRenderCellParams<any>) => {
            //     if (params.row.state !== 1) {
            //         return ();
            //     }
            //     return <div/>
            // },
        },
        {
            field: 'id', headerName: t('sinisters.doc_actions'), flex: 1, headerClassName: 'product-extension-header',
            renderCell: (params: GridRenderCellParams<any>) => {
                if (params.row.state !== 1) {
                    return (
                        <>
                            {/* <IconButton onClick={() => download(params.row.document, true)}><Visibility /></IconButton>
                            <IconButton onClick={() => download(params.row.document)}><Download /></IconButton> */}
                            <IconButton color="primary" size="large"><Upload onClick={() => setUploadExpected(params.row)} /></IconButton>
                        </>
                    );
                }
                return <div/>
            },
        },
    ];

    const getStatusColor = (status: number) => {
        switch (status) {
            case 1:
                return "#00457c";
            case 2:
                return "#dc001f";
            case 0:
                return "#a7a7a7";
            default:
                break;
        }
    };

    const download = (file: any, newTab?: boolean) => {
        if (file.id) {
            dowloadSinisterDocument(client_id, file.id, params.sinister_id, { token: searchParams.get("token") }).then((response) => {
                if (response.data) {
                    const url = URL.createObjectURL(response.data);
                    if (newTab) {
                        window.open(url, '_blank');
                    } else {
                        const a = document.createElement("a");
                        a.href = url;
                        a.setAttribute('download', file.filename);
                        a.dispatchEvent(new MouseEvent('click'));
                    }
                }
            }).catch((error) => {
                console.log(error);
            });
        }
    };

    const downloadTemplate = (documentToDownload: any, newTab?: boolean) => {
        const product_brand = selected_sinister?.policy?.principal_product?.brand;
        if (product_brand) {
            const file = documentToDownload?.documentTemplate?.find(t => (t.brand?.id || t.brand) == product_brand?.id);
            if (file?.id) {
                dowloadWarrantyModel(client_id, file.id, params.sinister_id, { token: searchParams.get("token") }).then((response) => {
                    if (response.data) {
                        const url = URL.createObjectURL(response.data);
                        if (newTab) {
                            window.open(url, '_blank');
                        } else {
                            const a = document.createElement("a");
                            a.href = url;
                            a.setAttribute('download', file.filename);
                            a.dispatchEvent(new MouseEvent('click'));
                        }
                    }
                }).catch((error) => {
                    console.log(error);
                });
            }
        }
    };

    const closeForm = () => {
        setUploadExpected(null);
    };

    const handleUpload = (file: any) => {
        if (selected_sinister?.id) {
            if (uploadExpected) {
                const method = uploadExpected?.state ? 'PATCH' : 'POST';
                const url = `${API_HREF}client/${client_id}/sinistres-documents/${uploadExpected?.state ? uploadExpected?.document.id + '/' : ''}?token=${searchParams.get("token")}`;
                setProgress(0);
                const request = new FormData();
                request.append('claims_id', selected_sinister.id.toString());
                request.append('expected_document_id', uploadExpected.id.toString());
                request.append('file', file);
                axios({
                    method,
                    url,
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    },
                    data: request,
                    onUploadProgress: (progressEvent) => {
                        setProgress(progressEvent.loaded / progressEvent.total * 100);
                    }
                }).then((response) => {
                    if (selected_sinister?.id) loadDocs(params.sinister_id);
                    closeForm();
                }).catch((error) => {
                    if (error.response?.data?.errors) {
                        for (const [key, value] of Object.entries(error.response.data.errors)) {
                            if (Array.isArray(value)) {
                                value.map(error => enqueueSnackbar(`${error}`, { variant: 'error' }));
                            } else {
                                enqueueSnackbar(`${value}`, { variant: 'error' });
                            }
                        }
                    } else if (error.response) {
                        if (error.response.status === 400) {
                            for (const [key, value] of Object.entries(error.response.data)) {
                                if (Array.isArray(value)) {
                                    value.map(error => enqueueSnackbar(`${key} : ${error}`, { variant: 'error' }));
                                } else {
                                    enqueueSnackbar(`${key} : ${value}`, { variant: 'error' });
                                }
                            }
                        } else if (error.response.status === 401 || error.response.status === 403) {
                            enqueueSnackbar(t('errors.permissions'), { variant: 'error' });
                        } else if (error.response.status === 500) {
                            enqueueSnackbar(t('errors.servers'), { variant: 'error' });
                        }
                    } else if (error.request) {
                        console.log('error.request : ', error.request);
                    } else {
                        console.log('Error', error.message);
                    }
                    console.log('error.config : ', error.config);
                });
            }
        }
    };

    function CustomColumnMenu(props: GridColumnMenuProps) {
        return (
            <GridColumnMenu
                {...props}
                slots={{
                    columnMenuColumnsItem: null,
                    columnMenuFilterItem: null,
                }}
            />
        );
    }

    const getName = (sinister: Sinister): string => {
        return `${sinister.policy?.subscriber?.title || ''} ${sinister.policy?.subscriber?.first_name || ''} ${sinister.policy?.subscriber?.last_name || ''}`;
    };

    const confirmPass = (pass: string) => {
        if (selected_sinister?.sso_pass == pass) {
            setLoggedIn(true);
        }
    };
    const exportDocs = () => {
        const doc = new jsPDF();
        const head: string[] = productColumns?.filter(c => ['label', 'required'].includes(c.field))?.map(column => column.headerName);
        const body: string[][] = [];
        documents?.map((item: any) => {
            body.push([item.label, item.required ? 'oui' : 'non']);
        });
        (doc as any).autoTable({
            head: [head],
            body: body
        });
        doc.save(`liste-des-justificatifs_-_sinistre.pdf`);
    };
    if (selected_sinister !== null) {
        return (
            <>
                <Grid container>
                    <Grid item xs={12} style={{ justifyContent: "space-around" }}>
                        <div style={{ display: "flex", justifyContent: "center", gap: 10, margin: "2em" }}>
                            <img className="logo" src="/images/VA_logo_3c.png" alt="" />
                        </div>
                        <Container style={{ backgroundColor: "white", paddingTop: 15 }}>
                            <Grid item xs={12}>
                                <Typography variant="h4" className="title" style={{ fontWeight: "bold", textAlign: "center" }}>{t('sinisters.number')}{selected_sinister.id}</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="h6" style={{ textAlign: "center" }}>{selected_sinister.policy?.group_name || getName(selected_sinister)}</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="subtitle1" className="title">{t('sinisters.user-docs')}</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Button onClick={exportDocs}>{t('sinisters.export_expected_docs')}</Button>
                            </Grid>
                            <Grid item xs={12} container spacing={2}>
                                <div style={{ height: 600, width: '100%', margin: 16, marginTop: 30 }}>
                                    <DataGrid
                                        slots={{ columnMenu: CustomColumnMenu }}
                                        rows={documents}
                                        columns={productColumns}
                                        initialState={{
                                            pagination: { paginationModel: { pageSize: 25 } },
                                        }}
                                        pageSizeOptions={[5, 10, 25, 50]}
                                        localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
                                        disableRowSelectionOnClick
                                    />
                                </div>
                            </Grid>
                        </Container>
                    </Grid>
                </Grid>
                <Dialog
                    open={Boolean(uploadExpected)}
                    onClose={closeForm}
                    maxWidth="sm"
                    fullWidth
                >
                    <DialogTitle id="alert-dialog-title">{t('sinisters.upload-document')}
                        <IconButton
                            aria-label="close"
                            onClick={closeForm}
                            sx={{
                                position: 'absolute',
                                right: 8,
                                top: 8,
                                color: (theme) => theme.palette.grey[500],
                            }}
                        >
                            <CloseIcon />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText style={{ marginTop: 15 }}>
                            <Grid container item xs={12} spacing={2}>
                                <Grid item xs={12}>
                                    <Dropzone onUpload={handleUpload} uploadProgress={progress} />
                                </Grid>
                                <Grid item xs={12} style={{ display: "flex", gap: 10, justifyContent: "flex-end" }}>
                                    <Button variant="outlined" onClick={closeForm}>{t('insurance.cancel')}</Button>
                                </Grid>
                            </Grid>
                        </DialogContentText>
                    </DialogContent>
                </Dialog>
                <Dialog
                    open={!loggedIn}
                    maxWidth="sm"
                    fullWidth
                >
                    <DialogContent>
                        <DialogContentText style={{ marginTop: 15 }}>
                            <UserLoginForm confirmPass={confirmPass} />
                        </DialogContentText>
                    </DialogContent>
                </Dialog>
                {loading && <Loading />}
            </>
        );
    }
    return <></>;
};

export default UserDocuments;
