import axios, { AxiosResponse, CancelTokenSource } from "axios";
import { OrganisationsDiscount } from "../types/contract/organisationsDiscount";

let cancelToken: CancelTokenSource | null = null;
export const getOrganisationsDiscounts = (client_id: number | null, limit: number, offset: number, filter?: any): Promise<AxiosResponse<{ count: number; results: OrganisationsDiscount[]; }>> => {
    // if (cancelToken) {
    //     cancelToken.cancel("Operation canceled due to new request.");
    // }
    // cancelToken = axios.CancelToken.source();
    let handledFilter = {};
    if (filter) {
        handledFilter = { ...filter };
    }
    return axios.get(
        `${API_HREF}client/${client_id}/value-insurances-free-of-charge/`,
        {
            params: {
                limit,
                offset,
                ...handledFilter,
            },
            // cancelToken: cancelToken.token,
            headers: {
                'Authorization': `Token ${localStorage.getItem('token')}`,
            }
        }
    );
};

export const getOrganisationsDiscountById = (client_id: number | null, discount_id: string | undefined): Promise<AxiosResponse<OrganisationsDiscount>> => {
    if (cancelToken) {
        cancelToken.cancel("Operation canceled due to new request.");
    }
    cancelToken = axios.CancelToken.source();
    return axios.get(
        `${API_HREF}client/${client_id}/value-insurances-free-of-charge/${discount_id}/`,
        {
            params: {},
            cancelToken: cancelToken.token,
            headers: {
                'Authorization': `Token ${localStorage.getItem('token')}`,
            }
        }
    );
};

export const postOrganisationsDiscount = (client_id: number | null, discount: OrganisationsDiscount): Promise<AxiosResponse<OrganisationsDiscount>> => {
    if (cancelToken) {
        cancelToken.cancel("Operation canceled due to new request.");
    }
    cancelToken = axios.CancelToken.source();
    return axios.post(
        `${API_HREF}client/${client_id}/value-insurances-free-of-charge/`,
        discount,
        {
            cancelToken: cancelToken.token,
            headers: {
                'Authorization': `Token ${localStorage.getItem('token')}`,
            }
        }
    );
};

export const patchOrganisationsDiscount = (client_id: number | null, discountId: number | undefined, data: any): Promise<AxiosResponse<OrganisationsDiscount>> => {
    // if (cancelToken) {
    //     cancelToken.cancel("Operation canceled due to new request.");
    // }
    // cancelToken = axios.CancelToken.source();
    return axios.patch(
        `${API_HREF}client/${client_id}/value-insurances-free-of-charge/${discountId}/`,
        data,
        {
            // cancelToken: cancelToken.token,
            headers: {
                'Authorization': `Token ${localStorage.getItem('token')}`,
            }
        }
    );
};

export const putOrganisationsDiscount = (client_id: number | null, discount: OrganisationsDiscount): Promise<AxiosResponse<OrganisationsDiscount>> => {
    if (cancelToken) {
        cancelToken.cancel("Operation canceled due to new request.");
    }
    cancelToken = axios.CancelToken.source();
    return axios.put(
        `${API_HREF}client/${client_id}/value-insurances-free-of-charge/${discount.id}/`,
        discount,
        {
            cancelToken: cancelToken.token,
            headers: {
                'Authorization': `Token ${localStorage.getItem('token')}`,
            }
        }
    );
};

export const deleteOrganisationsDiscount = (client_id: number | null, discount_id: number): Promise<AxiosResponse<any>> => {
    if (cancelToken) {
        cancelToken.cancel("Operation canceled due to new request.");
    }
    cancelToken = axios.CancelToken.source();
    return axios.delete(
        `${API_HREF}client/${client_id}/value-insurances-free-of-charge/${discount_id}/`,
        {
            cancelToken: cancelToken.token,
            headers: {
                'Authorization': `Token ${localStorage.getItem('token')}`,
            }
        }
    );
};