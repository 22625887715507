// Dependencies
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import React, { FC, ReactElement, useEffect, useState } from 'react';
// Core & Lab
import { Button, Grid } from '@mui/material';
import Container from '@mui/material/Container';
import { updateObjectField } from '../../functions/ObjectHandler';
import { initSinister } from '../../functions/contract';
import { RootState } from '../../store/store';
import { Sinister } from '../../types/contract/sinister';
import SinisterFormDescription from './sinisterFormDescription';
import SinisterFormWarranty from './sinisterFormWarranty';

const SinistersForm: FC<{
    onConfirm: (data: any) => void,
    onCancel?: () => void,
    onConfirmLabel: string,
    mode?: string,
    display?: string[]
}> = ({ onConfirm, onCancel, onConfirmLabel, display }): ReactElement => {
    const { t } = useTranslation();

    const selected_sinister_init = useSelector((state: RootState) => state.app.selected_sinister);
    const [selected_sinister, setSelectedSinister] = useState<Sinister | null>(null);
    const [validForm, setValidForm] = useState(false);
    console.log('selected_sinister:', selected_sinister);
    useEffect(() => {
        if (selected_sinister_init && !selected_sinister) {
            const clean_sinister = initSinister(selected_sinister_init);
            setSelectedSinister(clean_sinister);
        } else if (!selected_sinister) {
            setSelectedSinister(initSinister({} as any));
        }
    }, [selected_sinister_init]);

    useEffect(() => {
        let validForm = false;
        // if (selected_sinister && selected_sinister.warranty && selected_sinister.motif && selected_sinister.policy?.id
        //     && (selected_sinister.warranty?.id || (!selected_sinister.warranty.id &&
        //         selected_sinister.warranty.code && selected_sinister.warranty.reference && selected_sinister.warranty.name))) {
        //     validForm = true;
        // }
        if (selected_sinister && selected_sinister.warranty && selected_sinister.motif && selected_sinister.policy?.id) {
            validForm = true;
        }
        setValidForm(validForm);
    }, [selected_sinister]);

    const updateField = (field: string, value: any) => {
        updateObjectField(selected_sinister, field, value, setSelectedSinister);
    };

    const onValidate = () => {
        if (onConfirm !== undefined && onConfirm !== null) onConfirm(selected_sinister);
    };

    return (
        <Container className={'ft-container sinister-form'} maxWidth={'md'}>

            {
                (!display || display.includes('DESCRIPTION')) && <SinisterFormDescription sinister={selected_sinister} updateFieldCallback={updateField} />
            }
            <br />
            {
                (!display || display.includes('WARRANTY')) && <SinisterFormWarranty sinister={selected_sinister} updateFieldCallback={updateField} />
            }
            {/* {
                (!display || display.includes('FEES')) && <SinisterFormPrice sinister={selected_sinister} updateFieldCallback={updateField} />
            } */}
            <Grid container spacing={2} justifyContent={'flex-end'}>
                <Grid item>
                    <Button onClick={onCancel}>{t('insurance.cancel')}</Button>
                </Grid>
                <Grid item>
                    <Button onClick={onValidate} disabled={!validForm}>{onConfirmLabel}</Button>
                </Grid>
            </Grid>
        </Container>
    );

};

export default SinistersForm;