import { ArrowBackIosNew, Download } from "@mui/icons-material";
import { Button, Container, Dialog, DialogActions, DialogTitle, Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { FC, ReactElement } from "react";
import { useDispatch, useSelector } from "react-redux";
import { cleanContract } from "../../functions/contract";
import { patchContract, postContract } from "../../services/contractServices";
import { RootState } from "../../store/store";
import { Contract } from "../../types/contract/contract";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import ContractForm from "./ContractForm";
import ContractTemplate from "./ContractTemplate";
import jsPDF from "jspdf";
import { useTranslation } from "react-i18next";
import { setSelectedContract } from "../../store/reducer";
import axios from "axios";
import ContractGroupTemplate from "./ContractGroupTemplate";

const EditContractForm: FC = (): ReactElement => {
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();
    const { id } = useParams();
    const dispatch = useDispatch();

    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down(600));
    const client_id = useSelector((state: RootState) => state.app.client_id);
    const selected_contract = useSelector((state: RootState) => state.app.selected_contract);
    const contract_types = useSelector((state: RootState) => state.app.contract_types);

    const [openCancel, setOpenCancel] = React.useState(false);
    const [loading, SetLoading] = useState(false);
    const [contractToExport, SetContractToExport] = useState<Contract | null>(null);

    const [form_title, setFormTitle] = useState(t('contract.edit'));

    const [searchParams, setSearchParams] = useSearchParams();
    const type = searchParams.get("type");

    useEffect(() => {
        if (id && !selected_contract) navigate('/insurance/contracts/' + id);

    }, [id]);
    useEffect(() => {
        if (contractToExport !== null) {
            navigate('/insurance/contracts/' + contractToExport.id);
            buildPDF('contrat_' + contractToExport?.reference?.replace('/', '-'), () => {
                SetLoading(false);
                SetContractToExport(null);
                dispatch(setSelectedContract(contractToExport));
            });
        }
    }, [contractToExport]);
    const getAgeDiff = (birth_date: any) => {
        if (!birth_date) return 0;
        const now = new Date();
        const b_date = new Date(birth_date);
        const diff = now.getFullYear() - b_date.getFullYear();
        return diff;
    };

    const buildPDF = (filename: string, onExportCallback?: () => void) => {
        const refreshIntervalId = setInterval(async () => {
            const content: any = document.querySelector('#contract-template');
            if (content) {
                clearInterval(refreshIntervalId);

                const response = await axios.post<Blob>(
                    `https://docraptor.com/docs`,
                    {
                        user_credentials: 'a0Ahb9NO49IOnaQJH9Ir',
                        doc: {
                            test: true, // TODO: handle me
                            type: 'pdf',
                            pipeline: '9.2',
                            document_content: content.innerHTML,
                            javascript: true,
                            ignore_console_messages: false,
                            prince_options: {
                                css_dpi: 135
                            }
                        }
                    },
                    {
                        responseType: 'blob'
                    }
                );
                if (response.data) {
                    const url = URL.createObjectURL(response.data);
                    const a = document.createElement('a');
                    a.href = url;
                    a.download = filename + '.pdf';
                    document.body.appendChild(a);
                    a.click();
                    window.URL.revokeObjectURL(url);
                    onExportCallback();
                }
            }
        }, 100);
    };
    const confirmForm = (contract: Contract) => {
        const contractToAdd: any = cleanContract(contract);
        delete contractToAdd.created_date;
        delete contractToAdd.creator;
        delete contractToAdd.last_author;
        delete contractToAdd.modified_date;
        delete contractToAdd.group_passenger;
        delete contractToAdd.reference;
        if (!contractToAdd.contracts_type) contractToAdd.contracts_type = contract_types?.find(ct => ct.abbreviation === 'FT-INDIV')?.id;
        if (contractToAdd.contracts_type) contractToAdd.contracts_type = contractToAdd?.contracts_type?.id || contractToAdd?.contracts_type;
        contractToAdd.travelers_group = contractToAdd.travelers;
        delete contractToAdd.travelers;
        if (contractToAdd.travelers_group.some(p => p.birth_date && getAgeDiff(p.birth_date) <= 2)) contractToAdd.one_pax_is_baby = true;
        contractToAdd.agency = client_id;
        contractToAdd.product_extension = [];
        SetLoading(true);
        patchContract(client_id, contractToAdd.id, contractToAdd)
            .then((resp) => {
                enqueueSnackbar(t('contract.edited'), { variant: 'success' });
                SetContractToExport(resp.data);
            })
            .catch((error) => {
                if (error.response?.data?.errors) {
                    for (const [key, value] of Object.entries(error.response.data.errors)) {
                        if (Array.isArray(value)) {
                            value.map(error => enqueueSnackbar(`${error}`, { variant: 'error' }));
                        } else {
                            enqueueSnackbar(`${value}`, { variant: 'error' });
                        }
                    }
                } else if (error.response) {
                    if (error.response.status === 400) {
                        for (const [key, value] of Object.entries(error.response.data)) {
                            if (Array.isArray(value)) {
                                value.map(error => enqueueSnackbar(`${key} : ${error}`, { variant: 'error' }));
                            } else {
                                enqueueSnackbar(`${key} : ${value}`, { variant: 'error' });
                            }
                        }
                    } else if (error.response.status === 401 || error.response.status === 403) {
                        enqueueSnackbar(t('errors.permissions'), { variant: 'error' });
                    } else if (error.response.status === 500) {
                        enqueueSnackbar(t('errors.servers'), { variant: 'error' });
                    }
                } else if (error.request) {
                    console.log('error.request : ', error.request);
                } else {
                    console.log('Error', error.message);
                }
                console.log('error.config : ', error.config);
                SetLoading(false);
            });
    };
    return (
        <Container>
            <Grid container spacing={2}>
                {/* <Grid item xs={12}>
                    <Button startIcon={<Download />} onClick={() => buildPDF()}>download</Button>
                </Grid> */}
                <Grid item md={12} lg={4}>
                    <Button startIcon={<ArrowBackIosNew />} onClick={() => setOpenCancel(true)}>{t('shared.back')}</Button>
                </Grid>
                <Grid item md={12} lg={8}>
                    <Typography variant={isSmallScreen ? "h5" : "h4"} className="title" style={{ fontWeight: "bold" }}>{form_title} {selected_contract?.reference}</Typography>
                </Grid>
                <ContractForm onConfirm={confirmForm} type={type} loading={loading} />
            </Grid>
            {
                contractToExport && contractToExport.contracts_type?.abbreviation === 'FT-INDIV' && <ContractTemplate contract={contractToExport} />
            }
            {
                contractToExport && contractToExport.contracts_type?.abbreviation === 'FT-GROUP' && <ContractGroupTemplate contract={contractToExport} />
            }
            <Dialog open={openCancel} onClose={() => setOpenCancel(false)}>
                <DialogTitle> {t('contract.ask_edit')} </DialogTitle>
                <DialogActions>
                    <Button onClick={() => setOpenCancel(false)} autoFocus>{t('shared.no')}</Button>
                    <Button onClick={() => navigate('/insurance/contracts/' + id)}>{t('shared.yes')}</Button>
                </DialogActions>
            </Dialog>
        </Container>
    );
};

export default EditContractForm;