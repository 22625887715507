import { Add, Remove } from "@mui/icons-material";
import { Button, Grid, Typography } from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { FC, ReactElement } from "react";
import { useSelector } from "react-redux";
import { updateObjectField } from "../../functions/ObjectHandler";
import { getCountries, getOrganisations } from "../../services/commonServices";
import { getFamilies, getProductById, getProducts } from "../../services/productServices";
import { RootState } from "../../store/store";
import { Contract } from "../../types/contract/contract";
import GenericField from "../common/genericList/genericField";
import { useTranslation } from "react-i18next";
import { AgencyIncentive } from "../../types/insurance/insurance-product";
import { ContractType } from "../../types/contract/contractType";
import { resetContractPrices, updateContractPrices } from "../../functions/contract";

const ContractFormDescription: FC<{
    contract: Contract,
    SetContract: (contract: Contract) => void,
    selection: any,
    setSelection: (selection: any) => void,
    errors: any,
    incentive: AgencyIncentive | null,
    setIncentive: (incentive: AgencyIncentive | null) => void,
    contract_type: ContractType | null,
    subscriberIndex: number,
    type: string | undefined,
    travelerPriceCalulation: (index: any, price?: any, public_price?: any, force_price?: boolean, force_public_price?: boolean) => void
}> = ({ contract, SetContract, selection, setSelection, errors, incentive, setIncentive, contract_type, subscriberIndex, type, travelerPriceCalulation }): ReactElement => {

    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();

    const client_id = useSelector((state: RootState) => state.app.client_id);
    const profile = useSelector((state: RootState) => state.app.profile);

    const [has_sec_prod, setHasSecProd] = useState(false);
    const [has_ext, setHasExt] = useState(false);
    const [updateExt, setUpdateExt] = useState(false);
    useEffect(() => {
        if (contract?.secondary_product !== null) setHasSecProd(true);
        if (contract?.product_extension && contract?.product_extension.length !== 0) setHasExt(true);
    }, [contract]);
    useEffect(() => {
        if (updateExt && contract.product_extension !== null) {
            const findStandAlone = contract.product_extension.find(e => !e.agencies_prices);
            if (findStandAlone?.id) {
                const cleanValue = contract.product_extension.filter(e => e.agencies_prices);
                getProductById(client_id, findStandAlone.id)
                    .then(response => {
                        if (response.data) {
                            cleanValue.push(response.data);
                            updateObjectField(contract, 'product_extension', cleanValue, SetContract);
                            checkExtensionPrice();
                        }
                    })
                    .catch(error => console.log("Extension product loading error", error));
            } else {
                checkExtensionPrice();
            }
            setUpdateExt(false);
        }
    }, [updateExt]);
    const checkExtensionPrice = () => {
        if (contract.product_extension !== null) {
            let contract_c = JSON.parse(JSON.stringify((contract)));
            contract_c.travelers?.forEach((trav, trav_index) => {
                if (trav.price !== undefined) {
                    travelerPriceCalulation(trav_index, trav.price, undefined, true, false);
                    contract_c = updateContractPrices(contract_c, -1, selection.agency?.id || selection.agency || contract_c.subscribing_agency, trav_index, type);
                }
            });
            SetContract(contract_c);
        }
    }
    const updateField = (field: string, value: any) => {
        updateObjectField(contract, field, value, SetContract);
    };

    const updateCountry = (field: string, value: any) => {
        let contract_c = JSON.parse(JSON.stringify(contract));
        contract_c[field] = value;
        contract_c.principal_product = null;
        if (contract_c.travelers?.some(t => t.price)) {
            enqueueSnackbar(t('contract.product_reset'), { variant: "warning" });
        }
        contract_c = resetContractPrices(contract_c);
        SetContract(contract_c);
    };

    const changePrincipalProduct = (field: string, value: any) => {
        updateObjectField(contract, field, value, SetContract);
        getProductById(client_id, value?.id)
            .then(response => {
                if (response.data) {
                    const new_val = response.data;
                    const incentive = new_val?.agencies_incentive?.find(ai => (ai.agency?.id || ai.agency) === (selection?.agency?.id || selection?.agency));
                    if (incentive) setIncentive(incentive);
                    // updateObjectField(contract, field, new_val, SetContract);
                    let contract_c = JSON.parse(JSON.stringify(contract));
                    contract_c[field] = new_val;
                    contract_c.secondary_product = null;
                    if (contract_c.product_extension?.length) enqueueSnackbar(t('contract.removed_extension'), { variant: 'warning' });
                    contract_c.product_extension = [];
                    if (contract_c.travelers?.some(t => t.price)) {
                        enqueueSnackbar(t('contract.prices_changes'), { variant: "warning" });
                    }
                    contract_c.travelers?.forEach((trav, trav_index) => {
                        contract_c = updateContractPrices(contract_c, subscriberIndex, selection.agency?.id || selection.agency, trav_index, type);
                    });
                    // enqueueSnackbar(t('contract.incentive_added'), { variant: "warning" });
                    SetContract(contract_c);
                    let w_departue_set = false;
                    let w_two_days_set = false;
                    new_val.warranty?.forEach(warranty => {
                        if (warranty.apply_after_departure && !w_departue_set) {
                            enqueueSnackbar(t('contract.apply_after_departure'), { variant: 'warning' });
                            w_departue_set = true;
                        }
                        // if (warranty.not_editable_after_two_days && !w_two_days_set) {
                        //     const mesg = t('contract.not_editable_after_two_days').toString().replace('{product_name}', new_val.name).replace('{warranty_name}', warranty.name);
                        //     enqueueSnackbar(mesg, { variant: 'warning' });
                        //     w_two_days_set = true;
                        // }
                    });
                }
            })
            .catch(error => console.log("Principal product loading error", error));
    };

    const updateFamily = (field: string, value: any) => {
        let contract_c = JSON.parse(JSON.stringify(contract));
        contract_c[field] = value;
        contract_c.principal_product = null;
        contract_c.secondary_product = null;
        contract_c.product_extension = [];
        if (contract_c.travelers?.some(t => t.price)) {
            enqueueSnackbar(t('contract.product_reset'), { variant: "warning" });
            contract_c = resetContractPrices(contract_c);
        }
        setIncentive(null);
        SetContract(contract_c);
    };

    const changeSecondaryProduct = (field: string, value: any) => {
        updateObjectField(contract, field, value, SetContract);
        if (value !== null) {
            getProductById(client_id, value?.id)
                .then(response => {
                    if (response.data) {
                        updateObjectField(contract, field, response.data, SetContract);
                    }
                })
                .catch(error => console.log("Principal product loading error", error));
        }
    };
    const changeExtensions = (field: string, value: any) => {
        updateObjectField(contract, field, value, SetContract);
        setUpdateExt(true);
    };

    const onChangeAgency = (field: string, value: any) => {
        let contract_c = JSON.parse(JSON.stringify(contract));
        if (contract_c.travelers?.some(t => t.price)) {
            enqueueSnackbar(t('contract.product_reset'), { variant: "warning" });
        }
        contract_c.principal_product = null;
        contract_c = resetContractPrices(contract_c);
        // contract_c.travelers?.forEach((trav, trav_index) => {
        //     contract_c = updateContractPrices(contract_c, subscriberIndex, value?.id || value, trav_index);
        // });
        SetContract(contract_c);
        updateObjectField(selection, field, value, setSelection);
    };
    return (
        <>
            <Grid item xs={12}>
                <Typography variant="subtitle1" className="title">{t('contract.destinations')}</Typography>
            </Grid>
            <Grid item xs={12} md={12} lg={3}>
                <GenericField onChange={updateCountry} field={"departure_country"} object={contract} label={t('contract.departure_country')} type="autocomplete" required
                    autocompleteGetter={getCountries} injectedError={errors?.departure_country}
                // autocompleteFilter={contract.principal_product?.country_of_residence?.length ? (e, i) => contract.principal_product?.country_of_residence?.map(c => c?.id || c)?.includes(e.id) : undefined}
                />
            </Grid>
            <Grid item xs={12} md={12} lg={7}>
                <GenericField onChange={updateCountry} field={"destinations"} object={contract} label={t('contract.destinations')} type="autocomplete" multiple={contract.multi_destination_travel}
                    autocompleteGetter={getCountries} required injectedError={errors?.destinations}
                // autocompleteFilter={contract.principal_product?.non_authorized_countries?.length ? (e, i) => !contract.principal_product?.non_authorized_countries?.map(c => c?.id || c)?.includes(e.id) : undefined}
                />
            </Grid>
            <Grid item xs={12}>
                <Typography variant="subtitle1" className="title">{t('contract.products')}</Typography>
            </Grid>
            {
                (profile?.is_superuser || profile?.client_full?.name === "Valeurs Assurances") &&
                <>
                    <Grid item xs={4}>
                        <GenericField onChange={(f, v) => onChangeAgency(f, v)} field={"agency"} object={selection} label={t('contract.choose_agency')} type="autocomplete"
                            autocompleteGetter={getOrganisations} />
                    </Grid>
                    <Grid item xs={8}></Grid>
                </>
            }
            <Grid item xs={12} md={6} lg={3.5}>
                <GenericField onChange={updateFamily} field={"product_family"} object={contract} label={t('contract.product_family')} type="autocomplete"
                    autocompleteGetter={getFamilies} required injectedError={errors?.product_family} />
            </Grid>
            <Grid item xs={12} md={6} lg={3.5}>
                <GenericField onChange={changePrincipalProduct} field={"principal_product"} object={contract} label={t('contract.principal_product')} type="autocomplete" required
                    autocompleteGetter={(c, search) => getProducts(c, {
                        search,
                        type: "PRI",
                        contract: "True",
                        product_family__id: contract.product_family?.id || contract.product_family,
                        agencies_incentive__id: selection?.agency?.id || undefined,
                        subscription_method__id: contract_type?.id,
                        departure_country: contract.departure_country?.id,
                        destinations: Array.isArray(contract.destinations) ? contract.destinations?.map(d => d.id).join(',') : (contract.destinations as any)?.id
                    })}
                    injectedError={errors?.principal_product} readonly={!contract.product_family} />
            </Grid>
            <Grid item md={12} lg={5}></Grid>
            {
                !has_sec_prod &&
                <Grid item xs={12} md={6} lg={3.5}>
                    <Button startIcon={<Add />} disabled={!contract.principal_product} onClick={() => setHasSecProd(true)}>{t('contract.add_secondary_product')}</Button>
                </Grid>
            }
            {
                has_sec_prod &&
                <Grid item xs={12} md={6} lg={3.5}>
                    <GenericField onChange={changeSecondaryProduct} field={"secondary_product"} object={contract} label={t('contract.secondary_product')} type="autocomplete"
                        autocompleteGetter={(c, search) =>
                            getProducts(c, {
                                search,
                                contract: "True",
                                type: "SEC",
                                principal_product__id: contract?.principal_product?.id || contract?.principal_product,
                                minimal: "True",
                            })} />
                    <Button startIcon={<Remove />} onClick={() => {
                        changeSecondaryProduct('secondary_product', null);
                        setHasSecProd(false);
                    }}>{t('contract.remove_secondary_product')}</Button>
                </Grid>
            }
            {
                !has_ext &&
                <Grid item xs={12} md={6} lg={3.5}>
                    <Button startIcon={<Add />} disabled={!contract.principal_product} onClick={() => setHasExt(true)}>{t('contract.add_extension')}</Button>
                </Grid>
            }
            {
                has_ext &&
                <Grid item xs={12} md={6} lg={3.5}>
                    <GenericField onChange={changeExtensions} field={"product_extension"} object={contract} label={t('contract.extension')} type="autocomplete"
                        autocompleteGetter={(c, search) =>
                            getProducts(c, {
                                search,
                                contract: "True",
                                type: "EXT",
                                principal_product__id: contract?.principal_product?.id || contract?.principal_product,
                            })} multiple />
                    <Button startIcon={<Remove />} onClick={() => {
                        changeExtensions("product_extension", []);
                        setHasExt(false);
                    }}>{t('contract.remove_extension')}</Button>
                </Grid>
            }
            <Grid item md={0} lg={5}></Grid>
            <Grid item xs={12}>
                <hr />
            </Grid>
        </>
    );
};

export default ContractFormDescription;