// Dependencies
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import React, { FC, ReactElement, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
// Core & Lab
import { Button, Grid, Paper, Typography, useMediaQuery, useTheme } from '@mui/material';
import { RootState } from '../../store/store';
import { Sinister } from '../../types/contract/sinister';
import { initSinister } from '../../functions/contract';
import { getSinisterById } from '../../services/sinisterServices';
import { setSelectedSinister } from '../../store/reducer';
import { ArrowBackIosNew } from '@mui/icons-material';
import GenericSubmenu from '../common/genericList/genericSubmenu';
import SinisterExpectedDocuments from './sinisterExpectedDocuments';
import SinisterInfo from './sinisterInfo';
import SinisterContract from './sinisterContract';
import Loading from '../common/loading/loading';
// Icons

const SinisterEdit: FC = (): ReactElement => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const params = useParams();
    const navigate = useNavigate();
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down(600));
    const isTablet = useMediaQuery(theme.breakpoints.down(800));

    const client_id = useSelector((state: RootState) => state.app.client_id);
    const selected_sinister_init = useSelector((state: RootState) => state.app.selected_sinister);
    const [selected_sinister, setSelectedSinisterTemp] = useState<Sinister | null>(null);
    const [menuItems, setMenuItems] = useState<any[]>([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true);
        getSinisterById(client_id, params.id).then((response) => {
            setLoading(false);
            dispatch(setSelectedSinister(response.data));
            setSelectedSinisterTemp(response.data);
        }).catch((error) => {
            console.log('error : ', error);
        });
    }, []);

    const [selected_menu, setSelectedMenu] = useState(1);

    useEffect(() => {
        setMenuItems([]);
        const menuItems = [
            { id: 1, label: t('sinisters.description') },
            { id: 2, label: t('sinisters.contract') },
            { id: 3, label: t('sinisters.documents') }
        ];
        setMenuItems(menuItems);
    }, []);

    if (selected_sinister) {
        return (
            <>
                <Grid container spacing={2}>
                    <Grid item xs={isSmallScreen ? 12 : 2}>
                        <Button startIcon={<ArrowBackIosNew />} onClick={() => navigate('/insurance/sinisters')}>{t('shared.back')}</Button>
                    </Grid>
                    <Grid item xs={isSmallScreen ? 12 : 9}>
                        <Typography variant="h4" className="title" style={{ fontWeight: "bold", textAlign: "center" }}>{t('sinisters.number')}{selected_sinister.id}</Typography>
                    </Grid>
                </Grid>
                <Grid container spacing={2} alignItems={'flex-start'}>
                    <Grid id={'circuit-category-edit'} container item xs={9}>
                        <Paper variant='outlined' square style={{ padding: 16, width: "100%" }}>
                            {
                                selected_menu === 1 &&
                                <SinisterInfo />
                            }
                            {
                                selected_menu === 2 &&
                                <SinisterContract />
                            }
                            {
                                selected_menu === 3 &&
                                <SinisterExpectedDocuments />
                            }
                        </Paper>
                    </Grid>
                    <Grid item xs={3}>
                        <Paper variant='outlined' square style={{ padding: 16, width: "100%" }}>
                            <GenericSubmenu items={menuItems} onClickItem={setSelectedMenu} />
                        </Paper>
                    </Grid>
                </Grid>
                {loading && <Loading />}
            </>
        );
    }
    return <Loading />;
};

export default SinisterEdit;