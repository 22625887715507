// Dependencies
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import React, { FC, ReactElement, useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
// Core & Lab
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Dialog from '@mui/material/Dialog';
// Icons
import Close from '@mui/icons-material/Close';
import { RootState } from '../../store/store';
import { Sinister } from '../../types/contract/sinister';
import { getProviders } from '../../services/commonServices';
import { cleanSinister } from '../../functions/contract';
import { postSinister, postSinisterWarranty } from '../../services/sinisterServices';
import { useNavigate } from 'react-router-dom';
import SinistersForm from './sinisterForm';
import { DialogTitle } from '@mui/material';
import Loading from '../common/loading/loading';

const SinisterCreateForm: FC<{ open: boolean, onClose: () => void }> = ({ open, onClose }): ReactElement => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();

    const selected_sinister = useSelector((state: RootState) => state.app.selected_sinister);
    const client_id = useSelector((state: RootState) => state.app.client_id);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (selected_sinister) {
            //todo
        }
    }, [selected_sinister]);

    const onCreate = (modified_sinister: Sinister) => {
        setLoading(true);
        const sinisterToAdd: any = cleanSinister(modified_sinister);
        delete sinisterToAdd.id;
        sinisterToAdd.contracts_id = sinisterToAdd.policy;
        sinisterToAdd.warranty = sinisterToAdd.warranty;
        postSinister(client_id, sinisterToAdd)
            .then((respo) => {
                setLoading(false);
                enqueueSnackbar(t('insurance.product-sinister-created'), { variant: 'success' });
                navigate('/insurance/sinisters/' + respo.data?.id)
            }).catch(error => {
                setLoading(false);
                if (error.response?.data?.errors) {
                    for (const [key, value] of Object.entries(error.response.data.errors)) {
                        if (Array.isArray(value)) {
                            value.map(error => enqueueSnackbar(`${error}`, { variant: 'error' }));
                        } else {
                            enqueueSnackbar(`${value}`, { variant: 'error' });
                        }
                    }
                } else if (error.response) {
                    if (error.response.status === 400) {
                        for (const [key, value] of Object.entries(error.response.data)) {
                            if (Array.isArray(value)) {
                                value.map(error => enqueueSnackbar(`${key} : ${error}`, { variant: 'error' }));
                            } else {
                                enqueueSnackbar(`${key} : ${value}`, { variant: 'error' });
                            }
                        }
                    } else if (error.response.status === 401 || error.response.status === 403) {
                        enqueueSnackbar(t('errors.permissions'), { variant: 'error' });
                    } else if (error.response.status === 500) {
                        enqueueSnackbar(t('errors.servers'), { variant: 'error' });
                    }
                } else if (error.request) {
                    console.log('error.request : ', error.request);
                } else {
                    console.log('Error', error.message);
                }
                console.log('error.config : ', error.config);
            });
    };

    return (
        <>
            <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
                <DialogTitle style={{ display: "flex", justifyContent: "space-between" }}>
                    <Typography variant={'h5'}>{t('insurance.add-sinister')}</Typography>
                    <IconButton edge={'start'} color={'inherit'} onClick={onClose}><Close /></IconButton>
                </DialogTitle>
                <SinistersForm onConfirm={onCreate} onCancel={onClose} onConfirmLabel={t('insurance.add-sinister')} />
            </Dialog >
            {loading && <Loading />}
        </>
    );

};

export default SinisterCreateForm;