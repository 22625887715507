// Dependencies
import { Provider } from 'react-redux';
import React, { Suspense } from 'react';
import { SnackbarProvider, useSnackbar } from 'notistack';
import { createRoot } from 'react-dom/client';
import { I18nextProvider } from 'react-i18next';
import { BrowserRouter } from 'react-router-dom';
import CssBaseline from '@mui/material/CssBaseline';
import { store } from './store/store';
// Fonctions
import './index.css';
import i18n from './translation/i18n';
// Components
import App from './components/app';
import Loading from './components/common/loading/loading';
import { IconButton, ThemeProvider } from '@mui/material';
import theme from './styles/theme';
import { Close } from '@mui/icons-material';

const container = document.getElementById('app');
const root = createRoot(container!);

const dismissAction = (id:any) => {
    const { closeSnackbar } = useSnackbar();
    return (
        <IconButton onClick={() => closeSnackbar(id)}>
            <Close style={{color: 'white'}}/>
        </IconButton>
    );
};

root.render(
	<Provider store={store}>
		<I18nextProvider i18n={i18n}>
			<SnackbarProvider
				anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
				action={(snackbarId) => dismissAction(snackbarId)}
			>
				<CssBaseline />
				<Suspense fallback={<Loading />}>
					<BrowserRouter basename={WP_BASE_HREF}>
						<ThemeProvider theme={theme}>
							<App />
						</ThemeProvider>
					</BrowserRouter>
				</Suspense>
			</SnackbarProvider>
		</I18nextProvider>
	</Provider>
);