import { ArrowDownward, DoNotDisturbOnTotalSilenceOutlined, Search } from "@mui/icons-material";
import { Chip, Grid, IconButton, InputAdornment, TextField, Tooltip } from "@mui/material";
import { GridCheckCircleIcon } from "@mui/x-data-grid";
import i18next from "i18next";
import React, { FC, ReactElement, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import checkPermissions from "../../functions/checkPermissions";
import { getFamilies, getProductsExtended } from "../../services/productServices";
import { setPriceIndex, setProductList, setSelectedProduct } from "../../store/reducer";
import { RootState } from "../../store/store";
import { FilterData } from "../../types/genericModule/filterData";
import GenericList from "../common/genericList/genericList";
import Finished from "../common/loading/finished";
import { ProductBrand } from "../../types/insurance/insurance-product";
import { getContractTypes } from "../../services/contractServices";

const ProductsPage: FC = (): ReactElement => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const selected_product = useSelector((state: RootState) => state.app.selected_product);
    const product_list = useSelector((state: RootState) => state.app.product_list);
    const price_index = useSelector((state: RootState) => state.app.price_index);
    const client_id = useSelector((state: RootState) => state.app.client_id);
    const logged_user_client_id = useSelector((state: RootState) => state.app.logged_user_client_id);
    const permissions =  useSelector((state: RootState) => state.app.permissions);

    const [reloader, setRealoader] = useState(0);
    const [search, setSearch] = useState("");

    const columnsInit: any = [
        { order: 3, field: 'internal_reference', label: t("products.internal_reference"), sortable: true, active: true, ordering_asc: null },
        { order: 4, field: 'name', label: t("products.name"), width: 180, sortable: true, active: true, ordering_asc: null },
        {
            order: 5, field: 'product_family', label: t("products.product_family"), sortable: true, active: true, ordering_asc: null,
            displayValue: (value: any) => value?.name, nested_field: "product_family__name"
        },
        {
            order: 6, field: 'type', label: t("products.type"), active: true, ordering_asc: null, sortable: true,
            displayValue: (value: string) => {
                if (!value) return '';
                return (<Chip className="product-chip" style={{ backgroundColor: getTypeColor(value) }} label={t(`products.type_${value.toLowerCase()}`)} />);
            }
        },
        {
            order: 7, field: 'brand', label: t("products.brand"), active: true, ordering_asc: null,
            displayValue: (value: ProductBrand) => {
                if (!value?.name) return '';
                return (<Chip className="product-chip" style={{ backgroundColor: getBrandColor(value) }} label={value?.name} />);
            }
        },
        {
            order: 7, field: 'prices', label: t("products.real_price"), active: true, ordering_asc: null,
            displayValue: (prices: any[], row: any) => {
                const price_obj = prices?.find(p => p.min_slice == row?.prices?.at(row.type === "EXT" ? 0 : price_index)?.min_slice && p.max_slice == row?.prices?.at(row.type === "EXT" ? 0 : price_index)?.max_slice);
                let price = price_obj?.selling_price;
                if (!price) price = row?.prices?.at(price_index)?.selling_price;
                return displayPrice(price, price_obj?.price_type);
            }
        },
        {
            order: 8, field: 'prices', label: t("products.suggested_price"), active: true, ordering_asc: null,
            displayValue: (prices: any[], row: any) => {
                const price_obj = prices?.find(p => p.min_slice == row?.prices?.at(row.type === "EXT" ? 0 : price_index)?.min_slice && p.max_slice == row?.prices?.at(row.type === "EXT" ? 0 : price_index)?.max_slice);
                let price = price_obj?.recommended_price;
                if (!price) price = row?.prices?.at(price_index)?.recommended_price;
                return displayPrice(price, price_obj?.price_type);
            }
        },
        {
            // order: 9, field: 'agencies_prices', label: '', active: true, ordering_asc: null,
            // displayValue: (agencies_prices: any[], row: any) => {
            //     const newIndex = row?.prices?.at(price_index + 1) ? price_index + 1 : 0;
            //     if (!row?.prices?.length) return '';
            //     return <IconButton size='small' onClick={(e) => changePriceIndex(e, newIndex)}><ArrowDownward /> </IconButton>;
            // }
        },
    ];

    const [columns, setColumns] = useState(columnsInit);
    useEffect(() => {
        if (permissions !== null &&!checkPermissions('view_insurance')) {
            localStorage.removeItem('token');
            localStorage.removeItem('user_id');
            localStorage.removeItem('logged_user_client_id');
            navigate('/login');
        }
        dispatch(setSelectedProduct(null));
    }, []);
    useEffect(() => {
        if (permissions !== null && !checkPermissions('view_insurance')) {
            localStorage.removeItem('token');
            localStorage.removeItem('user_id');
            localStorage.removeItem('logged_user_client_id');
            navigate('/login');
        }
    }, [permissions]);

    useEffect(() => {
        setColumns(columnsInit);
        setRealoader(reloader + 1);
    }, [price_index]);

    const displayPrice = (price: any, type: string) => {
        if (type === "FIX") return price ? new Intl.NumberFormat(i18next.language, { style: 'currency', currency: 'EUR' }).format(price) : '';
        else if (type === "PER") return price ? price + '%' : '';
        return '';
    };
    
    const changePriceIndex = (e: any, index: number) => {
        e.stopPropagation();
        dispatch(setPriceIndex(index));
    };

    const getTypeColor = (type: string) => {
        switch (type) {
            case "PRI":
                return "#00457c";
            case "SEC":
                return "#5cabb8";
            case "EXT":
                return "#38a9d3";
            default:
                break;
        }
    };

    const getBrandColor = (brand: ProductBrand) => {
        switch (brand.name) {
            case "LIZA":
                return "#70c6c3";
            case "MICE":
                return "#bbc100";
            default:
                return "#344482";
        }
    };

    const onRowClick = (item: any) => {
        dispatch(setSelectedProduct(item));
        navigate(`/insurance/product/${item.id}`);
    };

    /** FILTERS */
    const filtersData: FilterData[] = [
        {
            label: t("products.product_family"), field: "product_family__id", type: "autocomplete",
            autocompleteGetter: getFamilies
        },
        {
            label: t("products.subscription_method"), field: "subscription_method__id", type: "autocomplete",
            autocompleteGetter: (c, s) => getContractTypes(c)
        },
        {
            label: t("products.brand"), field: "brand__name"
        },
        { label: t("list.created_by"), field: "creator__username" },
        { label: t("list.modified_by"), field: "last_author__username" },
        { type: "period", label: t("list.created_on"), field: "created_date" },
        { type: "period", label: t("list.modified_on"), field: "modified_date" },
    ];

    return (
        <>
            <Grid item xs={12} style={{ display: "flex", justifyContent: "center" }}>
                <TextField label={t('products.search')}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start"> <Search /> </InputAdornment>
                        ),
                        sx: { borderRadius: '35px'}
                    }}
                    variant="outlined"
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                    sx={{width: '50%'}}
                />
            </Grid>
            <GenericList
                prefModuleName="VA_ORGANISATION_INSURANCE"
                reloader={reloader}
                cols={columns}
                search={search}
                dataLoader={getProductsExtended}
                addPermission='add_insuranceproduct'
                onRowClick={onRowClick}
                selected_row={selected_product}
                cachedList={product_list}
                setCachedList={(list) => dispatch(setProductList(list))}
                filtersData={filtersData}
                labels={{
                    title: t('products.title'),
                    add_button: ""
                }}
            />
            <Finished />
        </>
    );
};

export default ProductsPage;