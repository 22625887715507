/* eslint-disable react/display-name */
import { AddCircle, ArrowDropDown, ArrowRight, Circle, Close, FlightTakeoff, Place, Search } from "@mui/icons-material";
import { Button, Chip, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, IconButton, InputAdornment, TextField, Tooltip, useMediaQuery, useTheme } from "@mui/material";
import { format } from "date-fns";
import React, { FC, ReactElement, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getAnomalies, getContractTypes, getContracts } from "../../services/contractServices";
import { setContractList, setContractTypes, setProductList, setSelectedContract } from "../../store/reducer";
import { FilterData } from "../../types/genericModule/filterData";
import { GenericColumn } from "../../types/genericModule/genericColumn";
import GenericList from "../common/genericList/genericList";
import { RootState } from "../../store/store";
import { OrganisationMinimal } from "../../types/organisationMinimal";
import { InsuranceProduct, ProductBrand } from "../../types/insurance/insurance-product";

const DefectsList: FC = (): ReactElement => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down(768));

    const selected_contract = useSelector((state: RootState) => state.app.selected_contract);
    const contract_types = useSelector((state: RootState) => state.app.contract_types);
    const contract_list = useSelector((state: RootState) => state.app.contract_list);
    const client_id = useSelector((state: RootState) => state.app.client_id);
    const profile = useSelector((state: RootState) => state.app.profile);

    const [detailId, setDetailId] = useState(-1);
    const [reloader, setRealoader] = useState(0);
    const [chooseCreateMode, setChooseCreateMode] = useState(false);
    const [search, setSearch] = useState("");

    const agency_column = {
        order: 1, field: 'subscribing_agency', label: t('contract.agency'), active: true, ordering_asc: null,
        displayValue: (value: OrganisationMinimal) => value?.name, nested_field: "subscribing_agency__name"
    };
    const columnsInit: GenericColumn[] = [
        {
            order: 1, field: 'contracts_status', label: t('contract.status'), active: true, ordering_asc: null, sortable: true,
            displayValue: (value: string) => (
                <Tooltip title={t('contract.status_' + (value || 'EMPTY_OR_NOT_APPLICABLE'))}>
                    <Circle style={{ fill: getStatusColor(value) }} />
                </Tooltip>
            ),
            displayExportValue: (value: string) => t('contract.status_' + (value || 'EMPTY_OR_NOT_APPLICABLE')),
        },
        {
            order: 1, field: 'contracts_type', label: t('contract.subscription_method'), active: true, ordering_asc: null, sortable: true,
            displayValue: (value: any) => value?.name, nested_field: "contracts_type__name"
        },
        {
            order: 1, field: 'reference', label: t('contract.reference'), active: true, ordering_asc: null, sortable: true
        },
        {
            order: 1, field: 'subscriber', label: t('contract.subscriber'), active: true, ordering_asc: null, sortable: true, nested_field: "subscriber__first_name",
            displayValue: (value: any) => {
                if (!value) return <></>;
                return (
                    <>
                        <p style={{ margin: 0 }}>{value.title} {value.first_name} {value.last_name}</p>
                        <p style={{ margin: 0 }}>{value.address} {value.city}</p>
                    </>
                );
            },
            displayExportValue: (value: any, row: any) => (!value && row?.group_name) ? row.group_name : `${value?.first_name || ''} ${value?.last_name || ''}`,
        },
        {
            order: 1, field: 'principal_product', label: t('contract.principal_product'), active: true, ordering_asc: null, sortable: true,
            displayValue: (value: any) => value?.name, nested_field: "principal_product__name"
        },
        {
            order: 1, field: 'principal_product.warranty', label: t('warranty.title'), active: true, ordering_asc: null, sortable: true,
            displayValue: (value: any, row: any) => row.principal_product?.warranty?.map(w => w.name)?.join(', '), nested_field: "principal_product__warranty__name"
        },
        {
            order: 1, field: 'destinations', label: t('contract.destinations'), active: true, ordering_asc: null, sortable: true,
            displayValue: (value: any) => <p style={{ margin: 0, display: "flex", alignItems: "center", gap: 5 }}>{isSmallScreen ? <></> : <Place />}{value?.map(v => v.name || v.international_name).join(' - ')}</p>,
            displayExportValue: (value: any) => value?.map(v => v.name || v.international_name)?.join(', ') || '', nested_field: "destinations__name"
        },
        {
            order: 1, field: 'travel_start_date', label: t('contract.departure_date'), active: true, ordering_asc: null, sortable: true,
            displayValue: (value: any) =>
                <p style={{ margin: 0, display: "flex", alignItems: "center", gap: 5 }}> {isSmallScreen ? <></> : <FlightTakeoff />}{value ? format(new Date(value), 'dd/MM/yyy') : ''}</p>,
            displayExportValue: (value: any) => `${value ? format(new Date(value), 'dd/MM/yyy') : ''}`
        },
        {
            order: 1, field: 'principal_product.brand', label: t("products.brand"), sortable: true, active: true, ordering_asc: null,
            displayValue: (value: InsuranceProduct, row: any) => {
                if (!row?.principal_product?.brand?.name) return '';
                return (<Chip className="brand-chip" style={{ backgroundColor: getBrandColor(row?.principal_product?.brand) }} label={row?.principal_product?.brand?.name} />);
            }, nested_field: "principal_product__brand__name"
        },
        {
            order: 1, field: 'creator', label: t("list.created_by"), sortable: true, active: false, ordering_asc: null, nested_field: "creator__username",
            displayValue: (value: any) => value?.username
        },
        {
            order: 1, field: 'created_date', label: t("list.created_on"), sortable: true, active: false, ordering_asc: null,
            displayValue: (value: any) => value ? format(new Date(value), 'dd/MM/yyy') : ''
        },
        {
            order: 1, field: 'last_author', label: t("list.modified_by"), sortable: true, active: false, ordering_asc: null, nested_field: "last_author__username",
            displayValue: (value: any) => value?.username
        },
        {
            order: 1, field: 'modified_date', label: t("list.modified_on"), sortable: true, active: false, ordering_asc: null,
            displayValue: (value: any) => value ? format(new Date(value), 'dd/MM/yyy') : ''
        },
        {
            order: 1, field: 'full', label: "", active: true, ordering_asc: null, fullWidth: true,
            displayValue: (value: number, row: any) =>
                <>
                    {
                        detailId === row.id &&
                        <div>
                            <ul>{row.principal_product?.name}</ul>
                            <ul>{row.secondary_product?.name}</ul>
                            <ul>{row.product_extension?.map(e => e.name)?.join(', ')}</ul>
                        </div>
                    }
                </>
        },
    ];
    const [columns, setColumns] = useState(columnsInit);

    const filtersData: FilterData[] = [
        {
            label: t("contract.reference"), field: "reference"
        },
        {
            label: t("products.subscription_method"), field: "contracts_type__name"
        },
        {
            label: t("products.brand"), field: "principal_product__brand__name"
        },
        {
            type: "select", label: t("contract.status"), field: "contracts_status", options: [
                { value: "EMPTY_OR_NOT_APPLICABLE", label: 'Vide / Non applicable' },
                { value: "IN_PROGRESS", label: 'En cours' },
                { value: "SUSPENDED", label: 'Suspendu' },
                { value: "CANCELLED", label: 'Résilié' },
            ]
        },
        { type: "period", label: t("contract.start_date"), field: "travel_start_date" },
        { label: t("list.created_by"), field: "creator__username" },
        { label: t("list.modified_by"), field: "last_author__username" },
        { type: "period", label: t("list.created_on"), field: "created_date" },
        { type: "period", label: t("list.modified_on"), field: "modified_date" },
    ];

    const getStatusColor = (status: string) => {
        switch (status) {
            case "EMPTY_OR_NOT_APPLICABLE":
                return '#F6A200';
            case "IN_PROGRESS":
                return '#9ff200';
            case "SUSPENDED":
                return '#EC977A';
            case "CANCELLED":
                return '#828282';
            default:
                return '#F6A200';
        }
    };

    const getBrandColor = (brand: ProductBrand) => {
        switch (brand?.name) {
            case "LIZA":
                return "#70c6c3";
            case "MICE":
                return "#bbc100";
            default:
                return "#344482";
        }
    };

    useEffect(() => {
        if (profile?.is_superuser || profile?.client_full?.name === "Valeurs Assurances") {
            const ag_idx = columnsInit.findIndex(column => column.field === "subscribing_agency");
            if (ag_idx === -1) columnsInit.splice(2, 0, agency_column);
        }
        else setColumns(columnsInit);
        setRealoader(reloader + 1);
    }, [detailId]);

    useEffect(() => {
        if (profile?.is_superuser || profile?.client_full?.name === "Valeurs Assurances") {
            const ag_idx = columnsInit.findIndex(column => column.field === "subscribing_agency");
            if (ag_idx === -1) columnsInit.splice(2, 0, agency_column);
            setColumns(columnsInit);
        }
    }, [profile]);
    
    const viewDetail = (row: any) => {
        dispatch(setSelectedContract(row));
        navigate('/insurance/contracts/' + row.id);
    };

    useEffect(() => {
        if (contract_types?.length) {
            console.log('types loaded');
        } else {
            getContractTypes(client_id)
                .then((response) => {
                    dispatch(setContractTypes(response.data.results));
                });
        }
    }, [contract_types]);

    // if (!contract_types?.length) return <></>;

    return (
        <Grid container spacing={2}>
            <Grid item xs={12} style={{ display: "flex", justifyContent: "center" }}>
                <TextField label={t('contract.search')}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start"> <Search /> </InputAdornment>
                        ),
                    }}
                    variant="outlined"
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                />
            </Grid>
            <Grid item xs={12}>
                <GenericList
                    prefModuleName="VA_CONTRACTS"
                    cols={columns}
                    search={search}
                    selected_row={selected_contract}
                    cachedList={contract_list}
                    setCachedList={(list) => dispatch(setContractList(list))}
                    dataLoader={(client_id, limit, offset, filter) => getAnomalies(client_id, limit, offset, {...filter, is_free: true, has_sinister: true})}
                    reloader={reloader}
                    onRowClick={viewDetail}
                    // filtersData={filtersData}
                    labels={{
                        title: t('contract.defects'),
                        add_button: ""
                    }}
                />
            </Grid>
        </Grid>
    );
};

export default DefectsList;