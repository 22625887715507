import axios, { AxiosResponse, CancelTokenSource } from "axios";
import { IataCountry } from "../types/common/iataCountry";
import { CustomProvider } from "../types/customProvider";
import { Destination } from "../types/destinations/destination";
import { store } from '../store/store';
import { Traveler } from "../types/users/traveler";
import { Provider } from "../types/provider";
import { OrganisationMinimal } from "../types/organisationMinimal";

let cancelToken: CancelTokenSource | null = null;
let previousRequestUrl: string | null = null;

export const getCountries = (client_id: number | null, search?: string): Promise<AxiosResponse<{ count: number; results: IataCountry[]; }>> => {
    const requestUrl = `${API_HREF}iata-countries/`;
    if (search && cancelToken && previousRequestUrl === requestUrl) {
        cancelToken.cancel("Operation canceled due to new request.");
    }
    previousRequestUrl = requestUrl;
    cancelToken = axios.CancelToken.source();
    return axios.get(
        requestUrl,
        {
            params: {
                search,
                limit: 1000
            },
            cancelToken: cancelToken.token,
            headers: {
                'Authorization': `Token ${localStorage.getItem('token')}`,
                'accept-language': 'fr'
            }
        }
    );
};

export const getCustomProviders = (client_id: number | null, search?: string): Promise<AxiosResponse<{ count: number; results: CustomProvider[]; }>> => {
    const requestUrl = `${API_HREF}client/${client_id}/custom-providers/`;
    if (search && cancelToken && previousRequestUrl === requestUrl) {
        cancelToken.cancel("Operation canceled due to new request.");
    }
    previousRequestUrl = requestUrl;
    cancelToken = axios.CancelToken.source();
    return axios.get(
        requestUrl,
        {
            params: {
                search,
                available_content_types: 8
            },
            cancelToken: cancelToken.token,
            headers: {
                'Authorization': `Token ${localStorage.getItem('token')}`,
            }
        }
    );
};

export const getOrganisations = (client_id: number | null, search?: string): Promise<AxiosResponse<{ count: number; results: OrganisationMinimal[]; }>> => {
    const requestUrl = `${API_HREF}client/${client_id}/children/`;
    if (search && cancelToken && previousRequestUrl === requestUrl) {
        cancelToken.cancel("Operation canceled due to new request.");
    }
    previousRequestUrl = requestUrl;
    cancelToken = axios.CancelToken.source();
    return axios.get(
        requestUrl,
        {
            params: {
                search,
                type: "1,2"
            },
            cancelToken: cancelToken.token,
            headers: {
                'Authorization': `Token ${localStorage.getItem('token')}`,
            }
        }
    );
};

export const getDestinations = (client_id: number | null, search?: string): Promise<AxiosResponse<{ count: number; results: Destination[]; }>> => {
    const requestUrl = `${API_HREF}client/${client_id}/destinations/`;
    if (search && cancelToken && previousRequestUrl === requestUrl) {
        cancelToken.cancel("Operation canceled due to new request.");
    }
    previousRequestUrl = requestUrl;
    cancelToken = axios.CancelToken.source();
    return axios.get(
        requestUrl,
        {
            params: {
                search,
            },
            cancelToken: cancelToken.token,
            headers: {
                'Authorization': `Token ${localStorage.getItem('token')}`,
            }
        }
    );
};

export const postLogin = (client_id: number | null, email: string, password: string): Promise<AxiosResponse<any>> => {
    const requestUrl = `${API_HREF}client/${client_id}/auth/`;
    return axios.post(
        requestUrl,
        {
            email, password
        }
    );
};

export const sendMailForgetPassword = (client_id: number | null, email: string): Promise<AxiosResponse<any>> => {
    const requestUrl = `${API_HREF}client/${client_id}/user/reset_password/`;
    return axios.post(
        requestUrl,
        {
            email
        }
    );
};

export const getPermissions = (client_id: number | null, user_id: number): Promise<AxiosResponse<any>> => {
    const requestUrl = `${API_HREF}client/${store.getState().app.logged_user_client_id || client_id}/user/${user_id}/permissions/?all=true`;
    return axios.get(
        requestUrl,
        {
            headers: {
                'Authorization': `Token ${localStorage.getItem('token')}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Accept-Language': 'fr'
            }
        }
    );
};

export const getUser = (client_id: number | null, user_id: number): Promise<AxiosResponse<any>> => {
    const requestUrl = `${API_HREF}client/${store.getState().app.logged_user_client_id || client_id}/user/${user_id}/`;
    return axios.get(
        requestUrl,
        {
            headers: {
                'Authorization': `Token ${localStorage.getItem('token')}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Accept-Language': 'fr'
            }
        }
    );
};

export const dowloadDocument = (client_id: number | null, file_name: string): Promise<AxiosResponse<Blob>> => {
    const requestUrl = `${API_HREF}client/${client_id}/value-insurances/download_document/`;
    if (cancelToken && previousRequestUrl === requestUrl) {
        cancelToken.cancel("Operation canceled due to new request.");
    }
    previousRequestUrl = requestUrl;
    cancelToken = axios.CancelToken.source();
    return axios.post(
        requestUrl,
        {
            file_name: file_name ? file_name : 'null'
        },
        {
            responseType: 'blob',
            params: {},
            cancelToken: cancelToken.token,
            headers: {
                'Authorization': `Token ${localStorage.getItem('token')}`,
            }
        }
    );
};

export const getTravelers = (client_id: number | null, search?: string): Promise<AxiosResponse<{ count: number; results: Traveler[]; }>> => {
    const requestUrl = `${API_HREF}client/${client_id}/travelers/`;
    if (search && cancelToken && previousRequestUrl === requestUrl) {
        cancelToken.cancel("Operation canceled due to new request.");
    }
    previousRequestUrl = requestUrl;
    cancelToken = axios.CancelToken.source();
    return axios.get(
        requestUrl,
        {
            params: {
                search,
                limit: 250
            },
            cancelToken: cancelToken.token,
            headers: {
                'Authorization': `Token ${localStorage.getItem('token')}`,
            }
        }
    );
};

export const getProviders = (): Promise<AxiosResponse<{ count: number; results: Provider[]; }>> => {
    const requestUrl = `${API_HREF}providers/`;
    return axios.get(
        requestUrl,
        {
            params: {
                limit: 2000
            },
            headers: {
                'Authorization': `Token ${localStorage.getItem('token')}`,
            }
        }
    );
};

export const resetPassword = (client_id: number | null, new_password: string, confirm_new_password: string, token: string|null, user_id: number|null): Promise<AxiosResponse<any>> => {
    const requestUrl = `${API_HREF}client/${client_id}/user/${user_id}/edit_password/`;
    let data = {
        user_id: user_id,
        password: new_password,
        confirm_password: confirm_new_password,
        token: token
    };
    return axios.post(
        requestUrl,
        data
    );
};