import React, { useEffect, useState, useRef, Fragment, FC, ReactElement } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import axios from "axios";
import { useTheme } from "@emotion/react";
import { useMediaQuery, Dialog, DialogTitle, Grid, IconButton, DialogContent, Tooltip, LinearProgress, Typography, DialogActions, Button } from "@mui/material";
import { Box } from "@mui/system";
import { AddAPhoto, Cached, Check, Close, NavigateBefore, PhotoLibrary, ZoomIn, ZoomOut } from "@mui/icons-material";
import Cropper, { ReactCropperElement } from "react-cropper";
import Webcam from "react-webcam";
import "cropperjs/dist/cropper.css";

const ChangePicture: FC<{ openModal, setOpenModal, setAvatar, setAvatarId, avatar, user }> = ({ openModal, setOpenModal, setAvatar, setAvatarId, avatar, user }): ReactElement => {
    const { t } = useTranslation();
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const { enqueueSnackbar } = useSnackbar();
    const dispatch = useDispatch();

    const [step, setStep] = useState(1);
    const [tmpAvatar, setTmpAvatar] = useState(null);
    const [file, setFile] = useState(null);
    const [progress, setProgress] = useState(0);
    const [loading, setLoading] = useState(false);
    const cropperRef = useRef<ReactCropperElement>(null);
    const camRef = useRef<any>(null);

    const videoConstraints = {
        width: 340,
        height: fullScreen ? 272 : 335,
        facingMode: "user"
    };

    useEffect(() => {
        if (avatar !== null) {
            resetAvatar();
        }
    }, [avatar, openModal])

    const handleClose = () => {
        resetAvatar();
        setOpenModal(false);
        setStep(1);
    }
    const changeImgHandler = (img) => {
        let reader = new FileReader();
        let file = img;
        reader.readAsDataURL(file);
        reader.onloadend = () => {
            setTmpAvatar(reader.result);
            setFile(file);
        };
    }
    const handleValidate = () => {
        setLoading(true);
        savePicture();
    }
    const savePicture = () => {
        cropperRef.current?.getCroppedCanvas().toBlob(function (blob) {
            let request = new FormData();
            request.append("name", "Thumbnails");
            request.append("description", "This is for testing thumbnails.");
            request.append('image', blob);
            axios({
                method: "POST",
                headers: {
                    'Authorization': `Token ${localStorage.getItem('token')}`,
                    'Content-Type': 'multipart/form-data'
                },
                url: `${API_HREF}client/${user.client}/pictures/?type=avatar`,
                data: request,
                onUploadProgress: function (progressEvent) {
                    // Do whatever you want with the native progress event
                    setProgress(progressEvent.loaded / progressEvent.total * 100)
                },
            }).then(function (response) {
                let new_avatar = {
                    description: response.data.description,
                    id: response.data.id,
                    name: response.data.name,
                    thumbnail_little: response.data.thumbnail_little,
                    url: response.data.url
                };
                let request1 = {
                    avatar_id: new_avatar.id
                };
                if (file) {
                    const reader = new FileReader();
                    reader.onload = function (e: any) {
                        const imagePreview: any = document.getElementById('imagePreview');
                        if (imagePreview) imagePreview.src = e.target.result;
                    };
                    reader.readAsDataURL(file);
                }
                axios({
                    method: "PATCH",
                    headers: {
                        'Authorization': `Token ${localStorage.getItem('token')}`,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Accept-Language': 'fr'
                    },
                    url: `${API_HREF}client/${user.client}/user/${user.id}/`,
                    data: JSON.stringify(request1),
                }).then(function (response) {
                    let data = Object.assign({}, response.data);
                    dispatch({
                        type: "USER_SET_USER",
                        payload: {
                            user: data
                        }
                    });
                    setAvatarId(new_avatar.id);
                    setAvatar(new_avatar);
                    setLoading(false);
                    setProgress(0);
                    handleClose();
                }).catch(function (error) {
                    //TODO: show snackbar error
                    console.log('error:', error);
                    enqueueSnackbar(t("notify.changed_failed"), {
                        variant: "error",
                    });
                });
            }).catch(function (error) {
                setLoading(false);
                setProgress(0);
                console.log('error:', error);
            });
        });
    }
    const resetAvatar = () => {
        setTmpAvatar(avatar !== null ? (avatar.thumbnail_little !== null ? avatar.thumbnail_little : avatar.url) : "Img/avatar.png");
    }
    const takePicture = () => {
        setTmpAvatar(camRef.current?.getScreenshot())
        setStep(1);
    }
    return (
        <Dialog
            fullScreen={fullScreen}
            open={openModal}
            onClose={handleClose}
        >
            {
                step === 1 && (
                    <Fragment>
                        <DialogTitle>
                            <Grid container justify={"space-between"} alignItems={"center"}>
                                {
                                    fullScreen ? (
                                        <Grid item>
                                            <IconButton edge={"end"} onClick={handleClose}>
                                                <NavigateBefore />
                                            </IconButton>
                                            <span style={{ fontSize: 16 }}>{t("profile.explication_title")}</span>
                                        </Grid>
                                    ) : (
                                        <Fragment>
                                            <Grid item>
                                                {t("profile.explication_title")}
                                            </Grid>
                                            <Grid item>
                                                <IconButton onClick={handleClose}><Close /></IconButton>
                                            </Grid>
                                        </Fragment>
                                    )
                                }
                            </Grid>
                        </DialogTitle>
                        <DialogContent>
                            <Grid container direction={"row"}>
                                <Grid item md={11} xs={12}>
                                    <Cropper
                                        ref={cropperRef}
                                        src={tmpAvatar}
                                        style={{ height: 350, width: '100%', marginRight: 15 }}
                                        // Cropper.js options
                                        aspectRatio={1}
                                        guides={false}
                                        viewMode={1}
                                        highlight={false}
                                        cropBoxResizable={false}
                                        cropBoxMovable={false}
                                        dragMode={'move'}
                                        background={false}
                                        zoomable={true}
                                        zoomOnWheel={true}
                                        autoCropArea={0.8}
                                        toggleDragModeOnDblclick={false}
                                        checkCrossOrigin={true}
                                        preview={'.img-preview'}
                                    />
                                </Grid>
                                <Grid item md={1} xs={12}>
                                    <Grid container direction={fullScreen ? "row" : "column"} justify={"center"} alignItems={"center"}>
                                        <Grid item>
                                            <IconButton onClick={() => cropperRef.current?.zoom(0.1)}>
                                                <ZoomIn />
                                            </IconButton>
                                        </Grid>
                                        <Grid item>
                                            <IconButton onClick={() => cropperRef.current?.zoom(-0.1)}>
                                                <ZoomOut />
                                            </IconButton>
                                        </Grid>
                                        <Grid item>
                                            <Tooltip title="">
                                                <IconButton onClick={() => cropperRef.current?.reset()}>
                                                    <Cached />
                                                </IconButton>
                                            </Tooltip>
                                        </Grid>
                                        <Grid item>
                                            <Tooltip title="">
                                                <IconButton onClick={resetAvatar}>
                                                    <Close />
                                                </IconButton>
                                            </Tooltip>
                                        </Grid>
                                        <Grid item>
                                            <Tooltip title="">
                                                <IconButton style={{ backgroundColor: "#E6592F", color: "white" }} onClick={handleValidate}>
                                                    <Check />
                                                </IconButton>
                                            </Tooltip>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                {
                                    loading && (
                                        <Grid item xs={12} style={{ marginBottom: 10 }}>
                                            <Box display="flex" alignItems="center">
                                                <Box width="100%" mr={1}>
                                                    <LinearProgress variant="determinate" />
                                                </Box>
                                                <Box minWidth={35}>
                                                    <Typography variant="body2" color="textSecondary">{`${Math.round(
                                                        progress,
                                                    )}%`}</Typography>
                                                </Box>
                                            </Box>
                                        </Grid>
                                    )
                                }
                            </Grid>
                        </DialogContent>
                        <DialogActions>
                            <Grid container direction={"row"} justify={"center"} alignItems={"center"}>
                                <Grid item>
                                    <input
                                        accept="image/*"
                                        style={{ display: "none" }}
                                        id="contained-button-file"
                                        type="file"
                                        onChange={(event) => { changeImgHandler(event.target.files[0]) }}
                                    />
                                    <label htmlFor="contained-button-file" style={{ marginBottom: 0 }}>
                                        <Button startIcon={<PhotoLibrary />} component="span">{t("profile.from_img")}</Button>
                                    </label>
                                </Grid>
                                <Grid item>
                                    <Button startIcon={<AddAPhoto />} onClick={() => setStep(2)}>{t("profile.from_camera")}</Button>
                                </Grid>
                            </Grid>
                        </DialogActions>
                    </Fragment>
                )
            }
            {
                step === 2 && (
                    <Fragment>
                        <DialogTitle>
                            <Grid container justify={"space-between"} alignItems={"center"}>
                                {
                                    fullScreen ? (
                                        <Grid item>
                                            <IconButton edge={"end"} onClick={() => setStep(1)}>
                                                <NavigateBefore />
                                            </IconButton>
                                            <span style={{ fontSize: 16 }}>{t("profile.webcam")}</span>
                                        </Grid>
                                    ) : (
                                        <Fragment>
                                            <Grid item>
                                                {t("profile.webcam")}
                                            </Grid>
                                            <Grid item>
                                                <IconButton onClick={() => setStep(1)}><Close /></IconButton>
                                            </Grid>
                                        </Fragment>
                                    )
                                }
                            </Grid>
                        </DialogTitle>
                        <DialogContent>
                            <Grid container direction={"row"}>
                                <Grid item xs={12} style={{ height: 335, background: "gray" }}>
                                    <Webcam
                                        audio={false}
                                        ref={camRef}
                                        screenshotFormat="image/jpeg"
                                        height={335}
                                        width={fullScreen ? 272 : 335}
                                        videoConstraints={videoConstraints}
                                    />
                                </Grid>
                            </Grid>
                        </DialogContent>
                        <DialogActions>
                            <Grid container direction={"row"} justify={"center"} alignItems={"center"}>
                                <Grid item>
                                    <Button startIcon={<AddAPhoto />} onClick={takePicture}>{t("profile.from_camera")}</Button>
                                </Grid>
                            </Grid>
                        </DialogActions>
                    </Fragment>
                )
            }
        </Dialog>
    )
};

export default ChangePicture;