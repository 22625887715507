import axios, { AxiosResponse, CancelTokenSource } from "axios";
import { Contract } from "../types/contract/contract";
import { ContractType } from "../types/contract/contractType";
import { store } from '../store/store';

let cancelToken: CancelTokenSource | null = null;
export const getIncentive = (client_id: number | null, limit: number, offset: number, filter?: any): Promise<AxiosResponse<{ count: number; results: Contract[]; }>> => {
    if (cancelToken) {
        cancelToken.cancel("Operation canceled due to new request.");
    }
    cancelToken = axios.CancelToken.source();
    let handledFilter = {};
    if (filter) {
        handledFilter = { ...filter };
    }
    let url = '';
    if (store.getState().app.profile?.client_full?.name === "Valeurs Assurances" || store.getState().app.profile?.is_network_director) {
        url = `${API_HREF}client/${client_id}/insurances-contract/`
    } else {
        url = `${API_HREF}client/${client_id}/insurances-contract/agent_incentive/`
    }
    return axios.get(
        url,
        {
            params: {
                limit,
                offset,
                ...handledFilter,
            },
            cancelToken: cancelToken.token,
            headers: {
                'Authorization': `Token ${localStorage.getItem('token')}`,
            }
        }
    );
};
