// Dependencies
import React, { FC, ReactElement, useEffect, useState } from 'react';
import { Button } from '@mui/material';

const GenericSubmenu: FC<{ items: { id: number, label: string }[], onClickItem: (id: number) => void }> = ({ items, onClickItem }): ReactElement => {

    const [selected, setSelected] = useState(0);
    useEffect(() => {
        if (items.length) {
            setSelected(items[0].id);
        }
    }, [items]);

    const handleClick = (id: number) => {
        onClickItem(id);
        setSelected(id);
    };

    return (
        <div className={'ft-user-editActions-leftBlock'}>
            {
                items.map(item => {
                    return (
                        <div key={`submenuItem-${item.id}`} className={'ft-user-editActions-buttonContainer'}>
                            <Button className={'ft-user-editActions-button'} style={{ justifyContent: "flex-start" }}
                                onClick={() => handleClick(item.id)} disabled={selected === item.id}>{item.label}</Button>
                        </div>
                    );
                })
            }
        </div>
    );
};

export default GenericSubmenu;
