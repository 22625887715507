/* eslint-disable react/display-name */
import { CancelOutlined, Edit, EditOutlined, FileDownload, Folder, Notes, Send, Visibility } from "@mui/icons-material";
import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, Grid, Paper, Popover, Step, StepLabel, Stepper, Switch, TextareaAutosize, useMediaQuery, useTheme } from "@mui/material";
import { format } from "date-fns";
import React, { FC, ReactElement, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { getContractById, patchContract } from "../../services/contractServices";
import { setSelectedContract } from "../../store/reducer";
import { RootState } from "../../store/store";
import { Contract } from "../../types/contract/contract";
import jsPDF from "jspdf";
import ContractTemplate from "./ContractTemplate";
import Docxtemplater from 'docxtemplater';
import PizZip from 'pizzip';
import { fr } from "date-fns/locale";
import { useSnackbar } from "notistack";
import { downloadOrganisationCertificate, getOrganisationCertificates } from "../../services/certificateServices";
import axios from "axios";
import { getSinisters } from "../../services/sinisterServices";
import ContractGroupTemplate from "./ContractGroupTemplate";

const ContractDetailPanel: FC<{ contract: Contract | null, setContract: (contract: Contract) => void }> = ({ contract, setContract }): ReactElement => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();

    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down(600));
    const isTablet = useMediaQuery(theme.breakpoints.down(800));

    const client_id = useSelector((state: RootState) => state.app.client_id);
    const contract_types = useSelector((state: RootState) => state.app.contract_types);
    const locales = useSelector((state: RootState) => state.app.locales);
    const profile = useSelector((state: RootState) => state.app.profile);

    const [loading, setLoading] = React.useState(false);
    const [noteLoading, setNoteLoading] = React.useState(false);
    const [viewLoading, setViewLoading] = React.useState(false);
    const { id } = useParams();

    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const open = Boolean(anchorEl);
    const popoverId = open ? 'simple-popover' : undefined;

    const [openCancel, setOpenCancel] = React.useState(false);
    const [canCancel, setCanCancel] = React.useState(true);
    const [notesDialog, setNotesDialog] = React.useState(false);
    const [note, setNote] = React.useState<string | null>('');

    useEffect(() => {
        if (contract !== undefined && contract !== null) {
            setNote(contract.note?.text);
        }
    }, []);

    const handleClickOpenCancel = () => {
        getSinisters(client_id, 10, 0, { policy_id: contract?.id })
            .then(response => {
                setOpenCancel(true);
                if (response.data.results?.length) setCanCancel(false);
            })
    };
    const handleCloseCancel = () => {
        setOpenCancel(false);
    };

    const edit = () => {
        const contract_type = contract_types?.find(t => t.id == (contract?.contracts_type?.id || contract?.contracts_type));
        let type = contract_type?.abbreviation?.replace("FT-", "") || "INDIV";
        if (!contract?.subscriber && type !== "GROUP") type = "GROUP";
        navigate('/insurance/edit-contract/' + contract?.id + '/?type=' + type);
    }

    const cancelContract = () => {
        patchContract(client_id, contract?.id, { contracts_status: "CANCELLED" })
            .then((response) => {
                if (response.data?.contracts_status === "CANCELLED") {
                    dispatch(setSelectedContract(response.data));
                    setOpenCancel(false);
                    enqueueSnackbar(t('contract.cancelled'), { variant: 'success' });
                }
            })
            .catch(() => enqueueSnackbar(t('contract.cancelled_fail'), { variant: 'error' }));
    }

    const setFree = (free: boolean) => {
        patchContract(client_id, contract?.id, { is_free: free })
            .then((response) => {
                enqueueSnackbar(t('contract.updated'), { variant: 'success' });
                setContract(response.data);
            })
            .catch(() => enqueueSnackbar(t('contract.cancelled_fail'), { variant: 'error' }));
    }

    const formatDate = (date?: Date | string | null) => {
        return format(new Date(date as string), 'dd/MM/yyyy', { locale: fr });
    };

    const buildPDF = async (newTab?: boolean) => {

        const refreshIntervalId = setInterval(async () => {
            const content: any = document.querySelector('#contract-template');
            if (content) {
                clearInterval(refreshIntervalId);

                const response = await axios.post<Blob>(
                    `https://docraptor.com/docs`,
                    {
                        user_credentials: 'a0Ahb9NO49IOnaQJH9Ir',
                        doc: {
                            test: true, // TODO: handle me
                            type: 'pdf',
                            pipeline: '9.2',
                            document_content: content.innerHTML,
                            javascript: true,
                            ignore_console_messages: false,
                            prince_options: {
                                css_dpi: 135
                            }
                        }
                    },
                    {
                        responseType: 'blob'
                    }
                );
                if (response.data) {
                    const fileName = 'contrat_n-' + (contract?.reference?.replace('/', '-') || contract?.id);
                    const url = URL.createObjectURL(response.data);
                    if (!newTab) {
                        const a = document.createElement('a');
                        a.href = url;
                        a.download = fileName + '.pdf';
                        document.body.appendChild(a);
                        a.click();
                        window.URL.revokeObjectURL(url);
                    } else {
                        setViewLoading(false);
                        let new_window = window.open(url, '_blank');
                        setTimeout(() => { 
                            new_window.document.title = fileName;
                        }, 1000);
                    }
                }
            }
        }, 100);
    };

    const handleCertificate = (contract: Contract, locale: number) => {
        getOrganisationCertificates(client_id, 100, 0)
            .then(resp => {
                const certificate = resp?.data?.results?.find(c => c.locale?.id == locale && c.provider?.id === contract.principal_product?.lead_provider?.id)
                if (certificate) {
                    try {
                        downloadOrganisationCertificate(client_id, certificate.id)
                            .then(async (respon) => {
                                if (respon.data) {
                                    // const url = '/documents/ATTESTATION-EN.docx';
                                    const url = URL.createObjectURL(respon.data);
                                    const response = await fetch(url);
                                    const docxContent = await response.arrayBuffer();
                                    if (docxContent) {
                                        const zip = new PizZip(docxContent);
                                        const doc = new Docxtemplater().loadZip(zip);

                                        doc.setOptions({
                                            delimiters: { start: '%', end: '%' }
                                        })
                                        doc.setData({
                                            Convention: contract.reference,
                                            NoContrat: '',
                                            NomPax: `${contract.subscriber?.first_name || ''} ${contract.subscriber?.last_name || ''}`,
                                            DateDepart: formatDate(contract.travel_start_date),
                                            DateRetour: formatDate(contract.travel_end_date),
                                            Destination: contract.destinations?.map(d => d.name || d.international_name)?.join(', '),
                                        });

                                        try {
                                            doc.render();
                                            const output = doc.getZip().generate({ type: 'blob' });

                                            const fileName = 'attestation_n-' + (contract?.reference?.replace('/', '-') || contract?.id);
                                            saveGeneratedDocx(output, fileName);
                                        } catch (error) {
                                            console.error('Error rendering the document:', error);
                                        }
                                    }
                                }
                            })
                            .catch(() => console.log('Error downloading certificate'));
                    } catch (error) {
                        console.error('Error loading the file:', error);
                    }
                } else enqueueSnackbar(t('contract.certificate_not_found'), { variant: 'error' });
            })
            .catch(error => {
                console.error('Error loading the file:', error);
            });
    };

    const saveGeneratedDocx = (output, fileName) => {
        const url = URL.createObjectURL(output);
        const a = document.createElement('a');
        a.href = url;
        a.download = fileName + '.docx';
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
    };

    const sendContract = () => {
        setLoading(true);

        const refreshIntervalId = setInterval(async () => {
            const content: any = document.querySelector('#contract-template');
            if (content) {
                clearInterval(refreshIntervalId);
                const response = await axios.post<Blob>(
                    `https://docraptor.com/docs`,
                    {
                        user_credentials: 'a0Ahb9NO49IOnaQJH9Ir',
                        doc: {
                            test: true, // TODO: handle me
                            type: 'pdf',
                            pipeline: '9.2',
                            document_content: content.innerHTML,
                            javascript: true,
                            ignore_console_messages: false,
                            prince_options: {
                                css_dpi: 135
                            }
                        }
                    },
                    {
                        responseType: 'blob'
                    }
                );

                if (response.data) {
                    const fileName = 'contrat_n-' + (contract?.reference?.replace('/', '-') || contract?.id) + '.pdf';
                    const url = URL.createObjectURL(response.data);
                    const a = document.createElement('a');
                    a.href = url;
                    a.download = fileName + '.pdf';
                    document.body.appendChild(a);
                    a.click();
                    window.URL.revokeObjectURL(url);

                    const request = new FormData();
                    request.append('contract', contract?.id);
                    request.append('contract_file', new File([response.data], fileName + '.pdf'));
                    axios({
                        method: 'POST',
                        url: `${API_HREF}client/${client_id}/insurances-contract/send_email/`,
                        headers: {
                            'Authorization': `Token ${localStorage.getItem('token')}`,
                            'Content-Type': 'multipart/form-data'
                        },
                        data: request
                    }).then(() => {
                        enqueueSnackbar(t('contract.sent'), { variant: 'success' });
                        getContractById(client_id, id).then((resp) => {
                            if (resp.data) {
                                dispatch(setSelectedContract(resp.data));
                            }
                        })
                        setLoading(false);
                    }).catch(() => {
                        enqueueSnackbar(t('errors.servers'), { variant: 'error' });
                    });
                }
            }
        }, 100);
    };
    const openNotesDialog = (event: any, reason: string) => {
        if (reason !== 'backdropClick') {
            setNotesDialog(!notesDialog);
        }
    };
    const saveNote = () => {
        setNoteLoading(true);
        patchContract(client_id, contract?.id, { note: {text: note}})
            .then((response) => {
                dispatch(setSelectedContract(response.data));
                setNoteLoading(false);
                setNotesDialog(false);
                enqueueSnackbar(t('contract.note_success'), { variant: 'success' });
            })
            .catch(() => enqueueSnackbar(t('contract.note_error'), { variant: 'error' }));
    }
    if (!contract) return <></>;
    return (
        <>
            <Grid item xs={isSmallScreen ? 12 : (isTablet ? 4 : 3)} container spacing={1}>
                <Grid item xs={12}>
                    <Paper variant='outlined' className="history-paper">
                        {/* {
                                (profile?.is_superuser || profile?.client_full?.name === "Valeurs Assurances") &&
                                <Typography><b>{t('contract.agency')} : </b>{contract.subscribing_agency?.name}</Typography>
                            } */}
                        <Stepper activeStep={0} orientation="vertical">
                            <Step>
                                <StepLabel icon={<Folder />}>{t('contract.created_at')} {contract.created_date ? format(new Date(contract.created_date), 'dd/MM/yyy') : ''} {t('contract.by')} {contract.creator?.username}</StepLabel>
                            </Step>
                            {
                                contract.created_date && contract.modified_date && contract.created_date.split('T')![0] != contract.modified_date.split('T')![0] &&
                                <Step>
                                    <StepLabel icon={<Edit />}>{t('contract.edited_at')} {contract.modified_date ? format(new Date(contract.modified_date), 'dd/MM/yyy') : ''} {t('contract.by')} {contract.last_author?.username} </StepLabel>
                                </Step>
                            }
                            <Step>
                                <StepLabel icon={<Edit />}>{t('contract.send_at')} {contract.contract_sent ? format(new Date(contract.contract_sent), 'dd/MM/yyy') : ''}</StepLabel>
                            </Step>
                        </Stepper>
                    </Paper>
                </Grid>
                {
                    profile?.is_superuser || profile?.client_full?.name === "Valeurs Assurances" &&
                    <Grid item xs={12}>
                        <FormControlLabel control={<Switch onClick={(e: any) => setFree(e.target?.checked)} checked={Boolean(contract.is_free)} />} label={t('contract.is_free')} />
                    </Grid>
                }
                <Grid item xs={12}>
                    <Button variant="outlined" className="history-button" style={{ width: '100%' }} startIcon={<Notes/>} onClick={openNotesDialog}>
                        {contract.note === null ? t('contract.add_note') : t('contract.edit_note')}
                    </Button>
                </Grid>
                <Grid item xs={12}>
                    <Button
                        variant="outlined"
                        className="history-button"
                        style={{ width: "100%" }}
                        onClick={() => {
                            setViewLoading(true);
                            buildPDF(true);
                        }}
                        disabled={viewLoading}
                        startIcon={<Visibility />}
                    >
                        {t('contract.view')}    
                    </Button>
                </Grid>
                <Grid item xs={12}>
                    <Button variant="outlined" className="history-button" style={{ width: "100%" }} onClick={() => buildPDF()}
                        startIcon={<FileDownload />}>{t('contract.download')}</Button>
                </Grid>
                <Grid item xs={12}>
                    <Button variant="outlined" className="history-button" style={{ width: "100%" }} onClick={handleClick}
                        startIcon={<FileDownload />}>{t('contract.export-certificate')}</Button>
                    <Popover
                        id={popoverId}
                        open={open}
                        anchorEl={anchorEl}
                        onClose={handleClose}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}
                    >
                        <div style={{ display: "flex", flexDirection: "column", padding: 15 }}>
                            {
                                contract.principal_product?.attestation_language?.map(locale => {
                                    return <Button key={"locale" + locale.id} onClick={() => handleCertificate(contract, locale.id)}>{locale.full_name}</Button>;
                                })
                            }
                        </div>
                    </Popover>
                </Grid>
                {
                    // !isMoreThan48HoursAgo(contract.created_date) && 
                    contract.contracts_status !== "CANCELLED" &&
                    <Grid item xs={12}>
                        <Button variant="outlined" className="history-button" startIcon={<EditOutlined />} style={{ width: "100%" }}
                            onClick={edit}>{t('contract.edit-contract')}</Button>
                    </Grid>
                }
                <Grid item xs={12}>
                    <Button variant="outlined" className="history-button" onClick={handleClickOpenCancel} disabled={contract.contracts_status === "CANCELLED"}
                        startIcon={<CancelOutlined />} style={{ width: "100%" }}>{contract.contracts_status === "CANCELLED" ? t('contract.cancelled') : t('contract.cancel')}</Button>
                </Grid>
                {
                    contract.contracts_status !== "CANCELLED" &&
                    <Grid item xs={12}>
                        <Button variant="contained" startIcon={<Send />} style={{ width: "100%" }} onClick={sendContract}>{t('contract.send')}</Button>
                        {loading && <CircularProgress size={24} />}
                    </Grid>
                }
            </Grid>
            {
                contract?.contracts_type?.abbreviation === 'FT-INDIV' &&
                <ContractTemplate contract={contract} />
            }
            {
                contract?.contracts_type?.abbreviation === 'FT-GROUP' &&
                <ContractGroupTemplate contract={contract} />
            }
            <Dialog open={openCancel} onClose={handleCloseCancel}>
                <DialogTitle> {canCancel ? t('contract.ask_cancel') : t('contract.cannot_cancel')} </DialogTitle>
                <DialogActions>
                    <Button onClick={handleCloseCancel}>{t('shared.cancel')}</Button>
                    {canCancel && <Button onClick={cancelContract} autoFocus>{t('shared.confirm')}</Button>}
                </DialogActions>
            </Dialog>

            <Dialog fullWidth maxWidth={'sm'} open={notesDialog} onClose={openNotesDialog} disableEscapeKeyDown>
                <DialogTitle>{contract.note === null ? t('contract.add_note') : t('contract.edit_note')}</DialogTitle>
                <DialogContent>
                    <Grid container sx={{width: '100%'}}>
                        <Grid item xs={12}>
                            <TextareaAutosize value={note} style={{width: '100%'}} minRows={5} onChange={(e) => setNote(e.target.value)}/>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={openNotesDialog}>{t('shared.cancel')}</Button>
                    <Button autoFocus onClick={saveNote}>{t('shared.confirm')}</Button>
                    {noteLoading && <CircularProgress size={24} />}
                </DialogActions>
            </Dialog>
        </>
    );
};

export default ContractDetailPanel;