import { useTranslation } from 'react-i18next';
import { LockOutlined, MailOutlined, Visibility, VisibilityOff } from "@mui/icons-material";
import { Button, Checkbox, FormControlLabel, Grid, IconButton, InputAdornment, TextField } from "@mui/material";
import { useSnackbar } from "notistack";
import React, { FC, ReactElement, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getPermissions, getUser, postLogin } from "../../services/commonServices";
import { RootState } from "../../store/store";
import '../../styles/authentification.css';
import { LoadingButton } from '@mui/lab';
import { setLoggedUserClientId, setPermissions, setProfile } from '../../store/reducer';

const UserLoginForm: FC<{ confirmPass: (pass: string) => void }> = ({ confirmPass }): ReactElement => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation();

    const [showPassword, setShowPassword] = React.useState(false);
    const [email, setEmail] = React.useState('');
    const [password, setPassword] = React.useState('');
    const [loading, SetLoading] = useState(false);

    const client_id = useSelector((state: RootState) => state.app.client_id);

    const handleClickShowPassword = () => setShowPassword((show) => !show);
    const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    };

    return (
        <>
            <div>
                <h2>{t('auth.login')}</h2>
                <p style={{ marginTop: 0 }}>{t('auth.login-info')}</p>
            </div>
            <div className="login-form-container-inputs" style={{ paddingTop: 30, paddingBottom: 30 }}>
                <TextField
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    fullWidth
                    label={t('auth.password')}
                    type={showPassword ? "text" : "password"}
                    placeholder={t('auth.password-info')}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <LockOutlined />
                            </InputAdornment>
                        ),
                        endAdornment: (
                            <InputAdornment position="start">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword}
                                    onMouseDown={handleMouseDownPassword}
                                    edge="end"
                                >
                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                    variant="outlined"
                />
            </div>
            <LoadingButton style={{ width: "100%", marginTop: 15 }} loading={loading} color='secondary' variant="contained" onClick={() => confirmPass(password)}>{t('auth.confirm')}</LoadingButton>
        </>
    );
};

export default UserLoginForm;