import { ArrowBackIosNew } from "@mui/icons-material";
import { Button, Container, Dialog, DialogActions, DialogTitle, Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { FC, ReactElement } from "react";
import { useDispatch, useSelector } from "react-redux";
import { cleanContract } from "../../functions/contract";
import { postContract } from "../../services/contractServices";
import { RootState } from "../../store/store";
import { Contract } from "../../types/contract/contract";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import ContractForm from "./ContractForm";
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import ContractTemplate from "./ContractTemplate";
import { useTranslation } from "react-i18next";
import { setSelectedContract } from "../../store/reducer";
import { id } from "date-fns/locale";
import axios from "axios";
import ContractGroupTemplate from "./ContractGroupTemplate";

const CreateContractForm: FC = (): ReactElement => {
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down(600));
    const client_id = useSelector((state: RootState) => state.app.client_id);
    const logged_user_client_id = useSelector((state: RootState) => state.app.logged_user_client_id);
    const contract_types = useSelector((state: RootState) => state.app.contract_types);
    const selected_contract = useSelector((state: RootState) => state.app.selected_contract);

    const [searchParams, setSearchParams] = useSearchParams();
    const type = searchParams.get("type");

    const [openCancel, setOpenCancel] = React.useState(false);
    const [loading, SetLoading] = useState(false);
    const [contractToExport, SetContractToExport] = useState<Contract | null>(null);

    const [form_title, setFormTitle] = useState(t('contract.create_indiv'));

    useEffect(() => {
        if (type === "GROUP") {
            setFormTitle(t('contract.create_group'));
        }
        if (type === "GIR") {
            setFormTitle(t('contract.create_gir'));
        }
        if (type === "LOC") {
            setFormTitle(t('contract.create_location'));
        }
    }, [type]);

    const getAgeDiff = (birth_date: any) => {
        if (!birth_date) return 0;
        const now = new Date();
        const b_date = new Date(birth_date);
        const diff = now.getFullYear() - b_date.getFullYear();
        return diff;
    };

    const buildPDF = (filename: string, contract?: any, onExportCallback?: () => void) => {
        const refreshIntervalId = setInterval(async () => {
            const content: any = document.querySelector('#contract-template');
            if (content) {
                clearInterval(refreshIntervalId);

                const response = await axios.post<Blob>(
                    `https://docraptor.com/docs`,
                    {
                        user_credentials: 'a0Ahb9NO49IOnaQJH9Ir',
                        doc: {
                            test: true, // TODO: handle me
                            type: 'pdf',
                            pipeline: '9.2',
                            document_content: content.innerHTML,
                            javascript: true,
                            ignore_console_messages: false,
                            prince_options: {
                                css_dpi: 135
                            }
                        }
                    },
                    {
                        responseType: 'blob'
                    }
                );
                if (response.data) {
                    const url = URL.createObjectURL(response.data);
                    const a = document.createElement('a');
                    a.href = url;
                    a.download = filename + '.pdf';
                    document.body.appendChild(a);
                    a.click();
                    window.URL.revokeObjectURL(url);

                    const request = new FormData();
                    request.append('contract', contract?.id);
                    request.append('contract_file', new File([response.data], filename + '.pdf'));
                    axios({
                        method: 'POST',
                        url: `${API_HREF}client/${client_id}/insurances-contract/send_email/`,
                        headers: {
                            'Authorization': `Token ${localStorage.getItem('token')}`,
                            'Content-Type': 'multipart/form-data'
                        },
                        data: request
                    }).then(() => {
                        enqueueSnackbar(t('contract.sent'), { variant: 'success' });
                    }).catch(() => {
                        enqueueSnackbar(t('errors.servers'), { variant: 'error' });
                    });
                    if (onExportCallback !== undefined) onExportCallback();
                }
            }
        }, 100);
    };

    const confirmForm = (contract: Contract) => {
        const contractToAdd: any = cleanContract(contract);
        delete contractToAdd.id;
        delete contractToAdd.reference;
        contractToAdd.travelers_group = contractToAdd.travelers;
        contractToAdd.contracts_type = contract_types?.find(ct => ct.abbreviation === 'FT-' + type)?.id;
        if (contractToAdd.travelers_group.some(p => p.birth_date && getAgeDiff(p.birth_date) <= 2)) contractToAdd.one_pax_is_baby = true;
        contractToAdd.agency = logged_user_client_id;
        SetLoading(true);
        postContract(client_id, contractToAdd)
            .then((resp) => {
                enqueueSnackbar(t('contract.created'), { variant: 'success' });
                navigate('/insurance/contracts/' + resp.data.id);
                SetContractToExport(resp.data);
                buildPDF('contrat_' + resp.data?.reference?.replace('/', '-'), resp.data, () => {
                    SetLoading(false);
                    SetContractToExport(null);
                    dispatch(setSelectedContract(resp.data));
                });
            })
            .catch((error) => {
                if (error.response?.data?.errors) {
                    for (const [key, value] of Object.entries(error.response.data.errors)) {
                        if (Array.isArray(value)) {
                            value.map(error => enqueueSnackbar(`${error}`, { variant: 'error' }));
                        } else {
                            enqueueSnackbar(`${value}`, { variant: 'error' });
                        }
                    }
                } else if (error.response) {
                    if (error.response.status === 400) {
                        for (const [key, value] of Object.entries(error.response.data)) {
                            if (Array.isArray(value)) {
                                value.map(error => enqueueSnackbar(`${key} : ${error}`, { variant: 'error' }));
                            } else {
                                enqueueSnackbar(`${key} : ${value}`, { variant: 'error' });
                            }
                        }
                    } else if (error.response.status === 401 || error.response.status === 403) {
                        enqueueSnackbar(t('errors.permissions'), { variant: 'error' });
                    } else if (error.response.status === 500) {
                        enqueueSnackbar(t('errors.servers'), { variant: 'error' });
                    }
                } else if (error.request) {
                    console.log('error.request : ', error.request);
                } else {
                    console.log('Error', error.message);
                }
                console.log('error.config : ', error.config);
            })
            .finally(() => SetLoading(false));
    };

    return (
        <Container>
            <Grid container spacing={2}>
                <Grid item md={12} lg={4}>
                    <Button startIcon={<ArrowBackIosNew />} onClick={() => setOpenCancel(true)}>{t('shared.back')}</Button>
                </Grid>
                <Grid item md={12} lg={8}>
                    <Typography variant={isSmallScreen ? "h5" : "h4"} className="title" style={{ fontWeight: "bold" }}>{form_title}</Typography>
                </Grid>
                <ContractForm type={type} onConfirm={confirmForm} loading={loading} />
                {
                    contractToExport && contractToExport.contracts_type?.abbreviation === 'FT-INDIV' && <ContractTemplate contract={contractToExport} />
                }
                {
                    contractToExport && contractToExport.contracts_type?.abbreviation === 'FT-GROUP' && <ContractGroupTemplate contract={contractToExport} />
                }
            </Grid>
            <Dialog open={openCancel} onClose={() => setOpenCancel(false)}>
                <DialogTitle> {t('contract.ask_edit')} </DialogTitle>
                <DialogActions>
                    <Button onClick={() => setOpenCancel(false)} autoFocus>{t('shared.no')}</Button>
                    <Button onClick={() => navigate('/insurance/contracts')}>{t('shared.yes')}</Button>
                </DialogActions>
            </Dialog>
        </Container>
    );
};

export default CreateContractForm;