
import * as locales from 'date-fns/locale';
import { format } from "date-fns";
import i18next from "i18next";
import React, { FC, ReactElement, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { IataCountry } from "../../types/common/iataCountry";
import { InsuranceProduct } from "../../types/insurance/insurance-product";
import GenericPanel from '../common/genericList/genericPanel';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/store';
import { getCountries } from '../../services/commonServices';
import { Grid, Stack, Tooltip, Typography } from '@mui/material';

const ProductDetailDescription: FC<{ product: InsuranceProduct }> = ({ product }): ReactElement => {
    const { t } = useTranslation();
    const locales_cpy: { [key: string]: any } = locales;
    const quotation_code = useSelector((state: RootState) => state.app.quotation_code);
    const client_id = useSelector((state: RootState) => state.app.client_id);
    const profile = useSelector((state: RootState) => state.app.profile);
    const [priceColumns, setPriceColumns] = useState<any>([]);
    const [priceRows, setPriceRows] = useState<any>([]);
    const [franceDestination, setFranceDestination] = useState<any>(null);
    const [isInDurationDestination, setIsInDurationDestination] = useState(false);
    const [destinations, setDestinations] = useState<any[]>([]);
    const [fieldRemise, setFieldRemise] = useState<any[]>([]);
    useEffect(() => {
        if (product?.is_in_duration_destination) {
            setIsInDurationDestination(true);
            if (product?.long_haul_destinations?.length !== 0 || product?.medium_haul_destinations?.length !== 0) {
                let tmp_destination:any[] = [];
                if (product?.long_haul_destinations?.length) {
                    tmp_destination.push({ label: t('insurance.products.long_haul_destinations'), value: 'long_haul_destinations' });
                }
                if (product?.medium_haul_destinations?.length) {
                    tmp_destination.push({ label: t('insurance.products.medium_haul_destinations'), value: 'medium_haul_destinations' });
                }
                if (quotation_code === 'va') {
                    getFranceDestination().then((france_data) => {
                        tmp_destination.push({ label: 'France', value: 'France' });
                        setFranceDestination(france_data);
                    });
                }
                setDestinations(tmp_destination);
            }
        } else {
            setIsInDurationDestination(false);
        }
    }, []);
    useEffect(() => {
        const all_providers_prices = product?.providers_prices?.map(p => p.provider?.id).filter(Boolean);
        let new_providers = product?.providers?.filter(p => !all_providers_prices?.includes(p.id));
        new_providers = new_providers?.length ? new_providers.filter(Boolean) : [];
        const column: any = {
            min_slice: 0,
            max_slice: 0,
            price: {
                min_slice: 0,
                max_slice: 0,
                selling_price: 0,
                recommended_price: 0,
                com_price: 0,
                price_type: "FIX",
                destination: []
            },
            providers_prices: [],
            agencies_prices: [],
        };
        if (product?.providers) {
            product.providers.forEach(provider => {
                column.providers_prices.push({
                    provider,
                    min_slice: 0,
                    max_slice: 0,
                    purchase_price: 0,
                    com_price: 0,
                    price_type: "FIX",
                    destinations: []
                });
            });
        }

        /** HANDLE ROWS */
        // COLUMNS
        const cols = [column];

        // ROWS
        const rows: any[] = [];
        product?.prices?.forEach((price) => {
            let price_destination = [];
            if (price.destinations.length !== 0) {
                if (product.long_haul_destinations.length) {
                    const p_dest = price.destinations.map(dest => dest.id);
                    const p_long = product.long_haul_destinations.map(medium => medium.id);
                    if (p_dest.some((dest) => p_long.includes(dest))) {
                        price_destination.push({ label: t('products.long_haul_destinations'), value: 'long_haul_destinations' });

                    }
                }
                if (product.medium_haul_destinations.length) {
                    const p_dest = price.destinations.map(dest => dest.id);
                    const p_medium = product.medium_haul_destinations.map(medium => medium.id);
                    if (p_dest.some((dest) => p_medium.includes(dest))) {
                        price_destination.push({ label: t('products.medium_haul_destinations'), value: 'medium_haul_destinations' });
                    }
                }
                if (franceDestination !== undefined && franceDestination !== null) {
                    const p_dest = price.destinations.map(dest => dest.id);
                    if (p_dest.some((dest) => dest === franceDestination[0].id)) {
                        price_destination.push({ label: 'France', value: 'France' });
                    }
                }
            }
            const item: any = {
                min_slice: price.min_slice,
                max_slice: price.max_slice,
                price: {
                    selling_price: price.selling_price,
                    price_type: price.price_type,
                    recommended_price: price.recommended_price
                },
                destinations: price_destination,
                providers_prices: [],
                agencies_prices: [],
                id: price.id
            };

            product.providers_prices.forEach((providerPrice) => {
                if (
                    providerPrice.min_slice === price.min_slice &&
                    providerPrice.max_slice === price.max_slice &&
                    providerPrice.destinations.map(dest => dest.id).join(', ') === price.destinations.map(dest => dest.id).join(', ')
                ) {
                    item.providers_prices.push({
                        provider: providerPrice.provider,
                     
                        purchase_price: providerPrice.purchase_price,
                        price_type: providerPrice.price_type
                    });
                }
            });
            new_providers?.forEach((prov) => {
                item.providers_prices.push({
                    provider: prov,
                    purchase_price: 0,
                    price_type: 'FIX',
                    min_slice: price.min_slice,
                    max_slice: price.max_slice,
                });
            });

            // new provider

            rows.push(item);
        });

        if (product?.agencies_prices) {
            const groups = groupAgencies(product.agencies_prices);
            const results = groupRows(product.agencies_prices, groups);
            cols[0].agencies_prices = results.map((a, i) => {
                return {
                    agencies: a.agencies,
                    ids: i
                };
            });
            rows.forEach((row, row_index) => {
                if (!row.agencies_prices?.length) row.agencies_prices = [];
                row.agencies_prices = results.map((a, i) => {
                    // const concerned_row = a.rows?.length && a.rows[row_index] ? a.rows.find(rw => rw.min_slice === row.min_slice && rw.max_slice === row.max_slice && rw.destinations === row.destinations) : {};
                    const concerned_row = a.rows?.length && a.rows[row_index] ? a.rows.find(rw => rw.min_slice === row.min_slice && rw.max_slice === row.max_slice) : {};
                    return {
                        ...concerned_row,
                        agencies: a.agencies,
                        ids: i
                    };
                });
            });
        }
        let fields = [];
        product?.product_discount.map((discount) => {
            fields.push({title: '', value: discount.name, gridSize: 12});
        });
        setFieldRemise(fields);
        /** VALIDATE */
        setPriceColumns(cols);
        setPriceRows(rows.sort((a, b) => a.min_slice - b.min_slice));

    }, [product, franceDestination]);
    const getFranceDestination = async () => {
        const response = getCountries(client_id, 'france').then((resp) => {
            if (resp.data?.results) {
                const res = resp.data.results.filter(result => result.country_code === 'FR');
                return res;
            }
        }).catch((err) => {
            console.log('Error AUTOCOMPLETE', err);
        });
        return response;
    };
    const getSettingsPanel = () => {
        const fields: any[] = [];
        if (product) {
            if (product.subscription_method?.length) {
                fields.push({
                    gridSize: 6,
                    title: t("products.subscription_method"),
                    value: product.subscription_method.map(tp => t(`products.souscription_mode_${tp.abbreviation?.toLowerCase()}`))?.join(', ')
                });
            }
            fields.push({
                gridSize: 6,
                title: t("products.brand"),
                value: product.brand?.name
            });
            if (product.providers?.length) {
                fields.push({
                    gridSize: 6,
                    title: t("products.providers"),
                    value: product.providers.map(pr => pr.provider?.name)?.filter(Boolean)?.join(', ')
                });
                fields.push({
                    gridSize: 6,
                    title: t("products.lead_provider"),
                    value: product.lead_provider?.provider?.name
                });
            }
            if (product.attestation_language?.length) {
                fields.push({
                    gridSize: 6,
                    title: t("products.attestation_language"),
                    value: product.attestation_language.map(pr => (pr as any).full_name)?.join(', ')
                });
            }
            if (product.non_authorized_countries?.length) {
                fields.push({
                    gridSize: 6,
                    title: t("products.non_authorized_countries"),
                    value: product.non_authorized_countries.map(pr => (pr as IataCountry).international_name)?.join(', ')
                });
            }
            if (product.country_of_residence?.length) {
                fields.push({
                    gridSize: 6,
                    title: t("products.country_of_residence"),
                    value: product.country_of_residence.map(pr => (pr as IataCountry).international_name)?.join(', ')
                });
            }
            fields.push({
                gridSize: 12,
                title: t("products.activation_date"),
                value: product.activation_date ? format(new Date(product.activation_date), 'P', { locale: locales_cpy[i18next.language] }) : '-'
            });
            if (product.long_haul_destinations?.length) {
                fields.push({
                    title: t("products.long_haul_destinations"), gridSize: 6,
                    value: product.long_haul_destinations.map(des => (des as IataCountry).international_name)?.join(', ')
                });
            }
            if (product.medium_haul_destinations?.length) {
                fields.push({
                    title: t("products.medium_haul_destinations"), gridSize: 6,
                    value: product.medium_haul_destinations.map(des => (des as IataCountry).international_name)?.join(', ')
                });
            }
            if (product.trip_durations?.length) {
                product.trip_durations.forEach((trip_duration, trip_duration_index) => {
                    const duration: number[] = trip_duration.duration ? JSON.parse(JSON.stringify(trip_duration.duration)) : [0, 30];
                    if (!duration[0]) duration[0] = 0;
                    if (!duration[1]) duration[1] = 30;
                    fields.push({
                        gridSize: 6,
                        title: (trip_duration_index === 0 ?
                            `${t('products.trip_duration_first')}` :
                            `${trip_duration_index + 1}${t('products.trip_duration_n')}`) + ` (${duration[0]} ${t('products.to')} ${duration[1]} ${t('products.days')})`,
                        value: new Intl.NumberFormat(i18next.language, { style: 'currency', currency: 'EUR' }).format(trip_duration.price)
                    });
                });
            }
        }
        return fields;
    };
    const groupAgencies = (inputData: any) => {
        const groups: any = {};

        inputData.forEach((entry: any) => {
            const sellingPrice = entry.selling_price;
            const agency = entry.agency;

            if (!groups.hasOwnProperty(sellingPrice)) {
                groups[sellingPrice] = [];
            }

            if (!groups[sellingPrice].some(a => a.id === agency.id)) {
                groups[sellingPrice].push(agency);
            }
        });

        return groups;
    };

    // Function to group rows based on common attributes
    const groupRows = (inputData: any, groups: any) => {
        const rows: any = {};

        inputData.forEach((entry: any) => {
            const sellingPrice = entry.selling_price;
            const key = JSON.stringify(groups[sellingPrice].map(a => a.id).sort());

            if (!rows.hasOwnProperty(key)) {
                rows[key] = {
                    agencies: groups[sellingPrice],
                    rows: []
                };
            }

            const row = rows[key].rows.find(r =>
                r.max_slice === entry.max_slice &&
                r.min_slice === entry.min_slice &&
                r.price_type === entry.price_type &&
                r.selling_price === entry.selling_price
            );

            if (!row) {
                rows[key].rows.push({
                    max_slice: entry.max_slice,
                    min_slice: entry.min_slice,
                    price_type: entry.price_type,
                    selling_price: entry.selling_price,
                    recommended_price: entry.recommended_price
                });
            }
        });

        return Object.values(rows);
    };
    if (!product) return <></>;
    return (
        <>
            <GenericPanel mainTitle={t("products.description")} editStepCallback={() => { }}
                fields={[
                    { title: t("products.name"), value: product.name },
                    { title: t("products.product_family"), value: product.product_family?.name },
                    { title: t("products.convention_number"), value: product.convention_number },
                    { title: t("products.internal_reference"), value: product.internal_reference },
                    {
                        title: t("products.type"),
                        value: product.type ? t(`products.type_${product.type.toLowerCase()}`) : null
                    },
                    { title: t("products.description"), value: product.description, gridSize: 12 }
                ]}
                readOnly
            />
            {/* <GenericPanel mainTitle={t("products.note")} editStepCallback={() => { }}
                fields={[
                    { title: '', value: product.note, gridSize: 12 },
                ]}
                readOnly
            /> */}
            <GenericPanel mainTitle={t("products.settings")} editStepCallback={() => { }}
                fields={getSettingsPanel()}
                readOnly
            />
            {
                priceRows.length !== 0 &&
                <Stack sx={{ml: 2}}>
                    <Typography variant="subtitle1" className="title">
                        {t('products.table_prices')}
                    </Typography>
                    <div className='insurance-price-table-container'>
                        {
                            priceColumns.map((column: any, column_index: number) => {
                                return (
                                    <React.Fragment key={'price-column-' + column_index}>
                                        <div>
                                            <Grid item xs={12} container>
                                                <Grid item xs={12}> <h4>{!isInDurationDestination ? t('products.slices') : t('products.durations')}</h4></Grid>
                                                {
                                                    !isInDurationDestination ?
                                                        <>
                                                            <Grid item xs={6}> <small>{t('products.min_price')}</small> </Grid>
                                                            <Grid item xs={6}> <small>{t('products.max_price')}</small> </Grid>
                                                        </>
                                                        :
                                                        <>
                                                            <Grid item xs={6}> <small>{'Jour(s) min'}</small> </Grid>
                                                            <Grid item xs={6}> <small>{'Jour(s) max'}</small> </Grid>
                                                        </>
                                                }
                                            </Grid>
                                            <Grid item xs={12} container>
                                                {
                                                    priceRows.length > 0 && priceRows.map((row: any, row_index: number) => {
                                                        return (
                                                            <React.Fragment key={'price-column-' + column_index + "-" + row_index}>
                                                                <Grid item xs={6}>
                                                                    <p><span>{row.min_slice ? row.min_slice : 0}</span></p>
                                                                </Grid>
                                                                <Grid item xs={6}>
                                                                    <p><span>{row.max_slice ? row.max_slice : 0}</span></p>
                                                                </Grid>
                                                            </React.Fragment>
                                                        );
                                                    })
                                                }
                                            </Grid>
                                        </div>
                                        {
                                            isInDurationDestination &&
                                            <div>
                                                <Grid item xs={12} container>
                                                    <Grid item xs={12}><h4>{t('products.destinations')}</h4></Grid>
                                                </Grid>
                                                <Grid item xs={12} container>
                                                    {
                                                        priceRows.length > 0 && priceRows.map((row: any, row_index: number) => {
                                                            return (
                                                                <React.Fragment key={'price-column-' + column_index + "-" + row_index}>
                                                                    <Grid item xs={12} sx={{ cursor: 'pointer' }}>
                                                                        <Tooltip title={row.destinations.map(destination => destination.label).join(', ')}>
                                                                            <p style={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis', width: '90%' }}>{row.destinations.map(destination => destination.label).join(', ')}</p>
                                                                        </Tooltip>
                                                                    </Grid>
                                                                </React.Fragment>
                                                            );
                                                        })
                                                    }
                                                </Grid>
                                            </div>
                                        }
                                        {/* AGENCY VIEW PRICE */}
                                        {
                                            profile !== undefined && profile?.client_full.name !== "Valeurs Assurances" &&
                                            <div>
                                                <Grid item xs={12} container>
                                                    <Grid item xs={12}> <h4>{t('products.selling_price')}</h4></Grid>
                                                    <Grid item xs={4}> <small>{t('products.price_type')}</small> </Grid>
                                                    <Grid item xs={4}> <small>{t('products.amount')}</small> </Grid>
                                                    <Grid item xs={4}> <small>{t('products.recommended')}</small> </Grid>
                                                </Grid>
                                                <Grid item xs={12} container>
                                                    {
                                                        priceRows.length > 0 && priceRows.map((row: any, row_index: number) => {
                                                            let agency_price = row.agencies_prices.find((agency_price: any) => agency_price.agencies.find((agency: any) => agency.id === (profile && profile.client)));
                                                            console.log('agency_price:', agency_price);
                                                            if (agency_price === undefined) {
                                                                return (
                                                                    <React.Fragment key={'price-column-' + column_index + "-price-" + row_index}>
                                                                        <Grid item xs={4}>
                                                                            <p><span>{t('products.prime')} {t(`products.${row.price.price_type ? row.price.price_type : 'FIX'}`)}</span></p>
                                                                        </Grid>
                                                                        <Grid item xs={4}>
                                                                            <p><span>{row.price.selling_price ? row.price.selling_price : 0}</span></p>
                                                                        </Grid>
                                                                        <Grid item xs={4}>
                                                                            <p><span>{row.price.recommended_price ? row.price.recommended_price : 0}</span></p>
                                                                        </Grid>
                                                                    </React.Fragment>
                                                                );
                                                            } else {
                                                                return (
                                                                    <React.Fragment key={'price-column-' + column_index + "-price-" + row_index}>
                                                                        <Grid item xs={4}>
                                                                            <p><span>{t('products.prime')} {t(`products.${agency_price.price_type ? agency_price.price_type : 'FIX'}`)}</span></p>
                                                                        </Grid>
                                                                        <Grid item xs={4}>
                                                                            <p><span>{agency_price.selling_price ? agency_price.selling_price : 0}</span></p>
                                                                        </Grid>
                                                                        <Grid item xs={4}>
                                                                            <p><span>{agency_price.recommended_price ? agency_price.recommended_price : 0}</span></p>
                                                                        </Grid>
                                                                    </React.Fragment>
                                                                );
                                                            }
                                                        })
                                                    }
                                                </Grid>
                                            </div>
                                        }
                                        {/* VA VIEW PRICE */}
                                        {
                                            profile !== undefined && (profile?.is_superuser || profile?.client_full.name === "Valeurs Assurances") &&
                                            <div>
                                                <Grid item xs={12} container>
                                                    <Grid item xs={12}> <h4>{t('products.selling_price')}</h4></Grid>
                                                    <Grid item xs={4}> <small>{t('products.price_type')}</small> </Grid>
                                                    <Grid item xs={4}> <small>{t('products.amount')}</small> </Grid>
                                                    <Grid item xs={4}> <small>{t('products.recommended')}</small> </Grid>
                                                </Grid>
                                                <Grid item xs={12} container>
                                                    {
                                                        priceRows.length > 0 && priceRows.map((row: any, row_index: number) => {
                                                            return (
                                                                <React.Fragment key={'price-column-' + column_index + "-price-" + row_index}>
                                                                    <Grid item xs={4}>
                                                                        <p><span>{t('products.prime')} {t(`products.${row.price.price_type ? row.price.price_type : 'FIX'}`)}</span></p>
                                                                    </Grid>
                                                                    <Grid item xs={4}>
                                                                        <p><span>{row.price.selling_price ? row.price.selling_price : 0}</span></p>
                                                                    </Grid>
                                                                    <Grid item xs={4}>
                                                                        <p><span>{row.price.recommended_price ? row.price.recommended_price : 0}</span></p>
                                                                    </Grid>
                                                                </React.Fragment>
                                                            );
                                                        })
                                                    }
                                                </Grid>
                                            </div>
                                        }
                                        {
                                            profile !== undefined && (profile?.is_superuser || profile?.client_full.name === "Valeurs Assurances") &&
                                            <>
                                            {
                                                column.agencies_prices.map((agency_price: any, ag_index: number) => {
                                                    return (
                                                        <div key={"price-column-agency-" + ag_index}>
                                                            <Grid item xs={12} container>
                                                                <Grid item xs={12}>
                                                                    <h4>{t('products.agency_selling_price')}</h4>
                                                                </Grid>
                                                                {
                                                                    agency_price.agencies.length > 0 &&
                                                                    <Grid item xs={12}>
                                                                        <Tooltip title={
                                                                            <p style={{ fontSize: 15 }}>{agency_price.agencies.map((agc: Organisation) => agc.name).join(', ')}</p>
                                                                        }>
                                                                            <small className='agency-names-container'>{agency_price.agencies.map((agc: Organisation) => agc.name).join(', ')}</small>
                                                                        </Tooltip>
                                                                    </Grid>
                                                                }
                                                                <Grid item xs={4}> <small>{t('products.type')}</small> </Grid>
                                                                <Grid item xs={4}> <small>{t('products.amount')}</small> </Grid>
                                                                <Grid item xs={4}> <small>{t('products.recommended')}</small> </Grid>
                                                            </Grid>
                                                            <Grid item xs={12} container>
                                                                {
                                                                    priceRows.length > 0 && priceRows.map((row: any, row_index: number) => {
                                                                        // const agency_price_row = row.agencies_prices.length && row.agencies_prices[0] ? row.agencies_prices[0] : null;
                                                                        const agency_price_row = row.agencies_prices.find(e => e.ids === agency_price.ids);
                                                                        if (agency_price_row) {
                                                                            return (
                                                                                <React.Fragment key={'price-column-' + column_index + "-agency-" + ag_index + "-" + row_index}>
                                                                                    <Grid item xs={4}>
                                                                                        <p><span>{t('products.prime')} {t(`products.${agency_price_row.price_type ? agency_price_row.price_type : 'FIX'}`)}</span></p>
                                                                                    </Grid>
                                                                                    <Grid item xs={4}>
                                                                                        <p><span>{agency_price_row.selling_price ? agency_price_row.selling_price : 0}</span></p>
                                                                                    </Grid>
                                                                                    <Grid item xs={4}>
                                                                                        <p><span>{agency_price_row.recommended_price ? agency_price_row.recommended_price : 0}</span></p>
                                                                                    </Grid>
                                                                                </React.Fragment>
                                                                            );
                                                                        }
                                                                    })
                                                                }
                                                            </Grid>
                                                        </div>
                                                    );
                                                })
                                    }
                                            </>
                                        }
                                    </React.Fragment>
                                );
                            })
                        }
                    </div>
                </Stack>
            }
            {
                product.type !== "EXT" && product.product_discount.length !== 0 &&
                <GenericPanel mainTitle={t("products.discount")} editStepCallback={() => { }}
                    fields={fieldRemise}
                    readOnly
                />
            }
        </>
    );
};

export default ProductDetailDescription;