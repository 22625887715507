// Dependencies
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import React, { FC, ReactElement, useEffect, useState } from 'react';
// Core & Lab
import Typography from '@mui/material/Typography';
import { Grid } from '@mui/material';
import { DataGrid, GridColumnMenu, GridColumnMenuProps, frFR } from '@mui/x-data-grid';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Accordion from '@mui/material/Accordion';
import { RootState } from '../../store/store';
import { getProductsExtended } from '../../services/productServices';
import { InsuranceProduct } from '../../types/insurance/insurance-product';

const ProductDetailJoinExtension: FC<{
    extension: InsuranceProduct | null, // or product
}> = ({ extension }): ReactElement => {
    const { t } = useTranslation();

    const client_id = useSelector((state: RootState) => state.app.client_id);
    const [productsRows, setProductsRows] = useState<InsuranceProduct[]>([]);
    const [loading, setLoading] = useState(false);

    const productColumns: any = [
        {
            field: 'name', headerName: t('products.name'), width: 250, headerClassName: 'product-extension-header',
        },
        { field: 'internal_reference', headerName: t('products.internal_reference'), width: 250, headerClassName: 'product-extension-header' },
    ];
    if (extension?.type === "EXT") {
        productColumns.push({
            field: 'providers', headerName: t('products.providers'), minWidth: 250, flex: 1, headerClassName: 'product-extension-header',
            valueGetter: (params: any) => { return params.value?.map((e: any) => e.client?.name)?.join(', '); }
        });
    }

    useEffect(() => {
        if (extension?.type && !productsRows?.length && !loading) {
            setLoading(true);
            if (extension.type !== "PRI") {
                getProductsExtended(client_id, 1000, 0, { type: "PRI" }).then((resp) => {
                    if (resp.data?.results) {
                        setLoading(false);
                        if (extension?.principal_product?.length) {
                            setProductsRows(resp.data.results.filter(p => extension?.principal_product?.map(e => (e.id || e))?.includes(p.id)));
                        }
                    }
                }).catch((e) => console.log('Failed to >>>', e));
            } else {
                getProductsExtended(client_id, 1000, 0, { type: "EXT" }).then((resp) => {
                    if (resp.data?.results) {
                        setLoading(false);
                        setProductsRows(resp.data.results.filter(r => r.principal_product?.map(e => e.id ? e.id : (e as any))?.includes(extension.id)));
                    }
                }).catch((e) => console.log('Failed to >>>', e));
            }
        }
    }, [extension]);

    function CustomColumnMenu(props: GridColumnMenuProps) {
        return (
            <GridColumnMenu
                {...props}
                slots={{
                    columnMenuColumnsItem: null,
                    columnMenuFilterItem: null,
                }}
            />
        );
    }

    return (
        <>
            <Typography variant="subtitle1" className="title">{extension?.type === "PRI" ? t('products.product_exts') : t('products.product_pris')}</Typography>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <div style={{ height: 400, width: '100%' }}>
                        <DataGrid
                            slots={{ columnMenu: CustomColumnMenu }}
                            rows={productsRows}
                            columns={productColumns}
                            initialState={{
                                pagination: { paginationModel: { pageSize: 10 } },
                            }}
                            pageSizeOptions={[5, 10, 25]}
                            localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
                            loading={loading}
                        />
                    </div>
                </Grid>
            </Grid>
        </>
    );

};

export default ProductDetailJoinExtension;