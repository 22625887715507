import { Place, Search } from "@mui/icons-material";
import { Autocomplete, Box, FormControl, FormControlLabel, FormLabel, Grid, InputAdornment, InputLabel, MenuItem, Paper, Radio, RadioGroup, Select, Stack, TextField, Typography, useMediaQuery, useTheme } from "@mui/material";
import React, { FC, ReactElement, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import GenericList from "../common/genericList/genericList";
import Finished from "../common/loading/finished";
import { GenericColumn } from "../../types/genericModule/genericColumn";
import { format } from "date-fns";
import { setIncentiveList, setSelectedContract } from "../../store/reducer";
import { RootState } from "../../store/store";
import { OrganisationMinimal } from "../../types/organisationMinimal";
import checkPermissions from "../../functions/checkPermissions";
import i18next from "i18next";
import GenericField from "../common/genericList/genericField";
import { getOrganisations } from "../../services/commonServices";
import { getIncentive } from "../../services/incentiveServices";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import * as locales from 'date-fns/locale';

type filterObjType = {
    created_date__gte?: string | undefined,
    created_date__lte?: string | undefined,
    travel_start_date__gte?: string | undefined,
    travel_start_date__lte?: string | undefined,
    subscribing_agency__id?: number | null | undefined,
    subscribing_agency__ids?: string | undefined,
    search?: string | undefined
}

const IncentivePage: FC = (): ReactElement => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down(768));
    const incentive_list = useSelector((state: RootState) => state.app.incentive_list);
    const profile = useSelector((state: RootState) => state.app.profile);
    const permissions =  useSelector((state: RootState) => state.app.permissions);

    const locales_cpy: { [key: string]: any } = locales;

    const agency_column = {
        order: 1, field: 'subscribing_agency', label: t('contract.agency'), active: true, ordering_asc: null,
        displayValue: (value: OrganisationMinimal) => value?.name, nested_field: "subscribing_agency__name"
    };
    const creator_column = {
        order: 1, field: 'creator', label: t("list.created_by"), sortable: true, active: true, ordering_asc: null, nested_field: "creator__username",
        displayValue: (value: any) => value?.username
    };
    const columnsInit: GenericColumn[] = [
        {
            order: 1, field: 'reference', label: t('contract.reference'), active: true, ordering_asc: null, sortable: true
        },
        {
            order: 1, field: 'contracts_type', label: t('contract.subscription_method'), active: true, ordering_asc: null, sortable: true,
            displayValue: (value: any) => value?.name, nested_field: "contracts_type__name"
        },
        {
            order: 1, field: 'is_incentive_paid', label: t('contract.is_incentive_paid'), active: true, ordering_asc: null, sortable: true,
            displayValue: (value: any) => value ? t('insurance.yes') : t('insurance.no')
        },
        {
            order: 2, field: 'principal_product', label: t('contract.principal_product'), active: true, ordering_asc: null, sortable: true,
            displayValue: (value: any) => value?.name, nested_field: "principal_product__name"
        },
        {
            order: 3, field: 'total_incentive', label: t('incentive.title'), active: true, ordering_asc: null, sortable: true,
        },
        {
            order: 4, field: 'destinations', label: t('contract.destinations'), active: true, ordering_asc: null, sortable: true,
            displayValue: (value: any) => <p style={{ margin: 0, display: "flex", alignItems: "center", gap: 5 }}>{isSmallScreen ? <></> : <Place />}{value?.map(v => v.name || v.international_name).join(' - ')}</p>,
            displayExportValue: (value: any) => value?.map(v => v.name || v.international_name)?.join(', ') || '', nested_field: "destinations__name"
        },
        {
            order: 5, field: 'created_date', label: t("list.created_on"), sortable: true, active: true, ordering_asc: null,
            displayValue: (value: any) => value ? format(new Date(value), 'dd/MM/yyy') : ''
        }, {
            order: 6, field: 'travel_start_date', label: 'Date de départ', sortable: true, active: true, ordering_asc: null,
            displayValue: (value: any) => value ? format(new Date(value), 'dd/MM/yyy') : ''
        }
    ];

    const [reloader, setRealoader] = useState(0);
    const [search, setSearch] = useState('');
    const [columns, setColumns] = useState(columnsInit);
    const [totalIncentive, setTotalIncentive] = useState(0);
    const [filterPeriod, setFilterPeriod] = useState('');
    const [incentiveFilter, setIncentiveFilter] = useState<null | filterObjType>(null);
    const [agenciesList, setAgenciesList] = useState<OrganisationMinimal[] | null>([]);
    const [selectedAgency, setSelectedAgency] = useState<null | OrganisationMinimal>(null);
    const [periodType, setPeriodType] = useState('created_date');
    const [startDate, setStartDate] = useState<null | Date>(new Date());
    const [endDate, setEndDate] = useState<null | Date>(new Date());

    const viewDetail = (row: any) => {
        dispatch(setSelectedContract(row));
        navigate('/insurance/contracts/' + row.id);
    };
    const formatDate = (date: Date) => {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    };
    const getDatesForValue = (value: string) => {
        const today = new Date();
        let start_date = new Date();
        let end_date = new Date();
        switch (value) {
            case 'this_year':
                start_date.setMonth(0); // Set to January 1st of the current year
                start_date.setDate(1);
                end_date.setMonth(12);
                end_date.setDate(31);
                break;
            case 'custom':
                start_date = startDate;
                end_date = endDate;
                break;
            default:
                break;
        }

        return {
            start_date: value !== '' ? formatDate(start_date) : null,
            end_date: value !== '' ? formatDate(end_date || today) : null,
        };
    };
    const getDataIncentive = () => {
        const { start_date, end_date } = getDatesForValue(filterPeriod);
        let filter_obj:filterObjType = {};
        if (periodType === 'created_date') {
            filter_obj.created_date__gte = start_date !== null ? start_date + 'T00:00:00' : undefined;
            filter_obj.created_date__lte = end_date !== null ? end_date + 'T00:00:00' : undefined;
        } else {
            filter_obj.travel_start_date__gte = start_date !== null ? start_date : undefined;
            filter_obj.travel_start_date__lte = end_date !== null ? end_date : undefined;
        }
        if (selectedAgency !== null) {
            filter_obj.subscribing_agency__id = selectedAgency.id;
        } else if (profile?.is_network_director) {
            let agencies_id:number[] = [];
            profile?.client_list?.forEach(element => {
                agencies_id.push(element.id);
                filter_obj.subscribing_agency__ids = agencies_id.join(',')
            });
        } else if (!profile?.is_superuser && profile?.client_full?.name != "Valeurs Assurances" && !profile?.is_network_director) {
            filter_obj.subscribing_agency__id = profile?.client;
        }
        if (search !== '') {
            filter_obj.search = search;
        }
        setIncentiveFilter(filter_obj);
    };
    const formatPrice = (price?: number | null) => {
        return new Intl.NumberFormat(i18next.language, { style: 'currency', currency: 'EUR' }).format(price || 0);
    };
    const getAgencyList = () => {
        let list = []; 
        if (profile?.client_full?.name === "Valeurs Assurances") {
            getOrganisations(profile?.client).then((resp) => {
                setAgenciesList([ ...resp.data.results ]);
            })
        } else if (profile?.is_network_director) {
            setAgenciesList(profile?.client_list);
        }
    }
    const checkExist = (value: any) => {
        return value !== null && value !== undefined;
    };
    const handlePeriodType = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPeriodType((event.target as HTMLInputElement).value);
    };
    useEffect(() => {
        getDataIncentive();
        getAgencyList();
        return () => {
            dispatch(setIncentiveList([]));
        }
    }, []);
    useEffect(() => {
        if (permissions !== null && !checkPermissions('view_insurance')) {
            localStorage.removeItem('token');
            localStorage.removeItem('user_id');
            localStorage.removeItem('logged_user_client_id');
            navigate('/login');
        }
        if (profile?.is_superuser || profile?.client_full?.name === "Valeurs Assurances" || profile?.is_network_director) {
            const ag_idx = columnsInit.findIndex(column => column.field === "subscribing_agency");
            if (ag_idx === -1) columnsInit.splice(2, 0, agency_column);
        }
        if (profile?.is_superuser || profile?.client_full?.name === "Valeurs Assurances" || profile?.is_agency_director) {
            const creator_idx = columnsInit.findIndex(column => column.field === "creator");
            if (creator_idx === -1) columnsInit.splice(columnsInit.length - 1, 0, creator_column);
        }
        setColumns(columnsInit);
    }, [profile]);
    useEffect(() => {
        if (permissions !== null && !checkPermissions('view_insurance')) {
            localStorage.removeItem('token');
            localStorage.removeItem('user_id');
            localStorage.removeItem('logged_user_client_id');
            navigate('/login');
        }
    }, [permissions]);
    useEffect(() => {
        getDataIncentive(); 
    }, [filterPeriod, selectedAgency, search, periodType, startDate, endDate]);
    console.log('periodType:', periodType);
    return (
    <>
        <Grid item xs={12} style={{ marginBottom: 20 }}>
            <Paper elevation={1} sx={{padding: '20px'}}>
                <Grid container direction={"row"} justifyContent={'space-between'} alignItems={'center'}>
                    <Grid item>
                        <Typography className={'list-title'} variant={'h5'} component={'div'}>
                            {t('incentive.total_incentive', {total: formatPrice(totalIncentive)})}
                        </Typography>
                    </Grid>
                    {
                        (profile?.client_full?.name === "Valeurs Assurances" || profile?.is_network_director) &&
                        <Grid item xs={ 3 }>
                            <FormControl fullWidth>
                                <Autocomplete
                                    fullWidth
                                    value={selectedAgency}
                                    onChange={(event: any, value: any) => {
                                        setSelectedAgency(value)
                                    }}
                                    options={agenciesList}
                                    getOptionLabel={(option) => option.name}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label={t('contract.choose_agency')}
                                            InputProps={{
                                                ...params.InputProps
                                            }}
                                            size="small"
                                        />
                                    )}
                                />
                            </FormControl>
                        </Grid>
                    }
                    <Grid item>
                        <FormControl>
                            <RadioGroup
                                value={periodType}
                                name="radio-period-button"
                                onChange={handlePeriodType}
                            >
                                <FormControlLabel value="created_date" control={<Radio />} label="Date de création" />
                                <FormControlLabel value="started_date" control={<Radio />} label="Date de début de voyage" />
                            </RadioGroup>
                        </FormControl>
                    </Grid>
                    <Grid item xs={ profile?.client_full?.name !== "Valeurs Assurances" && !profile?.is_agency_director ? 4 : 3}>
                        <FormControl fullWidth>
                            <InputLabel id={`period-label`} size="small">{t('list.period_filters')}</InputLabel>
                            <Select labelId={`period-label`} label={t('list.period_filters')} fullWidth value={filterPeriod} onChange={(event) => setFilterPeriod(event.target.value)} inputProps={{
                                sx: {padding: '7px'}
                            }}
                            >
                                <MenuItem value={''}>{t('list.none')}</MenuItem>
                                <MenuItem value="this_year">{t('list.this_year')}</MenuItem>
                                <MenuItem value="custom">{'Période personalisée'}</MenuItem>
                            </Select>
                        </FormControl>
                        {
                            filterPeriod === 'custom' &&
                            <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={locales_cpy[i18next.language]}>
                                <Grid item container direction={'row'} justifyContent={'space-between'} sx={{ marginTop: '10px' }}>
                                    <Grid item xs>
                                        <DatePicker
                                            value={checkExist(startDate) ? startDate : null}
                                            onChange={(newValue) => setStartDate(newValue)}
                                            openTo={'day'}
                                            views={["year", "month", "day"]}
                                            renderInput={(props) => (
                                                <TextField
                                                    {...props}
                                                    InputLabelProps={{ shrink: checkExist(startDate) }}
                                                    sx={{
                                                        input: { padding: '7px 14px'}
                                                    }}
                                                    // fullWidth={true}
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs>
                                        <DatePicker
                                            value={checkExist(endDate) ? endDate : null}
                                            onChange={(newValue) => setEndDate(newValue)}
                                            minDate={checkExist(startDate) ? startDate : undefined}
                                            openTo={'day'}
                                            views={["year", "month", "day"]}
                                            renderInput={(props) => (
                                                <TextField
                                                    {...props}
                                                    InputLabelProps={{ shrink: checkExist(endDate) }}
                                                    sx={{
                                                        input: { padding: '7px 14px'}
                                                    }}
                                                    // fullWidth={true}
                                                />
                                            )}
                                        />
                                    </Grid>
                                </Grid>
                            </LocalizationProvider>

                        }
                    </Grid>
                </Grid>
            </Paper>
        </Grid>
        <Grid item xs={12} style={{ display: "flex", justifyContent: "center" }}>
            <TextField label={t('contract.search')}
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start"> <Search /> </InputAdornment>
                    ),
                    sx: { borderRadius: '35px' }
                }}
                variant="outlined"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                sx={{width: '50%'}}
            />
        </Grid>
        <Grid item xs={12}>
            <GenericList
                prefModuleName="VA_INCENTIVE"
                cols={columns}
                search={search}
                cachedList={incentive_list}
                setCachedList={(list) => {
                    dispatch(setIncentiveList(list));
                }}
                onRowClick={viewDetail}
                dataLoader={getIncentive}
                reloader={reloader}
                labels={{
                    title: "Incentives"
                }}
                incentiveFilter={incentiveFilter}
                setTotalIncentive={setTotalIncentive}
            />
        </Grid>
        <Finished />
    </>
    )
};

export default IncentivePage;
