import { useTranslation } from 'react-i18next';
import { LockOutlined, MailOutlined, Visibility, VisibilityOff } from "@mui/icons-material";
import { Button, Checkbox, FormControlLabel, Grid, IconButton, InputAdornment, TextField } from "@mui/material";
import { useSnackbar } from "notistack";
import React, { FC, ReactElement, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getPermissions, getUser, postLogin } from "../../services/commonServices";
import { RootState } from "../../store/store";
import '../../styles/authentification.css';
import { LoadingButton } from '@mui/lab';
import { setLoggedUserClientId, setPermissions, setProfile } from '../../store/reducer';

const LoginForm: FC<{ switchPage: (page: string) => void }> = ({ switchPage }): ReactElement => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation();

    const [showPassword, setShowPassword] = React.useState(false);
    const [email, setEmail] = React.useState('');
    const [password, setPassword] = React.useState('');
    const [loading, SetLoading] = useState(false);

    const client_id = useSelector((state: RootState) => state.app.client_id);

    const handleClickShowPassword = () => setShowPassword((show) => !show);
    const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    };

    const onLogin = () => {
        SetLoading(true);
        postLogin(client_id, email, password).then((response) => {
            const { token, user } = response.data;
            localStorage.setItem('token', token);
            localStorage.setItem('user_id', user.id);
            localStorage.setItem('logged_user_client_id', user.client);
            if(user?.client) dispatch(setLoggedUserClientId(user.client));
            getUser(user?.client || client_id, user.id).then((resp) => {
                dispatch(setProfile(resp.data));
                getPermissions(client_id, user.id).then((res) => {
                    dispatch(setPermissions(res.data));
                    navigate('/insurance/contracts');
                }).catch((error) => {
                    console.log('error : ', error.config);
                }).finally(() => SetLoading(false));
            }).catch((error) => {
                console.log('error : ', error.config);
            });
        }).catch((error) => {
            SetLoading(false);
            if (error.response) {
                if (error.response.status === 400) {
                    for (const [key, value] of Object.entries(error.response.data)) {
                        if (Array.isArray(value)) {
                            value.map(error => enqueueSnackbar(key === 'non_field_errors' ? error : `${key} : ${error}`, { variant: 'error' }));
                        } else {
                            enqueueSnackbar(key === 'non_field_errors' ? error : `${key} : ${value}`, { variant: 'error' });
                        }
                    }
                } else if (error.response.status === 401 || error.response.status === 403) {
                    enqueueSnackbar(t('errors.permissions'), { variant: 'error' });
                } else if (error.response.status === 500) {
                    enqueueSnackbar(t('errors.servers'), { variant: 'error' });
                }
            } else if (error.request) {
                console.log('error.request : ', error.request);
            } else {
                console.log('Error', error.message);
            }
            console.log('error.config : ', error.config);
        });
    };

    return (
        <>
            <div>
                <h3>{t('auth.login')}</h3>
                <p style={{ marginTop: 0 }}>{t('auth.login-info')}</p>
            </div>
            <div className="login-form-container-inputs">
                <TextField
                    fullWidth
                    label={t('auth.email')}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <MailOutlined />
                            </InputAdornment>
                        ),
                    }}
                    variant="standard"
                    placeholder={t('auth.email-placeholder')}
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                />
                <TextField
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    fullWidth
                    label={t('auth.password')}
                    type={showPassword ? "text" : "password"}
                    placeholder={t('auth.password-info')}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <LockOutlined />
                            </InputAdornment>
                        ),
                        endAdornment: (
                            <InputAdornment position="start">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword}
                                    onMouseDown={handleMouseDownPassword}
                                    edge="end"
                                >
                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                    variant="standard"
                />
                <div style={{ display: "flex", justifyContent: "flex-end", width: "100%" }}>
                    {/* <FormControlLabel control={<Checkbox />} label="Se souvenir de moi" /> */}
                    <Button variant="text" onClick={() => switchPage('FORGET')}>{t('auth.forget')}</Button>
                </div>
            </div>
            <LoadingButton loading={loading} color='secondary' variant="contained" onClick={onLogin}>{t('auth.confirm')}</LoadingButton>
        </>
    );
};

export default LoginForm;