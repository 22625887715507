import { AttachMoney, Dangerous, ListAlt, Loyalty, Receipt } from "@mui/icons-material";
import { Avatar, Box, Button, Container, IconButton, Menu, MenuItem, Paper, Popover, Tab, Tabs, Typography, useMediaQuery, useTheme } from "@mui/material";
import React, { FC, ReactElement, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import checkPermissions from "../../functions/checkPermissions";
import MenuIcon from '@mui/icons-material/Menu';
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { setLogout } from "../../store/reducer";

const InsurancePage: FC = (): ReactElement => {
    const { t } = useTranslation();
    const [value, setValue] = React.useState(0);
    const navigate = useNavigate();
    const location = useLocation();
    const theme = useTheme();
    const dispatch = useDispatch();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down(768));

    const profile = useSelector((state: RootState) => state.app.profile);

    const [freeze, setFreeze] = React.useState(false);
    const [anchorElPopover, setAnchorElPopover] = React.useState<HTMLButtonElement | null>(null);
    const handleClickPopover = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorElPopover(event.currentTarget);
    };
    const handleClosePopover = () => {
        setAnchorElPopover(null);
    };
    const openPopover = Boolean(anchorElPopover);
    const idPopover = openPopover ? 'simple-popover-Popover' : undefined;

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const logout = () => {
        dispatch(setLogout());
        setAnchorEl(null);
        localStorage.removeItem('token');
        localStorage.removeItem('user_id');
        localStorage.removeItem('logged_user_client_id');
        navigate('/login');
    };

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        if (freeze) return;
        setValue(newValue);
        let newRoute = '';
        switch (newValue) {
            case 0:
                newRoute = ``;
                break;
            case 1:
                newRoute = 'contracts'
                break;
            case 2:
                newRoute = 'incentive'
                break;
            case 3:
                newRoute = 'sinisters'
                break;
            case 4:
                newRoute = 'bills'
                break;
            default:
                break;
        }
        navigate(`/insurance/${newRoute}`);
    };

    useEffect(() => {
        if (location.pathname.includes('contract')) setValue(1);
        else if (location.pathname.includes('/incentive')) setValue(2);
        else if (location.pathname.includes('/sinisters')) setValue(3);
        else if (location.pathname.includes('/bills')) setValue(4);
        else if (location.pathname === '/insurance/') setValue(0);
        else setValue(5);
    }, [location]);

    useEffect(() => {
        if (location.pathname.includes('add-contract') || location.pathname.includes('edit-contract')) setFreeze(true);
        else setFreeze(false);
    }, [location]);

    return (
        <>
            <div className="va-app-bar">
                <img onClick={() => navigate('/')} style={{ cursor: "pointer" }} src="/images/VA_logo_blanc.png" alt="logo" />
                <div>

                    <Avatar style={{ backgroundColor: '#F6A200', cursor: "pointer" }} onClick={handleClickPopover}>{profile?.username?.charAt(0)?.toUpperCase()}</Avatar>
                    <Popover
                        id={idPopover}
                        open={openPopover}
                        anchorEl={anchorElPopover}
                        onClose={handleClosePopover}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}
                    >
                        <div style={{ padding: 15 }}>
                            <Typography variant="body1">@{profile?.username}</Typography>
                            <Typography variant="body2">{profile?.first_name} {profile?.last_name}</Typography>
                            <hr />
                            <Typography variant="subtitle1">{profile?.client_full?.name}</Typography>
                            <hr />
                            <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                                <Button onClick={() => navigate('/insurance/profile')}>Modifier le profil</Button>
                                {
                                    checkPermissions("view_user") &&
                                    <Button onClick={() => navigate('/insurance/users')}>Gestion des utilisateurs</Button>
                                }
                                <Button onClick={logout}>{t('logout')}</Button>
                            </div>
                        </div>
                    </Popover>
                    <IconButton
                        id="basic-button"
                        aria-controls={open ? 'basic-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                        onClick={handleClickPopover}
                    >
                        <MenuIcon style={{ fill: "white" }} />
                    </IconButton>
                </div>
            </div>
            <Paper elevation={3}>
                <Box sx={{ width: '100%', bgcolor: 'background.paper' }}>
                    <Tabs value={value} centered className={`homepage-tabs ${freeze ? 'homepage-tabs-disable' : ''}`}>
                        <Tab value={0} icon={<ListAlt />} onClick={(e) => handleChange(e, 0)} label={isSmallScreen ? '' : t('contract.menu_products')} />
                        <Tab value={1} icon={<Loyalty />} onClick={(e) => handleChange(e, 1)} label={isSmallScreen ? '' : t('contract.menu_subscriptions')} />
                        {
                            (profile?.is_agency_director || profile?.client_full?.name === "Valeurs Assurances" || profile?.is_network_director || profile?.client_full?.display_incentive) &&
                            <Tab value={2} icon={<AttachMoney />} onClick={(e) => handleChange(e, 2)} label={isSmallScreen ? '' : 'Incentive'} />
                        }
                        <Tab value={3} icon={<Dangerous />} onClick={(e) => handleChange(e, 3)} label={isSmallScreen ? '' : t('contract.menu_sinisters')} />
                        <Tab value={4} icon={<Receipt />} onClick={(e) => handleChange(e, 4)} label={isSmallScreen ? '' : t('contract.menu_bills')} />
                    </Tabs>
                </Box>
            </Paper>
            <Container className={"homepage-container"}>
                <Outlet />
            </Container>
        </>
    );
};

export default InsurancePage;