import React, { FC, ReactElement, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Button from '@mui/material/Button';
import ViewColumnIcon from '@mui/icons-material/ViewColumn';
import FilterListIcon from '@mui/icons-material/FilterList';
import Tooltip from '@mui/material/Tooltip';
import Popover from '@mui/material/Popover';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Refresh from '@mui/icons-material/Refresh';
import CloseIcon from '@mui/icons-material/Close';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { SaveAlt } from "@mui/icons-material";
import GenericField from "./genericField";
import { CircularProgress, Drawer, InputLabel, useMediaQuery, useTheme } from "@mui/material";
import ExportDialog from "./exportDialog";
import Box from '@mui/material/Box';
import 'jspdf-autotable';
import { FilterData } from "../../../types/genericModule/filterData";

const ListHandler: FC<{
    onChangeDensity?: (value: string) => void,
    onUpdateColumns?: (value: any) => void,
    refreshList?: () => void,
    onExport?: (type: string) => void,
    columns: any,
    filtersData?: FilterData[],
    filterChangeHandler?: (field: string, value: any, nested_field?: string) => void,
    applyFilterHandler?: (filters: FilterData[]) => void,
    resetFilterHandler?: () => void,
    handleClickDrawer: (event: any) => void,
    handleCloseDrawer: () => void,
    anchorElDrawer: any
}> = ({ columns, onUpdateColumns, onChangeDensity, onExport, refreshList, filtersData, filterChangeHandler, applyFilterHandler, resetFilterHandler, handleClickDrawer, handleCloseDrawer, anchorElDrawer }): ReactElement => {
    const { t } = useTranslation();

    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down(550));

    const [loading, setLoading] = React.useState(false);
    const [openExportModal, setOpenExportModal] = React.useState(false);

    //Column popover
    const [anchorEl, setAnchorEl] = useState(null);
    const handleClickColumnPopover = (event: any) => {
        setAnchorEl(event.currentTarget);
    };
    const handleCloseColumnPopover = () => {
        setAnchorEl(null);
    };
    const open = Boolean(anchorEl);

    //Density popover
    const [anchorElDensity, setAnchorElDensity] = useState(null);
    const [density, setDensity] = useState('high');
    const handleClickDensityPopover = (event: any) => {
        setAnchorElDensity(event.currentTarget);
    };
    const handleCloseDensityPopover = () => {
        setAnchorElDensity(null);
    };
    const setDensityValue = (value: string) => {
        setAnchorElDensity(null);
        setDensity(value);
        if (onChangeDensity !== undefined) {
            onChangeDensity(value);
        }
    };
    const openDensity = Boolean(anchorElDensity);


    //Custom filter
    const [filters, setFilters] = useState<any>(null);
    useEffect(() => {
        if (filtersData?.length && !filters?.length) {
            setFilters(filtersData.map(d => { return { ...d, value: null }; }));
        }
    }, [filtersData]);
    const handleChangeFilter = (index: number, value: any) => {
        const filter_c = JSON.parse(JSON.stringify(filters));
        if (filter_c && filter_c[index]) {
            filter_c[index].value = value;
            // if (filter_c[index].onChange !== undefined) {
            //     filter_c[index].onChange(value);
            // } 
            // else if (filterChangeHandler !== undefined) {
            //     filterChangeHandler(filter_c[index].field, value, filter_c[index].nested_field);
            // }
            setFilters(filter_c);
        }
    };

    //Reset all filter
    const resetFilter = () => {
        setFilters(filtersData?.map(d => { return { ...d, value: '' }; }));
        if (resetFilterHandler !== undefined) resetFilterHandler();
    };

    const applyFilter = () => {
        if (applyFilterHandler !== undefined) {
            applyFilterHandler(filters);
        }
    };

    const closeFilter = () => {
        // resetFilter();
        handleCloseDrawer();
    };

    //Set column order
    const setColumnOrder = (field: string, direction: string) => {
        const itemIndex = columns.findIndex((item: any) => item.field === field);
        if (itemIndex !== -1) {
            const updatedList = [...columns];
            const itemToMove = updatedList[itemIndex];
            updatedList.splice(itemIndex, 1);

            if (direction === 'up') {
                updatedList.splice(itemIndex - 1, 0, itemToMove);
            } else if (direction === 'down') {
                updatedList.splice(itemIndex + 1, 0, itemToMove);
            }

            if (onUpdateColumns !== undefined) {
                onUpdateColumns(updatedList);
            }
        }
    };

    //Set column order
    const switchColumnDisplay = (field: string) => {
        const itemIndex = columns.findIndex((item: any) => item.field === field);
        if (itemIndex !== -1) {
            const updatedList = [...columns];
            updatedList[itemIndex].active = !updatedList[itemIndex].active;
            if (onUpdateColumns !== undefined) {
                onUpdateColumns(updatedList);
            }
        }
    };

    const onExp = (type: string) => {
        setOpenExportModal(false);
        if (onExport !== undefined) onExport(type);
    };
    const onClickDrawer = (event: any) => {
        if (anchorElDrawer === null) {
            handleClickDrawer(event);
        } else {
            handleCloseDrawer();
        }
    }
    return (
        <div className="list-handler">
            <Tooltip title={t('list.columns_to_render')}>
                <Button variant="text" startIcon={<ViewColumnIcon />} onClick={handleClickColumnPopover}>{isSmallScreen ? '' : t('list.columns')}</Button>
            </Tooltip>
            <Popover
                open={open}
                anchorEl={anchorEl}
                onClose={handleCloseColumnPopover}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left'
                }}
            >
                <div className="column-settings">
                    <p>{t('list.column_title')}</p>
                    {
                        columns?.filter(c => c.label)?.map((column: any, col_index: number) => {
                            return (
                                <div key={`column-settings-${col_index}`}>
                                    <FormControlLabel control={<Checkbox checked={column.active} onChange={() => switchColumnDisplay(column.field)} />} label={column.label} />
                                    <div>
                                        {col_index !== 0 && <KeyboardArrowUpIcon onClick={() => setColumnOrder(column.field, 'up')} />}
                                        {col_index !== columns.length - 1 && <KeyboardArrowDownIcon onClick={() => setColumnOrder(column.field, 'down')} />}
                                        {col_index === columns.length - 1 && <div style={{ visibility: "hidden" }}><KeyboardArrowDownIcon /></div>}
                                    </div>
                                </div>
                            );
                        })
                    }
                    {/*<Button variant="outlined">{t('list.save')}</Button> */}
                </div>
            </Popover>
            {
                Boolean(filtersData) &&
                <Tooltip title={t('list.show_filters')}>
                    <Button className={'ft-list-blueTextButton'} variant="text" startIcon={<FilterListIcon />} onClick={onClickDrawer}>{isSmallScreen ? '' : t('list.filters')}</Button>
                </Tooltip>
            }
            {
                onExport !== undefined &&
                <Tooltip title={t('list.export')}>
                    <Button className={'ft-list-blueTextButton'} startIcon={<SaveAlt />} onClick={() => setOpenExportModal(true)}>{isSmallScreen ? '' : t('list.export')}</Button>
                </Tooltip>
            }
            <Tooltip title={t('list.refresh')}>
                <Button variant="text" startIcon={<Refresh />} onClick={refreshList}>{isSmallScreen ? '' : t('list.refresh')}</Button>
            </Tooltip>
            <Popover
                open={openDensity}
                anchorEl={anchorElDensity}
                onClose={handleCloseDensityPopover}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left'
                }}
            >
                <div className="density-settings">
                    <p>{t('list.set_density')}</p>
                    <div>
                        <Button variant="outlined" onClick={() => setDensityValue('high')} style={{ backgroundColor: density === 'high' ? '#d3d3d366' : '' }}>
                            {t('list.density_high')}
                        </Button>
                        <Button variant="outlined" onClick={() => setDensityValue('medium')} style={{ backgroundColor: density === 'medium' ? '#d3d3d366' : '' }}>
                            {t('list.density_medium')}
                        </Button>
                        <Button variant="outlined" onClick={() => setDensityValue('low')} style={{ backgroundColor: density === 'low' ? '#d3d3d366' : '' }}>
                            {t('list.density_low')}
                        </Button>
                    </div>
                    {/*<Button variant="outlined">{t('list.save')}</Button> */}
                </div>
            </Popover>
            <Drawer
                anchor={'right'}
                open={Boolean(anchorElDrawer)}
                onClose={handleCloseDrawer}
                variant={"persistent"}
                className={ 'ft-user-listFilter-navigation' }
                classes={{ paper: 'ft-user-listFilter-navigationPaper' }}    
            >
                <div className="filter-panel">
                    <div> <CloseIcon onClick={closeFilter} /> <h4>{t('list.filters')}</h4> </div>
                    {
                        filters?.map((filter: any, index: number) => {
                            if (filter.type === 'select') {
                                return (
                                    <React.Fragment key={`filter-${index}`}>
                                        <GenericField object={filters} field={`${index}.value`} onChange={(field, value) => handleChangeFilter(index, value)}
                                            label={filter.label} type="select" options={filter.options} size="small" />
                                    </React.Fragment>
                                );
                            }
                            if (filter.type === 'autocomplete') {
                                return (
                                    <React.Fragment key={`filter-${index}`}>
                                        <GenericField object={filters} field={`${index}.value`} onChange={(field, value) => handleChangeFilter(index, value)}
                                            label={filter.label} type="autocomplete" autocompleteGetter={filter.autocompleteGetter} getOptionLabel={filter.getOptionLabel} size="small" />
                                    </React.Fragment>
                                );
                            }
                            if (filter.type === 'period') {
                                return (
                                    <React.Fragment key={`filter-${index}`}>
                                        <FormControl fullWidth size="small">
                                            <InputLabel id={`filter-${index}`}>{filter.label}</InputLabel>
                                            <Select
                                                labelId={`filter-${index}`}
                                                value={filter.value || ''}
                                                onChange={(event) => handleChangeFilter(index, event.target.value)}
                                                label={filter.label}
                                            >
                                                <MenuItem value={''}>{t('list.none')}</MenuItem>
                                                <MenuItem value="current_week">{t('list.current_week')}</MenuItem>
                                                <MenuItem value="last_week">{t('list.last_week')}</MenuItem>
                                                <MenuItem value="current_month">{t('list.current_month')}</MenuItem>
                                                <MenuItem value="last_month">{t('list.last_month')}</MenuItem>
                                                <MenuItem value="last_30">{t('list.last_30')}</MenuItem>
                                                <MenuItem value="this_year">{t('list.this_year')}</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </React.Fragment>
                                );
                            }
                            return (
                                <React.Fragment key={`filter-${index}`}>
                                    <GenericField object={filters} field={`${index}.value`} onChange={(field, value) => handleChangeFilter(index, value)}
                                        label={filter.label} size="small" />
                                </React.Fragment>
                            );
                        })
                    }
                    <div>
                        <Button variant="outlined" onClick={resetFilter}>{t('list.reset')}</Button>
                        <Button variant="outlined" onClick={applyFilter}>{t('list.apply')}</Button>
                    </div>
                </div>
            </Drawer>
            <ExportDialog open={openExportModal} hideFull={true}
                onCloseCallback={() => setOpenExportModal(false)} onExportCallback={onExp} />
            {
                loading &&
                <div className="upload-progress">
                    <Box sx={{ width: '400px' }}>
                        <CircularProgress size={24} />
                    </Box>
                </div>
            }
        </div>
    );
};
export default ListHandler;