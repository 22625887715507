
import * as locales from 'date-fns/locale';
import { ArrowBackIosNew, Category } from "@mui/icons-material";
import { Grid, Button, Typography, Paper, useTheme, useMediaQuery, Switch } from "@mui/material";
import React, { FC, ReactElement, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { arrayPatch, getUserById, patchUser, postUser } from "../../services/userServices";
import { setPermissions, setSelectedUser } from "../../store/reducer";
import { RootState } from "../../store/store";
import Loading from '../common/loading/loading';
import { User } from '../../types/users/user';
import GenericField from '../common/genericList/genericField';
import { updateObjectField } from '../../functions/ObjectHandler';
import { useSnackbar } from 'notistack';
import checkPermissions from '../../functions/checkPermissions';

const UserDetail: FC = (): ReactElement => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const locales_cpy: { [key: string]: any } = locales;

    const { enqueueSnackbar } = useSnackbar();

    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down(600));

    const [user, setUser] = useState<User | null>(null);
    const [selected_permissions, setSelectedPermissions] = useState<string[]>([]);
    const [loading, setLoading] = useState(false);
    const client_id = useSelector((state: RootState) => state.app.profile?.client);

    const { id } = useParams();
    useEffect(() => {
        if (id && +id && client_id) {
            setLoading(true);
            getUserById(parseFloat(client_id), id).then((resp) => {
                if (resp.data) {
                    setLoading(false);
                    setUser(resp.data);
                    setSelectedPermissions(resp.data.perms?.filter(p => p.codename)?.map(p => p.codename) || []);
                }
            })
        } else {
            setUser(({} as any));
        }
    }, [id]);

    const updateField = (field: string, value: any) => {
        updateObjectField(user, field, value, setUser);
    };

    const validate = () => {
        if (user) {
            if (user.password && user.password !== user.confirm_password) {
                enqueueSnackbar('Mot de passe invalide', { variant: "error" });
                return;
            }

            const user_c = { id: user.id, username: user.username, email: user.email, first_name: user.first_name, last_name: user.last_name, password: user.password };
            if (user.id && id && +id) {
                patchUser(client_id, user.id, user_c)
                    .then(response => {
                        enqueueSnackbar('Utilisateur modifié', { variant: "success" });
                        postPermissions(response.data.id);
                    }).catch(error => {
                        setLoading(false);
                        if (error.response?.data?.errors) {
                            for (const [key, value] of Object.entries(error.response.data.errors)) {
                                if (Array.isArray(value)) {
                                    value.map(error => enqueueSnackbar(`${error}`, { variant: 'error' }));
                                } else {
                                    enqueueSnackbar(`${value}`, { variant: 'error' });
                                }
                            }
                        } else if (error.response) {
                            if (error.response.status === 400) {
                                for (const [key, value] of Object.entries(error.response.data)) {
                                    if (Array.isArray(value)) {
                                        value.map(error => enqueueSnackbar(`${key} : ${error}`, { variant: 'error' }));
                                    } else {
                                        enqueueSnackbar(`${key} : ${value}`, { variant: 'error' });
                                    }
                                }
                            } else if (error.response.status === 401 || error.response.status === 403) {
                                enqueueSnackbar(t('errors.permissions'), { variant: 'error' });
                            } else if (error.response.status === 500) {
                                enqueueSnackbar(t('errors.servers'), { variant: 'error' });
                            }
                        } else if (error.request) {
                            console.log('error.request : ', error.request);
                        } else {
                            console.log('Error', error.message);
                        }
                        console.log('error.config : ', error.config);
                    });
            } else {
                postUser(client_id, user_c)
                    .then(response => {
                        enqueueSnackbar('Utilisateur créé', { variant: "success" });
                        postPermissions(response.data.id);
                    }).catch(error => {
                        setLoading(false);
                        if (error.response?.data?.errors) {
                            for (const [key, value] of Object.entries(error.response.data.errors)) {
                                if (Array.isArray(value)) {
                                    value.map(error => enqueueSnackbar(`${error}`, { variant: 'error' }));
                                } else {
                                    enqueueSnackbar(`${value}`, { variant: 'error' });
                                }
                            }
                        } else if (error.response) {
                            if (error.response.status === 400) {
                                for (const [key, value] of Object.entries(error.response.data)) {
                                    if (Array.isArray(value)) {
                                        value.map(error => enqueueSnackbar(`${key} : ${error}`, { variant: 'error' }));
                                    } else {
                                        enqueueSnackbar(`${key} : ${value}`, { variant: 'error' });
                                    }
                                }
                            } else if (error.response.status === 401 || error.response.status === 403) {
                                enqueueSnackbar(t('errors.permissions'), { variant: 'error' });
                            } else if (error.response.status === 500) {
                                enqueueSnackbar(t('errors.servers'), { variant: 'error' });
                            }
                        } else if (error.request) {
                            console.log('error.request : ', error.request);
                        } else {
                            console.log('Error', error.message);
                        }
                        console.log('error.config : ', error.config);
                    });
            }
        }
    };

    const postPermissions = (user_id?: number) => {
        arrayPatch(client_id, selected_permissions, user_id)
            .then(response => {
                enqueueSnackbar('Permission mise à jour', { variant: "success" });
                setSelectedPermissions([]);
                setTimeout(() => navigate('/insurance/users/'), 1000)
            }).catch(error => {
                setLoading(false);
                if (error.response?.data?.errors) {
                    for (const [key, value] of Object.entries(error.response.data.errors)) {
                        if (Array.isArray(value)) {
                            value.map(error => enqueueSnackbar(`${error}`, { variant: 'error' }));
                        } else {
                            enqueueSnackbar(`${value}`, { variant: 'error' });
                        }
                    }
                } else if (error.response) {
                    if (error.response.status === 400) {
                        for (const [key, value] of Object.entries(error.response.data)) {
                            if (Array.isArray(value)) {
                                value.map(error => enqueueSnackbar(`${key} : ${error}`, { variant: 'error' }));
                            } else {
                                enqueueSnackbar(`${key} : ${value}`, { variant: 'error' });
                            }
                        }
                    } else if (error.response.status === 401 || error.response.status === 403) {
                        enqueueSnackbar(t('errors.permissions'), { variant: 'error' });
                    } else if (error.response.status === 500) {
                        enqueueSnackbar(t('errors.servers'), { variant: 'error' });
                    }
                } else if (error.request) {
                    console.log('error.request : ', error.request);
                } else {
                    console.log('Error', error.message);
                }
                console.log('error.config : ', error.config);
            });
    }

    const onTogglePermission = (event: { target: { name: any; checked: any; }; }, category: string) => {
        const selected_permissions_c = JSON.parse(JSON.stringify(selected_permissions));
        const name = event.target.name;
        const checked = event.target.checked;
        if (name.includes("all_")) {
            ["view_" + category, "add_" + category, "change_" + category, "delete_" + category].forEach(p => {
                if (checked) {
                    const index = selected_permissions_c.indexOf(p);
                    if (index === -1) {
                        selected_permissions_c.push(p);
                    }
                } else {
                    const index = selected_permissions_c.indexOf(p);
                    if (index !== -1) {
                        selected_permissions_c.splice(index, 1);
                    }
                }
            });
        } else if (name.includes("view_") && !checked) {
            ["view_" + category, "add_" + category, "change_" + category, "delete_" + category].forEach(p => {
                const index = selected_permissions_c.indexOf(p);
                if (index !== -1) {
                    selected_permissions_c.splice(index, 1);
                }
            });
        } else {
            if (checked) {
                const index = selected_permissions_c.indexOf(name);
                if (index === -1) {
                    selected_permissions_c.push(name);
                }
            } else {
                const index = selected_permissions_c.indexOf(name);
                if (index !== -1) {
                    selected_permissions_c.splice(index, 1);
                }
            }
        }
        setSelectedPermissions(selected_permissions_c);
    };

    const displayPermissions = (category: string, title: string) => {
        return (
            <>
                {
                    checkPermissions('view_' + category) &&
                    <Grid container item>
                        <Grid item xs={2}>
                            <Typography className={'ft-permissions-subtitle'}>{title}</Typography>
                        </Grid>
                        <Grid className={'ft-permissions-alignCenter'} item xs={2}>
                            <Switch checked={Boolean(selected_permissions.find(e => e == "view_" + category))} onChange={(e) => onTogglePermission(e, category)} color={'primary'} name={'view_' + category} />
                        </Grid>
                        <Grid className={'ft-permissions-alignCenter'} item xs={2}>
                            <Switch checked={Boolean(selected_permissions.find(e => e == "add_" + category))} onChange={(e) => onTogglePermission(e, category)} color={'primary'} name={'add_' + category} disabled={!(selected_permissions.find(e => e == "view_" + category) && checkPermissions('add_' + category))} />
                        </Grid>
                        <Grid className={'ft-permissions-alignCenter'} item xs={2}>
                            <Switch checked={Boolean(selected_permissions.find(e => e == "change_" + category))} onChange={(e) => onTogglePermission(e, category)} color={'primary'} name={'change_' + category} disabled={!(selected_permissions.find(e => e == "view_" + category) && checkPermissions('change_' + category))} />
                        </Grid>
                        <Grid className={'ft-permissions-alignCenter'} item xs={2}>
                            <Switch checked={Boolean(selected_permissions.find(e => e == "delete_" + category))} onChange={(e) => onTogglePermission(e, category)} color={'primary'} name={'delete_' + category} disabled={!(selected_permissions.find(e => e == "view_" + category) && checkPermissions('delete_' + category))} />
                        </Grid>
                        <Grid className={'ft-permissions-alignCenter'} item xs={2}>
                            <Switch checked={
                                Boolean(selected_permissions.find(e => e == "view_" + category)) &&
                                Boolean(selected_permissions.find(e => e == "add_" + category)) &&
                                Boolean(selected_permissions.find(e => e == "change_" + category)) &&
                                Boolean(selected_permissions.find(e => e == "delete_" + category))
                            } onChange={(e) => onTogglePermission(e, category)} color={'primary'} name={'all_' + category} />
                        </Grid>
                    </Grid>
                }
            </>
        )
    }

    if (!user) return <Loading />;
    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={isSmallScreen ? 12 : 2}>
                    <Button startIcon={<ArrowBackIosNew />} onClick={() => navigate('/insurance/users/')}>{t('shared.back')}</Button>
                </Grid>
                <Grid item xs={isSmallScreen ? 12 : 9}>
                    <Typography variant="h4" className="title" style={{ fontWeight: "bold", textAlign: "center" }}>{(id && +id && user.id) ? "Modification d'utilisateur" : "Création d'utilisateur"}</Typography>
                </Grid>
            </Grid>
            <Grid container spacing={2} alignItems={'flex-start'}>
                <Grid container item xs={12}>
                    <Paper variant='outlined' square style={{ padding: 16, width: "100%" }}>
                        <Grid container item xs={12} spacing={1}>
                            <Grid item xs={12}>
                                <h5>Informations Personnelles</h5>
                            </Grid>

                            <Grid item xs={12} lg={6}>
                                <GenericField onChange={updateField} field='username' object={user} label="Nom d'utilisateur" />
                            </Grid>
                            <Grid item xs={12} lg={6}>
                                <GenericField onChange={updateField} field='first_name' object={user} label="Prénom" />
                            </Grid>
                            <Grid item xs={12} lg={6}>
                                <GenericField onChange={updateField} field='last_name' object={user} label="Nom" />
                            </Grid>
                            <Grid item xs={12} lg={6}>
                                <GenericField onChange={updateField} field='email' object={user} label="Email" />
                            </Grid>
                            <Grid item xs={12} lg={6}>
                                <GenericField onChange={updateField} type='password' field='password' object={user} label="Mot de passe temporaire" />
                            </Grid>
                            <Grid item xs={12} lg={6}>
                                <GenericField onChange={updateField} type='password' field='confirm_password' object={user} label="Confirmer le mot de passe" />
                            </Grid>

                            <Grid item xs={12}>
                                <h5>Droits personnalisés</h5>
                            </Grid>
                            <Grid container item xs={12} spacing={1}>
                                <Grid container item>
                                    <Grid item xs={2}>
                                        <Typography variant={'h6'}></Typography>
                                    </Grid>
                                    <Grid className={'ft-permissions-alignCenter'} item xs={2}>
                                        <Typography>{t('permissions.see')}</Typography>
                                    </Grid>
                                    <Grid className={'ft-permissions-alignCenter'} item xs={2}>
                                        <Typography>{t('permissions.add')}</Typography>
                                    </Grid>
                                    <Grid className={'ft-permissions-alignCenter'} item xs={2}>
                                        <Typography>{t('permissions.edit')}</Typography>
                                    </Grid>
                                    <Grid className={'ft-permissions-alignCenter'} item xs={2}>
                                        <Typography>{t('permissions.delete')}</Typography>
                                    </Grid>
                                    <Grid className={'ft-permissions-alignCenter'} item xs={2}>
                                        <Typography>{t('permissions.all')}</Typography>
                                    </Grid>
                                </Grid>

                                {displayPermissions('user', "Utilisateurs")}
                                {displayPermissions('insuranceproduct', "Produits")}
                                {displayPermissions('insuranceproductfamily', "Famille")}
                                {displayPermissions('insuranceproductdiscount', "Remise / surprime")}
                            </Grid>

                            <Grid item xs={12} style={{ display: "flex", justifyContent: "flex-end", gap: 15 }}>
                                <Button onClick={() => navigate('/insurance/users/')}>Annuler</Button>
                                <Button variant='contained' onClick={validate}>Confirmer</Button>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
            </Grid>
            {loading && <Loading />}
        </>
    );
};

export default UserDetail;