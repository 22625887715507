import { Edit, Euro } from "@mui/icons-material";
import { Button, InputAdornment, Popover, TextField } from "@mui/material";
import React, { useEffect } from "react";
import { FC, ReactElement } from "react";
import { useTranslation } from "react-i18next";

const ContractFormTravelersPublicPriceInput: FC<{
    row: any,
    price: string,
    reduction?: string,
    is_reduction?: boolean,
    SetPrice: (price: string) => void,
}> = ({ row, price, reduction, is_reduction, SetPrice }): ReactElement => {

    const { t } = useTranslation();
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
    const [value, setValue] = React.useState<any>('0.0');

    useEffect(() => {
        setValue(parseFloat(price || "0").toFixed(2));
    }, [price]);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const updatePrice = () => {
        SetPrice(value);
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? `pop-${row.id}` : undefined;
    return (
        <div>
            <Button aria-describedby={id} endIcon={<Edit />} onClick={handleClick}>{value}</Button>
            {
                reduction !== undefined && parseFloat(reduction) !== 0 && isFinite(parseFloat(reduction)) &&
                <div>{`${is_reduction ? 'Remise' : 'Surprime'}: ${parseFloat(reduction).toFixed(2)}€`}</div>
            }
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                <div style={{ padding: 15, display: "flex", gap: 10 }}>
                    <TextField
                        variant="standard"
                        value={value} onChange={(e) => setValue(e.target.value)}
                        InputProps={{
                            endAdornment:
                                <InputAdornment position="end">
                                    <Euro color={parseFloat(value || '0') < parseFloat(row.net_price || '0') ? 'error' : 'inherit'} fontSize="small" />
                                </InputAdornment>,
                        }}
                        helperText={parseFloat(value || '0') < parseFloat(row.net_price || '0') ? 'prix invalide' : ''}
                        error={parseFloat(value || '0') < parseFloat(row.net_price || '0')}
                    />
                    <Button variant="contained" onClick={updatePrice}>{t('shared.confirm')}</Button>
                </div>
            </Popover>
        </div>
    );
};

export default ContractFormTravelersPublicPriceInput;