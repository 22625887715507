import { createTheme } from '@mui/material/styles';

const theme = createTheme({
    typography: {
        "fontFamily": `"ITC Officina Sans Std Book", "Baloo 2", "Arial", sans-serif`,
    },
    palette: {
        primary: {
            main: '#344482',
        },
        secondary: {
            main: '#F6A200',
        },
        info: {
            main: '#007CA9',
        },
        warning: {
            main: '#EC977A',
        },
    },
});

export default theme;