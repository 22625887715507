// Dependencies
import { useTranslation } from 'react-i18next';
import React, { FC, ReactElement, useEffect, useState } from 'react';
// Core & Lab
import { Grid } from '@mui/material';
import { Sinister } from '../../types/contract/sinister';
import GenericField from '../common/genericList/genericField';

const SinisterFormWarranty: FC<{ sinister: Sinister | null, updateFieldCallback: (field: string, value: any) => void }> = ({ sinister, updateFieldCallback }): ReactElement => {
    const { t } = useTranslation();

    const [selectedWarranty, setSelectedWarranty] = useState<any>(null);

    useEffect(() => {
        if(sinister && !selectedWarranty) {
            setSelectedWarranty(sinister.warranty);
        }
    }, [sinister]);

    const updateField = (field: string, value: any) => {
        if (updateFieldCallback !== undefined) updateFieldCallback(field, value);
    };
    const updateWarranty = (field: string, value: any) => {
        setSelectedWarranty(sinister?.policy?.principal_product?.warranty?.find(e => e.id === value));
        if (updateFieldCallback !== undefined) updateFieldCallback(field, value);
    };

    return (
        <Grid container spacing={2}>
            <Grid item xs={6}>
                <GenericField object={sinister} field={'warranty'} onChange={updateWarranty} label={`${t('sinisters.choose_warranty')}`}
                    type="select" options={sinister?.policy?.principal_product?.warranty?.map(w => { return { ...w, label: w.name, value: w.id }; })} />
            </Grid>
            <Grid item xs={6}>
                <GenericField object={sinister} field={'motif'} onChange={updateField} label={`${t('warranty.motifs')}`}
                    type="select" options={selectedWarranty?.motifs?.map(m => {
                        return { value: m.id, label: m.motif };
                    })} readonly={!selectedWarranty?.id} />
            </Grid>
        </Grid>
    );

};

export default SinisterFormWarranty;