import { Visibility } from "@mui/icons-material";
import { Button, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import { useSnackbar } from "notistack";
import React from "react";
import { FC, ReactElement } from "react";
import { useSelector } from "react-redux";
import { dowloadDocument } from "../../services/commonServices";
import { RootState } from "../../store/store";
import { Contract } from "../../types/contract/contract";
import { useTranslation } from "react-i18next";
import { AgencyIncentive } from "../../types/insurance/insurance-product";

const ContractFormAdditionnalInfo: FC<{
    contract: Contract,
    incentive: AgencyIncentive | null,
    subscriberIndex: number
}> = ({ contract, incentive, subscriberIndex }): ReactElement => {

    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();

    const client_id = useSelector((state: RootState) => state.app.client_id);

    const download = (file: any) => {
        dowloadDocument(client_id, file.file_name).then((response) => {
            if (response.data) {
                const url = URL.createObjectURL(response.data);
                window.open(url, '_blank');
            }
        }).catch((error) => {
            console.log(error);
        });
    };

    return (
        <>
            <Grid item xs={12}>
                <Typography variant="subtitle1" className="title">{t('contract.products_info')}</Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography variant="subtitle2" className="title" style={{ margin: 0 }}>{t('contract.principal_product')}</Typography>
            </Grid>
            <Grid item xs={12}>
                <Paper elevation={3} style={{ padding: 16 }}>
                    <Typography variant="subtitle1" className="title orange-title">{contract.principal_product?.name}</Typography>
                    <p>{contract.principal_product?.description}</p>
                    {
                        contract.principal_product?.documents?.map((doc) => {
                            return (
                                <div key={"principal_product-doc" + doc.id} style={{ display: "flex", justifyContent: "space-between" }}>
                                    <p>{doc.name}</p>
                                    <Button startIcon={<Visibility />} onClick={() => download(doc)}>{t('contract.view_detail')}</Button>
                                </div>
                            )
                        })
                    }
                </Paper>
            </Grid>
            {
                Boolean(contract.secondary_product?.id) &&
                <>
                    <Grid item xs={12}>
                        <Typography variant="subtitle2" className="title" style={{ margin: 0 }}>{t('contract.secondary_product')}</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Paper elevation={3} style={{ padding: 16 }}>
                            <Typography variant="subtitle1" className="title orange-title">{contract.secondary_product?.name}</Typography>
                            {/* <p>{contract.secondary_product?.note}</p> */}
                            {
                                contract.secondary_product?.documents?.map((doc) => {
                                    return (
                                        <div key={"secondary_product-doc" + doc.id} style={{ display: "flex", justifyContent: "space-between" }}>
                                            <p>{doc.name}</p>
                                            <Button startIcon={<Visibility />} onClick={() => download(doc)}>{t('contract.view_detail')}</Button>
                                        </div>
                                    )
                                })
                            }
                        </Paper>
                    </Grid>
                </>
            }
            {
                Boolean(contract.product_extension?.length) &&
                <>
                    <Grid item xs={12}>
                        <Typography variant="subtitle2" className="title" style={{ margin: 0 }}>{t('contract.extension')}</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Paper sx={{padding: '10px'}}>
                            <Typography sx={{textAlign: 'center', fontWeight: 'bold'}}>{'Récapitulatif prix'}</Typography>
                            <TableContainer>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>{"Extension"}</TableCell>
                                            <TableCell align="right">{'Prix NET'}</TableCell>
                                            <TableCell align="right">{'Prix de vente'}</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {
                                            contract.product_extension?.map((extension) => {
                                                let extension_price = 0;
                                                let extension_price_net = 0;
                                                contract.travelers.forEach((trav, index) => {
                                                    const price_found = extension.prices?.find(p => p.min_slice <= trav.price && trav.price <= p.max_slice);
                                                    const agency_price_found = extension.agencies_prices?.find(p => p.min_slice <= trav.price && trav.price <= p.max_slice && (p.agency?.id || p.agency) == contract.subscribing_agency);    
                                                    if (agency_price_found?.price_type !== 'PER' && price_found?.price_type !== 'PER') {
                                                        extension_price += parseFloat(agency_price_found?.recommended_price || agency_price_found?.selling_price || price_found?.recommended_price || price_found?.selling_price || "0");
                                                        extension_price_net += parseFloat(agency_price_found?.selling_price || price_found?.selling_price || "0");
                                                    } else if (agency_price_found?.price_type !== 'PER' || price_found?.price_type !== 'PER') {
                                                        extension_price += (parseFloat(agency_price_found?.recommended_price || agency_price_found?.selling_price || price_found?.recommended_price || price_found?.selling_price || "0") / 100) * trav.price;
                                                        extension_price_net += (parseFloat(agency_price_found?.selling_price || price_found?.selling_price || "0") / 100) * trav.price;
                                                    }
                                                })
                                                return (
                                                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 }, padding: '5px' }}>
                                                        <TableCell component="th" scope="row">{extension.name}</TableCell>
                                                        <TableCell align="right">{extension_price_net.toFixed(2)}€</TableCell>
                                                        <TableCell align="right">{extension_price.toFixed(2)}€</TableCell>
                                                    </TableRow>
                                                )
                                            })
                                        }
                                        <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 }, padding: '5px' }}>
                                            <TableCell component="th" scope="row" sx={{fontWeight: 'bold'}}>{'TOTAL'}</TableCell>
                                            <TableCell align="right">{(parseFloat(contract.travelers?.reduce((acc, t: any) => acc + parseFloat(t.extension_price_net || '0'), 0).toFixed(2))).toFixed(2)}€</TableCell>
                                            <TableCell align="right">{(parseFloat(contract.travelers?.reduce((acc, t: any) => acc + parseFloat(t.extension_price || '0'), 0).toFixed(2))).toFixed(2)}€</TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Paper>
                        {
                            // contract.product_extension?.map((extension) => {
                            //     return (
                            //         <Paper elevation={3} key={'ext-detail-' + extension.id} style={{ padding: 16, marginBottom: 10 }}>
                            //             <Typography variant="subtitle1" className="title orange-title">{extension.name}</Typography>
                            //             {/* <p>{extension.note}</p> */}
                            //             {
                            //                 extension.documents?.map((doc) => {
                            //                     return (
                            //                         <div key={"product_extension-doc" + doc.id} style={{ display: "flex", justifyContent: "space-between" }}>
                            //                             <p>{doc.name}</p>
                            //                             <Button startIcon={<Visibility />} onClick={() => download(doc)}>{t('contract.view_detail')}</Button>
                            //                         </div>
                            //                     )
                            //                 })
                            //             }
                            //         </Paper>

                            //     );
                            // })
                        }
                    </Grid>
                </>
            }
            {
                incentive &&
                <>
                    <Grid item xs={12}>
                        <Typography variant="subtitle2" className="title" style={{ margin: 0 }}>{t('contract.incentive')}</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Paper elevation={3} style={{ padding: 16, marginBottom: 10 }}>
                            <p><b>{`${t('incentive.amount')} ${incentive.per_traveler ? 'par pax' : 'par dossier'}`} : </b>{incentive.amount} {incentive.bonus_type === 'FIX' ? '€' : '%'}</p>
                            {/* <p><b>{t('incentive.bonus_short')} : </b>{incentive.is_re_invoiced ? t('shared.yes') : t('shared.no')}</p> */}
                            <p><b>Incentive total : </b>{parseFloat(contract.travelers?.reduce((acc, t: any) => acc + parseFloat(t.incentive || '0'), 0).toFixed(2))}€</p>
                            {/* <p><b>Par Pax : </b>{incentive.per_traveler ? t('shared.yes') : t('shared.no')}</p> */}
                        </Paper>
                    </Grid>
                </>
            }
        </>
    );
};

export default ContractFormAdditionnalInfo;