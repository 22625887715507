import axios, { AxiosResponse, CancelTokenSource } from "axios";
import { Contract } from "../types/contract/contract";
import { ContractType } from "../types/contract/contractType";
import { store } from '../store/store';

let cancelToken: CancelTokenSource | null = null;
export const getContracts = (client_id: number | null, limit: number, offset: number, filter?: any): Promise<AxiosResponse<{ count: number; results: Contract[]; }>> => {
    if (cancelToken) {
        cancelToken.cancel("Operation canceled due to new request.");
    }
    cancelToken = axios.CancelToken.source();
    let handledFilter = {};
    if (filter) {
        handledFilter = { ...filter };
    }
    return axios.get(
        `${API_HREF}client/${client_id}/insurances-contract/`,
        {
            params: {
                limit,
                offset,
                ...handledFilter,
                subscribing_agency__id: (
                    (store.getState().app.profile?.is_superuser || store.getState().app.profile?.client_full?.name === "Valeurs Assurances")
                        ? undefined
                        : store.getState().app.profile?.client
                ) || undefined,
            },
            cancelToken: cancelToken.token,
            headers: {
                'Authorization': `Token ${localStorage.getItem('token')}`,
            }
        }
    );
};

export const getContractById = (client_id: number | null, contract_id: string | undefined): Promise<AxiosResponse<Contract>> => {
    if (cancelToken) {
        cancelToken.cancel("Operation canceled due to new request.");
    }
    cancelToken = axios.CancelToken.source();
    return axios.get(
        `${API_HREF}client/${client_id}/insurances-contract/${contract_id}/`,
        {
            params: {},
            cancelToken: cancelToken.token,
            headers: {
                'Authorization': `Token ${localStorage.getItem('token')}`,
            }
        }
    );
};

export const postContract = (client_id: number | null, contract: Contract): Promise<AxiosResponse<Contract>> => {
    if (cancelToken) {
        cancelToken.cancel("Operation canceled due to new request.");
    }
    cancelToken = axios.CancelToken.source();
    return axios.post(
        `${API_HREF}client/${client_id}/insurances-contract/`,
        contract,
        {
            cancelToken: cancelToken.token,
            headers: {
                'Authorization': `Token ${localStorage.getItem('token')}`,
            }
        }
    );
};

export const patchContract = (client_id: number | null, contractId: number | undefined, data: any): Promise<AxiosResponse<Contract>> => {
    if (cancelToken) {
        cancelToken.cancel("Operation canceled due to new request.");
    }
    cancelToken = axios.CancelToken.source();
    return axios.patch(
        `${API_HREF}client/${client_id}/insurances-contract/${contractId}/`,
        data,
        {
            cancelToken: cancelToken.token,
            headers: {
                'Authorization': `Token ${localStorage.getItem('token')}`,
            }
        }
    );
};

export const putContract = (client_id: number | null, contract: Contract): Promise<AxiosResponse<Contract>> => {
    if (cancelToken) {
        cancelToken.cancel("Operation canceled due to new request.");
    }
    cancelToken = axios.CancelToken.source();
    return axios.put(
        `${API_HREF}client/${client_id}/insurances-contract/${contract.id}/`,
        contract,
        {
            cancelToken: cancelToken.token,
            headers: {
                'Authorization': `Token ${localStorage.getItem('token')}`,
            }
        }
    );
};

export const getContractTypes = (client_id: number | null): Promise<AxiosResponse<{ count: number; results: ContractType[]; }>> => {
    // if (cancelToken) {
    //     cancelToken.cancel("Operation canceled due to new request.");
    // }
    // cancelToken = axios.CancelToken.source();
    return axios.get(
        `${API_HREF}client/${client_id}/value-insurances-product-type/`,
        {
            // cancelToken: cancelToken.token,
            headers: {
                'Authorization': `Token ${localStorage.getItem('token')}`,
            }
        }
    );
};

export const postContractType = (client_id: number | null, contractType: ContractType): Promise<AxiosResponse<ContractType>> => {
    return axios.post(
        `${API_HREF}client/${client_id}/value-insurances-product-type/`,
        contractType,
        {
            headers: {
                'Authorization': `Token ${localStorage.getItem('token')}`,
            }
        }
    );
};

// ANOMALIES
export const getAnomalies = (client_id: number | null, limit: number, offset: number, filter?: any): Promise<AxiosResponse<{ count: number; results: Contract[]; }>> => {
    if (cancelToken) {
        cancelToken.cancel("Operation canceled due to new request.");
    }
    cancelToken = axios.CancelToken.source();
    let handledFilter = {};
    if (filter) {
        handledFilter = { ...filter };
    }
    return axios.get(
        `${API_HREF}client/${client_id}/insurances-contract/anomaly/`,
        {
            params: {
                limit,
                offset,
                ...handledFilter,
                subscribing_agency__id: (
                    (store.getState().app.profile?.is_superuser || store.getState().app.profile?.client_full?.name === "Valeurs Assurances")
                        ? undefined
                        : store.getState().app.profile?.client
                ) || undefined,
            },
            cancelToken: cancelToken.token,
            headers: {
                'Authorization': `Token ${localStorage.getItem('token')}`,
            }
        }
    );
};