import { Search } from "@mui/icons-material";
import { Grid, InputAdornment, TextField } from "@mui/material";
import React, { FC, ReactElement, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import checkPermissions from "../../functions/checkPermissions";
import { setUserList } from "../../store/reducer";
import { RootState } from "../../store/store";
import GenericList from "../common/genericList/genericList";
import Finished from "../common/loading/finished";
import { getUsers } from "../../services/userServices";
import { format } from "date-fns";

const UsersPage: FC = (): ReactElement => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const selected_user = useSelector((state: RootState) => state.app.selected_user);
    const user_list = useSelector((state: RootState) => state.app.user_list);
    const price_index = useSelector((state: RootState) => state.app.price_index);
    const profile = useSelector((state: RootState) => state.app.profile);

    const [reloader, setRealoader] = useState(0);
    const [search, setSearch] = useState("");

    const agency_column = {
        order: 1, field: 'client_full', label: "Organisation", active: true, ordering_asc: null,
        displayValue: (value: any) => value?.name
    };
    const columnsInit: any = [
        { order: 1, field: 'first_name', label: "Prénom(s)", sortable: true, active: true, ordering_asc: null },
        { order: 2, field: 'last_name', label: "Nom", sortable: true, active: true, ordering_asc: null },
        { order: 3, field: 'username', label: "Nom d'utilisateur", sortable: true, active: true, ordering_asc: null },
        { order: 4, field: 'email', label: "Email", sortable: true, active: true, ordering_asc: null },
        {
            order: 5, field: 'groups', label: "Rôle", sortable: false, active: true, ordering_asc: null,
            displayValue: (value: any) => value?.name
        },
        {
            order: 6, field: 'date_joined', label: "Date de création", sortable: false, active: true, ordering_asc: null,
            displayValue: (value: any) => value ? format(new Date(value), 'dd/MM/yyy') : ''
        },
    ];

    const [columns, setColumns] = useState(columnsInit);
    useEffect(() => {
        if (!checkPermissions('view_insurance')) {
            navigate('/login');
        }
    }, []);
    useEffect(() => {
        if (profile?.is_superuser || profile?.client_full?.name === "Valeurs Assurances") {
            const ag_idx = columnsInit.findIndex(column => column.field === "subscribing_agency");
            if (ag_idx === -1) columnsInit.splice(2, 0, agency_column);
        }
        setColumns(columnsInit);
    }, [profile]);
    useEffect(() => {
        setColumns(columnsInit);
        setRealoader(reloader + 1);
    }, [price_index]);

    const onRowClick = (item: any) => {
        if (checkPermissions("change_user")) {
            navigate(`/insurance/user/${item.id}`);
        }
    };

    return (
        <>
            <Grid item xs={12} style={{ display: "flex", justifyContent: "center" }}>
                <TextField label={"Rechercher un utilisateur"}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start"> <Search /> </InputAdornment>
                        ),
                        sx: { borderRadius: '35px' }
                    }}
                    variant="outlined"
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                    sx={{width: '50%'}}
                />
            </Grid>
            <GenericList
                prefModuleName="VA_USERS"
                reloader={reloader}
                cols={columns}
                search={search}
                dataLoader={getUsers}
                addPermission='add_user'
                onRowClick={onRowClick}
                onAddClick={() => navigate(`/insurance/user/0`)}
                selected_row={selected_user}
                cachedList={user_list}
                setCachedList={(list) => dispatch(setUserList(list))}
                labels={{
                    title: "Utilisateurs",
                    add_button: "Ajouter un utilisateur"
                }}
            />
            <Finished />
        </>
    );
};

export default UsersPage;