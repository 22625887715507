// Dependencies
import { useTranslation } from "react-i18next";
import React, { FC, ReactElement, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useSnackbar } from "notistack";
import i18next from "i18next";
import { format } from "date-fns";
import * as locales from 'date-fns/locale';
import SinisterForm from "./sinisterForm";
import { cleanSinister } from "../../functions/contract";
import { patchSinister } from "../../services/sinisterServices";
import { setSelectedSinister } from "../../store/reducer";
import { RootState } from "../../store/store";
import { Sinister } from "../../types/contract/sinister";
import GenericPanel from "../common/genericList/genericPanel";

const SinisterContract: FC = (): ReactElement => {
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const dispatch = useDispatch();
    const locales_cpy: { [key: string]: any } = locales;

    const selected_sinister = useSelector((state: RootState) => state.app.selected_sinister);
    const client_id = useSelector((state: RootState) => state.app.client_id);

    const [edit_step, setEditStep] = useState<string | null>(null);
    const [openCancel, setOpenCancel] = useState(false);

    const onClose = () => {
        setEditStep(null);
    };

    const onEdit = (sinister: Sinister) => {
        const sinisterToEdit: any = cleanSinister(sinister);
        patchSinister(client_id, sinisterToEdit.id, sinisterToEdit)
            .then((response) => {
                enqueueSnackbar(t('insurance.sinister-updated'), { variant: 'success' });
                setEditStep(null);
                dispatch(setSelectedSinister(response?.data));
            }).catch((error) => {
                if (error.response) {
                    if (error.response.status === 400) {
                        for (const [key, value] of Object.entries(error.response.data)) {
                            if (Array.isArray(value)) {
                                value.map(error => enqueueSnackbar(`${key} : ${error}`, { variant: 'error' }));
                            } else {
                                enqueueSnackbar(`${key} : ${value}`, { variant: 'error' });
                            }
                        }
                    } else if (error.response.status === 401 || error.response.status === 403) {
                        enqueueSnackbar(t('errors.permissions'), { variant: 'error' });
                    } else if (error.response.status === 500) {
                        enqueueSnackbar(t('errors.servers'), { variant: 'error' });
                    }
                } else if (error.request) {
                    console.log('error.request : ', error.request);
                } else {
                    console.log('Error', error.message);
                }
                console.log('error.config : ', error.config);
            });
    };

    if (edit_step) {
        return (
            <>
                <SinisterForm mode="edit" onConfirm={onEdit} onConfirmLabel={t('insurance.edit-sinister')} onCancel={onClose} display={[edit_step]} />
            </>
        );
    }
    else if (selected_sinister !== null) {
        return (
            <>
                <GenericPanel mainTitle={t("sinisters.contract")} readOnly
                    fields={[
                        { title: t("sinisters.contract_reference"), value: selected_sinister.policy?.id ? `N°${selected_sinister.policy?.id}` : '' },
                        { title: t("sinisters.contract_date"), value: selected_sinister.policy?.created_date ? format(new Date(selected_sinister.policy?.created_date), 'P', { locale: locales_cpy[i18next.language] }) : '' },
                        { title: t("sinisters.contract_destination"), value: selected_sinister.policy?.destinations?.map(d => d.name || d.international_name) },
                        {
                            title: t("sinisters.contract_principal_product"), gridSize: 6,
                            value: selected_sinister.policy?.principal_product?.name
                        },
                        {
                            title: t("sinisters.contract_secondary_product"), gridSize: 6,
                            value: selected_sinister.policy?.secondary_product?.name
                        },
                        {
                            title: t("sinisters.contract_extensions"), gridSize: 6,
                            value: selected_sinister.policy?.product_extension?.map(pr => pr.name)?.join(', ')
                        }
                    ]}
                />
                <GenericPanel mainTitle={t("sinisters.contact_info")} editStepCallback={() => setEditStep("CONTACT")} readOnly={true}
                    fields={[
                        { title: t("sinisters.contact_title"), value: selected_sinister.policy?.subscriber?.title },
                        { title: t("sinisters.contact_first_name"), value: selected_sinister.policy?.subscriber?.first_name },
                        { title: t("sinisters.contact_last_name"), value: selected_sinister.policy?.subscriber?.last_name },
                        { title: t("sinisters.contact_phone_number"), value: selected_sinister.policy?.subscriber?.phone_number },
                        { title: t("sinisters.contact_email"), value: selected_sinister.policy?.subscriber?.email },
                    ]}
                />
            </>
        );
    }
    return <></>;
};

export default SinisterContract;
