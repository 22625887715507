import { useTranslation } from 'react-i18next';
import { LockOutlined, MailOutlined, Visibility, VisibilityOff } from "@mui/icons-material";
import { Button, Checkbox, FormControlLabel, Grid, IconButton, InputAdornment, TextField } from "@mui/material";
import { useSnackbar } from "notistack";
import React, { FC, ReactElement, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getPermissions, postLogin } from "../../services/commonServices";
import { RootState } from "../../store/store";
import '../../styles/authentification.css';
import { LoadingButton } from '@mui/lab';
import LoginForm from './LoginForm';
import ForgetForm from './ForgetForm';
import ResetForm from './ResetForm';

const LoginPage: FC = (): ReactElement => {
    const { t } = useTranslation();
    const [resetToken, setResetToken] = useState<string|null>(null);
    const [resetId, setResetId] = useState<number|null>(null);
    const [formType, setFormType] = React.useState<string>("SIGNIN");
    const switchPage = (page: string) => {
        console.log('page', page);
        setFormType(page);
    };
    useEffect(() => {
        const query = new URLSearchParams(window.location.search);
        if (query.get('token') !== undefined && query.get('token') !== null && query.get('id') !== undefined && query.get('id') !== null) {
            setFormType('RESET');
            setResetId(parseInt(query.get('id')));
            setResetToken(query.get('token'));
        }
    }, []);
    return (
        <>
            <img className="bg-img" src="images/bg-log.png" alt="" />
            <div className="auth-container">
                <div className="overlay"></div>
                <Grid container>
                    <Grid item xs={12} md={5} lg={7}>
                        <div style={{ display: "flex", gap: 10 }}>
                            <img className="logo" src="images/VA_logo_blanc.png" alt="" />
                        </div>
                        <h1>{t('auth.va')}</h1>
                    </Grid>
                    <Grid item xs={12} md={7} lg={5} className="login-form-container">
                        {
                            formType === "SIGNIN" &&
                            <LoginForm switchPage={switchPage} />
                        }
                        {
                            formType === "FORGET" &&
                            <ForgetForm switchPage={switchPage} />
                        }
                        {
                            formType === "RESET" &&
                            <ResetForm switchPage={switchPage} token={resetToken} user_id={resetId}/>
                        }
                    </Grid>
                </Grid>
            </div>
        </>
    );
};

export default LoginPage;