// Dependencies
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import React, { FC, ReactElement, useEffect, useState } from 'react';
import { format } from 'date-fns';
import { Chip, Grid, InputAdornment, TextField, Tooltip } from '@mui/material';
import { RootState } from '../../store/store';
import { FilterData } from '../../types/genericModule/filterData';
import GenericList from '../common/genericList/genericList';
import Finished from '../common/loading/finished';
import { setSelectedSinister, setSinisterList } from '../../store/reducer';
import { getProviders } from '../../services/commonServices';
import { getSinisters } from '../../services/sinisterServices';
import SinisterCreateForm from './sinisterCreateForm';
import { Circle, Search } from '@mui/icons-material';
import { OrganisationMinimal } from '../../types/organisationMinimal';
import { Contract } from '../../types/contract/contract';
import { Sinister } from '../../types/contract/sinister';
import checkPermissions from '../../functions/checkPermissions';

const SinistersList: FC = (): ReactElement => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const selected_sinister = useSelector((state: RootState) => state.app.selected_sinister);
    const sinister_list = useSelector((state: RootState) => state.app.sinister_list);
    const profile = useSelector((state: RootState) => state.app.profile);
    const permissions =  useSelector((state: RootState) => state.app.permissions);

    const [openAdd, setOpenAdd] = useState(false);
    const [search, setSearch] = useState("");
    const agency_column: any = {
        order: 1, field: 'policy_agency', label: t('contract.agency'), active: true, ordering_asc: null,
        displayValue: (value: Contract, row: Sinister) => row?.policy?.subscribing_agency?.name
    };
    const columnsInit: any = [
        {
            order: 1, field: 'status', label: t("sinisters.status"), sortable: true, active: true,
            // eslint-disable-next-line react/display-name
            displayValue: (value: number) => (
                <Tooltip title={t(`sinisters.status_${value}`)}>
                    <Circle style={{ fill: getStatusColor(value) }} />
                </Tooltip>
            ),
            displayExportValue: (value: string) => t(`sinisters.status_${value}`),
        },
        { order: 2, field: 'id', label: t("sinisters.reference"), sortable: true, active: true },
        {
            order: 3, field: 'policy', label: t("sinisters.policy"), sortable: true, active: true,
            displayValue: (value: any) => value?.reference, nested_field: 'policy__reference'
        },
        {
            order: 3, field: 'warranty', label: t("contract.warranty"), sortable: true, active: true,
            displayValue: (value: any) => value?.name, nested_field: "warranty__name"
        },
        {
            order: 4, field: 'policy_agency', label: t('sinisters.agency'), active: true, ordering_asc: null, sortable: true,
            displayValue: (value: Contract, row: Sinister) => row?.policy?.subscribing_agency?.name, nested_field: "policy__subscribing_agency__name"
        },
        {
            order: 5, field: 'policy', label: t("sinisters.beneficiary_traveler"), active: true,
            displayValue: (value: any) => { return `${value.subscriber?.first_name ? value.subscriber.first_name : ''} ${value.subscriber?.last_name ? value.subscriber.last_name : ''}`; }
        },
        {
            order: 6, field: 'claim_date', label: t("sinisters.claim_date"), sortable: true, active: true,
            displayValue: (value: any) => value ? format(new Date(value), 'dd/MM/yyy') : null
        },
        {
            order: 7, field: 'policy.destinations', label: t("sinisters.destination"), sortable: true, active: true,
            displayValue: (value: any, row: any) => row.policy?.destinations?.map(e => e?.name || e?.international_name)?.join(', '), nested_field: "policy__destinations__international_name"
        },
        {
            order: 8, field: 'creator', label: t("list.created_by"), sortable: true, active: false, ordering_asc: null,
            displayValue: (value: any) => value?.username, nested_field: "creator__username"
        },
        {
            order: 9, field: 'created_date', label: t("list.created_on"), sortable: true, active: false, ordering_asc: null,
            displayValue: (value: any) => value ? format(new Date(value), 'dd/MM/yyy') : ''
        },
        {
            order: 10, field: 'last_author', label: t("list.modified_by"), sortable: true, active: false, ordering_asc: null,
            displayValue: (value: any) => value?.username, nested_field: "last_author__username"
        },
        {
            order: 11, field: 'modified_date', label: t("list.modified_on"), sortable: true, active: false, ordering_asc: null,
            displayValue: (value: any) => value ? format(new Date(value), 'dd/MM/yyy') : ''
        }
    ];
    const [columns, setColumns] = useState(columnsInit);

    const filtersData: FilterData[] = [
        { label: t("sinisters.warranty"), field: "warranty__name" },
        {
            type: "select", label: t("sinisters.status"), field: "status", options: [
                { value: "0", label: t('sinisters.status_0') },
                { value: "1", label: t('sinisters.status_1') },
                { value: "2", label: t('sinisters.status_2') },
                { value: "3", label: t('sinisters.status_3') },
            ]
        },
        { type: "period", label: t("sinisters.claim_date"), field: "claim_date" },
        { label: t("list.created_by"), field: "creator__username" },
        { label: t("list.modified_by"), field: "last_author__username" },
        { type: "period", label: t("list.created_on"), field: "created_date" },
        { type: "period", label: t("list.modified_on"), field: "modified_date" },
    ];

    useEffect(() => {
        if (permissions !== null && !checkPermissions('view_insurance')) {
            localStorage.removeItem('token');
            localStorage.removeItem('user_id');
            localStorage.removeItem('logged_user_client_id');
            navigate('/login');
        }
        if (profile?.is_superuser || profile?.client_full?.name === "Valeurs Assurances") {
            const ag_idx = columnsInit.findIndex(column => column.field === "policy_agency");
            if (ag_idx === -1) columnsInit.splice(2, 0, agency_column);
        }
        setColumns(columnsInit);
    }, [profile]);
    useEffect(() => {
        if (permissions !== null && !checkPermissions('view_insurance')) {
            localStorage.removeItem('token');
            localStorage.removeItem('user_id');
            localStorage.removeItem('logged_user_client_id');
            navigate('/login');
        }
    }, [permissions]);

    const getStatusColor = (status: number) => {
        switch (status) {
            case 0:
                return "#00457c";
            case 7:
                return "#dc001f";
            case 2:
                return "#00ae61";
            case 3:
                return "#a7a7a7";
            default:
                break;
        }
    };

    const onRowClick = (item: any) => {
        dispatch(setSelectedSinister(item));
        navigate(`/insurance/sinisters/${item.id}`);
    };

    const onAdd = () => {
        dispatch(setSelectedSinister(null));
        setOpenAdd(true);
    };

    // if (!provider_id) return <></>;

    return (
        <>
            <Grid item xs={12} style={{ display: "flex", justifyContent: "center" }}>
                <TextField label={t('sinisters.search')}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start"> <Search /> </InputAdornment>
                        ),
                        sx: { borderRadius: '35px' }
                    }}
                    variant="outlined"
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                    sx={{width: '50%'}}
                />
            </Grid>
            <GenericList
                prefModuleName="VA_SINISTERS"
                cols={columns}
                search={search}
                onAddClick={onAdd}
                dataLoader={getSinisters}
                // dataLoader={(c, l, o, f) => getSinisters(c, l, o, { ...f })}
                addPermission='add_insuranceproduct'
                onRowClick={onRowClick}
                selected_row={selected_sinister}
                cachedList={sinister_list}
                setCachedList={(list) => dispatch(setSinisterList(list))}
                filtersData={filtersData}
                labels={{
                    title: t('sinisters.title'),
                    add_button: t('sinisters.add')
                }}
            />
            <Finished />
            <SinisterCreateForm open={openAdd} onClose={() => setOpenAdd(false)} />
        </>
    );
};

export default SinistersList;